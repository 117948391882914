import styled from 'styled-components/macro';
import { Telephone } from '../../assets/iconComponents/Telephone';
import { Email } from '../../assets/iconComponents/Email';
import { Fax } from '../../assets/iconComponents/Fax';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

const getFooterPadding = (deviceType?: DeviceType) => {
  if (deviceType === 'mobile') {
    return '40px 20px 25px 24px';
  }
  if (deviceType === 'tablet') {
    return '50px 45px 25px 45px';
  }
  return '60px 0 20px 160px';
};

export const FooterStyled = styled.div<{
  deviceType: DeviceType;
}>`
  background-color: ${({ theme }) => theme.neutral100};
  text-align: left;
  height: auto;
  padding: ${({ deviceType }) => getFooterPadding(deviceType)};
`;

export const BabyPeekFooterStyled = styled.div<{
  deviceType: DeviceType;
}>`
  background-color: #1a202c;
  grid-gap: 24px;
  justify-content: center;
  align-items: center;

  display: grid;
  padding: 60px 24px 40px 24px;

  hr {
    height: 4px;
    border: 0;
    border-top: 1px solid #edf2f7;
    margin: 16px 0px;
    padding: 0px 25px;
    width: calc(100% - 10px);
    justify-self: center;
  }
`;

export const BabyPeekFooterItem = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #edf2f7;
  a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24.3px;
    text-align: left;
    color: #edf2f7;
  }

  svg {
    margin-right: 10px;
    margin-left: 10px;
  }

  path {
    fill: #edf2f7;
  }
`;

export const FooterUnityContainer = styled.div<{
  isDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding-bottom: ${({ isDesktop }) => (isDesktop ? '20px' : '0px')};
`;

export const FooterUnityImage = styled.img``;

export const FooterContactContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: ${({ deviceType }) =>
    deviceType === 'desktop' ? '15px 0 10px 0' : '30px 0 10px 0'};
`;

export const FooterDesktopContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  padding-top: 20px;
`;

export const FooterSocialContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-gap: 33px;
  padding-top: 15px;
  &:hover {
    svg {
      cursor: pointer;
    }
  }
`;

export const FooterLinksContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === 'desktop' ? '1fr 1fr 1fr' : '1fr 1fr'};
  padding: ${({ deviceType }) =>
    deviceType === 'desktop' ? '0 0 100px 0' : '7px 0 10px 0'};
  grid-gap: 10px 0;
`;

export const FooterLegalContainer = styled.div<{
  isDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding-top: ${({ isDesktop }) => (isDesktop ? '40px' : '0px')};
`;

export const FooterPrivacyContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
`;

export const FooterAccreditationContainerDesktop = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
  grid-gap: 3px;
`;

export const FooterAccredContainerNotDesktop1 = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  white-space: nowrap;
  grid-gap: 3px;
`;

export const FooterAccredContainerNotDesktop2 = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  white-space: nowrap;
  grid-gap: 3px;
`;

export const FooterHr = styled.hr`
  border: 1px solid ${({ theme }) => theme.neutral300};
  margin: 25px 0px 25px 0px;
`;

export const FooterPaddedTelephone = styled(Telephone)`
  margin-top: 1px;
`;

export const FooterPaddedEmail = styled(Email)`
  margin-top: 2px;
`;

export const FooterPaddedFax = styled(Fax)`
  margin-top: 1px;
`;
