import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '@mui/material';

import Confetti from 'react-confetti';
import { ShareBabyPeekPDFPayment } from './Services/BabyPeekService';
import {
  BabyPeekShareTraitModalWrapper,
  BabyPeekShareTraitModalTitle,
  BabyPeekShareTraitModalSubTitle,
  BabyPeekShareTraitModalSubTitleInput,
  BabyPeekFilledButton,
  BabyPeekShareErrorText,
  BabyPeekShareReceiptWrapper,
  BabyPeekReceiptContainer,
  BabyPeekShareTitle,
  BabyPeekReceiptHeader,
  BabyPeekShareTraitModalHelp,
} from './BabyPeekTrait.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { validateEmail } from '../../utils/hooks/helpers';

import { BabyPeekShareAck } from './BabyPeekStripe.styles';
import { GreenFullCheckIcon } from '../../assets/iconComponents/GreenFullCheckIcon';

export const BabyPeekDownloadModal: React.FC<{
  shareTraitModal: boolean;
  setShareTraitModal: (show: boolean) => void;
}> = ({ setShareTraitModal, shareTraitModal }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const { deviceType } = useDeviceHook();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();

  const shareTrait = async () => {
    if (!validateEmail(email)) {
      setInvalidEmail(true);
    } else if (email) {
      setLoading(true);
      const response = await ShareBabyPeekPDFPayment(
        babypeekPK,
        name ?? '',
        email,
        'en'
      );
      if (response.ok) {
        setSubmitted(true);
        setName('');
        setEmail('');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={shareTraitModal}
        onClose={() => setShareTraitModal(false)}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <>
          {submitted && (
            <BabyPeekReceiptContainer deviceType={deviceType}>
              <Confetti
                height={220}
                numberOfPieces={100}
                gravity={0.1}
                initialVelocityX={5}
                initialVelocityY={5}
                recycle
                colors={[
                  '#FF00C7',
                  '#FFCCF4',
                  '#544DFD',
                  '#FFE500',
                  '#8A69F2',
                  '#FDF075',
                ]}
                width={400}
              />

              <BabyPeekShareReceiptWrapper deviceType={deviceType}>
                <>
                  <BabyPeekReceiptHeader>
                    <>
                      {' '}
                      <GreenFullCheckIcon />
                      <BabyPeekShareTitle>
                        Your report is on its way!
                      </BabyPeekShareTitle>{' '}
                      <BabyPeekFilledButton
                        onClick={() => setShareTraitModal(false)}
                        style={{
                          justifySelf: 'center',
                          width: `100%`,
                          height: '44px',
                        }}
                      >
                        OK
                      </BabyPeekFilledButton>
                    </>
                  </BabyPeekReceiptHeader>
                </>
              </BabyPeekShareReceiptWrapper>
            </BabyPeekReceiptContainer>
          )}
          {!submitted && (
            <BabyPeekShareTraitModalWrapper deviceType={deviceType}>
              <div style={{ display: 'grid', width: '100%', gridGap: '4px' }}>
                <BabyPeekShareTraitModalTitle
                  style={{ justifyContent: 'start' }}
                >
                  {submitted ? 'Results Shared!' : 'Access Your Report'}
                </BabyPeekShareTraitModalTitle>
                <BabyPeekShareTraitModalSubTitle>
                  {`Your report will be sent via email. `}
                </BabyPeekShareTraitModalSubTitle>
              </div>

              <div style={{ display: 'grid', gridGap: '16px' }}>
                <div style={{ width: '100%' }}>
                  <BabyPeekShareTraitModalSubTitle>
                    {` Recipient's Email (Required)`}
                  </BabyPeekShareTraitModalSubTitle>
                  <BabyPeekShareTraitModalSubTitleInput
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setInvalidEmail(false);
                    }}
                  />
                  {invalidEmail && (
                    <BabyPeekShareErrorText>
                      Please enter valid email
                    </BabyPeekShareErrorText>
                  )}
                </div>
                <div style={{ width: '100%' }}>
                  <BabyPeekShareTraitModalSubTitle>
                    {`Name your report `}
                  </BabyPeekShareTraitModalSubTitle>
                  <BabyPeekShareTraitModalSubTitleInput
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <BabyPeekShareTraitModalHelp>
                    For example: Jane’s baby, Jane & John’s Baby, Baby Jo,
                    Little Angel, etc.
                  </BabyPeekShareTraitModalHelp>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto',
                    gap: '16px',
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gap: '16px',
                      marginTop: '6px',
                    }}
                  >
                    {loading ? (
                      <SpinnerLoading />
                    ) : (
                      <BabyPeekFilledButton onClick={() => shareTrait()}>
                        Share Now
                      </BabyPeekFilledButton>
                    )}
                    <BabyPeekFilledButton
                      onClick={() => setShareTraitModal(false)}
                      style={{ background: 'white', color: 'blue' }}
                    >
                      Cancel
                    </BabyPeekFilledButton>
                  </div>
                  <BabyPeekShareAck>
                    I acknowledge that my BabyPeek report does not contain any
                    UNITY medical test results. I’m responsible for the
                    distribution of my BabyPeek report.
                  </BabyPeekShareAck>
                </div>
              </div>
            </BabyPeekShareTraitModalWrapper>
          )}
        </>
      </Modal>
    </>
  );
};
