import React from 'react';
import { NegativeIcon } from '../../../assets/iconComponents/Negative';
import { NotOrderedIcon } from '../../../assets/iconComponents/NotOrdered';
import { ProcessingIcon } from '../../../assets/iconComponents/Processing';
import { EyeIcon } from '../../../assets/iconComponents/EyeIcon';
import {
  AneuploidyReportDataSerializer,
  FetalSexCodeResultSerializer,
  NoValueProvided,
  UnityReportDataSerializer,
} from '../../../recoil/types';
import { themePalette } from '../../../theme';

export interface DisplayWithIcon {
  display: string;
  icon: React.ReactElement;
}

export function GetCarrierAneuploidyInfo(
  report?: UnityReportDataSerializer | AneuploidyReportDataSerializer
): DisplayWithIcon {
  const summary = report?.summary;
  if (report?.status === 'Processing') {
    return { display: report.status, icon: <ProcessingIcon /> };
  }
  if (report?.status === 'Not Ordered') {
    return { display: report.status, icon: <NotOrderedIcon /> };
  }
  if (report?.status === 'Final' || report?.status === 'Corrected') {
    if (summary === 'Negative' || summary === 'Low Risk') {
      return { display: summary, icon: <NegativeIcon /> };
    }
    if (summary === 'See Report') {
      return { display: summary, icon: <EyeIcon /> };
    }
    if (summary === 'Test Not Performed') {
      return { display: summary, icon: <NotOrderedIcon /> };
    }
  }

  return { display: 'Not Ordered', icon: <NotOrderedIcon /> };
}

export function GetRhDInfo(
  rhdValue?:
    | 'Not Ordered'
    | 'Opted out'
    | 'Processing'
    | 'Detected'
    | 'Not Detected'
    | 'See Report'
    | 'Test Not Performed'
): DisplayWithIcon {
  let icon = <NotOrderedIcon />;

  if (rhdValue === 'Not Ordered') {
    icon = <NotOrderedIcon />;
  }
  if (rhdValue === 'Opted out') {
    icon = <NotOrderedIcon />;
  }
  if (rhdValue === 'Test Not Performed') {
    icon = <NotOrderedIcon />;
  }
  if (rhdValue === 'Processing') {
    icon = <ProcessingIcon />;
  }
  if (rhdValue === 'Not Detected') {
    icon = <NegativeIcon />;
  }
  if (rhdValue === 'Detected') {
    icon = <EyeIcon color={themePalette.resultsNegativeGreen} />;
  }
  if (rhdValue === 'See Report') {
    icon = <EyeIcon />;
  }

  return {
    display: rhdValue || 'Not Ordered',
    icon,
  };
}

export function Get22qInfo(
  del22q?: 'Opted out' | 'Processing' | 'See Report' | 'Low Risk'
): DisplayWithIcon {
  const display = del22q || 'See Report';
  if (del22q === 'Processing') {
    return { display, icon: <ProcessingIcon /> };
  }
  if (del22q === 'Opted out') {
    return { display, icon: <NotOrderedIcon /> };
  }
  if (del22q === 'See Report') {
    return {
      display,
      icon: <EyeIcon color={themePalette.redMedium} />,
    };
  }
  if (del22q === 'Low Risk') {
    return { display, icon: <NegativeIcon /> };
  }

  return {
    display,
    icon: <EyeIcon color={themePalette.redMedium} />,
  };
}

export function GetFetalSexInfo(
  fetalSexInfo?: FetalSexCodeResultSerializer,
  aneuploidyProcessing?: boolean
): DisplayWithIcon {
  if (fetalSexInfo?.value === NoValueProvided) {
    return {
      display: 'See Report',
      icon: <EyeIcon />,
    };
  }
  if (fetalSexInfo?.status === 'Not Ordered') {
    return { display: 'Opted Out', icon: <NotOrderedIcon /> };
  }
  if (fetalSexInfo?.value === 'NOT ORDERED') {
    return { display: 'Opted Out', icon: <NotOrderedIcon /> };
  }
  if (fetalSexInfo?.value === 'N/A') {
    return { display: 'N/A', icon: <NotOrderedIcon /> };
  }
  if (fetalSexInfo?.status === 'Processing' || aneuploidyProcessing) {
    return { display: 'Processing', icon: <ProcessingIcon /> };
  }
  return {
    display: 'See Report',
    icon: <EyeIcon color={themePalette.resultsNegativeGreen} />,
  };
}
