import styled from 'styled-components/macro';

import { DeviceType } from '../../utils/hooks/useDeviceHook';
import { BabyPeekV2HeaderTitleWrapper } from './Traits/Shared/BabyPeekTrait.styles';

export const BabyPeekViewResultsTileWrapperText = styled.div`
  color: var(--Grey-900, #171923);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;

export const BabyPeekViewResultsTileSeeResultsText = styled.div`
  height: 35px;
  background-color: white;
  color: #2b3ff6;
  width: 140px;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: grid;
`;

export const BabyPeekViewResultsTileWrapper = styled.div<{
  deviceType: DeviceType;
  grayed?: boolean;
  clicked?: boolean;
}>`
  display: flex;
  height: ${({ deviceType }) => (deviceType === 'mobile' ? '164px' : '170px')};
  width: ${({ deviceType }) => (deviceType === 'mobile' ? '150px' : '185px')};
  padding: 20px var(--Spacing, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing, 12px);
  flex: 1 0 0;
  border-radius: 16px;
  opacity: ${({ grayed }) => (grayed ? 0.7 : 1)};
  border: 1.469px solid var(--Grey-100, #edf2f7);
  background: ${({ grayed }) => (grayed ? '#f3f4f7' : 'white')};

  justify-self: center;
  /* Drop shadow */
  box-shadow: ${({ clicked }) =>
    clicked ? undefined : ' 0px 2px 4px 0px rgba(0, 0, 0, 0.05)'};
  cursor: pointer;

  ${BabyPeekViewResultsTileWrapperText} {
    font-size: ${({ deviceType }) =>
      deviceType === 'mobile' ? '14px' : '16px'};
  }
`;

export const BabyPeekViewResultsTileWrapperGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
`;

export const BabyPeekViewResultsTileGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === 'mobile'
      ? 'max-content max-content'
      : 'repeat(auto-fit, minmax(215px, 215px))'};
  justify-content: center;
  grid-gap: 20px;
  justify-content: center;
`;

export const BabyPeekPreviewModalWrapper = styled.div<{
  deviceType: DeviceType;
  showBlur: boolean;
  windowWidth: number;
}>`
  display: grid;
  margin: 64px;
  justify-content: center;
  background: transparent;
  border-radius: 20px;
  min-height: 650px;
  max-height: 650px;
  overflow-y: ${({ deviceType }) =>
    deviceType === 'mobile' ? 'scroll' : undefined};
  overflow-x: hidden;
  width: fit-content;
  justify-self: center;

  ${BabyPeekV2HeaderTitleWrapper} {
    filter: ${({ showBlur }) => (showBlur ? 'blur(10px)' : 'none')};
  }
  .trait-top-background {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .close-icon {
    display: grid;
    position: absolute;
    margin-top: 15px;
    margin-left: -25px;
    justify-content: flex-end;
    align-items: flex-end;
    width: ${({ deviceType, windowWidth }) =>
      deviceType === 'mobile' ? `${windowWidth + 10}px` : '720px'};
  }
`;
