import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { InfoOutlined } from '@mui/icons-material';
import {
  UpfrontPaymentAccordionContainer,
  UpfrontPaymentCost,
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentInfoBillContent,
  UpfrontPaymentInfoRow,
  UpfrontPaymentMainBanner,
  UpfrontPaymentMainBannerBottomRow,
  UpfrontPaymentMainBannerTopRow,
  UpfrontPaymentMainText,
  UpfrontPaymentPlanAccordionSummary,
  UpfrontPaymentPlanAccordionTitle,
  UpfrontPaymentPlanTitle,
  UpfrontPaymentYouHaveABill,
  UpfrontPaymentFieldRow,
  UpfrontPaymentPhoneLink,
  UpfrontPaymentSolidLine,
} from './UpfrontPayment.styles';

import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import {
  GetUpfrontPaymentPlan,
  UpfrontPaymentPlan,
  UpfrontPaymentType,
  getCentsToDollars,
} from './Services/UpfrontPaymentService';

import { GetTypographyContent } from '../shared/Typography/Typography';
import { UpfrontPaymentPlanSteps } from './UpfrontPaymentPlanSteps';
import { BillingSupportNumber } from './types';
import { BabyPeekIntroFAQAccordionWrapper } from './PaymentPlanNumPayments.styles';

export const UpfrontPaymentPlanAccordion: React.FC<{
  upfrontBill: UpfrontPaymentType;
}> = ({ upfrontBill }) => {
  const { deviceType } = useDeviceHook();
  const { billingPK, adminID } =
    useParams<{ billingPK: string; adminID?: string }>();

  const [paymentPlan, setPaymentPlan] = useState<UpfrontPaymentPlan[]>();
  const [numberPayments, setNumberPayments] = useState<'6'>();

  useEffect(() => {
    const getUpfrontPaymentPlanDetails = async () => {
      if (upfrontBill.is_payment_plan_enabled) {
        const paymentPlanResponse = await GetUpfrontPaymentPlan(
          billingPK,
          adminID
        );
        const paymentPlanJson = await paymentPlanResponse.json();
        setPaymentPlan(paymentPlanJson);
        if (paymentPlanJson) {
          setNumberPayments(paymentPlanJson.length.toString());
        }
      }
    };
    getUpfrontPaymentPlanDetails();
  }, []);

  const nextPayment = paymentPlan?.find((payment) => payment.status !== 'paid');

  const remainingPayments = paymentPlan?.filter(
    (payment) => payment.status !== 'paid'
  );

  const paidPayment = paymentPlan?.find((payment) => payment.status === 'paid');

  return (
    <>
      <UpfrontPaymentAccordionContainer deviceType={deviceType} padding>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <UpfrontPaymentPlanAccordionSummary>
              <UpfrontPaymentFieldRowTitle>
                Payment Plan
              </UpfrontPaymentFieldRowTitle>
              <UpfrontPaymentInfoBillContent>
                <UpfrontPaymentPlanTitle>
                  <UpfrontPaymentCost>
                    {getCentsToDollars(upfrontBill?.start_of_payment_amount)}
                  </UpfrontPaymentCost>
                  <UpfrontPaymentMainText>
                    {`  split up monthly`}
                  </UpfrontPaymentMainText>
                </UpfrontPaymentPlanTitle>
              </UpfrontPaymentInfoBillContent>
            </UpfrontPaymentPlanAccordionSummary>
          </AccordionSummary>
          <AccordionDetails>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Next Payment Date
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content:
                    nextPayment &&
                    moment(new Date(nextPayment.epoch * 1000)).format(
                      'MMMM Do, YYYY'
                    ),
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Next Payment
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: getCentsToDollars(nextPayment?.amount),
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  {`You've Paid `}
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: getCentsToDollars(upfrontBill?.paid_amount),
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Payment Method
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: `${paidPayment?.card_brand || ''} - ${
                    paidPayment?.last_four_digits || ''
                  }`,
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Remaining balance
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: getCentsToDollars(
                    upfrontBill?.total_patient_responsibility_amount
                  ),
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Remaining Payments
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: remainingPayments?.length,
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentSolidLine />
            <UpfrontPaymentPlanAccordionTitle>
              <div>Payment Schedule</div>{' '}
              <BabyPeekIntroFAQAccordionWrapper>{`${
                numberPayments || 6
              } Payments`}</BabyPeekIntroFAQAccordionWrapper>
            </UpfrontPaymentPlanAccordionTitle>
            {paymentPlan && <UpfrontPaymentPlanSteps steps={paymentPlan} />}
            <UpfrontPaymentSolidLine />
            <UpfrontPaymentInfoBillContent style={{ marginTop: '10px' }}>
              <UpfrontPaymentMainText>No interest</UpfrontPaymentMainText>
              <UpfrontPaymentMainText>
                {`Total: ${getCentsToDollars(
                  upfrontBill?.start_of_payment_amount
                )}`}
              </UpfrontPaymentMainText>
            </UpfrontPaymentInfoBillContent>
            <UpfrontPaymentInfoBillContent>
              <UpfrontPaymentYouHaveABill>
                <UpfrontPaymentMainBanner>
                  <UpfrontPaymentMainBannerTopRow>
                    <InfoOutlined /> Need help with the payment plan?
                  </UpfrontPaymentMainBannerTopRow>
                  <UpfrontPaymentMainBannerBottomRow>
                    {`If you have any questions or need help with your payment
                    plan, please call us at `}

                    <>
                      <a
                        href={`tel:${BillingSupportNumber}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UpfrontPaymentPhoneLink>
                          {BillingSupportNumber}
                        </UpfrontPaymentPhoneLink>
                      </a>
                      {` to discuss your options with one of our team members.`}
                    </>
                  </UpfrontPaymentMainBannerBottomRow>
                </UpfrontPaymentMainBanner>
              </UpfrontPaymentYouHaveABill>
            </UpfrontPaymentInfoBillContent>
          </AccordionDetails>
        </Accordion>
      </UpfrontPaymentAccordionContainer>
    </>
  );
};
