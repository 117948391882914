import * as React from 'react';

export const FrecklesHowItWorks = (): React.ReactElement => (
  <svg
    width="273"
    height="175"
    viewBox="0 0 273 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1168_40067)">
      <path
        d="M131.208 79.8043C108.033 96.1568 78.3612 102.02 50.3559 93.2C49.2288 91.9782 33.6056 74.4668 39.3385 52.5815C44.1792 34.1023 62.1489 20.511 83.2761 20.314C112.459 23.4161 134.378 49.6174 131.208 79.8043Z"
        fill="#E7896E"
      />
      <path
        d="M50.3559 93.2C38.0019 89.3107 28.9413 83.5345 23.7921 79.8043C20.1811 45.4251 49.0371 16.6587 83.2761 20.3139C59.7724 29.3536 44.0323 60.3881 50.3559 93.2Z"
        fill="#DD6642"
      />
      <path
        d="M119.771 166.782C119.771 171.479 115.966 175 111.54 175H105.67L103.331 174.08L100.992 175H75.3138L57.4888 159.309L70.236 139.588C80.9819 141.265 91.5767 139.123 100.402 134.208C112.058 141.107 119.771 153.118 119.771 166.782Z"
        fill="#83DEFF"
      />
      <path
        d="M75.3137 175H54.0085L51.6692 171.881L49.33 175H43.46C38.9502 175 35.2386 171.373 35.2292 166.866C35.2292 153.133 42.9017 141.132 54.5979 134.211C59.5288 136.949 64.8279 138.743 70.2362 139.588C64.3063 151.144 66.6243 167.636 75.3137 175Z"
        fill="#2ECEFB"
      />
      <path
        d="M100.402 134.208C94.2533 144.909 80.2503 149.545 68.0837 145.211L63.8872 135.404L79.5583 130.426H92.1211C95.0471 131.415 97.8228 132.679 100.402 134.208Z"
        fill="#FFCCBF"
      />
      <path
        d="M79.5586 130.426C73.5972 132.704 69.7413 139.049 68.0841 145.211C62.264 143.137 57.4736 139.201 54.5979 134.211C54.5979 134.18 58.0975 131.998 62.6885 130.426H79.5586Z"
        fill="#FFBBAE"
      />
      <path
        d="M123.169 96.77C129.321 99.3642 136.209 94.8489 136.209 88.1106C136.209 79.605 125.711 75.4881 119.927 81.7244L123.169 96.77Z"
        fill="#FFCCBF"
      />
      <path
        d="M31.8311 96.77C25.6786 99.3642 18.7908 94.8489 18.7908 88.1106C18.7908 80.7415 26.8771 76.3106 33.0671 80.0845L31.8311 96.77Z"
        fill="#FFBBAE"
      />
      <path
        d="M124.468 86.3915C124.146 112.843 101.763 134.223 74.7461 132.648C58.2622 128.712 45.8352 116.432 43.202 101.519C40.8534 88.2167 47.1412 77.6092 49.0963 74.571C50.9956 73.2922 52.5357 71.9348 53.7526 70.6972C55.3362 69.0869 57.8571 68.8756 59.7192 70.1544C68.0248 75.8527 74.8614 77.1127 79.8486 77.0597C87.6583 76.9726 88.9558 73.6821 96.2883 74.7111C104.84 75.9088 110.133 82.73 119.262 81.7506C122.05 81.4527 124.504 83.5885 124.468 86.3915Z"
        fill="#FFDED0"
      />
      <path
        d="M74.7461 132.648C49.2395 131.185 29.6372 109.487 30.5575 84.0958C30.6447 81.7284 32.4568 79.773 34.8148 79.5766C39.8629 79.1624 44.7827 77.4838 49.0963 74.5706C47.7125 99.795 58.6948 121.91 74.7461 132.648Z"
        fill="#FFCCBF"
      />
      <path
        d="M105.67 163.769V175H100.992V163.769C100.992 162.478 102.04 161.43 103.331 161.43C104.625 161.43 105.67 162.478 105.67 163.769Z"
        fill="#2ECEFB"
      />
      <path
        d="M54.0085 163.769V175H49.3301V163.769C49.3301 162.478 50.3748 161.43 51.6693 161.43C52.9605 161.43 54.0085 162.478 54.0085 163.769Z"
        fill="#00BDFD"
      />
      <path
        d="M49.3197 102.201C50.6167 102.201 51.6681 101.149 51.6681 99.8525C51.6681 98.5555 50.6167 97.5042 49.3197 97.5042C48.0228 97.5042 46.9714 98.5555 46.9714 99.8525C46.9714 101.149 48.0228 102.201 49.3197 102.201Z"
        fill="#FFBBAE"
      />
      <path
        d="M47.3486 110.697C48.6455 110.697 49.6969 109.645 49.6969 108.348C49.6969 107.051 48.6455 106 47.3486 106C46.0516 106 45.0002 107.051 45.0002 108.348C45.0002 109.645 46.0516 110.697 47.3486 110.697Z"
        fill="#FFBBAE"
        fillOpacity="0.64"
      />
      <path
        d="M56.3647 106.897C57.6616 106.897 58.713 105.846 58.713 104.549C58.713 103.252 57.6616 102.201 56.3647 102.201C55.0677 102.201 54.0164 103.252 54.0164 104.549C54.0164 105.846 55.0677 106.897 56.3647 106.897Z"
        fill="#FFBBAE"
      />
      <path
        d="M63.4101 102.201C64.707 102.201 65.7584 101.149 65.7584 99.8525C65.7584 98.5555 64.707 97.5042 63.4101 97.5042C62.1131 97.5042 61.0618 98.5555 61.0618 99.8525C61.0618 101.149 62.1131 102.201 63.4101 102.201Z"
        fill="#FFBBAE"
      />
      <path
        d="M65.3486 110.697C66.6455 110.697 67.6969 109.645 67.6969 108.348C67.6969 107.051 66.6455 106 65.3486 106C64.0516 106 63.0002 107.051 63.0002 108.348C63.0002 109.645 64.0516 110.697 65.3486 110.697Z"
        fill="#FFBBAE"
        fillOpacity="0.64"
      />
      <path
        d="M105.681 102.201C106.978 102.201 108.029 101.149 108.029 99.8525C108.029 98.5555 106.978 97.5042 105.681 97.5042C104.384 97.5042 103.332 98.5555 103.332 99.8525C103.332 101.149 104.384 102.201 105.681 102.201Z"
        fill="#FFBBAE"
      />
      <path
        d="M106.349 109.697C107.645 109.697 108.697 108.645 108.697 107.348C108.697 106.051 107.645 105 106.349 105C105.052 105 104 106.051 104 107.348C104 108.645 105.052 109.697 106.349 109.697Z"
        fill="#FFBBAE"
        fillOpacity="0.64"
      />
      <path
        d="M98.6355 106.897C99.9324 106.897 100.984 105.846 100.984 104.549C100.984 103.252 99.9324 102.201 98.6355 102.201C97.3385 102.201 96.2872 103.252 96.2872 104.549C96.2872 105.846 97.3385 106.897 98.6355 106.897Z"
        fill="#FFBBAE"
      />
      <path
        d="M91.5903 102.201C92.8872 102.201 93.9386 101.149 93.9386 99.8525C93.9386 98.5555 92.8872 97.5042 91.5903 97.5042C90.2933 97.5042 89.2419 98.5555 89.2419 99.8525C89.2419 101.149 90.2933 102.201 91.5903 102.201Z"
        fill="#FFBBAE"
      />
      <path
        d="M90.3486 110.697C91.6455 110.697 92.6969 109.645 92.6969 108.348C92.6969 107.051 91.6455 106 90.3486 106C89.0516 106 88.0002 107.051 88.0002 108.348C88.0002 109.645 89.0516 110.697 90.3486 110.697Z"
        fill="#FFBBAE"
        fillOpacity="0.64"
      />
      <path
        d="M52.0728 92.819C51.2231 91.846 51.3233 90.3681 52.296 89.5186C53.2689 88.6688 54.7466 88.7684 55.5967 89.7417C56.4268 90.6923 57.8984 90.6911 58.7263 89.7387C59.5749 88.7636 61.0525 88.6613 62.0264 89.5086C63.0012 90.3563 63.1042 91.8339 62.2565 92.8087C59.5667 95.9021 54.7693 95.9063 52.0728 92.819Z"
        fill="#495560"
      />
      <path
        d="M92.7467 92.7807C91.8969 91.8077 91.9971 90.3297 92.9698 89.4803C93.9431 88.6305 95.4204 88.7301 96.2705 89.7034C97.1006 90.654 98.5719 90.6524 99.4005 89.7003C100.248 88.7255 101.727 88.6229 102.7 89.4706C103.675 90.3185 103.778 91.7962 102.93 92.7707C100.24 95.8637 95.4428 95.868 92.7467 92.7807Z"
        fill="#495560"
      />
      <path
        d="M77.2799 95.3041C77.9805 94.6937 79.0428 94.7669 79.6532 95.4672C80.2634 96.1675 80.1905 97.2299 79.4902 97.8405C77.9043 99.2224 77.9043 101.68 79.4902 103.061C80.1905 103.672 80.2634 104.734 79.6532 105.435C79.0428 106.135 77.9803 106.208 77.2799 105.598C74.1562 102.876 74.1562 98.0262 77.2799 95.3041Z"
        fill="#495560"
      />
    </g>
    <g clipPath="url(#clip1_1168_40067)">
      <path
        d="M256.115 62.4417C239.668 74.0467 218.611 78.2078 198.736 71.9483C197.937 71.0812 186.849 58.6538 190.918 43.1223C194.353 30.0081 207.106 20.3626 222.099 20.2228C242.81 22.4243 258.365 41.0188 256.115 62.4417Z"
        fill="#F6D989"
      />
      <path
        d="M198.736 71.9483C189.969 69.1882 183.539 65.089 179.885 62.4417C177.322 38.0436 197.801 17.6287 222.099 20.2227C205.419 26.638 194.249 48.6625 198.736 71.9483Z"
        fill="#EDC06F"
      />
      <path
        d="M247.999 124.168C247.999 127.501 245.298 130 242.157 130H237.992L236.332 129.347L234.671 130H216.448L203.798 118.865L212.845 104.869C220.471 106.059 227.99 104.539 234.253 101.051C242.525 105.947 247.999 114.471 247.999 124.168Z"
        fill="#8397FF"
      />
      <path
        d="M216.448 130H201.329L199.668 127.787L198.008 130H193.843C190.642 130 188.008 127.426 188.001 124.227C188.001 114.482 193.446 105.964 201.747 101.053C205.246 102.996 209.007 104.269 212.845 104.869C208.637 113.07 210.282 124.774 216.448 130Z"
        fill="#5872FB"
      />
      <path
        d="M234.253 101.051C229.889 108.645 219.952 111.935 211.317 108.86L208.339 101.9L219.461 98.3668H228.376C230.453 99.0685 232.423 99.9659 234.253 101.051Z"
        fill="#FFCCBF"
      />
      <path
        d="M219.461 98.3668C215.23 99.9833 212.494 104.486 211.318 108.86C207.187 107.388 203.788 104.594 201.747 101.053C201.747 101.031 204.23 99.4825 207.489 98.3668H219.461Z"
        fill="#FFBBAE"
      />
      <path
        d="M250.41 74.4819C254.776 76.3229 259.665 73.1185 259.665 68.3366C259.665 62.3003 252.214 59.3786 248.11 63.8044L250.41 74.4819Z"
        fill="#FFCCBF"
      />
      <path
        d="M185.59 74.4819C181.223 76.3229 176.335 73.1185 176.335 68.3365C176.335 63.1068 182.074 59.9624 186.467 62.6406L185.59 74.4819Z"
        fill="#FFBBAE"
      />
      <path
        d="M251.332 67.1165C251.104 85.8887 235.219 101.061 216.046 99.9438C204.347 97.1504 195.528 88.4356 193.659 77.8518C191.993 68.4118 196.455 60.8839 197.842 58.7277C199.19 57.8202 200.283 56.8568 201.147 55.9786C202.271 54.8358 204.06 54.6858 205.381 55.5933C211.276 59.6373 216.127 60.5315 219.667 60.4939C225.209 60.4321 226.13 58.0969 231.334 58.8272C237.403 59.6771 241.159 64.518 247.638 63.8229C249.616 63.6115 251.358 65.1272 251.332 67.1165Z"
        fill="#FFDED0"
      />
      <path
        d="M216.046 99.9438C197.944 98.9057 184.033 83.507 184.686 65.4874C184.748 63.8073 186.034 62.4196 187.707 62.2802C191.29 61.9863 194.781 60.795 197.843 58.7275C196.86 76.6287 204.654 92.3231 216.046 99.9438Z"
        fill="#FFCCBF"
      />
      <path
        d="M237.992 122.029V130H234.671V122.029C234.671 121.113 235.415 120.369 236.332 120.369C237.25 120.369 237.992 121.113 237.992 122.029Z"
        fill="#3453F3"
      />
      <path
        d="M201.329 122.029V130H198.008V122.029C198.008 121.113 198.75 120.369 199.668 120.369C200.585 120.369 201.329 121.113 201.329 122.029Z"
        fill="#3453F3"
      />
      <path
        d="M199.955 71.6781C199.352 70.9876 199.423 69.9387 200.113 69.3359C200.804 68.7328 201.852 68.8035 202.456 69.4942C203.045 70.1688 204.089 70.168 204.677 69.4921C205.279 68.8001 206.328 68.7274 207.019 69.3288C207.71 69.9303 207.784 70.979 207.182 71.6708C205.273 73.8661 201.868 73.8691 199.955 71.6781Z"
        fill="#495560"
      />
      <path
        d="M228.82 71.6508C228.217 70.9603 228.288 69.9114 228.979 69.3085C229.669 68.7055 230.718 68.7762 231.321 69.4669C231.91 70.1415 232.954 70.1404 233.542 69.4647C234.144 68.7729 235.193 68.7001 235.884 69.3017C236.576 69.9034 236.649 70.9521 236.047 71.6437C234.138 73.8387 230.734 73.8417 228.82 71.6508Z"
        fill="#495560"
      />
      <path
        d="M217.358 73.6319C217.904 73.1559 218.733 73.213 219.209 73.759C219.684 74.3051 219.628 75.1334 219.082 75.6096C217.845 76.6871 217.845 78.603 219.082 79.6805C219.628 80.1563 219.684 80.9846 219.209 81.5311C218.733 82.0769 217.904 82.1341 217.358 81.6582C214.922 79.5355 214.922 75.7543 217.358 73.6319Z"
        fill="#495560"
      />
    </g>
    <defs>
      <clipPath id="clip0_1168_40067">
        <rect
          width="155"
          height="155"
          fill="white"
          transform="translate(0 20)"
        />
      </clipPath>
      <clipPath id="clip1_1168_40067">
        <rect
          width="110"
          height="110"
          fill="white"
          transform="translate(163 20)"
        />
      </clipPath>
    </defs>
  </svg>
);
