import styled from 'styled-components/macro';

import {
  BabyPeekButtonGradient,
  BabyPeekButtonGradientContent,
} from './BabyPeekIntro.styles';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const BabyPeekViewResultsMainTitleHeader = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  grid-gap: 16px;
  background: white;
  padding-top: 32px;
  padding-left: 20px;
`;

export const BabyPeekViewResultsTitleMobile = styled.div`
  font-size: 32px;
  font-family: Poppins;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: 0.96px;
  color: ${({ theme }) => theme.greyBase};
`;
export const BabyPeekViewResultsTitleDescription = styled.div`
  color: var(--Grey-700, #2d3748);
  font-feature-settings: 'case' on;
  /* Text/Medium/Mobile */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
`;

export const BabyPeekViewDownload = styled.div`
  padding: var(--Spacing, 12px) 15px;

  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  border-radius: var(--Media-Border-Radius, 16px);

  border: 1px solid var(--Grey-100, #edf2f7);
  background: var(--Base-White, #fff);
  /* Drop shadow */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
`;

export const BabyPeekViewDownloadSubTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  font-feature-settings: 'case' on;
  /* Text/Small/Mobile */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const BabyPeekDownloadTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  /* Text/Regular/Mobile Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

export const BabyPeekViewResultsMobileWrapper = styled.div`
  overflow-x: hidden;
  background: white;

  ${BabyPeekButtonGradient} {
    height: 49px;
  }

  ${BabyPeekButtonGradientContent} {
    font-size: 16px;
    height: 44px;
  }
  .thumbnail {
    display: none;
    position: absolute;
    top: 0;
    z-index: -5000;
    width: 0px;
    height: 0px;
    img {
      object-fit: cover;
      width: 0px;
      height: 0px;
    }
  }
`;

export const BabyPeekMobleTraitsMenuItem = styled.div<{ selected: boolean }>`
  color: #2d3748;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  line-height: 150%; /* 25px */
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const transitionName = 'mobile-menu';
const appearDuration = 400;
export const BabyPeekMobleTraitsMenuContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(12, auto);
  grid-gap: 2px;

  background-color: ${({ theme }) => theme.white};
  margin-left: -16px;
  width: calc(100% + 16px);
  height: calc(100vh - 130px);
  z-index: 1000;
  &.${transitionName}-enter {
    opacity: 0.01;
  }
  &.${transitionName}-enter.${transitionName}-enter-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-in;
  }
  &.${transitionName}-exit {
    opacity: 1;
  }
  &.${transitionName}-exit.${transitionName}-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

export const BabyPeekExperienceGradientContent = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: ${({ deviceType }) => (deviceType !== 'desktop' ? '24px' : '40px')};
  background: white;
  border-radius: 12px;
  color: var(--Grey-900, #171923);
  /* Text/Medium/Desktop */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  font-style: normal;
  justify-content: center;

  cursor: pointer;
  width: calc(100% - 5px);
  height: 100%;

  label {
    width: 50px;
  }

  .MuiRating-root {
    display: flex;
  }
`;

export const BabyPeekExperienceGradientRatingGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 24px;

  div {
    align-items: center;
    display: flex;
  }
`;

export const BabyPeekExperienceGradientContentGrid = styled.div<{
  deviceType: DeviceType;
  $disabled?: boolean;
}>`
  .MuiRating-root {
    display: flex;
    margin-bottom: 36px;
  }

  ${BabyPeekButtonGradient} {
    width: 90%;
    opacity: ${({ $disabled }) => ($disabled ? '.3' : undefined)};
  }

  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #cbd5e0;
    margin: 20px 0px;
    padding: 0px 25px;

    justify-self: center;
  }
`;

export const BabyPeekExperienceGradientSectionGrid = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

export const BabyPeekExperienceGradientCheckboxGrid = styled.div`
  width: 100%;

  display: grid;
  align-items: center;

  .checkbox {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  input[type='checkbox']:checked + label::before {
    content: '';
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    top: 5px;
    background-color: #fa9e57;
    font-family: 'Montserrat';
    border-radius: 2px;
    border: 1px solid rgb(150 150 150 / 30%);
  }

  input[type='checkbox']:checked + label::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
  }

  color: var(--grey-base, #231f20);
  color: #272727;

  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  grid-template-columns: max-content auto;
  margin-bottom: 36px;
`;

export const BabyPeekExperienceGradientText = styled.div<{
  deviceType: DeviceType;
}>`
  color: var(--Grey-900, #171923);
  font-feature-settings: 'case' on;
  /* Text/Medium/Mobile */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 12px;
`;

export const BabyPeekExperienceThankText = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 24px;
  width: 100%;
`;

export const BabyPeekExperienceGradientTextTitle = styled.div`
  color: var(--Grey-900, #171923);
  text-align: center;
  font-feature-settings: 'case' on;
  /* Text/Medium/Mobile */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
`;

export const BabyPeekExperienceTextField = styled.textarea`
  display: flex;
  height: 120px;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grey-300, #cbd5e0);
  background: var(--Base-White, #fff);
  text-align: center;
  resize: none;
  margin-bottom: 36px;
`;

export const BabyPeekExperienceButtonWrapper = styled.div`
  display: grid;
  ${BabyPeekButtonGradient} {
    justify-self: center;
  }
`;
