import React, { useState } from 'react';
import { DetailedResultsForwardFetalSex } from '../../components/DetailedResults/DetailedResultsForwardFetalSex';
import { DetailedResultsFetalSexForwardConfirm } from '../../components/DetailedResults/DetailedResultsForwardFetalSexConfirmation';
import { ForwardFetalSexWrapper } from './Aneuploidy.styles';

const FetalSexForwardPage: React.FC<{
  setForwardFetalSex: (forward: boolean) => void;
  requisitionID: string;
}> = ({ setForwardFetalSex, requisitionID }) => {
  const [fetalSexSent, setFetalSexSent] = useState(false);
  const [confirmedEmail, setConfirmedEmail] = useState('');

  if (fetalSexSent) {
    return (
      <ForwardFetalSexWrapper>
        <DetailedResultsFetalSexForwardConfirm
          confirmedEmail={confirmedEmail}
        />
      </ForwardFetalSexWrapper>
    );
  }
  return (
    <ForwardFetalSexWrapper>
      <DetailedResultsForwardFetalSex
        requsitionID={requisitionID}
        setForwardFetalSex={setForwardFetalSex}
        setFetalSexSent={setFetalSexSent}
        setConfirmedEmail={setConfirmedEmail}
      />
    </ForwardFetalSexWrapper>
  );
};

export default FetalSexForwardPage;
