import styled from 'styled-components/macro';

const transitionName = 'mobile-menu';
const appearDuration = 400;
export const MenuContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto auto 100vh;
  grid-gap: 2px;
  place-items: start;
  background-color: ${({ theme }) => theme.blueDark};
  margin-left: -16px;
  width: calc(100% + 32px);
  height: calc(100vh - 15px);
  z-index: 1000;
  &.${transitionName}-enter {
    opacity: 0.01;
  }
  &.${transitionName}-enter.${transitionName}-enter-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-in;
  }
  &.${transitionName}-exit {
    opacity: 1;
  }
  &.${transitionName}-exit.${transitionName}-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

export const MobileMenuHr = styled.hr`
  border: 1px solid ${({ theme }) => theme.blueMedium};
  margin-left: 10px;
  width: calc(100vw - 21px);
`;

export const MobileMenuLinkHrContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 15px;
`;
