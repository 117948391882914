import { Typography } from '@mui/material';
import styled, { DefaultTheme } from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const FileUploaderWrapper = styled.div``;

export const FileUploaderTitle = styled.div<{ isError: boolean }>`
  font-size: 16px !important;
  font-weight: bold;
  color: ${({ theme, isError }) => (isError ? theme.redDark : theme.blueDark)};
  margin-top: 8px;
  font-weight: 400;
  line-height: 24px;
`;

export const FileUploaderDropzoneWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  justify-content: center;
  display: flex;

  .dzu-dropzoneActive {
    opacity: 0.7;
  }

  .dzu-inputLabelWithFiles {
    color: ${({ theme }) => theme.redMedium};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.redMedium};
    border-radius: 3rem;
    width: 100px;
    font-size: 14px !important;
    margin: 10px 10px !important;
    display: ${({ deviceType }) =>
      deviceType === 'mobile' ? 'none' : undefined};
  }
`;

export const ProfileInsuranceFileUploaderDropzoneAntigen = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.neutral400};
  padding: 35px 0px;
  grid-column-gap: 10px;
`;

export const ProfileInsuranceFileUploaderMobile = styled.div<{
  financialAssistance?: boolean;
}>`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme, financialAssistance }) =>
    financialAssistance ? theme.greenDark : theme.redMedium};
`;

export const ProfileInsuranceFileUploaderGreyText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
  text-align: center;
`;

export const FileUploaderBrowse = styled(Typography)<{
  upfrontPayment?: boolean;
}>`
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme, upfrontPayment }) =>
    upfrontPayment ? theme.greenDark : theme.redMedium};
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 3rem;
  padding: 11px 16px;
  width: 140px;
  font-size: 14px !important;
  margin: auto !important;
  text-transform: uppercase;
  font-weight: bold !important;

  button {
    padding: 4px 16px;
    font-family: light !important;
  }

  :hover {
    opacity: 0.9;
  }
`;

const getUploadBorder = (validUpload?: boolean) => {
  if (validUpload === undefined) {
    return '2px dashed orange';
  }
  if (validUpload) {
    return '2px dashed green';
  }
  return '2px dashed red';
};

const getPreviewBackground = (theme: DefaultTheme, validUpload?: boolean) => {
  if (validUpload === undefined) {
    return theme.yellowPastel;
  }
  if (validUpload) {
    return theme.greenPastel;
  }
  return theme.redPastel;
};

export const FileUploaderPreview = styled.div<{
  validUpload?: boolean;
  previewUrl?: string;
}>`
  display: grid;
  grid-template-columns: auto auto;
  border-radius: 6px;
  background-color: ${({ validUpload, theme }) =>
    getPreviewBackground(theme, validUpload)};
  height: auto;
  min-width: 275px;
  width: 90%;
  padding: 10px 10px;
  border: ${({ validUpload }) => getUploadBorder(validUpload)};
  margin: 8px 0px 0px 0px;

  img {
    width: 80px;
    height: auto;
  }
`;

export const FileUploaderPreviewButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 10px;
  color: ${({ theme }) => theme.redMedium};
  width: 82px;
  right: 16px;
  top: 25%;
  bottom: 25%;
  border: 1px solid ${({ theme }) => theme.redMedium};
  border-radius: 30px;
  cursor: pointer;
  font-weight: 700;

  :hover {
    padding: 5px 17px;
  }
`;

export const FileUploaderPreviewMobileWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-self: flex-start;
  align-items: center;
  grid-row-gap: 20px;

  ${FileUploaderPreviewButton} {
    justify-self: center;
  }
  padding: 10px;
`;

export const FileUploaderPreviewMobileImage = styled.div`
  img {
    width: 300px;
    height: 200px;
    border: 2px dashed ${({ theme }) => theme.neutral200};
    border-radius: 12px;
  }
`;

export const FileUploaderPreviewTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-self: flex-start;
  align-items: center;
  grid-column-gap: 20px;
`;
export const FileNameUploaded = styled.div`
  width: 10vw !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
`;

export const FileUploaderPreviewDelete = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-self: flex-end;
  align-items: center;
  grid-column-gap: 20px;
`;

export const FileUploaderPreviewError = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.redMedium};
`;
