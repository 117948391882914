import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ProfileResetButtonWrapper,
  ProfileResetPasswordWrapper,
} from './Profile.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { CreateAccountPasswordInfo } from '../CreateAccount/CreateAccountPasswordInfo';
import { StyledButton } from '../shared/Button/Button.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Password } from '../shared/TextField/Password';
import { UpdatePasswordRequest } from './Services/UpdateProfileInfoService';
import { getUserAuth } from '../../recoil/selectors';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { networkErrorMessage } from '../../utils/helpers';

export const ProfileResetPassword: React.FC<{
  setResetPassword: (reset: boolean) => void;
}> = ({ setResetPassword }) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [isPw8Chars, setIsPw8Chars] = useState(false);
  const [isPwUpperChar, setIsPwUpperChar] = useState(false);
  const [isPwLowerChar, setIsPwLowerChar] = useState(false);
  const [isPwDigit, setIsPwDigit] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getLoadingOrMessage = () => {
    if (isLoading) {
      return <SpinnerLoading />;
    }
    if (responseMessage) {
      return (
        <ErrorPopup
          message={responseMessage}
          hideMessage={setResponseMessage}
          closeMethod={() => setResetPassword(false)}
        />
      );
    }
  };

  const checkPasswordRequirements = (input: string): boolean => {
    const pw8Chars = input.length >= 8;
    const pwUpperChar = /[A-Z]/.test(input);
    const pwLowerChar = /[a-z]/.test(input);
    const pwDigit = /[0-9]/.test(input);
    setIsPw8Chars(pw8Chars);
    setIsPwUpperChar(pwUpperChar);
    setIsPwLowerChar(pwLowerChar);
    setIsPwDigit(pwDigit);
    return pw8Chars && pwUpperChar && pwLowerChar && pwDigit;
  };

  const submitNewPassword = async (
    passwordOld: string,
    passwordNew: string
  ) => {
    if (!passwordOld) {
      setCurrentPasswordError('Required');
    } else {
      setIsLoading(true);
      try {
        const response = await UpdatePasswordRequest(
          {
            new_password: passwordNew,
            password: passwordOld,
            email: auth?.email,
          },
          auth?.access_token
        );
        setIsLoading(false);
        if (response.ok) {
          setResponseMessage('Password updated successfully!');
          setNewPassword('');
          setCurrentPassword('');
        } else {
          setResponseMessage('There was an error - please try again.');
        }
      } catch (e) {
        setIsLoading(false);
        setResponseMessage(networkErrorMessage);
      }
    }
  };

  return (
    <ProfileResetPasswordWrapper deviceType={deviceType}>
      {GetTypographyContent({
        content: 'Edit password',
        size: 'xlarge',
        textColor: 'greyMedium',
        isBold: true,
      })}
      <Password
        size="small"
        label="Current password"
        value={currentPassword}
        onChange={(e) => {
          setCurrentPasswordError('');
          setCurrentPassword(e.target.value);
        }}
        error={Boolean(currentPasswordError)}
        helperText={currentPasswordError}
      />
      <Password
        size="small"
        label="Choose new password"
        value={newPassword}
        onChange={(e) => {
          checkPasswordRequirements(e.target.value);
          setNewPassword(e.target.value);
        }}
      />
      <CreateAccountPasswordInfo
        isPw8Chars={isPw8Chars}
        isPwDigit={isPwDigit}
        isPwUpperChar={isPwUpperChar}
        isPwLowerChar={isPwLowerChar}
      />
      {getLoadingOrMessage() || (
        <ProfileResetButtonWrapper deviceType={deviceType}>
          <StyledButton
            $reverse
            size="small"
            $backgroundColor="white"
            $textColor="redMedium"
            $borderColor="redMedium"
            onClick={() => setResetPassword(false)}
          >
            <div>
              {GetTypographyContent({
                content: 'Cancel',
                size: 'medium',
                textColor: 'redMedium',
                isCaps: true,
                padding: '0 0 0 3px',
              })}
            </div>
          </StyledButton>
          <StyledButton
            size="small"
            $backgroundColor="redMedium"
            $textColor="white"
            disabled={
              !isPw8Chars || !isPwDigit || !isPwUpperChar || !isPwLowerChar
            }
            onClick={() => submitNewPassword(currentPassword, newPassword)}
          >
            <div>
              {GetTypographyContent({
                content: 'Update',
                size: 'medium',
                textColor: 'white',
                isCaps: true,
                padding: '0 0 0 3px',
              })}
            </div>
          </StyledButton>
        </ProfileResetButtonWrapper>
      )}
    </ProfileResetPasswordWrapper>
  );
};
