import styled from 'styled-components/macro';

import { Accordion } from '@mui/material';

import { DeviceType } from '../../utils/hooks/useDeviceHook';
import {
  BabyPeekButtonGradient,
  BabyPeekButtonGradientContent,
} from './BabyPeekIntro.styles';

import {
  BabyPeekFilledButton,
  BabyPeekShareOutlineButton,
} from './BabyPeekTrait.styles';

export const BabyPeekPaymentAccordion = styled(Accordion)<{
  deviceType: DeviceType;
}>`
  box-shadow: none !important;

  div {
    box-shadow: none;
  }

  &:before {
    background-color: transparent !important;
  }
  .MuiButtonBase-root {
    padding: 0px 0px;
    height: 24px;
    max-height: 24px;
    min-height: 24px;

    .MuiSvgIcon-root {
      display: none;
    }
  }

  .MuiPaper-root:before {
    background-color: transparent !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: 24px;
    max-height: 24px;
    min-height: 24px;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0px 0px;
  }

  .MuiAccordionDetails-root {
    padding: 16px 16px 16px 0px !important;
  }
`;

export const BabyPeekPaymentSummaryItemGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px;
  align-items: center;
`;

export const BabyPeekSummaryDropDownTitle = styled.div`
  color: ${({ theme }) => theme.greyBase};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: Poppins;

  svg {
    color: ${({ theme }) => theme.greyBase};
  }
`;

export const BabyPeekPaymentSummaryTitleTrait = styled.div<{
  deviceType: DeviceType;
}>`
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const BabyPeekPaymentSummaryDescription = styled.div<{
  deviceType: DeviceType;
}>`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const BabyPeekPaymentSummaryGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 20px;

  ${BabyPeekPaymentSummaryTitleTrait} {
    font-size: 16px;
  }
  ${BabyPeekPaymentSummaryDescription} {
    font-size: 14px;
  }
`;

export const BabyPeekPaymentSummaryItemGridRow = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  text-align: left;
`;

export const BabyPeekPaymentSummaryTitle = styled.div`
  color: var(--grey-base, #231f20);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  cursor: pointer;
`;

export const BabyPeekPaymentLogoImg = styled.img`
  cursor: pointer;
`;

export const BabyPeekPaymentDropdownGridPaymentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  padding: 16px;

  align-self: stretch;
  border-radius: ${({ deviceType }) =>
    deviceType !== 'mobile' ? '16px' : '0px'};
  border: 1px solid var(--grey-light, #dadada);
  background: var(--basic-white, #fff);
  height: min-content;

  hr {
    display: block;
    border: 0;
    border: 1px solid #e2e8f0;
    padding: 0;
    margin-top: 15px;
  }
`;

export const BabyPeekPaymentDropdownGrid = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: flex-start;
  gap: 24px;
  img {
    height: 40px;
  }
`;

export const BabyPeekPaymentDropdownGridLeft = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 16px;
  width: 100%;
`;

export const BabyPeekCheckoutTitle = styled.div`
  color: var(--grey-dark, #231f20);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  margin-left: 16px;
  padding: 24px 0px;
`;

export const BabyPeekPaymentDropdownWrapper = styled.div<{
  deviceType: DeviceType;
  termsOnly: boolean;
}>`
  padding: ${({ deviceType }) =>
    deviceType === 'desktop' ? '24px 24px' : '0px 0px'};
  display: ${({ deviceType, termsOnly }) =>
    deviceType === 'desktop' && !termsOnly ? 'flow' : 'grid'};
  justify-content: center;
`;

const getStripePadding = () => '16px 16px';

const getStripeHeight = (isGifting: boolean, deviceType: DeviceType) => {
  if (isGifting) {
    return '450px';
  }

  return deviceType === 'mobile' ? '400px' : 'auto';
};

export const BabyPeekPaymentDropdownStripeWrapper = styled.div<{
  deviceType: DeviceType;
  isGifting: boolean;
}>`
  background: 'white';
  ${BabyPeekButtonGradient} {
    width: 90%;
    justify-self: center;
  }

  margin-top: ${({ deviceType }) => (deviceType === 'tablet' ? '25px' : '0px')};

  .StripeElement {
    background: white;
    padding: ${() => getStripePadding()};
    height: ${({ deviceType, isGifting }) =>
      getStripeHeight(isGifting, deviceType)};
  }
`;

const getGridMainParam = (
  deviceType: DeviceType,
  termsOnly: boolean,
  column: boolean,
  savedPayment: boolean
) => {
  if (deviceType === 'desktop' && savedPayment && !column) {
    return termsOnly ? 'auto' : 'auto auto';
  }
  if (deviceType === 'desktop' && column && !savedPayment) {
    return termsOnly ? '100%' : '65% 30%';
  }
  if (deviceType !== 'desktop' && !column) {
    return termsOnly ? 'auto' : 'auto auto';
  }
};

export const BabyPeekPaymentPromotionGradientContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  border-radius: 24px;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  line-height: 100%;
  font-weight: 600;
  padding: 24px 24px;

  svg {
    margin-right: 8px;
  }
`;

export const BabyPeekPaymentPromotionGradient = styled.div<{
  deviceType: DeviceType;
}>`
  border-radius: 24px;

  /* Card shadow */
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  margin: ${({ deviceType }) => (deviceType === 'desktop' ? '64px' : '16px')};
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
  width: ${({ deviceType }) =>
    deviceType === 'desktop' ? '-webkit-fill-available;' : undefined};
  justify-self: center;

  ${BabyPeekPaymentPromotionGradientContent} {
    width: calc(100% - 10px);
    justify-self: center;
    align-self: center;
    height: calc(100% - 10px);
  }
`;

export const BabyPeekPaymentGradientContentSpecialGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 24px;
`;

export const BabyPeekPaymentGradientTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: center;
  text-align: left;
  gap: 40px;
`;

export const BabyPeekPaymentGradientTitleMobile = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  align-items: start;
  text-align: left;
  gap: 16px;
`;

export const BabyPeekPaymentPromotionalPriceGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  text-align: right !important;
`;

export const BabyPeekPaymentGradientTitleStrikeThrough = styled.div`
  color: var(--grey-mid, #231f20);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  text-decoration-line: line-through;
`;

export const BabyPeekPaymentValue = styled.div`
  color: var(--grey-base, #231f20);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  align-self: start;
  margin-top: 25px;
  justify-content: end;
`;

export const BabyPeekFreePromotion = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 8px;
`;

export const BabyPeekFreePromotionColumns = styled.div<{
  freePromotion?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ freePromotion }) =>
    freePromotion ? 'auto auto' : 'max-content max-content'};
  grid-gap: 12px;
  align-self: start;
  align-items: center;
`;

export const BabyPeekFreePromotionRows = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 12px;
  align-self: start;
  align-items: center;
`;

export const BabyPeekFreePromotionLarge = styled.div`
  color: var(--grey-base, #231f20);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

export const BabyPeekFreePromotionCrossOut = styled.div`
  color: var(--grey-subtle, #b2b1b1);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
  text-decoration-line: line-through;
`;

export const BabyPeekFreePromotionRed = styled.div`
  color: var(--coral-base, #ef413d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

export const BabyPeekFreePromotionDescription = styled.div`
  color: var(--grey-mid, #231f20);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

export const BabyPeekFreeSwitch = styled.div``;

export const BabyPeekSaveCreditCardGrid = styled.div<{ marginTop?: boolean }>`
  display: grid;
  grid-template-columns: min-content auto;
  margin-top: ${({ marginTop }) => marginTop && '-55px'};
  color: var(--grey-700, #2d3748);
  font-feature-settings: 'case' on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  grid-gap: 10px;
  cursor: pointer;
  height: 44px;
  padding: 0px 16px;

  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    accent-color: var(--primary-blue, #2b3ff6);
  }
`;

export const BabyPeekShareModalCheckboxGrid = styled.div<{
  marginTop?: boolean;
}>`
  display: grid;
  grid-template-columns: min-content auto;
  margin-top: ${({ marginTop }) => marginTop && '-55px'};
  color: var(--grey-700, #2d3748);
  font-feature-settings: 'case' on;
  /* Text/Regular/Mobile */
  //styleName: Text/Regular/Mobile Bold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 150%;
  grid-gap: 10px;
  cursor: pointer;

  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    accent-color: var(--primary-blue, #2b3ff6);
  }
`;

export const BabyPeekShareBullets = styled.ul`
  //styleName: Text/Regular/Mobile;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 12px;
  margin-top: 5px;
  margin-bottom: 0px;

  br {
    display: block;
    content: '';
    margin-top: 8px;
  }
`;

export const BabyPeekShareAck = styled.ul`
  //styleName: Text/Regular/Mobile;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 0px;
  margin-top: 5px;
  margin-bottom: 0px;

  br {
    display: block;
    content: '';
    margin-top: 8px;
  }
`;

export const BabyPeekMobileButtonSticky = styled.div`
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  position: sticky;
  bottom: 0;
  background: white;
  display: grid;
  z-index: 11;
  border-top: 1px solid ${({ theme }) => theme.neutral100};

  justify-content: center;
  ${BabyPeekButtonGradient} {
    min-width: 325px;
    width: 100%;
    height: 48px;
  }

  ${BabyPeekButtonGradientContent} {
    height: 43px;
  }

  gap: 20px;

  ${BabyPeekFilledButton} {
    width: 100%;
  }
  ${BabyPeekShareOutlineButton} {
    width: 100%;
  }
`;

export const BabyPeekPaymentPlanTitle = styled.div`
  color: var(--grey-dark, #000);
  font-feature-settings: 'case' on;
  /* Body semibold */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
`;

export const BabyPeekPaymentMainGrid = styled.div<{
  deviceType: DeviceType;
  termsOnly: boolean;
  checkBoxUrl: string;
  savedPayment: boolean;
}>`
  display: grid;
  height: 100%;
  grid-template-columns: ${({ deviceType, termsOnly, savedPayment }) =>
    getGridMainParam(deviceType, termsOnly, true, savedPayment)};
  grid-template-rows: ${({ deviceType, termsOnly, savedPayment }) =>
    getGridMainParam(deviceType, termsOnly, false, savedPayment)};
  grid-gap: ${({ deviceType }) => deviceType === 'desktop' && '36px'};
  background: var(--primary-beige, #fff6d2);
  padding-bottom: 25px;

  .checkbox {
    -webkit-appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: white;
    border: 2px solid ${({ theme }) => theme.greyMedium};
    cursor: pointer;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  .checkbox:checked {
    background: linear-gradient(
      46deg,
      #ffde27 0%,
      #fa709a 48.44%,
      #c670fa 84.13%,
      #3431ce 100%
    );
    border: none;
  }

  .ack-line {
    margin-bottom: 8px;
    display: block;
  }

  .checkbox::before {
    content: '';
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 65%;
    pointer-events: none;
    background-image: url(${({ checkBoxUrl }) => checkBoxUrl});
    background-size: contain;
    background-repeat: no-repeat;
  }

  .checkbox::before {
    display: none;
  }

  .checkbox:checked::before {
    display: block;
  }

  ${BabyPeekButtonGradient} {
    width: 100%;
  }
`;

export const BabyPeekLearnMorePublic = styled.div`
  color: var(--Neutral-Neutral-7, #272727);
  text-align: center;
  font-feature-settings: 'case' on;
  /* Body semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  text-align: center;
  margin-bottom: 10px;
`;

export const BabyPeekStripeIconPointGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: ${({ deviceType }) => (deviceType === 'mobile' ? '16px' : '24px')};
`;

export const BabyPeekStripeIconPointGridRow = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 8px;
  align-items: center;
  color: var(--grey-base, #231f20);

  svg {
    color: var(--grey-base, #231f20);
  }
`;

interface BabyPeekSummaryGridRowProps {
  isPadded?: boolean;
}

export const BabyPeekSummaryGridRow = styled.div<BabyPeekSummaryGridRowProps>`
  padding: ${(props) => (props.isPadded ? '8px 0 0 0' : '0')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-base, #231f20);
  svg {
    color: var(--grey-base, #231f20);
  }

  .MuiAutocomplete-root {
    width: 100%;
  }
  .Mui-focused {
    outline: none;
  }
`;

interface BabyPeekStripeIconPointGridTextProps {
  red?: boolean;
}

export const BabyPeekStripeIconPointGridText = styled.div<BabyPeekStripeIconPointGridTextProps>`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  color: ${(props) => (props.red ? '#E22828' : '#231f20')};
`;

export const BabyPeekStripeIconPointGridLargeText = styled.div`
  padding-top: 8px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  color: var(--grey-base, #231f20);
`;

export const BabyPeekStripeIconPointViewSampleTax = styled.div<{
  deviceType: DeviceType;
}>`
  font-family: Poppins;
  font-size: ${({ deviceType }) => (deviceType === 'mobile' ? '14px' : '18px')};
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 12px;
  color: var(--grey-base, #231f20);

  padding-bottom: 4px;
  position: relative;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--primary-blue, #2b3ff6) blue;
  text-underline-offset: 8px;
`;

export const BabyPeekPaymentSectionTitle = styled.div`
  color: var(--grey-dark, #000);
  font-feature-settings: 'case' on;
  /* Body semibold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
`;

export const BabyPeekPSavedCardGridRow = styled.div`
  display: grid;
  grid-template-columns: min-content max-content auto;
  grid-gap: 10px;
  cursor: pointer;
  align-items: center;
  margin-top: 12px;

  div {
    display: flex;
    justify-content: end;
    font-size: 14px !important;
  }

  img {
    height: 20px;
    margin-right: 5px;
  }
`;

export const BabyPeekPaymentPlanGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 16px;
`;

export const BabyPeekPaymentPlanScheduleWrapper = styled.div`
  margin-left: 33px;
`;

export const BabyPeekTraitNavigationButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const BabyPeekTraitNavigationWrapper = styled.div<{
  $width: number;
  deviceType: DeviceType;
}>`
  display: ${({ deviceType }) => (deviceType === 'mobile' ? 'grid' : 'flex')};
  grid-template-columns: ${({ deviceType }) =>
    deviceType === 'mobile' ? '1fr auto 1fr' : undefined};
  justify-content: space-between;
  width: ${({ $width, deviceType }) =>
    (deviceType !== 'desktop' ? $width : $width * 0.9) - 50}px;
  svg {
    cursor: pointer;
    width: 60px;
  }

  ${BabyPeekShareOutlineButton} {
    width: auto !important;
    border: 3px solid var(--primary-blue, #2b3ff6);
    margin-bottom: 0px;
  }

  ${BabyPeekButtonGradient} {
    width: 150px !important;
    min-width: 0px;
    align-self: center;
    justify-self: center;
  }
`;

export const BabyPeekPreviewModalTraitNavigationWrapper = styled.div<{
  $width: number;
  deviceType: DeviceType;
}>`
  display: flex;
  justify-content: space-between;
  width: ${({ deviceType }) => (deviceType === 'mobile' ? '90%' : '95%')};
  svg {
    cursor: pointer;
  }

  padding: 16px var(--Padding-Left-with-Icon, 24px) 30px
    var(--Padding-Left-with-Icon, 24px);

  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.06);
  position: sticky;
  bottom: 0;
  background: transparent;
  height: 100px;
  align-self: flex-end;
  z-index: 11;
`;

export const BabyPeekPreviewTraitNavigationButton = styled.div``;

export const BabyPeekDesktopButtonSticky = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 24px 24px;
  position: sticky;
  bottom: 2%;
  justify-self: center;
  border-radius: 24.567px;
  border: 1.024px solid var(--neutral-neutral-2, #f2f2f2);
  background: var(--neutral-neutral-1, #fff);
  box-shadow: 0px 6.14169px 11.25976px 0px rgba(0, 0, 0, 0.1);

  justify-content: center;
  ${BabyPeekButtonGradient} {
    min-width: 325px;
    width: 100%;
    height: 48px;
  }

  ${BabyPeekButtonGradientContent} {
    height: 43px;
  }

  gap: 20px;
  width: 90%;
`;

export const BabyPeekPreTestMessage = styled.div`
  color: var(--grey-600, #4a5568);
  text-align: center;
  font-feature-settings: 'case' on;

  /* Caption/Desktop */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.42px;
  margin-top: 8px;
`;
