import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InfoOutlined } from '@mui/icons-material';
import {
  TestResultsButtonContainer,
  TestResultsRiskDesktopContainer,
  TestResultsCenterContainer,
  TestResultsHr,
  TestResultsInfoContainer,
  TestResultsLearnMoreContainer,
  TestResultsDesktopContainer,
  TestResultsInnerContainer,
  TestResultsOutterContainer,
  TestResultsInfoDesktopContainer,
  TestResultsInnerDesktopContainer,
  TestResultsViewButton,
} from './TestResults.styles';
import {
  Get22qInfo,
  GetCarrierAneuploidyInfo,
  GetFetalSexInfo,
  GetRhDInfo,
} from '../shared/Typography/types';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { TestResultsId } from './TestResultsId';
import { TestResultsReceived } from './TestResultsReceived';
import { TestResultsPhysician } from './TestResultsPhysician';
import { TestResultsCarrierInfo } from './TestResultsCarrierInfo';
import { TestResultsLearnMore } from './TestResultsLearnMore';
import { TestResultsAneuploidyRisk } from './TestResultsAneuploidyRisk';
import { TestResultsRhdInfo } from './TestResultsRhdInfo';
import { TestResultsFetalSexInfo } from './TestResultsFetalSexInfo';
import {
  ANEUPLOIDY_PAGE_URL,
  CARRIER_PAGE_URL,
  HELP_PAGE_URL,
} from '../../utils/urlConstants';
import {
  getAllRequisitions,
  getSpecificRequsition,
  getUserAuth,
} from '../../recoil/selectors';
import { ProcessingToolTip } from '../../pages/Landing/Landing.styles';
import { SendActionType } from '../shared/Services/SharedServices';
import { patientProfileState } from '../../recoil/atoms';
import { isAddressOrInsuranceRequired } from '../Profile/helpers';
import { TestResults22q } from './TestResults22QInfo';

export const TestResults: React.FC<{
  requisitionID: string;
  setInsuranceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ requisitionID, setInsuranceModalOpen }) => {
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);
  const [profileInfo] = useRecoilState(patientProfileState);
  const requisition = useRecoilValue(getSpecificRequsition(requisitionID));
  const [patientViewInsuranceModal, setPatientViewedInsuranceModal] =
    useState(false);
  const { isDesktop, deviceType } = useDeviceHook();
  const carrierStatus =
    requisition?.unity_reports && requisition?.unity_reports[0]?.status;
  const carrierStatusValid =
    carrierStatus &&
    carrierStatus !== 'Processing' &&
    carrierStatus !== 'Not Ordered';

  const aneuploidyStatus =
    requisition?.aneuploidy_reports &&
    requisition?.aneuploidy_reports[0]?.status;
  const aneuploidyStatusValid =
    aneuploidyStatus &&
    aneuploidyStatus !== 'Processing' &&
    aneuploidyStatus !== 'Not Ordered';

  useEffect(() => {
    const sendUIAction = async () => {
      try {
        await SendActionType(
          'ViewDashboard',
          requisitionID,
          auth?.access_token
        );
      } catch (e) {
        return undefined;
      }
    };
    sendUIAction();
  }, []);

  const carrierButton = (
    <TestResultsViewButton
      deviceType={deviceType}
      role="button"
      onClick={() => {
        if (
          isAddressOrInsuranceRequired(profileInfo) &&
          !patientViewInsuranceModal
        ) {
          setInsuranceModalOpen(true);
          setPatientViewedInsuranceModal(true);
        } else {
          history.push(`${CARRIER_PAGE_URL}/${requisitionID}`);
        }
      }}
    >
      VIEW RESULTS
    </TestResultsViewButton>
  );

  const aneuploidyButton = (
    <TestResultsViewButton
      role="button"
      deviceType={deviceType}
      onClick={() => {
        if (
          isAddressOrInsuranceRequired(profileInfo) &&
          !patientViewInsuranceModal
        ) {
          setInsuranceModalOpen(true);
          setPatientViewedInsuranceModal(true);
        } else {
          history.push(`${ANEUPLOIDY_PAGE_URL}/${requisitionID}`);
        }
      }}
    >
      VIEW RESULTS
    </TestResultsViewButton>
  );

  const processingToolTip = (
    <ProcessingToolTip title="Results are not yet available in the portal. In rare cases your doctor may receive and share your results with you prior to the results becoming available in the portal.">
      <InfoOutlined />
    </ProcessingToolTip>
  );

  const carrierProccessingToolTip =
    carrierStatus === 'Processing' && processingToolTip;

  const aneuploidyProccessingToolTip =
    aneuploidyStatus === 'Processing' && processingToolTip;

  const aneuploidyReport =
    requisition?.aneuploidy_reports && requisition?.aneuploidy_reports[0];
  const carrierReport =
    requisition?.unity_reports && requisition?.unity_reports[0];

  const requistions = useRecoilValue(getAllRequisitions);
  let isRedrawParent = false;
  const isCarrierOrdered = carrierReport?.is_ordered;
  const isAneuOrdered = aneuploidyReport?.is_ordered;
  if (requistions && requisition) {
    isRedrawParent = requistions.some(
      (req) => req.redraw_parent === requisition.req_identifier
    );
  }

  return (
    <>
      {requisition && (
        <TestResultsCenterContainer isRedrawParent={isRedrawParent}>
          {!isDesktop && (
            <>
              <TestResultsOutterContainer>
                <TestResultsInnerContainer deviceType={deviceType}>
                  {TestResultsId(
                    requisition.req_identifier,
                    Boolean(requisition.redraw_parent)
                  )}
                  {TestResultsReceived(requisition.date_received)}
                  {TestResultsPhysician(requisition.physician_name)}
                </TestResultsInnerContainer>
              </TestResultsOutterContainer>

              {isAneuOrdered && isCarrierOrdered && <TestResultsHr />}
              {isAneuOrdered && (
                <>
                  <TestResultsInfoContainer>
                    {TestResultsAneuploidyRisk(
                      GetCarrierAneuploidyInfo(
                        requisition.aneuploidy_reports &&
                          requisition.aneuploidy_reports[0]
                      )
                    )}
                  </TestResultsInfoContainer>
                  <TestResultsLearnMoreContainer deviceType={deviceType}>
                    {TestResultsLearnMore(
                      'Learn More',
                      `${HELP_PAGE_URL}#aneuploidyTestFor-help`,
                      '10px 20px 10px 25px'
                    )}
                  </TestResultsLearnMoreContainer>

                  {aneuploidyReport?.report_data.fetal_rhd_fa !==
                    'Not Ordered' &&
                    aneuploidyReport?.report_data.fetal_rhd_fa !==
                      'Opted out' && (
                      <TestResultsInfoContainer>
                        {TestResultsRhdInfo(
                          GetRhDInfo(aneuploidyReport?.report_data.fetal_rhd_fa)
                        )}
                      </TestResultsInfoContainer>
                    )}
                  {aneuploidyReport?.report_data.del22q &&
                    aneuploidyReport?.report_data.del22q !== 'Opted out' && (
                      <TestResultsInfoContainer>
                        {TestResults22q(
                          Get22qInfo(aneuploidyReport?.report_data.del22q)
                        )}
                      </TestResultsInfoContainer>
                    )}
                  <TestResultsInfoContainer>
                    {TestResultsFetalSexInfo(
                      GetFetalSexInfo(aneuploidyReport?.report_data.fetal_sex),
                      requisitionID,
                      aneuploidyStatusValid
                    )}
                  </TestResultsInfoContainer>
                  <TestResultsButtonContainer
                    deviceType={deviceType}
                    processing={aneuploidyStatus === 'Processing'}
                  >
                    {aneuploidyStatusValid && aneuploidyButton}
                    {aneuploidyProccessingToolTip}
                  </TestResultsButtonContainer>
                </>
              )}

              {isAneuOrdered && isCarrierOrdered && <TestResultsHr />}
              {isCarrierOrdered && (
                <>
                  <TestResultsInfoContainer>
                    {TestResultsCarrierInfo(
                      GetCarrierAneuploidyInfo(
                        requisition.unity_reports &&
                          requisition.unity_reports[0]
                      )
                    )}
                  </TestResultsInfoContainer>
                  <TestResultsLearnMoreContainer deviceType={deviceType}>
                    {TestResultsLearnMore(
                      'Learn More',
                      `${HELP_PAGE_URL}#carrierTestFor-help`,
                      '0px 20px 0px 20px'
                    )}
                  </TestResultsLearnMoreContainer>
                  <TestResultsButtonContainer
                    deviceType={deviceType}
                    processing={carrierStatus === 'Processing'}
                  >
                    {carrierStatusValid && carrierButton}
                    {carrierProccessingToolTip}
                  </TestResultsButtonContainer>
                </>
              )}
            </>
          )}
          {isDesktop && (
            <>
              <TestResultsDesktopContainer>
                <TestResultsInnerDesktopContainer>
                  {TestResultsId(
                    requisition.req_identifier,
                    Boolean(requisition.redraw_parent)
                  )}
                </TestResultsInnerDesktopContainer>
                <TestResultsInnerDesktopContainer>
                  {TestResultsReceived(requisition.date_received)}
                </TestResultsInnerDesktopContainer>
                <TestResultsInnerDesktopContainer>
                  {TestResultsPhysician(requisition.physician_name)}
                </TestResultsInnerDesktopContainer>
              </TestResultsDesktopContainer>
              {isAneuOrdered && isCarrierOrdered && <TestResultsHr />}
              {isAneuOrdered && (
                <>
                  <TestResultsRiskDesktopContainer>
                    {TestResultsAneuploidyRisk(
                      GetCarrierAneuploidyInfo(aneuploidyReport)
                    )}
                    <TestResultsButtonContainer
                      deviceType={deviceType}
                      processing={aneuploidyStatus === 'Processing'}
                    >
                      {aneuploidyStatusValid && aneuploidyButton}
                      {aneuploidyProccessingToolTip}
                    </TestResultsButtonContainer>
                  </TestResultsRiskDesktopContainer>
                  <TestResultsLearnMoreContainer deviceType={deviceType}>
                    {TestResultsLearnMore(
                      'Learn More',
                      `${HELP_PAGE_URL}#aneuploidyTestFor-help`,
                      '10px 20px 10px 25px'
                    )}
                  </TestResultsLearnMoreContainer>

                  {aneuploidyReport?.report_data.fetal_rhd_fa !==
                    'Not Ordered' &&
                    aneuploidyReport?.report_data.fetal_rhd_fa !==
                      'Opted out' && (
                      <TestResultsInfoDesktopContainer>
                        {TestResultsRhdInfo(
                          GetRhDInfo(aneuploidyReport?.report_data.fetal_rhd_fa)
                        )}
                      </TestResultsInfoDesktopContainer>
                    )}
                  {aneuploidyReport?.report_data.del22q &&
                    aneuploidyReport?.report_data.del22q !== 'Opted out' && (
                      <TestResultsInfoDesktopContainer>
                        {TestResults22q(
                          Get22qInfo(aneuploidyReport?.report_data.del22q)
                        )}
                      </TestResultsInfoDesktopContainer>
                    )}

                  <TestResultsInfoDesktopContainer>
                    {TestResultsFetalSexInfo(
                      GetFetalSexInfo(
                        aneuploidyReport?.report_data?.fetal_sex,
                        requisition.aneuploidy_reports &&
                          requisition.aneuploidy_reports[0].status ===
                            'Processing'
                      ),
                      requisitionID,
                      aneuploidyStatusValid
                    )}
                  </TestResultsInfoDesktopContainer>
                </>
              )}

              {isAneuOrdered && isCarrierOrdered && <TestResultsHr />}
              {isCarrierOrdered && (
                <>
                  <TestResultsRiskDesktopContainer>
                    {TestResultsCarrierInfo(
                      GetCarrierAneuploidyInfo(carrierReport)
                    )}
                    <TestResultsButtonContainer
                      deviceType={deviceType}
                      processing={carrierStatus === 'Processing'}
                    >
                      {carrierStatusValid && carrierButton}
                      {carrierProccessingToolTip}
                    </TestResultsButtonContainer>
                  </TestResultsRiskDesktopContainer>
                  {TestResultsLearnMore(
                    'Learn More',
                    `${HELP_PAGE_URL}#carrierTestFor-help`,
                    '0px 20px 8px 25px'
                  )}
                </>
              )}
            </>
          )}
        </TestResultsCenterContainer>
      )}
    </>
  );
};
