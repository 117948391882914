import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DetailedResultsHeaderContainer,
  DetailedResultIconRow,
} from './DetailedResults.styles';
import { GetCarrierAneuploidyInfo } from '../shared/Typography/types';

import { GetTypographyContent } from '../shared/Typography/Typography';
import { TestResultsIcon } from '../TestResults/TestResults.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import {
  getSpecificAneuploidy,
  getSpecificUnity,
} from '../../recoil/selectors';

export const DetailedResultsHeader: React.FC<{
  type: 'carrier' | 'aneuploidy';
  requisitionID: string;
}> = ({ type, requisitionID }) => {
  const { deviceType } = useDeviceHook();
  const carrierState = useRecoilValue(getSpecificUnity(requisitionID));
  const aneuploidyState = useRecoilValue(getSpecificAneuploidy(requisitionID));

  const reportDisplay =
    type === 'carrier'
      ? GetCarrierAneuploidyInfo(carrierState)
      : GetCarrierAneuploidyInfo(aneuploidyState);

  const lowRisk =
    type === 'carrier'
      ? carrierState?.summary === 'Negative'
      : aneuploidyState?.summary === 'Low Risk';

  return (
    <>
      {lowRisk !== undefined && (
        <DetailedResultsHeaderContainer deviceType={deviceType}>
          <div>
            {GetTypographyContent({
              content: `${type} screen`,
              size: 'label',
              textColor: 'greyMedium',
              isBold: true,
              isCaps: true,
            })}
          </div>
          <div>
            <DetailedResultIconRow>
              <TestResultsIcon>{reportDisplay.icon}</TestResultsIcon>
              {GetTypographyContent({
                content: reportDisplay.display,
                size: 'h2',
                textColor: 'greyMedium',
              })}
            </DetailedResultIconRow>
          </div>
        </DetailedResultsHeaderContainer>
      )}
    </>
  );
};
