import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { useRecoilState } from 'recoil';
import { Button } from '../shared/Button/Button';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  ButtonContainer,
  CenterContainter,
  DesktopColorbackground,
  DesktopContainer,
  LoginFormFieldInput,
  Image,
  LoginInput,
  LoginPassword,
  MobileContainer,
  PasswordLinkContainer,
  Vector,
  LoginFormWrapper,
  LoginError,
  LoginPrivacyPolicy,
} from './Login.styles';
import unityRound from '../../assets/logos/unityRound.svg';
import vector from '../../assets/icons/vector.svg';
import LoginRequest, {
  LoginInitialValues,
  LoginRequestType,
} from './Services/LoginService';
import { LANDING_PAGE_URL, RESET_PASSWORD_URL } from '../../utils/urlConstants';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { patientState } from '../../recoil/atoms';
import { UserAuthClientResponse } from '../../recoil/types';
import { validateEmail } from '../../utils/hooks/helpers';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { networkErrorMessage } from '../../utils/helpers';
import {
  removeCookieAccessToken,
  setCookieAccessToken,
} from '../../utils/requestUtils';

export const LoginForm: React.FC<{ setPathname: () => void }> = ({
  setPathname,
}) => {
  const history = useHistory();
  const { isDesktop, deviceType } = useDeviceHook();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useRecoilState(patientState);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPathname();
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (patientInfo?.auth0.email_verified) {
      history.push(
        sessionStorage.getItem('current-pathname') || LANDING_PAGE_URL
      );
      sessionStorage.removeItem('current-pathname');
    }
  }, [patientInfo?.auth0.email_verified]);

  const onSubmit = async ({
    email,
    password,
  }: LoginRequestType): Promise<void> => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const response = await LoginRequest({
        email,
        password,
      });
      setIsLoading(false);
      if (response.ok) {
        const userData: UserAuthClientResponse = await response.json();
        setCookieAccessToken(userData.auth0.access_token);
        setPatientInfo(userData);
        if (userData.auth0.email_verified) {
          history.push(
            sessionStorage.getItem('current-pathname') || LANDING_PAGE_URL
          );
          sessionStorage.removeItem('current-pathname');
        } else {
          history.push(LANDING_PAGE_URL);
        }
      } else {
        removeCookieAccessToken();
        setPatientInfo(undefined);
        setErrorMessage(
          'Invalid email and password combination. Please try again.'
        );
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(networkErrorMessage);
    }
  };

  const loginSection = (
    <CenterContainter data-testid="loginCenterContainer">
      {GetTypographyContent({
        content: 'Login to your account',
        size: 'h3',
        isBold: true,
        textColor: 'greyMedium',
      })}
      {GetTypographyContent({
        content: 'Please enter your account details',
        size: 'large',
        textColor: 'greyMedium',
        padding: '30px 0 35px 0',
      })}
      {errorMessage && (
        <LoginError>
          {GetTypographyContent({
            content: errorMessage,
            size: 'medium',
            textColor: 'redDark',
          })}
        </LoginError>
      )}
      <Formik
        initialValues={LoginInitialValues}
        validateOnChange={false}
        validate={(values) => {
          const errors: FormikErrors<LoginRequestType> = {};
          if (!values.email) {
            errors.email = 'Required';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          if (!validateEmail(values.email)) {
            errors.email = 'Invalid email';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          setFieldError,
          handleSubmit,
          setFieldValue,
        }: FormikProps<LoginRequestType>) => (
          <LoginFormWrapper
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isLoading) {
                handleSubmit();
              }
            }}
          >
            <LoginFormFieldInput deviceType={deviceType}>
              <LoginInput
                type="text"
                name="email"
                value={values.email}
                placeholder="Email"
                label="Email"
                size="small"
                onChange={(e) => {
                  setErrorMessage('');
                  setFieldError('email', '');
                  setFieldValue('email', e.target.value);
                }}
                error={errors.email}
              />
            </LoginFormFieldInput>
            <PasswordLinkContainer deviceType={deviceType}>
              {GetTypographyContent({
                content: 'Forgot password?',
                size: 'small',
                textColor: 'greenDark',
                link: RESET_PASSWORD_URL,
              })}
            </PasswordLinkContainer>
            <LoginFormFieldInput deviceType={deviceType}>
              <LoginPassword
                type="password"
                name="password"
                value={values.password}
                placeholder="Password"
                label="Password"
                size="small"
                onChange={(e) => {
                  setErrorMessage('');
                  setFieldError('password', '');
                  setFieldValue('password', e.target.value);
                }}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
            </LoginFormFieldInput>
            <LoginPrivacyPolicy>
              <p>
                {`By continuing, you agree to our `}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://billiontoone.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                {`, `}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://billiontoone.com/privacy-practices/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notice of Privacy Practices
                </a>
                {`, and `}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="../../client/terms_of_use"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use.
                </a>
              </p>
            </LoginPrivacyPolicy>
            {isLoading ? (
              <>
                <SpinnerLoading />
              </>
            ) : (
              <ButtonContainer>
                <Button
                  type="submit"
                  label="Login"
                  id="LoginButton"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  textColor="white"
                />
              </ButtonContainer>
            )}
          </LoginFormWrapper>
        )}
      </Formik>
    </CenterContainter>
  );

  return (
    <>
      {!patientInfo?.auth0 && isDesktop && (
        <DesktopContainer>
          <DesktopColorbackground>
            <>
              {' '}
              <Vector src={vector} />
              <Image src={unityRound} width="321px" />{' '}
            </>
          </DesktopColorbackground>
          {loginSection}
        </DesktopContainer>
      )}
      {!patientInfo?.auth0 && !isDesktop && (
        <MobileContainer>{loginSection}</MobileContainer>
      )}
    </>
  );
};
