import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useRecoilValue } from 'recoil';
import { FemaleFetus } from '../../assets/iconComponents/FemaleFetus';
import { MaleFetus } from '../../assets/iconComponents/MaleFetus';
import { getSpecificFetalSex, getUserAuth } from '../../recoil/selectors';
import { DeviceType, useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { DetailedResultsPatientReviewModal } from './DetailedResultsPatientReviewModal';
import {
  DetailedResultFetalSexRevealRow,
  DetailedResultsFetalGrid,
  DetailedResultsFetalSexRevealWrapper,
  DetailedResultsRevealTitle,
} from './DetailedResults.styles';
import { GetPatientReviewRequest } from './Services/DetailedResultsService';

const getFetalDisplay = (fetalSex?: string) => {
  if (
    fetalSex === 'MALE' ||
    fetalSex === 'FEMALE' ||
    fetalSex === 'MALE & MALE' ||
    fetalSex === 'FEMALE & FEMALE' ||
    fetalSex === 'MALE & FEMALE'
  ) {
    return fetalSex.split('&').map((sex) => {
      if (sex.trim() === 'MALE') {
        return (
          <DetailedResultFetalSexRevealRow key={sex}>
            <MaleFetus />
            {GetTypographyContent({
              content: 'Boy',
              size: 'h2',
              textColor: 'greyMedium',
            })}
          </DetailedResultFetalSexRevealRow>
        );
      }
      return (
        <DetailedResultFetalSexRevealRow key={sex}>
          <FemaleFetus />
          {GetTypographyContent({
            content: 'Girl',
            size: 'h2',
            textColor: 'greyMedium',
          })}
        </DetailedResultFetalSexRevealRow>
      );
    });
  }

  if (fetalSex === 'ONE OR BOTH MALES') {
    return (
      <DetailedResultFetalSexRevealRow extend key={fetalSex}>
        <MaleFetus />
        {GetTypographyContent({
          content: 'One or both boys',
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </DetailedResultFetalSexRevealRow>
    );
  }
};

export const DetailedResultsFetalSexReveal: React.FC<{
  requisitionID: string;
}> = ({ requisitionID }) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const [showPatientReviewModal, setShowPatientReviewModal] = useState(false);

  const fetalSex = useRecoilValue(getSpecificFetalSex(requisitionID));

  const twins =
    fetalSex?.value === 'MALE & MALE' ||
    fetalSex?.value === 'FEMALE & FEMALE' ||
    fetalSex?.value === 'MALE & FEMALE' ||
    fetalSex?.value === 'ONE OR BOTH MALES';

  useEffect(() => {
    async function getPatientReview() {
      const response = await GetPatientReviewRequest(auth?.access_token);
      const patientReviews = await response.json();
      if (!patientReviews.length) {
        const timer = setTimeout(() => {
          setShowPatientReviewModal(true);
        }, 20000); // the patient clicks "Yes, reveal fetal sex"; wait 20 seconds; then show the review modal
        return () => clearTimeout(timer);
      }
    }

    getPatientReview();
  }, []);

  const confettiWidth = (deviceTypeConfetti: DeviceType): number => {
    if (deviceTypeConfetti === 'mobile') return 320;
    if (deviceTypeConfetti === 'tablet') return 530;
    return 720;
  };

  const confettiHeight = (deviceTypeConfetti: DeviceType): number => {
    if (deviceTypeConfetti === 'mobile') return 220;
    if (deviceTypeConfetti === 'tablet') return 220;
    return 220;
  };

  return (
    <DetailedResultsFetalSexRevealWrapper>
      <Confetti
        width={confettiWidth(deviceType)}
        height={confettiHeight(deviceType)}
        numberOfPieces={100}
        gravity={0.1}
        initialVelocityX={5}
        initialVelocityY={5}
        recycle
        colors={['yellow', '#fa709a', '#c670fa', '#3431ce']}
      />

      <DetailedResultsRevealTitle>
        {GetTypographyContent({
          content: `Your test results show your ${
            twins ? 'babies are:' : 'baby is a:'
          }`,
          size: 'medium',
          textColor: 'greyMedium',
          padding: '0px 0px 0px 10px',
        })}
      </DetailedResultsRevealTitle>
      <DetailedResultsFetalGrid isTwin={twins}>
        {getFetalDisplay(fetalSex?.value)}
      </DetailedResultsFetalGrid>

      {showPatientReviewModal && (
        <DetailedResultsPatientReviewModal
          showPatientReviewModal={showPatientReviewModal}
          setShowPatientReviewModal={setShowPatientReviewModal}
          requisitionID={requisitionID}
        />
      )}
    </DetailedResultsFetalSexRevealWrapper>
  );
};
