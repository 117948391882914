import styled from 'styled-components/macro';
import { TextField } from '@mui/material';
import { StyledTypography } from '../shared/Typography/Typography.styles';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const ContactUsWrapper = styled.div`
  display: grid;
  grid-template-rows: 20px 20px auto;
  grid-row-gap: 30px;
  padding-top: 12px;
  padding-bottom: 40px;

  ${StyledTypography} {
    line-height: 20px;
  }
`;

export const ContactUsMessageWrapper = styled.div`
  p {
    display: inline;
  }
`;

const getContactFormPadding = (deviceType?: DeviceType) => {
  if (deviceType === 'mobile') {
    return undefined;
  }
  if (deviceType === 'tablet') {
    return '0 60px';
  }
  return '0 120px';
};

export const ContactUsFormGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: 40px 40px 40px 40px 120px 75px auto;
  grid-row-gap: 40px;
  padding-top: 20px;
  padding: ${({ deviceType }) => getContactFormPadding(deviceType)};
`;

export const ContactUsInput = styled(TextField)``;

export const ContactUsButtonContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 200px;
  justify-content: flex-start;
`;
