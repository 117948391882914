import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getUserAuth } from '../../recoil/selectors';
import {
  EXTERNAL_FORWARD_FETAL_SEX,
  LOGIN_PAGE_URL,
  VERIFY_EMAIL_SEND_AGAIN_URL,
  VERIFY_EMAIL_URL,
} from '../urlConstants';

export const useLoginScreen = (): void => {
  const auth = useRecoilValue(getUserAuth);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      !pathname.startsWith(VERIFY_EMAIL_URL) &&
      !pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL)
    ) {
      sessionStorage.setItem(
        'current-pathname',
        `${pathname}${window.location.search}`
      );
    }
    if (!pathname.startsWith(EXTERNAL_FORWARD_FETAL_SEX)) {
      if (!auth && !pathname.startsWith(VERIFY_EMAIL_URL)) {
        history.push(LOGIN_PAGE_URL);
      } else if (
        auth &&
        !auth.email_verified &&
        !pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL)
      ) {
        history.push(VERIFY_EMAIL_URL);
      } else if (
        (pathname.startsWith(VERIFY_EMAIL_URL) ||
          pathname.startsWith(VERIFY_EMAIL_SEND_AGAIN_URL)) &&
        auth?.email_verified
      ) {
        history.push(LOGIN_PAGE_URL);
      }
    }
  }, [auth, pathname]);
};
