import styled from 'styled-components/macro';
import MuiAccordion from '@mui/material/Accordion';

export const BabyPeekStyledAccordion = styled(MuiAccordion)`
  padding: 0 !important;
  margin: 2px 0 0 0 !important;
  box-shadow: none !important;
  background: ${({ theme }) => theme.white};
  width: 100%;
  &:before {
    margin: auto;
    width: calc(100% - 32px);
    opacity: 1 !important;
    background-color: #ffe88d;
  }
`;

export const BabyPeekAccordionQuestion = styled.div`
  color: var(--base-dark-gray, #1d232c);
  /* Heading/H5/Desktop */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.24px;
`;

export const BabyPeekAccordionAnswer = styled.div`
  color: var(--grey-800, #1a202c);
  font-feature-settings: 'case' on;
  /* Text/Medium/Desktop */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;

export const BabyPeekGameAccordionTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  /* Text/Medium/Mobile Bold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
`;

export const BabyPeekGameAccordionTitleGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
`;

export const BabyPeekGamesAccordionText = styled.div`
  color: var(--Grey-700, #2d3748);
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  justify-content: center;
  align-items: center;
  display: grid;

  span {
    font-weight: 700;
  }

  svg {
    justify-self: center;
  }
`;
