import styled from 'styled-components/macro';
import { TextField } from '../shared/TextField/TextField';
import { Password } from '../shared/TextField/Password';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

const formFieldWidth = (deviceType: string) => {
  if (deviceType === 'desktop') return '447px';
  if (deviceType === 'tablet') return '421px';
  return '327px';
};

export const CenterContainter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  margin: auto;
  grid-template-rows: min-content min-content min-content;
`;

export const LoginFormWrapper = styled.div``;

export const LoginError = styled.div`
  margin: 20px;
  max-width: 75%;
`;

export const LoginFormFieldInput = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => formFieldWidth(deviceType)};
`;

export const LoginInput = styled(TextField)`
  border: 1px solid ${({ theme }) => theme.greyLight};
  background-color: #fff;
  color: ${({ theme }) => theme.greyMedium};
  padding: 14px;
  padding-top: 17px;
  width: 100%;
`;

export const LoginPassword = styled(Password)`
  border: 1px solid ${({ theme }) => theme.greyLight};
  background-color: #fff;
  color: ${({ theme }) => theme.greyMedium};
  padding: 14px;
  padding-top: 17px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  margin-top: 24px;
`;

export const PasswordLinkContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: 1fr;
  place-items: flex-end;
  padding-top: 20px;
  width: ${({ deviceType }) => formFieldWidth(deviceType)};
  text-align: end;
`;

export const DesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
`;

export const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const DesktopColorbackground = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background: ${({ theme }) => theme.gradient};
`;

export const Image = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  margin: auto;
`;

export const Vector = styled.img`
  position: absolute;
  left: 28.4%;
`;

export const LoginPrivacyPolicy = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 24px 0;
  text-align: left;

  p {
    font-size: 12px;
    line-height: 1.5;
    color: ${({ theme }) => theme.greyLight};
  }

  a {
    color: inherit;
  }
`;
