export enum StepperIndex {
  Main = 0,
  ItemizedBill = 1,
  Insurance = 2,
  Receipt = 3,
  PaymentPlan = 4,
  Preflight = 5,
  Confirmed = 6,
}

export type PortalBillStatusType =
  | 'not_available'
  | 'outstanding'
  | 'paid_in_full'
  | 'external'
  | 'failure'
  | 'reviewable'
  | 'in_progress'
  | 'primary_ins_provided'
  | 'secondary_ins_provided'
  | 'medicaid_or_hmo_ins_provided'
  | 'fa_fully_covered'
  | 'has_medicaid'
  | 'refunded'
  | 'external_billed_through_collectly'
  | 'unusual_balance';

export type PortalBillType =
  | 'cash_pay'
  | 'out_of_network'
  | 'in_network'
  | 'has_secondary_insurance'
  | 'unknown';

export const CentsConversion = 100;
export const PaymentPlanMinDollars = 90;
export const FinancialAssistanceMinDollars = 349;
export const BillingSupportNumber = '(650) 517-7243';
