import * as React from 'react';
import { themePalette } from '../../theme';

export const QuestionMark = ({
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="32"
    height="35"
    viewBox="0 0 32 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 18.9972C18 16.8174 16.1879 15.05 13.953 15.05H4.04698C1.81208 15.05 0 16.8174 0 18.9972V26.6852C0 28.6294 1.44966 30.2494 3.35235 30.5735V33.902C3.35235 34.1377 3.50336 34.3733 3.71477 34.4911C3.80537 34.5501 3.92617 34.55 4.01678 34.55C4.16779 34.55 4.28859 34.5206 4.4094 34.4322L6.43289 32.9889C8.63758 31.4277 11.2349 30.6029 13.953 30.6029C16.1879 30.6029 18 28.8355 18 26.6558V18.9972Z"
      fill={themePalette.greenMedium}
    />
    <path
      d="M31.3996 6.7C31.3996 3 28.3996 0 24.6996 0H8.29961C4.59961 0 1.59961 3 1.59961 6.7V19.75C1.59961 23.05 3.99961 25.8 7.14961 26.35V32C7.14961 32.4 7.39961 32.8 7.74961 33C7.89961 33.1 8.09961 33.1 8.24961 33.1C8.49961 33.1 8.69961 33.05 8.89961 32.9L12.2496 30.45C15.8996 27.8 20.1996 26.4 24.6996 26.4C28.3996 26.4 31.3996 23.4 31.3996 19.7V6.7ZM29.1996 19.75C29.1996 22.2 27.1996 24.2 24.7496 24.2C19.7996 24.2 15.0496 25.75 10.9996 28.65L9.39961 29.8V25.3C9.39961 24.7 8.89961 24.2 8.29961 24.2C5.84961 24.2 3.84961 22.2 3.84961 19.75V6.7C3.84961 4.25 5.84961 2.25 8.29961 2.25H24.7496C27.1996 2.25 29.1996 4.25 29.1996 6.7V19.75Z"
      fill={themePalette.greyMedium}
    />
    <path
      d="M16.4996 7.05005C14.6496 7.05005 12.8496 8.25005 12.8496 10.55C12.8496 11.15 13.3496 11.65 13.9496 11.65C14.5496 11.65 15.0496 11.15 15.0496 10.55C15.0496 9.35005 16.3496 9.30005 16.4996 9.30005C16.6496 9.30005 17.9496 9.35005 17.9496 10.55V10.8C17.9496 11.3 17.6996 11.7 17.2496 11.95L16.2496 12.5C15.6996 12.8 15.3996 13.35 15.3996 13.95V14.85C15.3996 15.45 15.8996 15.95 16.4996 15.95C17.0996 15.95 17.5996 15.45 17.5996 14.85V14.3L18.2996 13.95C19.4496 13.35 20.1496 12.15 20.1496 10.85V10.55C20.1496 8.25005 18.2996 7.05005 16.4996 7.05005Z"
      fill={themePalette.greyMedium}
    />
    <path
      d="M16.5004 17.2C14.7004 17.2 14.7004 20 16.5004 20C18.3004 20 18.3004 17.2 16.5004 17.2Z"
      fill={themePalette.greyMedium}
    />
  </svg>
);
