import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { InfoOutlined } from '@mui/icons-material';
import { PopperProps } from '@mui/material';
import VideoEmbed from '../../components/shared/Video/VideoEmbed';
import { TestResults } from '../../components/TestResults/TestResults';
import {
  LandingGeneralContainer,
  LandingPageAccordionLinkWrapper,
  LandingPageAccordionWrapper,
  LandingPageDesktopLeftWrapper,
  LandingPageDesktopRightWrapper,
  LandingPageDesktopWrapper,
  LandingPageDesktopWrapperPadding,
  LandingPageTabletMobileRowWrapper,
  LandingPageTabletMobileWrapper,
  LandingPageTitleWrapper,
  LandingPageWarningMobileTextWrapper,
  LandingPageWarningText,
  LandingPageWarningWrapper,
  LandingPageWarningWrapperMobile,
  LandingPageWrapper,
  LandingPretestConsentGrid,
  LandingPretestQuestionWrapper,
  LandingTypeformWrapper,
  LandingPretestConsentText,
  LandingPretestConsentTitle,
  LandingPageBillingTextTop,
  LandingPageBillingTextBottom,
  LandingPageBillingTextWrapper,
  LandingPageBillingMessageWrapper,
  LandingPageBillIconGrid,
  BabyPeekToolTip,
  BabyPeekAvailable,
  LandingPageBillingViewBill,
  LandingPagePatientSurveyWrapper,
} from './Landing.styles';
import { Card } from '../../components/shared/Cards/Card';
import { Accordions } from '../../components/shared/Accordion/Accordions';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import {
  BABYPEEK_URL,
  BILLING_URL,
  HELP_PAGE_URL,
} from '../../utils/urlConstants';
import { getAllRequisitions, getUserAuth } from '../../recoil/selectors';
import { landingPageAccordions, landingPageCard } from './LandingHelpData';
import { useLoginScreen } from '../../utils/hooks/useLoginScreen';
import { WarningRounded } from '../../assets/iconComponents/WarningRounded';
import { Button } from '../../components/shared/Button/Button';
import {
  getMissingProfileInfoMessage,
  isAddressOrInsuranceRequired,
} from '../../components/Profile/helpers';
import {
  babyPeekStatus,
  patientProfileState,
  showABNForm,
} from '../../recoil/atoms';
import { ProfileInsuranceModal } from '../../components/Profile/ProfileInsuranceUploadModal';
import { Typeform } from '../../components/Typeform/Typeform';
import { Spinner } from '../../components/CreateAccount/CreateAccountForm.styles';
import { UpdateConsent } from './Services/LandingService';
import {
  ConsentStatus,
  UserProfileAuthClientResponse,
} from '../../recoil/types';
import { GetPhiRequest } from '../../components/Profile/Services/UpdateProfileInfoService';
import { BillIcon } from '../../assets/iconComponents/BillIcon';
import { ProfileAlabamaWarning } from '../../components/Profile/ProfileAlabamaWarning';
import { BabyPeekOptIn } from '../../components/BabyPeek/BabyPeekOptIn';
import BabyPeekHeader from '../../components/BabyPeek/BabyPeekIcons/BabyPeekHeader.png';
import { BabyPeekButton } from '../../components/BabyPeek/BabyPeekButton';
import { DetailedResultsShareBabyPeek } from '../../components/DetailedResults/DetailedResultsShareBabyPeek';

const babyPeekUnavailableText =
  'We were unable to provide you with BabyPeek results. BabyPeek is not available for twin pregnancies, pregnancies conceived with a donor egg, surrogate pregnancies, or following a No Call or redraw request.';

const Landing = (): React.ReactElement => {
  useLoginScreen();

  const { deviceType } = useDeviceHook();

  const requisitions = useRecoilValue(getAllRequisitions);
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);
  const [patientInfo, setPatientInfo] = useRecoilState(patientProfileState);
  const [showABNFormPatient, setShowABNFormPatient] =
    useRecoilState(showABNForm);
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
  const [infoSubmitted, setInfoSubmitted] = useState('');
  const [submittingConsent, setSubmittingConsent] = useState(false);

  const [tooltip, setTooltip] = useState(false);
  const [showBabyPeekPopUp, setShowBabyPeekPopUp] = useState(false);
  const babyPeekStatusFlag = useRecoilValue(babyPeekStatus);
  const [showOutcomesBanner, setShowOutcomesBanner] = useState(false);
  const [showPatientConsent, setShowPatientConsent] = useState(false);

  useEffect(() => {
    const localPopUp = localStorage.getItem('babyPeekPopUpDismmised');
    const sessionPopUp = sessionStorage.getItem('babyPeekPopUpDismmised');

    if (localPopUp !== 'true' && sessionPopUp !== 'true') {
      setShowBabyPeekPopUp(true);
    }
  }, []);

  useEffect(() => {
    if (patientInfo?.user?.consent_status === ConsentStatus.INCOMPLETE) {
      setShowPatientConsent(true);
    }
    if (patientInfo?.user?.consent_status === ConsentStatus.COMPLETE) {
      const timeout = setTimeout(() => {
        setShowPatientConsent(false);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [patientInfo?.user?.consent_status]);

  useEffect(() => {
    if (localStorage.getItem('seenOutcomesBanner') !== 'true') {
      window.onbeforeunload = () => {
        localStorage.setItem('seenOutcomesBanner', 'true');
      };
      setShowOutcomesBanner(true);
    }
  }, []);

  useEffect(() => {
    if (
      !showABNFormPatient.loaded &&
      !showABNFormPatient.show &&
      requisitions?.[0]?.show_abn_form
    ) {
      setShowABNFormPatient({ loaded: true, show: true });
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (infoSubmitted) {
      timeout = setTimeout(() => setInfoSubmitted(''), 10000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [infoSubmitted]);

  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await GetPhiRequest(auth?.access_token);
      if (response.ok) {
        const profile =
          (await response.json()) as UserProfileAuthClientResponse;
        setPatientInfo(profile);
      }
    };
    if (!patientInfo && auth) {
      getProfileInfo();
    }
  }, [patientInfo, auth]);

  const ConsentForm = (
    <>
      {patientInfo?.user && showPatientConsent && (
        <LandingPretestQuestionWrapper>
          {patientInfo?.user?.consent_status !==
            ConsentStatus.NOT_APPLICABLE && (
            <LandingPretestConsentGrid>
              <LandingPretestConsentTitle>
                Patient Consent
              </LandingPretestConsentTitle>
              <LandingPretestConsentText>
                {patientInfo?.user?.consent_status === ConsentStatus.INCOMPLETE
                  ? `I have been informed of and understand the details of the tests
                ordered herein for me by my healthcare provider, including the
                risks, benefits and alternatives, and consented to testing. I
                understand (1) the test results may inform me of a medical
                condition that may require follow-up and (2) a negative result
                does not rule out the possibility of such medical condition in
                the fetus, myself or my partner.`
                  : 'Thank you for your response, your physician will follow up with you on next steps.'}
              </LandingPretestConsentText>
              {patientInfo?.user?.consent_status === ConsentStatus.INCOMPLETE &&
                (submittingConsent ? (
                  <Spinner />
                ) : (
                  <Button
                    label="Agree"
                    onClick={async () => {
                      setSubmittingConsent(true);
                      if (patientInfo?.user) {
                        const response = await UpdateConsent(
                          {
                            consent_status: ConsentStatus.COMPLETE,
                            has_carrier_consent: true,
                            has_aneuploidy_consent: true,
                          },
                          auth?.access_token
                        );
                        if (response.ok) {
                          const updatedUserInfo = await response.json();
                          setPatientInfo(
                            updatedUserInfo as UserProfileAuthClientResponse
                          );
                        }
                        setSubmittingConsent(false);
                      }
                    }}
                    size="medium"
                  />
                ))}
            </LandingPretestConsentGrid>
          )}
          {patientInfo?.user?.survey_status === ConsentStatus.INCOMPLETE && (
            <LandingTypeformWrapper>
              <Typeform
                typeformID="jvuge1VD"
                onSubmit={async () => {
                  if (patientInfo?.user) {
                    const response = await UpdateConsent(
                      {
                        survey_status: ConsentStatus.COMPLETE,
                      },
                      auth?.access_token
                    );
                    if (response.ok) {
                      const updatedUserInfo = await response.json();
                      setPatientInfo(
                        updatedUserInfo as UserProfileAuthClientResponse
                      );
                    }
                  }
                }}
              />
            </LandingTypeformWrapper>
          )}
        </LandingPretestQuestionWrapper>
      )}
    </>
  );

  const getLandingContent = () => {
    if (deviceType === 'desktop') {
      return (
        <>
          <LandingPageWrapper data-testid="LandingPageWrapper">
            <LandingPageDesktopWrapperPadding>
              {isAddressOrInsuranceRequired(patientInfo) && (
                <LandingPageWarningWrapper deviceType={deviceType}>
                  <WarningRounded />
                  <LandingPageWarningText>
                    {getMissingProfileInfoMessage(
                      patientInfo?.phi.ins_status === 'Required',
                      patientInfo?.phi.address_status === 'Required',
                      patientInfo?.phi.prebilling_status === 'Required'
                    )}
                  </LandingPageWarningText>
                  <Button
                    size="medium"
                    onClick={() => setInsuranceModalOpen(true)}
                    label="UPDATE"
                  />
                </LandingPageWarningWrapper>
              )}

              {ConsentForm}
              {showOutcomesBanner && (
                <LandingPagePatientSurveyWrapper deviceType={deviceType}>
                  <div style={{ fontSize: '30px', fontWeight: 700 }}>
                    Help Us Improve Prenatal Care!
                  </div>

                  <div style={{ fontSize: '16px' }} className="body">
                    {`You will receive a link to complete a survey about your baby’s health 4 weeks after your due date. Completing this survey will help us continue to improve our products. Thank you for your help! `}
                  </div>
                </LandingPagePatientSurveyWrapper>
              )}

              {Boolean(
                requisitions?.filter(
                  (req) => req.portal_bill_status === 'outstanding'
                ).length
              ) && (
                <LandingPageBillingMessageWrapper deviceType={deviceType}>
                  <LandingPageBillIconGrid>
                    <BillIcon />
                    <LandingPageBillingTextWrapper>
                      <LandingPageBillingTextTop>
                        Your bill is ready
                      </LandingPageBillingTextTop>
                    </LandingPageBillingTextWrapper>
                  </LandingPageBillIconGrid>
                  <LandingPageBillingTextBottom>
                    <LandingPageBillingViewBill
                      onClick={() =>
                        history.push(
                          `${BILLING_URL}/${
                            requisitions?.filter(
                              (req) => req.portal_bill_status === 'outstanding'
                            )[0].bill_pk
                          }`
                        )
                      }
                    >
                      VIEW MY BILL
                    </LandingPageBillingViewBill>
                  </LandingPageBillingTextBottom>
                </LandingPageBillingMessageWrapper>
              )}

              {/* {requisitions && requisitions[0]?.reveal_status === 'pending' && (
                <DetailedResultsBabyPeekDesktop
                  babyPeekPK={requisitions[0]?.baby_peek_pk}
                />
              )} */}
              <LandingPageDesktopWrapper>
                <LandingPageDesktopLeftWrapper
                  requisitionCount={requisitions?.length || 0}
                  showBabyPeek={Boolean(
                    requisitions && requisitions[0]?.reveal_status === 'pending'
                  )}
                  babyPeekAvailable={Boolean(
                    requisitions &&
                      (requisitions[0]?.reveal_status === 'available' ||
                        requisitions[0]?.reveal_status === 'removed')
                  )}
                >
                  {Boolean(requisitions?.length) && (
                    <>
                      <LandingPageTitleWrapper>
                        {GetTypographyContent({
                          content: 'Your Tests',
                          size: 'h2',
                          textColor: 'greyMedium',
                          isBold: true,
                        })}
                      </LandingPageTitleWrapper>
                      {Boolean(requisitions?.length) &&
                        requisitions?.map((requistion) => (
                          <LandingGeneralContainer
                            padding="0px"
                            key={requistion.req_identifier}
                          >
                            <TestResults
                              requisitionID={requistion.req_identifier}
                              setInsuranceModalOpen={setInsuranceModalOpen}
                            />
                          </LandingGeneralContainer>
                        ))}
                    </>
                  )}

                  {Boolean(
                    requisitions &&
                      (requisitions[0]?.reveal_status === 'available' ||
                        requisitions[0]?.reveal_status === 'removed')
                  ) && (
                    <LandingGeneralContainer padding="0px">
                      {requisitions &&
                      requisitions[0]?.reveal_status === 'removed' ? (
                        <BabyPeekToolTip
                          onClick={() => setTooltip(!tooltip)}
                          PopperProps={
                            {
                              sx: {
                                '& .MuiTooltip-tooltip': {
                                  backgroundColor: 'white',
                                  boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
                                  color: 'black',
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '150%',
                                  textAlign: 'center',
                                  border: '1px solid #cbd5e0',
                                },
                              },
                              open: tooltip,
                            } as Partial<PopperProps>
                          }
                          title={babyPeekUnavailableText}
                        >
                          <div>
                            BabyPeek is currently unavailable <InfoOutlined />
                          </div>
                        </BabyPeekToolTip>
                      ) : (
                        <BabyPeekAvailable>
                          <div>
                            <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                            <div>BabyPeek</div>
                          </div>
                          <div>
                            <BabyPeekButton
                              variant="filled"
                              content={<>View Results</>}
                              onClick={() =>
                                history.push(
                                  `${BABYPEEK_URL}/${
                                    requisitions &&
                                    requisitions[0]?.baby_peek_pk
                                  }/${
                                    babyPeekStatusFlag === 'available'
                                      ? 'summary'
                                      : 'intro'
                                  }?loc=dashboard`
                                )
                              }
                            />
                          </div>
                        </BabyPeekAvailable>
                      )}
                    </LandingGeneralContainer>
                  )}
                  {requisitions &&
                    requisitions[0]?.reveal_status === 'pending' && (
                      <DetailedResultsShareBabyPeek
                        babyPeekPK={requisitions[0]?.baby_peek_pk}
                        page="Dashboard"
                      />
                    )}
                  <LandingGeneralContainer padding="20px">
                    <VideoEmbed
                      overlayText="Want to know more about UNITY Screen™?"
                      requisitionID={
                        (requisitions && requisitions[0]?.req_identifier) || ''
                      }
                    />
                  </LandingGeneralContainer>
                  <LandingGeneralContainer padding="0px">
                    <Card
                      {...landingPageCard(deviceType)}
                      buttons={[
                        {
                          buttonAction: () => history.push('scheduling'),
                          buttonText: 'Book a Consultation',
                        },
                      ]}
                    />
                  </LandingGeneralContainer>
                </LandingPageDesktopLeftWrapper>
                <LandingPageDesktopRightWrapper>
                  <LandingPageTitleWrapper>
                    {GetTypographyContent({
                      content: 'Have a question?',
                      size: 'large',
                      textColor: 'greyMedium',
                      isBold: true,
                    })}
                  </LandingPageTitleWrapper>
                  <LandingGeneralContainer padding="0px">
                    <LandingPageAccordionWrapper>
                      <Accordions
                        data={landingPageAccordions(
                          (requisitions && requisitions[0]?.req_identifier) ??
                            ''
                        )}
                        size="small"
                        isLinked
                      />
                      <LandingPageAccordionLinkWrapper>
                        {GetTypographyContent({
                          content: 'See all FAQs',
                          size: 'medium',
                          link: HELP_PAGE_URL,
                          textColor: 'greenDark',
                        })}
                      </LandingPageAccordionLinkWrapper>
                    </LandingPageAccordionWrapper>
                  </LandingGeneralContainer>
                </LandingPageDesktopRightWrapper>
              </LandingPageDesktopWrapper>
            </LandingPageDesktopWrapperPadding>
          </LandingPageWrapper>
        </>
      );
    }
    return (
      <LandingPageWrapper data-testid="LandingPageWrapper">
        <LandingPageTabletMobileWrapper deviceType={deviceType}>
          {isAddressOrInsuranceRequired(patientInfo) && (
            <LandingPageWarningWrapperMobile deviceType={deviceType}>
              <LandingPageWarningMobileTextWrapper>
                <WarningRounded />
                <LandingPageWarningText>
                  {getMissingProfileInfoMessage(
                    patientInfo?.phi.ins_status === 'Required',
                    patientInfo?.phi.address_status === 'Required',
                    patientInfo?.phi.prebilling_status === 'Required'
                  )}
                </LandingPageWarningText>
              </LandingPageWarningMobileTextWrapper>
              <Button
                size="medium"
                onClick={() => setInsuranceModalOpen(true)}
                label="UPDATE"
              />
            </LandingPageWarningWrapperMobile>
          )}
          {ConsentForm}
          {showOutcomesBanner && (
            <LandingPagePatientSurveyWrapper deviceType={deviceType}>
              <div style={{ fontSize: '28px', fontWeight: 700 }}>
                Help Us Improve Prenatal Care!
              </div>

              <div className="body">
                {`You will receive a link to complete a survey about your baby’s health 4 weeks after your due date. `}
              </div>

              <div className="body">
                Completing this survey will help us continue to improve our
                products. Thank you for your help!
              </div>
            </LandingPagePatientSurveyWrapper>
          )}

          {Boolean(
            requisitions?.filter(
              (req) => req.portal_bill_status === 'outstanding'
            ).length
          ) && (
            <LandingPageBillingMessageWrapper deviceType={deviceType}>
              <LandingPageBillIconGrid>
                <BillIcon />
                <LandingPageBillingTextWrapper>
                  <LandingPageBillingTextTop>
                    Your bill is ready
                  </LandingPageBillingTextTop>
                </LandingPageBillingTextWrapper>
              </LandingPageBillIconGrid>
              <LandingPageBillingTextBottom>
                <LandingPageBillingViewBill
                  onClick={() =>
                    history.push(
                      `${BILLING_URL}/${
                        requisitions?.filter(
                          (req) => req.portal_bill_status === 'outstanding'
                        )[0].bill_pk
                      }`
                    )
                  }
                >
                  VIEW MY BILL
                </LandingPageBillingViewBill>
              </LandingPageBillingTextBottom>
            </LandingPageBillingMessageWrapper>
          )}

          <LandingPageTabletMobileRowWrapper
            deviceType={deviceType}
            requisitionCount={requisitions?.length || 0}
            showBabyPeek={false}
            babyPeekAvailable={Boolean(
              requisitions &&
                (requisitions[0]?.reveal_status === 'available' ||
                  requisitions[0]?.reveal_status === 'removed')
            )}
          >
            <LandingPageTitleWrapper>
              {GetTypographyContent({
                content: 'Your Tests',
                size: 'h2',
                textColor: 'greyMedium',
                isBold: true,
                padding:
                  deviceType === 'mobile' ? '40px 0 0 15px' : '40px 0 0 0',
              })}
            </LandingPageTitleWrapper>
            {requisitions &&
              requisitions.map((requistion) => (
                <LandingGeneralContainer
                  padding="0px"
                  key={requistion.req_identifier}
                >
                  <TestResults
                    requisitionID={requistion.req_identifier}
                    setInsuranceModalOpen={setInsuranceModalOpen}
                  />
                </LandingGeneralContainer>
              ))}

            {Boolean(
              requisitions && requisitions[0]?.reveal_status === 'available'
            ) && (
              <LandingGeneralContainer padding="0px">
                {requisitions &&
                requisitions[0]?.reveal_status === 'removed' ? (
                  <BabyPeekToolTip
                    onClick={() => setTooltip(!tooltip)}
                    PopperProps={
                      {
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'white',
                            boxShadow: '0px 10px 20px rgb(0 0 0 / 10%)',
                            color: 'black',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '150%',
                            textAlign: 'center',
                            border: '1px solid #cbd5e0',
                          },
                        },
                        open: tooltip,
                      } as Partial<PopperProps>
                    }
                    title={babyPeekUnavailableText}
                  >
                    <div>
                      BabyPeek is currently unavailable <InfoOutlined />
                    </div>
                  </BabyPeekToolTip>
                ) : (
                  <BabyPeekAvailable>
                    <div>
                      <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                      <div>BabyPeek</div>
                    </div>
                    <div>
                      <BabyPeekButton
                        variant="filled"
                        content={<>View Results</>}
                        onClick={() =>
                          history.push(
                            `${BABYPEEK_URL}/${
                              requisitions && requisitions[0]?.baby_peek_pk
                            }/${
                              babyPeekStatusFlag === 'available'
                                ? 'summary'
                                : 'intro'
                            }?loc=dashboard`
                          )
                        }
                      />
                    </div>
                  </BabyPeekAvailable>
                )}
              </LandingGeneralContainer>
            )}
            {requisitions && requisitions[0]?.reveal_status === 'pending' && (
              <DetailedResultsShareBabyPeek
                babyPeekPK={requisitions[0]?.baby_peek_pk}
                page="Dashboard"
              />
            )}
            <LandingGeneralContainer
              padding={deviceType === 'mobile' ? '16px' : '20px'}
            >
              <VideoEmbed
                overlayText="Want to know more about UNITY Screen™?"
                requisitionID={
                  (requisitions && requisitions[0]?.req_identifier) || ''
                }
              />
            </LandingGeneralContainer>

            <LandingGeneralContainer
              padding={deviceType === 'mobile' ? '16px' : '0px'}
            >
              <Card
                {...landingPageCard(deviceType)}
                buttons={[
                  {
                    buttonAction: () => history.push('scheduling'),
                    buttonText: 'Book a Consultation',
                  },
                ]}
              />
            </LandingGeneralContainer>

            <LandingPageDesktopRightWrapper>
              <LandingPageTitleWrapper>
                {GetTypographyContent({
                  content: 'Have a question?',
                  size: 'xlarge',
                  textColor: 'greyMedium',
                  isBold: true,
                  padding:
                    deviceType === 'mobile' ? '40px 0 0 15px' : '40px 0 0 0',
                })}
              </LandingPageTitleWrapper>
              <LandingGeneralContainer padding="0px">
                <LandingPageAccordionWrapper>
                  <Accordions
                    data={landingPageAccordions(
                      (requisitions && requisitions[0]?.req_identifier) ?? ''
                    )}
                    isLinked={deviceType !== 'mobile'}
                    size="small"
                  />
                  <LandingPageAccordionLinkWrapper>
                    {GetTypographyContent({
                      content: 'See all FAQs',
                      size: 'medium',
                      link: HELP_PAGE_URL,
                      textColor: 'greenDark',
                    })}
                  </LandingPageAccordionLinkWrapper>
                </LandingPageAccordionWrapper>
              </LandingGeneralContainer>
            </LandingPageDesktopRightWrapper>
          </LandingPageTabletMobileRowWrapper>
        </LandingPageTabletMobileWrapper>
      </LandingPageWrapper>
    );
  };

  return (
    <>
      {!showBabyPeekPopUp && <BabyPeekOptIn />}
      {requisitions?.[0] && (
        <ProfileAlabamaWarning
          alabamaModalOpen={showABNFormPatient.show}
          setAlabamaModalOpen={(show: boolean) =>
            setShowABNFormPatient({ show, loaded: true })
          }
          requisition={requisitions?.[0]}
        />
      )}
      <ProfileInsuranceModal
        profileModalInsuranceOpen={insuranceModalOpen}
        setProfileModalInsuranceOpen={setInsuranceModalOpen}
        setInfoSubmitted={setInfoSubmitted}
      />
      {getLandingContent()}
    </>
  );
};

export default Landing;
