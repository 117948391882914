import * as React from 'react';
import { themePalette } from '../../theme';

export const CheckMark = ({
  color = themePalette.greyLight,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.99975 6.80001L1.66642 4.46667C1.40642 4.20667 0.993086 4.20667 0.733086 4.46667C0.473086 4.72667 0.473086 5.14001 0.733086 5.40001L3.52642 8.19334C3.78642 8.45334 4.20642 8.45334 4.46642 8.19334L11.5331 1.13334C11.7931 0.873338 11.7931 0.460005 11.5331 0.200005C11.2731 -0.0599951 10.8598 -0.0599951 10.5998 0.200005L3.99975 6.80001Z"
      fill={color}
    />
  </svg>
);
