import { BabyPeekReveal } from '../../../recoil/types';
import { DeviceType } from '../../../utils/hooks/useDeviceHook';
import { BASE_URL } from '../../../utils/requestUtils';

export interface SavedCardType {
  payment_method_id: string;
  last_four_digits: string;
  card_brand: string;
  wallet_type: string;
  is_used_for_payment_plan: boolean;
  is_chargeable: boolean;
}

export type Trait =
  | 'Sweet'
  | 'SweetSalty'
  | 'Bitter'
  | 'Cilantro'
  | 'Freckle'
  | 'BabyTeeth'
  | 'Asparagus'
  | 'EyeColor'
  | 'HairThickness'
  | 'CurlyHair'
  | 'RedHair'
  | 'LightOrDarkHair';

export type TraitLabel =
  | 'Eye Color'
  | 'Light or Dark Hair'
  | 'Hair Thickness'
  | 'Hair Texture'
  | 'Red Hair'
  | 'Freckles'
  | 'Teething Timeline'
  | 'Cilantro Aversion'
  | 'Asparagus'
  | 'Bitter Taste'
  | 'Sweet Taste'
  | 'Sweet vs. Salty';

export type LowerCasePageIndex =
  | 'sweet'
  | 'sweetsalty'
  | 'bitter'
  | 'cilantro'
  | 'freckle'
  | 'babyteeth'
  | 'asparagus'
  | 'eyecolor'
  | 'hairthickness'
  | 'curlyhair'
  | 'redhair'
  | 'lightordarkhair'
  | 'checkout'
  | 'intro'
  | 'socialpromo'
  | 'summary'
  | 'samplereport'
  | 'games'
  | 'advocacy';

export type BabyPeekButtonNames =
  | 'IntroSample'
  | 'IntroMainUnlock'
  | 'IntroStickyUnlock'
  | 'IntroStickySample'
  | 'PromoUnlock'
  | 'IntroDNAUnlock'
  | 'SampleReportUnlock'
  | 'IntroPromoUnlock'
  | 'PromoJuly4';

export interface BabyPeekTraitResponse {
  requisition: string;
  red_hair_prob: number;
  black_hair_prob: number;
  dark_brown_hair_prob: number;
  brown_hair_prob: number;
  dark_blonde_hair_prob: number;
  blonde_hair_prob: number;
  brown_eyes_prob: number;
  inter_eyes_prob: number;
  blue_eyes_prob: number;
  bitter_taste_prob: number;
  texture_prob: number;
  asparagus_detection_prob: number;
  freckles_prob: number;
  salty_sweet_prob: number;
  aversion_to_cilantro_prob: number;
  hair_thickness_dosage: number;
  time_first_tooth_dosage: number;
  sugar_sensitivity_dosage: number;
  umami_sensitivity_dosage: number;
  reveal_status: BabyPeekReveal;
  reveal_free_trait: boolean;
  bill_details: {
    balance: number;
    portal_bill_status:
      | 'not_available'
      | 'outstanding'
      | 'paid_in_full'
      | 'failure'
      | 'in_progress'
      | 'refunded';
    client_secret?: string;
    start_of_payment_amount: number;
    pk: string;
    is_payment_plan_enabled: boolean;
    show_processing_fee_offer: boolean;
    signup_code: string;
    promo_code: {
      code?: string;
      amount_discounted?: number;
      starting_balance?: number;
      is_enabled?: boolean;
    };
  };
  experiment_details: {
    experiment_id: number;
    landing_page: 'a' | 'b';
    initial_notification_time: string;
    email_and_sms_pairing: string;
    second_notification_offset: string;
    trait_order: 'a' | 'b';
    scroll_type: 'a' | 'b';
    offer_discount: boolean;
    mobile_checkout_type: 'a' | 'b';
  };
  unlock_type: 'payment' | 'terms_only' | 'advocacy' | 'discount_payment';
  first_name: string;
}

export interface BabyPeekFeedbackResponse {
  trait_feedback: {
    [key in Trait]: boolean;
  };
  has_general_feedback: boolean;
}

const getHeaders = (auth_token?: string) => {
  if (auth_token) {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth_token}`,
    } as HeadersInit | undefined;
  }
  return {
    'Content-Type': 'application/json',
  } as HeadersInit | undefined;
};

export const GetBabyPeekTraitDataRequest = async (
  trait_pk: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/shared_trait/${trait_pk}/`, {
    method: 'GET',
    headers: getHeaders(auth_token),
  });

export const GetBabyPeekDataRequest = async (
  babypeek_pk: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/`, {
    method: 'GET',
    headers: getHeaders(auth_token),
  });

export const PostBabyPeekAction = async (
  babypeek_pk: string,
  action_type: string,
  device_type: DeviceType,
  is_from_email?: boolean,
  is_from_sms?: boolean,
  meta_data?: { [key: string]: string | undefined }
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/actions/`, {
    body: JSON.stringify({
      action_type,
      device_type,
      is_from_email,
      is_from_sms,
      meta_data,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const convertPercentage = (percentage: number): number => {
  if (percentage < 0.01) return 0.99;
  if (percentage > 0.99) return 99.1;
  return Math.round(Number((percentage * 100).toFixed(2)));
};

export const GetBabyPeekFeedback = async (
  babypeek_pk: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/feedback/`, {
    method: 'GET',
    headers: getHeaders(auth_token),
  });

export const PostBabyPeekUpdateGiftEmail = async (
  babypeek_pk: string,
  client_secret: string,
  receipt_email: string,
  auth_token?: string
): Promise<Response> =>
  fetch(
    `${BASE_URL}/api/v1/u_traits/${babypeek_pk}/update_gifting_receipt_email/`,
    {
      body: JSON.stringify({
        client_secret,
        receipt_email,
      }),
      method: 'POST',
      headers: getHeaders(auth_token),
    }
  );

export const PostGeneralBabyPeekFeedback = async (
  babypeek_pk: string,
  interest_level: number,
  recommend_level: number,
  custom_feedback: string,
  agree_to_be_contacted: boolean,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/submit_general_feedback/`, {
    body: JSON.stringify({
      interest_level,
      recommend_level,
      custom_feedback,
      agree_to_be_contacted,
    }),
    method: 'POST',
    headers: getHeaders(auth_token),
  });

export const PostTraitBabyPeekOneTime = async (
  babypeek_pk: string,
  is_saved: boolean,
  payment_method_id?: string,
  email?: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/submit_one_time_payment/`, {
    method: 'POST',
    body: JSON.stringify({ email, is_saved, payment_method_id }),
    headers: getHeaders(auth_token),
  });

export const GetBabyPeekPaymentPlan = async (
  babypeek_pk: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/get_payment_plan/`, {
    method: 'GET',
    headers: getHeaders(auth_token),
  });

export const GetBabyPeekSingleTrait = async (
  babypeek_pk: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/trait/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const GetSavedCardsBabyPeek = async (
  babypeek_pk: string,
  access_token: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/traits/${babypeek_pk}/payment_methods/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  });

export const PayWithSavedCardBabyPeek = async (
  babypeek_pk: string,
  payment_method_id: string,
  email: string,
  payment_type: 'pay_in_full' | 'payment_plan',
  access_token: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/traits/${babypeek_pk}/submit_saved_payment/`, {
    method: 'POST',
    body: JSON.stringify({ payment_method_id, email, payment_type }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  });

export const ShareBabyPeekPDFPayment = async (
  babypeek_pk: string,
  custom_message: string,
  email: string,
  language_code: 'es' | 'en'
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/u_traits/${babypeek_pk}/generate_pdf/`, {
    method: 'POST',
    body: JSON.stringify({ custom_message, language_code, email }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const GetBabyPeekTraitSharing = async (
  babypeek_pk: string,
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/traits/${babypeek_pk}/share_status`, {
    method: 'GET',
    headers: getHeaders(auth_token),
  });

export const PostBabyPeekTraitSharing = async (
  babypeek_pk: string,
  trait_name: string,
  share_status: 'shared' | 'not_shared',
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/traits/${babypeek_pk}/share_status/`, {
    method: 'POST',
    headers: getHeaders(auth_token),
    body: JSON.stringify([{ trait_name, share_status }]),
  });

export const getTraitDocumentLabel = (page: LowerCasePageIndex): string => {
  if (page === 'sweet') return 'Sweet taste';
  if (page === 'sweetsalty') return 'Sweet vs. Salty ';
  if (page === 'bitter') return 'Bitter taste';
  if (page === 'cilantro') return 'Cilantro aversion';
  if (page === 'freckle') return 'Freckles';
  if (page === 'babyteeth') return 'Teething timeline';
  if (page === 'asparagus') return 'Asparagus odor detection';
  if (page === 'eyecolor') return 'Eye color';
  if (page === 'hairthickness') return 'Hair Thickness';
  if (page === 'curlyhair') return 'Curly hair';
  if (page === 'redhair') return 'Red hair';
  if (page === 'lightordarkhair') return 'Light or dark hair';
  if (page === 'checkout') return 'Checkout';
  if (page === 'intro') return 'Intro';
  if (page === 'summary') return 'Summary';
  if (page === 'samplereport') return 'Sample report';
  return 'BabyPeek';
};

export const getUnlockButtonText = (): string => 'Unlock BabyPeek';

export const BabyPeekDownloadGames = async (
  babypeek_pk: string,
  game_type: 'GuessTheLittleOne' | 'PeekABoo' | 'TraitsReveal',
  download_type: 'AnswerSheet' | 'GamePlay' | 'Preview',
  auth_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/traits/${babypeek_pk}/download_game/`, {
    method: 'POST',
    headers: getHeaders(auth_token),
    body: JSON.stringify({ game_type, download_type }),
  });
