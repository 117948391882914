import styled from 'styled-components/macro';

export const SpinnerWrapper = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  img {
    width: 50px;
  }
`;
