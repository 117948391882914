import {
  BabyPeekMobleTraitsMenuContainer,
  BabyPeekMobleTraitsMenuItem,
} from '../../BabyPeekViewResults.styles';
import { Trait } from '../../Services/BabyPeekService';

const traitArray = [
  'Asparagus',
  'Bitter',
  'Cilantro',
  'EyeColor',
  'Freckle',
  'CurlyHair',
  'HairThickness',
  'LightOrDarkHair',
  'RedHair',
  'Sweet',
  'SweetSalty',
  'BabyTeeth',
] as Trait[];

const getTraitLabel = (trait: Trait) => {
  if (trait === 'LightOrDarkHair') return 'Light or dark hair';
  if (trait === 'RedHair') return 'Red hair';
  if (trait === 'CurlyHair') return 'Hair texture';
  if (trait === 'HairThickness') return 'Hair thickness';
  if (trait === 'EyeColor') return 'Eye color';
  if (trait === 'BabyTeeth') return 'Teething timeline';
  if (trait === 'Sweet') return 'Sweet taste';
  if (trait === 'SweetSalty') return 'Sweet vs salty';
  if (trait === 'Freckle') return 'Freckles';
  if (trait === 'Bitter') return 'Bitter taste';
  if (trait === 'Cilantro') return 'Cilantro aversion';
  if (trait === 'Asparagus') return 'Asparagus odor detection';
  return '';
};

export const TraitMenu = (
  setCurrentTrait: (trait: Trait) => void,
  currentTrait: Trait
): React.ReactElement => (
  <BabyPeekMobleTraitsMenuContainer>
    {traitArray.map((trait) => (
      <BabyPeekMobleTraitsMenuItem
        onClick={() => setCurrentTrait(trait)}
        selected={currentTrait === trait}
      >
        {getTraitLabel(trait)}
      </BabyPeekMobleTraitsMenuItem>
    ))}
  </BabyPeekMobleTraitsMenuContainer>
);
