import React, { useEffect, useState } from 'react';
import Dropzone, {
  IFileWithMeta,
  IPreviewProps,
  StatusValue,
} from 'react-dropzone-uploader';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import {
  FileUploaderBrowse,
  FileUploaderDropzoneWrapper,
  FileUploaderWrapper,
  FileUploaderPreview,
  FileUploaderPreviewButton,
  FileUploaderPreviewTitle,
  FileUploaderPreviewDelete,
  FileUploaderPreviewError,
  FileNameUploaded,
  FileUploaderPreviewMobileImage,
  ProfileInsuranceFileUploaderGreyText,
  ProfileInsuranceFileUploaderDropzoneAntigen,
  FileUploaderTitle,
  ProfileInsuranceFileUploaderMobile,
  FileUploaderPreviewMobileWrapper,
} from './ProfileInsuranceFileUploader.styles';
import 'react-dropzone-uploader/dist/styles.css';

import { themePalette } from '../../theme';
import { SuccessUploadIcon } from '../../assets/iconComponents/SuccessUploadIcon';
import { ExclamationIcon } from '../../assets/iconComponents/ExclamationIcon';
import { getUserAuth } from '../../recoil/selectors';
import { uploadProfileInsuranceImageForProcessing } from './Services/UpdateProfileInfoService';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { UploadSecondaryInsurance } from '../UpfrontPayment/Services/UpfrontPaymentService';
import { toBase64 } from '../../utils/helpers';

export interface FileUploadInfo {
  [fileName: string]: {
    fileName: string;
    id: string;
    valid: boolean;
    file: string;
    side: 'back' | 'front';
  };
}

const InsuranceFileUploadFeedback = (
  side?: 'front' | 'back',
  upfrontPayment?: boolean
) => {
  if (side) {
    return (
      <ProfileInsuranceFileUploaderMobile financialAssistance>
        {`Add ${side} image`}
      </ProfileInsuranceFileUploaderMobile>
    );
  }
  return (
    <ProfileInsuranceFileUploaderDropzoneAntigen key="custom">
      <FileUploaderBrowse upfrontPayment={upfrontPayment}>
        UPLOAD FILE
      </FileUploaderBrowse>
      <ProfileInsuranceFileUploaderGreyText>
        or drag and drop files here
      </ProfileInsuranceFileUploaderGreyText>
    </ProfileInsuranceFileUploaderDropzoneAntigen>
  );
};

interface FileUploaderProps {
  value: FileUploadInfo;
  setValue: (files: FileUploadInfo) => void;
  uploadProcessing: boolean;
  isError: boolean;
  setUploadProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  upfrontPayment?: 'primary' | 'secondary' | 'medicaid_or_hmo';
  medicaid?: boolean;
}

const ProfileInsuranceFileUploader: React.FC<FileUploaderProps> = ({
  value,
  setValue,
  uploadProcessing,
  setUploadProcessing,
  isError,
  upfrontPayment,
  medicaid,
}) => {
  const [dropzoneKey] = useState(0);
  const [activeFiles, setActiveFiles] = useState<FileUploadInfo>({});
  const [duplicateError, setDuplicateError] = useState<string>('');
  const { deviceType } = useDeviceHook();
  const authInfo = useRecoilValue(getUserAuth);
  const { billingPK, adminID } =
    useParams<{ billingPK: string; adminID?: string }>();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (duplicateError) {
      timeout = setTimeout(() => setDuplicateError(''), 4000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [duplicateError]);

  const PreviewComponent = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, previewUrl } = meta;
    const { remove } = fileWithMeta;

    if (deviceType === 'mobile') {
      return (
        <FileUploaderPreviewMobileWrapper>
          <FileUploaderPreviewMobileImage>
            {' '}
            <img src={previewUrl} alt="ins-side" />{' '}
          </FileUploaderPreviewMobileImage>
          {activeFiles[name]?.valid !== undefined && (
            <FileUploaderPreviewButton onClick={() => remove()}>
              Remove
            </FileUploaderPreviewButton>
          )}
        </FileUploaderPreviewMobileWrapper>
      );
    }

    return (
      <FileUploaderPreview
        previewUrl={previewUrl}
        validUpload={activeFiles[name]?.valid}
      >
        <FileUploaderPreviewTitle>
          {activeFiles[name]?.valid !== undefined &&
            (activeFiles[name]?.valid ? (
              <SuccessUploadIcon />
            ) : (
              <ExclamationIcon color={themePalette.redMedium} />
            ))}
          <FileNameUploaded> {name} </FileNameUploaded>
        </FileUploaderPreviewTitle>
        <FileUploaderPreviewDelete>
          <FileUploaderPreviewError>
            {activeFiles[name]?.valid === false ? 'Upload Failed!' : ''}
          </FileUploaderPreviewError>
          {activeFiles[name]?.valid !== undefined && (
            <FileUploaderPreviewButton onClick={() => remove()}>
              Remove
            </FileUploaderPreviewButton>
          )}
        </FileUploaderPreviewDelete>
      </FileUploaderPreview>
    );
  };

  useEffect(() => {
    setValue(activeFiles);
  }, [activeFiles]);

  const handleChangeStatus: (
    recentFile: IFileWithMeta,
    status: StatusValue,
    side: 'back' | 'front'
  ) => void = async (
    recentFile: IFileWithMeta,
    status: StatusValue,
    side: 'back' | 'front'
  ) => {
    const activeFileName = activeFiles[recentFile.file.name]?.fileName;
    const activeID = activeFiles[recentFile.file.name]?.id;
    const uploadFunction = upfrontPayment
      ? UploadSecondaryInsurance
      : uploadProfileInsuranceImageForProcessing;

    if (activeFileName && status === 'done' && deviceType !== 'mobile') {
      setDuplicateError('Cannot upload same file twice.');
    }

    if (status === 'done' && (authInfo || billingPK)) {
      if (!activeFileName || deviceType === 'mobile') {
        setUploadProcessing(true);
        const base64Image = await toBase64(recentFile.file);
        const response = await uploadFunction(
          base64Image,
          billingPK || authInfo?.access_token || '',
          upfrontPayment,
          adminID
        );
        setUploadProcessing(false);
        if (response) {
          await setActiveFiles((active) => ({
            ...active,
            [deviceType === 'mobile'
              ? `${recentFile.file.name}${recentFile.meta.id}`
              : recentFile.file.name]: {
              ...response,
              id: activeID || recentFile.meta.id,
              file: base64Image,
              side,
            },
          }));
          recentFile.restart();
          if (activeID) {
            recentFile.remove();
          }
        }
      } else {
        recentFile.remove();
      }
    } else if (status === 'removed') {
      const oldActive = { ...activeFiles };
      if (oldActive[recentFile.file.name]?.id === recentFile?.meta.id) {
        delete oldActive[recentFile.file.name];
        await setActiveFiles(oldActive);
      }
    }
  };

  return (
    <>
      <ErrorPopup message={duplicateError} hideMessage={setDuplicateError} />
      <FileUploaderWrapper>
        <FileUploaderTitle
          isError={Boolean(
            isError &&
              !Object.keys(value).filter((file) => value[file].side === 'front')
                .length
          )}
        >
          {`Front of your ${medicaid ? 'medicaid' : 'insurance'} card*`}
        </FileUploaderTitle>
        <FileUploaderDropzoneWrapper deviceType={deviceType}>
          <Dropzone
            disabled={uploadProcessing}
            key={dropzoneKey}
            accept=".png, .jpg, .jpeg, .pdf"
            onChangeStatus={(recentFile: IFileWithMeta, status: StatusValue) =>
              handleChangeStatus(recentFile, status, 'front')
            }
            submitButtonDisabled
            styles={{
              dropzone: {
                border: 'none',
                height: 'fit-content',
                overflow: 'hidden',
                background: themePalette.neutral50,
                display: 'flex',
                flexFlow: 'wrap',
                flexDirection: 'row',
                overflowY: 'scroll',
                alignItems: 'start',
                justifyContent: 'center',
              },
              submitButtonContainer: {
                display: 'none',
              },
              preview: {
                backgroundColor: themePalette.redMedium,
                padding: '20px 3%',
              },
            }}
            inputContent={InsuranceFileUploadFeedback(
              deviceType === 'mobile' ? 'front' : undefined,
              Boolean(upfrontPayment)
            )}
            PreviewComponent={PreviewComponent}
          />
        </FileUploaderDropzoneWrapper>
        <FileUploaderTitle
          isError={Boolean(
            isError &&
              !Object.keys(value).filter((file) => value[file].side === 'back')
                .length
          )}
        >
          {`Back of your ${medicaid ? 'medicaid' : 'insurance'} card*`}
        </FileUploaderTitle>
        <FileUploaderDropzoneWrapper deviceType={deviceType}>
          <Dropzone
            disabled={uploadProcessing}
            key={dropzoneKey}
            accept=".png, .jpg, .jpeg, .pdf"
            onChangeStatus={(recentFile: IFileWithMeta, status: StatusValue) =>
              handleChangeStatus(recentFile, status, 'back')
            }
            submitButtonDisabled
            styles={{
              dropzone: {
                border: 'none',
                height: 'fit-content',
                overflow: 'hidden',
                background: themePalette.neutral50,
                display: 'flex',
                flexFlow: 'wrap',
                flexDirection: 'row',
                overflowY: 'scroll',
                alignItems: 'start',
                justifyContent: 'center',
              },
              submitButtonContainer: {
                display: 'none',
              },
              preview: {
                backgroundColor: themePalette.redMedium,
                padding: '20px 3%',
              },
            }}
            inputContent={InsuranceFileUploadFeedback(
              deviceType === 'mobile' ? 'back' : undefined,
              Boolean(upfrontPayment)
            )}
            PreviewComponent={PreviewComponent}
          />
        </FileUploaderDropzoneWrapper>
      </FileUploaderWrapper>
    </>
  );
};

export default ProfileInsuranceFileUploader;
