import React from 'react';

import { useWindowWidth } from '@react-hook/window-size';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from '../Shared/BabyPeekTrait.styles';
import CurlyHairBackground from './CurlyHairBackground.png';
import CurlyHairIcon from './CurlyHairIcon.png';

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from '../../Services/BabyPeekService';
import { useDeviceHook } from '../../../../utils/hooks/useDeviceHook';
import { TraitNavigationLeft } from '../Shared/TraitNavigationLeft';
import { TraitNavigationRight } from '../Shared/TraitNavigationRight';
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from '../../BabyPeekStripe.styles';
import { BabyPeekButton } from '../../BabyPeekButton';
import { TraitMenu } from '../Shared/TraitMenu';
import { BabyPeekTraitAccordion } from '../Shared/BabyPeekTraitAccordion';
import { CurlyHairGene } from './CurlyHairGene';
import { CurlyHairHowItWorks } from './CurlyHairHowItWorks';
import { CurlyHairOtherFactors } from './CurlyHairOtherFactors';
import { BabyPeekGauge } from '../Shared/BabyPeekGauge';
import { ShareUnderline } from '../../BabyPeekIcons/ShareUnderline';
import { BabyPeekSingleFeedback } from '../../BabyPeekSingleFeedback';

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      How does hair get its texture?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Hair texture is determined by the shape of individual hair strands. Hair
      strands grow out from a small pore in the scalp called the{' '}
      <span>“follicle.”</span>
      <br />
      Whether strands curve or curl is likely related to how cells within the
      hair follicle divide and grow. Cells that grow in a single-file line lead
      to straight hair while cells of different shapes and sizes lead to hair
      follicles that bend and curl.
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        {' '}
        <CurlyHairHowItWorks />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      {`How did we determine your baby's hair texture? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at 10 genetic variations that help determine hair texture.
      Variations in a specific gene called <span className="italic">TCHH</span>{' '}
      are well-studied. This gene helps the body make proteins in the hair
      strand called keratins which in turn help decide if hair strands grow
      straight, curved, or curly.
      <br />
      <BabyPeekV2SectionTitle>The gene we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <CurlyHairGene />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect hair thickness?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect hair texture include:
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        {' '}
        <CurlyHairOtherFactors />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{' '}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${'(650) 460-2551'}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const CurlyHairTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData?: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  hideTraitMenu: boolean;
  publicTrait?: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
  curlyHairVal?: number;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  curlyHairVal,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== 'desktop'
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const curlyTraits = [
    {
      color: '#FCD712',
      gradient:
        'linear-gradient(90deg, rgba(252, 214, 17, 0.48) 0%, #FCD611 98.96%)',
      name: 'Hair texture',
      percentage: traitData
        ? convertPercentage(traitData.texture_prob)
        : convertPercentage(curlyHairVal || 0),
      textColor: '#000000',
    },
  ].sort((a, b) => b.percentage - a.percentage);

  return (
    <>
      {' '}
      <div
        style={{
          backgroundImage: `url(${CurlyHairBackground})`,
          height: '220px',
          backgroundSize: 'cover',
          padding: '20px',
          display: 'grid',
          justifyContent: deviceType !== 'mobile' ? 'center' : 'flex-start',
          paddingBottom: '32px',
          paddingTop: '24px',
          gridGap: '12px',
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={CurlyHairIcon}
              alt="CurlyHairIcon"
              style={{ justifySelf: 'center' }}
            />
            <div>Hair Texture</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {' '}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? `The baby is more likely `
              : `Your baby is more likely `}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`to ${
              curlyTraits[0].percentage >= 50 ? '' : 'not'
            } have curly hair`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div style={{ paddingBottom: '10px' }} />
        <BabyPeekGauge
          showGif={modalPreview || showAnimation}
          percentage={curlyTraits[0].percentage}
          bottomText="chance to have curly hair"
        />

        {!modalPreview && (
          <>
            {' '}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: '25px',
                paddingTop: '0px',
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'How it works',
                    content: HowItWorks,
                    key: 'howItWorksEye',
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'Other factors',
                    content: OtherFactors,
                    key: 'otherFactorsEye',
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'About BabyPeek',
                    content: AboutBabyPeek,
                    key: 'aboutBabyPeekEye',
                  },
                ]}
              />
            </div>{' '}
          </>
        )}
        {!modalPreview && !curlyHairVal && (
          <BabyPeekSingleFeedback onlyFeedback />
        )}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, 'CurlyHair')}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait('Freckle')}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{' '}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? ' Hide Traits' : 'View Traits'}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait('HairThickness')}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
