import { themePalette } from '../../theme';

export const ArrowIcon = ({
  color = themePalette.redMedium,
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.29 6.46593C13.9 6.85955 13.9 7.4954 14.29 7.88903L18.17 11.8051H3C2.45 11.8051 2 12.2592 2 12.8143C2 13.3695 2.45 13.8236 3 13.8236H18.18L14.3 17.7397C13.91 18.1333 13.91 18.7691 14.3 19.1628C14.69 19.5564 15.32 19.5564 15.71 19.1628L21.3 13.5209C21.69 13.1272 21.69 12.4914 21.3 12.0978L15.7 6.46593C15.32 6.07231 14.68 6.07231 14.29 6.46593Z"
      fill={color}
    />
  </svg>
);
