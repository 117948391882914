import React, { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { useHistory, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { Modal, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';

import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck as faCircleCheckOutline } from '@fortawesome/pro-regular-svg-icons';
import { BabyPeekIntro } from './BabyPeekIntro';
import { LightOrDarkHairTrait } from './Traits/LightOrDarkHair/LightOrDarkHairTrait';
import { RedHairTrait } from './Traits/RedHair/RedHairTrait';
import { CurlyHairTrait } from './Traits/CurlyHair/CurlyHairTrait';
import { HairThicknessTrait } from './Traits/HairThickness/HairThicknessTrait';
import { EyeColorTrait } from './Traits/EyeColor/EyeColorTrait';
import { AsparagusTrait } from './Traits/Asparagus/AsparagusTrait';
import { BabyTeethTrait } from './Traits/BabyTeeth/BabyTeethTrait';
import { FreckleTrait } from './Traits/Freckles/FreckleTrait';
import { CilantroTrait } from './Traits/Cilantro/CilantroTrait';
import { BitterTrait } from './Traits/BitterTaste/BitterTrait';
import { SweetSaltyTrait } from './Traits/SweetSalty/SweetSaltyTrait';
import { SweetTrait } from './Traits/Sweet/SweetTrait';
import {
  BabyPeekButtonNames,
  BabyPeekFeedbackResponse,
  BabyPeekTraitResponse,
  GetBabyPeekDataRequest,
  GetBabyPeekFeedback,
  LowerCasePageIndex,
  PostBabyPeekAction,
  Trait,
  getTraitDocumentLabel,
} from './Services/BabyPeekService';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

import { BabyPeekIntroMobile } from './BabyPeekIntroMobile';

import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import {
  BABYPEEK_URL,
  LANDING_PAGE_URL,
  LOGIN_PAGE_URL,
} from '../../utils/urlConstants';
import { BabyPeekStripe } from './BabyPeekStripe';

import {
  BabyPeekAdvocacyModalConfettiWrapper,
  BabyPeekAdvocacyModalWrapper,
  BabyPeekAdvocacyModalText,
  BabyPeekAdvocacyModalButton,
  BabyPeekProcessingStepperWrapper,
  BabyPeekProcessingStepperLabel,
} from './BabyPeekAdvocacy.styles';
import { BabyPeekButton } from './BabyPeekButton';
import { UpfrontPaymentMainTextCentered } from '../UpfrontPayment/UpfrontPayment.styles';

import { BabyPeekShareTraitModal } from './BabyPeekShareTraitModal';
import { getUserAuth } from '../../recoil/selectors';
import { BabyPeekViewResultsMobile } from './BabyPeekViewResultsMobile';
import { useBabyPeekLoginScreen } from './Services/useBabyPeekLoginScreen';
import { BabyPeekFooter } from '../Footer/BabyPeekFooter';
import { BabyPeekGames } from './BabyPeekGames';

export const BabyPeek: React.FC = () => {
  let stripeBabyPeekPromise: Promise<Stripe | null>;
  if (import.meta.env.VITE_BABYPEEK_STRIPE_PK) {
    stripeBabyPeekPromise = loadStripe(import.meta.env.VITE_BABYPEEK_STRIPE_PK);
  } else {
    const message = 'VITE_BABYPEEK_STRIPE_PK env var not set';
    // this should probably be a helper
    if (
      import.meta.env.VITE_ENV === 'production' ||
      import.meta.env.VITE_ENV === 'staging'
    ) {
      Sentry.captureMessage(message, 'fatal');
    } else {
      // eslint-disable-next-line
      console.error(message);
    }
  }

  const params = new URLSearchParams(window.location.search);

  // const auth = useRecoilValue(getUserAuth);
  const isGifting = (params.get('gifting') as 'true' | 'false') === 'true';
  const [isFromEmailLink, setIsFromEmailLink] = useState(false);
  const [isFromSmsLink, setIsFromSmsLink] = useState(false);
  const [isFromLoc, setIsFromLoc] = useState<string>();
  const [traitData, setTraitData] = useState<BabyPeekTraitResponse>();
  const [lastButtonClicked, setLastButtonClicked] =
    useState<BabyPeekButtonNames>();
  const [feedbackData, setFeedback] = useState<BabyPeekFeedbackResponse>();
  const { deviceType } = useDeviceHook();

  const auth = useRecoilValue(getUserAuth);

  const [babyPeekUnlockOpen, setBabyPeekUnlockOpen] = useState(false);
  const [babyPeekProcessing, setBabyPeekProcessing] = useState([
    {
      label: 'Sequencing your baby’s DNA',
      status: 'pending',
    },
    { label: 'Analyzing trait probabilities', status: 'empty' },
    {
      label: 'Predicting your baby’s traits',
      status: 'empty',
    },
    {
      label: 'Finalizing outcomes',
      status: 'empty',
    },
  ]);
  const [startBabyPeekProcessing, setStartBabyPeekProcessing] = useState(false);

  useEffect(() => {
    if (startBabyPeekProcessing) {
      const interval = setInterval(() => {
        setProcessing();
      }, 2000);
      const setProcessing = () => {
        const pending = babyPeekProcessing.findIndex(
          (step) => step.status === 'pending'
        );

        if (pending !== undefined && pending > -1) {
          setBabyPeekProcessing((prev) => {
            const newProcessing = [...prev];

            newProcessing[pending].status = 'done';

            if (newProcessing[pending + 1]) {
              newProcessing[pending + 1].status = 'pending';
            }
            return newProcessing;
          });
        } else {
          clearInterval(interval);
        }
      };
    }
  }, [startBabyPeekProcessing]);
  // babyPeekPublicUnlockOpen is used when user has purchased BabyPeek without login
  const [babyPeekPublicUnlockOpen, setBabyPeekPublicUnlockOpen] =
    useState(false);

  const [lastSentPageAction, setLastSetPageAction] = useState('');
  const [shareTraitModal, setShareTraitModal] = useState(false);
  const [campaign, setCampaign] = useState('');

  const windowWidth = useWindowWidth();
  const { babypeekPK } = useParams<{
    babypeekPK: string;
    pageIndex?:
      | Trait
      | 'Intro'
      | 'Advocacy'
      | 'Summary'
      | 'Trait'
      | 'Checkout'
      | 'SocialPromo'
      | 'Games'
      | 'SampleReport';
  }>();

  let { pageIndex } = useParams<{
    babypeekPK: string;
    pageIndex?:
      | Trait
      | 'Intro'
      | 'Advocacy'
      | 'Summary'
      | 'Trait'
      | 'Checkout'
      | 'SocialPromo'
      | 'SampleReport'
      | 'Games'
      | LowerCasePageIndex;
  }>();

  pageIndex = pageIndex?.toLowerCase() as LowerCasePageIndex;
  document.title = `${getTraitDocumentLabel(pageIndex)}`;

  const history = useHistory();
  const goToLoginPage = () => {
    history.push(LOGIN_PAGE_URL);
  };
  useBabyPeekLoginScreen(pageIndex, traitData?.bill_details.signup_code);
  const setViewTrait = (viewTrait?: Trait) => {
    history.push(`${BABYPEEK_URL}/${babypeekPK}/${viewTrait ?? ''}`);
  };

  const getCharge = () => traitData?.bill_details.balance || 999;

  const options = useMemo(
    () => ({
      mode: 'payment' as 'payment' | 'setup' | 'subscription' | undefined,
      paymentMethodCreation: 'manual' as 'manual' | undefined,
      currency: 'usd',
      amount: getCharge(),
      appearance: {
        /* ... */
      },
    }),

    [traitData]
  );

  useEffect(() => {
    if (isGifting && auth) {
      history.push(LANDING_PAGE_URL);
    }
  }, [isGifting, auth]);

  useEffect(() => {
    try {
      const fromEmail = params.get('e'); // e is set from a sign up email link
      setIsFromEmailLink(fromEmail === 'True');
    } catch (e) {
      // from sms
      setIsFromEmailLink(false);
    }
  }, []);

  useEffect(() => {
    try {
      const campaignParam = params.get('c');
      setCampaign(campaignParam || '');
    } catch (e) {
      setCampaign('');
    }
  }, []);

  useEffect(() => {
    try {
      const fromLoc = params.get('loc');
      setIsFromLoc(fromLoc || undefined);
    } catch (e) {
      //
    }
  }, []);

  useEffect(() => {
    try {
      const fromSms = params.get('s'); // s is set from the sms link
      setIsFromSmsLink(fromSms === 'True');
    } catch (e) {
      setIsFromSmsLink(false);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await GetBabyPeekDataRequest(
        babypeekPK,
        auth?.access_token
      );
      if (response.ok) {
        const responseJson = (await response.json()) as BabyPeekTraitResponse;

        setTraitData(responseJson);
      } else {
        history.push(LANDING_PAGE_URL);
      }
    };
    getData();
  }, []);

  const getTraitFeedback = async () => {
    const response = await GetBabyPeekFeedback(babypeekPK, auth?.access_token);
    if (response.ok) {
      const responseJson = (await response.json()) as BabyPeekFeedbackResponse;
      setFeedback(responseJson);
    }
  };

  useEffect(() => {
    getTraitFeedback();
  }, []);

  useEffect(() => {
    if (traitData) {
      if (
        traitData?.reveal_status === 'removed' ||
        traitData?.reveal_status === 'removed_prepaid' ||
        traitData?.reveal_status === 'dismissed' ||
        traitData?.reveal_status === 'never' ||
        traitData?.reveal_status === 'hidden'
      ) {
        history.push(LANDING_PAGE_URL);
      }
    }
  }, [traitData]);

  useEffect(() => {
    if (!pageIndex && traitData) {
      history.push(
        `${BABYPEEK_URL}/${babypeekPK}/${
          traitData?.reveal_status === 'available' ? 'summary' : 'intro'
        }`
      );
    }
    if (
      traitData?.reveal_status === 'available' &&
      (pageIndex === 'intro' ||
        pageIndex === 'samplereport' ||
        pageIndex === 'checkout' ||
        pageIndex === 'socialpromo') &&
      !babyPeekUnlockOpen
    ) {
      history.push(`${BABYPEEK_URL}/${babypeekPK}/Summary`);
    }
    if (
      traitData &&
      traitData?.reveal_status !== 'available' &&
      pageIndex &&
      pageIndex !== 'intro' &&
      pageIndex !== 'samplereport' &&
      pageIndex !== 'checkout' &&
      pageIndex !== 'socialpromo'
    ) {
      history.push(`${BABYPEEK_URL}/${babypeekPK}/Intro`);
    }
  }, [pageIndex, traitData]);

  useEffect(() => {
    if (babyPeekPublicUnlockOpen) {
      history.push(`${BABYPEEK_URL}/${babypeekPK}/Checkout`);
    }
    // correct any route typos to intro page
    if (
      ['pending'].includes(
        traitData?.reveal_status ? traitData?.reveal_status : ''
      ) &&
      pageIndex !== 'checkout' &&
      pageIndex !== 'samplereport' &&
      pageIndex !== 'socialpromo'
    ) {
      history.push(`${BABYPEEK_URL}/${babypeekPK}/Intro`);
    }
    // don't allow pretest
    if (
      traitData?.reveal_status === 'pretest' ||
      traitData?.reveal_status === 'prepaid'
    ) {
      history.push(LANDING_PAGE_URL);
    }
    //
  }, [traitData, babyPeekPublicUnlockOpen]);

  useEffect(() => {
    const sendPageAction = async () => {
      window.scrollTo(0, 0);
      let pageAction: string | undefined;
      if (pageIndex === 'summary' && traitData?.reveal_status === 'available') {
        pageAction = 'ViewedSummaryPage';
      }
      if (pageIndex === 'games' && traitData?.reveal_status === 'available') {
        pageAction = 'ViewedGamesPage';
      } else if (
        pageIndex === 'intro' &&
        traitData?.reveal_status !== 'available'
      ) {
        pageAction = 'ViewedInfoPage';
      } else if (pageIndex === 'samplereport') {
        pageAction = 'ViewedSampleReport';
      } else if (
        pageIndex === 'checkout' &&
        traitData?.reveal_status !== 'available'
      ) {
        pageAction = 'ViewedUnlockPage';
      } else if (
        pageIndex === 'socialpromo' &&
        traitData?.reveal_status !== 'available'
      ) {
        pageAction = 'BabyPeekSocialMediaPromo20Viewed';
      } else if (
        pageIndex === 'advocacy' &&
        traitData?.reveal_status !== 'available'
      ) {
        pageAction = 'ViewedUnlockPage';
      } else if (traitData) {
        switch (pageIndex) {
          case 'sweet':
            pageAction = 'ViewedSweetTaste';
            break;

          case 'sweetsalty':
            pageAction = 'ViewedSweetVsSaltyTaste';
            break;
          case 'bitter':
            pageAction = 'ViewedBitterTaste';
            break;
          case 'cilantro':
            pageAction = 'ViewedCilantro';
            break;
          case 'freckle':
            pageAction = 'ViewedFreckles';
            break;

          case 'babyteeth':
            pageAction = 'ViewedTeeth';
            break;

          case 'asparagus':
            pageAction = 'ViewedAsparagus';
            break;

          case 'eyecolor':
            pageAction = 'ViewedEyeColor';
            break;

          case 'hairthickness':
            pageAction = 'ViewedHairThickness';
            break;

          case 'curlyhair':
            pageAction = 'ViewedCurlyHair';
            break;

          case 'redhair':
            pageAction = 'ViewedRedHair';
            break;

          case 'lightordarkhair':
            pageAction = 'ViewedLightOrDarkHair';
            break;

          default:
        }
      }

      if (pageAction) {
        const getUTM = () => {
          if (pageAction === 'ViewedInfoPage') {
            if (isFromEmailLink) {
              return 'email';
            }
            if (isFromSmsLink) {
              return 'sms';
            }
            return isFromLoc;
          }
          if (pageAction === 'ViewedUnlockPage') {
            return lastButtonClicked;
          }
        };

        if (lastSentPageAction !== pageAction) {
          await PostBabyPeekAction(
            babypeekPK,
            pageAction,
            deviceType,
            isFromEmailLink,
            isFromSmsLink,
            {
              utm_source: getUTM(),
              is_logged_in: auth ? 'true' : 'false',
              campaign_id: campaign[0],
              drip_number: campaign[1],
              content_version: campaign[2],
            }
          );
          setLastButtonClicked(undefined);
          setLastSetPageAction(pageAction);
        }
      }
    };
    sendPageAction();
  }, [pageIndex, traitData]);

  const getPage = () => {
    if (
      pageIndex !== 'intro' &&
      pageIndex !== 'summary' &&
      pageIndex !== 'checkout' &&
      pageIndex !== 'socialpromo' &&
      pageIndex !== 'samplereport' &&
      traitData
    ) {
      switch (pageIndex) {
        case 'games': {
          return <BabyPeekGames />;
        }
        case 'sweet': {
          return (
            <SweetTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'sweetsalty': {
          return (
            <SweetSaltyTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'bitter': {
          return (
            <BitterTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'cilantro': {
          return (
            <CilantroTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'freckle': {
          return (
            <FreckleTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'babyteeth': {
          return (
            <BabyTeethTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'asparagus': {
          return (
            <AsparagusTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'eyecolor': {
          return (
            <EyeColorTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'hairthickness': {
          return (
            <HairThicknessTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'curlyhair': {
          return (
            <CurlyHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'redhair': {
          return (
            <RedHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        case 'lightordarkhair': {
          return (
            <LightOrDarkHairTrait
              setCurrentTrait={setViewTrait}
              traitData={traitData}
              hideTraitMenu={false}
              setShareModal={setShareTraitModal}
            />
          );
        }
        default:
          return (
            <BabyPeekViewResultsMobile
              setCurrentTrait={setViewTrait}
              feedbackGiven={feedbackData?.has_general_feedback}
              getTraitFeedback={getTraitFeedback}
              traitData={traitData}
              // setShareModalOpen={setShareTraitModal}
            />
          );
      }
    } else if (pageIndex === 'intro' && traitData) {
      return deviceType === 'desktop' ? (
        <BabyPeekIntro
          babyPeekReport={traitData}
          setLastButtonClick={setLastButtonClicked}
        />
      ) : (
        <BabyPeekIntroMobile
          babyPeekReport={traitData}
          setLastButtonClick={setLastButtonClicked}
        />
      );
    } else if (pageIndex === 'checkout') {
      return (
        stripeBabyPeekPromise &&
        traitData && (
          <Elements stripe={stripeBabyPeekPromise} options={options}>
            <BabyPeekStripe
              setBabyPeekData={setTraitData}
              babyPeekData={traitData}
              setBabyPeekUnlockOpen={setBabyPeekUnlockOpen}
              setBabyPeekPublicUnlockOpen={setBabyPeekPublicUnlockOpen}
            />
          </Elements>
        )
      );
    }
    return (
      <BabyPeekViewResultsMobile
        setCurrentTrait={setViewTrait}
        feedbackGiven={feedbackData?.has_general_feedback}
        getTraitFeedback={getTraitFeedback}
        traitData={traitData}
      />
    );
  };

  return (
    <>
      <div style={{ maxWidth: '99%', display: 'grid', background: 'white' }}>
        <Modal
          open={babyPeekUnlockOpen}
          onClose={() => {
            if (!isGifting) {
              setBabyPeekUnlockOpen(false);
            }
          }}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: windowWidth,
          }}
        >
          <BabyPeekAdvocacyModalConfettiWrapper
            style={{ width: deviceType !== 'mobile' ? 525 : windowWidth - 50 }}
          >
            <Confetti
              height={startBabyPeekProcessing ? 400 : 250}
              numberOfPieces={100}
              gravity={0.1}
              initialVelocityX={5}
              initialVelocityY={5}
              colors={['yellow', '#fa709a', '#c670fa', '#3431ce']}
              width={deviceType !== 'mobile' ? 525 : windowWidth - 25}
            />
            <BabyPeekAdvocacyModalWrapper
              paymentPlan
              babyPeekProcessing={startBabyPeekProcessing}
            >
              <>
                {!startBabyPeekProcessing && (
                  <BabyPeekAdvocacyModalText>
                    Congratulations for unlocking BabyPeek!
                  </BabyPeekAdvocacyModalText>
                )}
                {startBabyPeekProcessing && (
                  <BabyPeekProcessingStepperWrapper>
                    <Stepper
                      activeStep={babyPeekProcessing.findIndex(
                        (step) => step.status === 'pending'
                      )}
                      orientation="vertical"
                    >
                      {babyPeekProcessing.map((step) => (
                        <Step key={step.label}>
                          <StepLabel
                            StepIconComponent={() => {
                              if (step.status === 'done') {
                                return (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#008777"
                                  />
                                );
                              }
                              if (step.status === 'empty') {
                                return (
                                  <FontAwesomeIcon
                                    icon={faCircleCheckOutline}
                                    color="#CBD5E0"
                                  />
                                );
                              }
                              return (
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  color="#718096"
                                  spin
                                />
                              );
                            }}
                          >
                            <div>
                              <BabyPeekProcessingStepperLabel
                                style={{
                                  fontWeight:
                                    step.status === 'pending' ? 600 : 400,
                                }}
                              >
                                {' '}
                                {step.label}
                              </BabyPeekProcessingStepperLabel>
                            </div>
                          </StepLabel>
                          <StepContent />
                        </Step>
                      ))}
                    </Stepper>
                  </BabyPeekProcessingStepperWrapper>
                )}
                {babyPeekPublicUnlockOpen && (
                  <UpfrontPaymentMainTextCentered>
                    Please log in to view your BabyPeek results.
                  </UpfrontPaymentMainTextCentered>
                )}

                {!babyPeekPublicUnlockOpen && !startBabyPeekProcessing && (
                  <BabyPeekAdvocacyModalButton deviceType={deviceType}>
                    <BabyPeekButton
                      variant="filled"
                      style={{ width: '200px' }}
                      content={
                        <>
                          {traitData?.reveal_status === 'prepaid'
                            ? 'OK'
                            : 'Continue'}
                        </>
                      }
                      onClick={async () => {
                        if (traitData?.reveal_status === 'prepaid') {
                          setBabyPeekUnlockOpen(false);
                          history.push(`${BABYPEEK_URL}/${babypeekPK}/intro`);
                        } else {
                          setStartBabyPeekProcessing(true);
                        }
                      }}
                    />
                  </BabyPeekAdvocacyModalButton>
                )}

                {startBabyPeekProcessing &&
                  babyPeekProcessing.findIndex(
                    (step) => step.status === 'pending'
                  ) === -1 && (
                    <BabyPeekAdvocacyModalButton deviceType={deviceType}>
                      <BabyPeekButton
                        variant="filled"
                        style={{ width: '300px', marginTop: '20px' }}
                        content={<>View My Results</>}
                        onClick={async () => {
                          setBabyPeekUnlockOpen(false);
                          history.push(`${BABYPEEK_URL}/${babypeekPK}/Summary`);
                        }}
                      />
                    </BabyPeekAdvocacyModalButton>
                  )}
                {babyPeekPublicUnlockOpen && (
                  <BabyPeekAdvocacyModalButton deviceType={deviceType}>
                    <BabyPeekButton
                      variant="filled"
                      content={<>View Results</>}
                      onClick={goToLoginPage}
                    />
                  </BabyPeekAdvocacyModalButton>
                )}
              </>
            </BabyPeekAdvocacyModalWrapper>
          </BabyPeekAdvocacyModalConfettiWrapper>
        </Modal>
        <BabyPeekShareTraitModal
          setShareTraitModal={setShareTraitModal}
          shareTraitModal={shareTraitModal}
          traitPage={pageIndex}
        />
        {!pageIndex || !traitData ? <SpinnerLoading /> : getPage()}
        {!auth && <BabyPeekFooter />}
      </div>
    </>
  );
};
