import { Box, Popper, TextField } from '@mui/material';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { ExclamationIcon } from '../../../assets/iconComponents/ExclamationIcon';
import {
  FieldContainer,
  FieldTitle,
  CheckboxInputField,
  RadioInputField,
  AutoCompleteTextfieldWrapper,
} from './FormField.styles';

type UserReportFieldProps = {
  fieldTitle: string;
  fieldValue: string;
  fieldChecked?: boolean;
  placeholderOnError?: string;
  autoCompleteOptions?: string[];
  autoCompleteDropdownStyle?: boolean;
  fieldType?:
    | 'date'
    | 'text'
    | 'radio'
    | 'result'
    | 'checkbox'
    | 'autocomplete';
  onFieldChange?: (val: string | boolean) => void;
  edit?: boolean;
  isError?: boolean;
  styles?: Record<string, unknown>;
  hideTitle?: boolean;
  newRequisition?: boolean;
  autoFill?: boolean;
  autoCompletePlaceholder?: string;
  autoCompleteFreeSolo?: boolean;
  autoCompleteNoOptionsText?: string;
};

const FormField: React.FC<UserReportFieldProps> = ({
  fieldTitle,
  fieldValue,
  fieldType = 'text',
  placeholderOnError,
  autoCompleteOptions = [],
  autoCompleteDropdownStyle,
  fieldChecked,
  onFieldChange,
  isError = false,
  edit = false,
  styles = {},
  hideTitle,
  newRequisition,
  autoFill = false,
  autoCompletePlaceholder,
  autoCompleteFreeSolo,
  autoCompleteNoOptionsText,
}: UserReportFieldProps) => {
  const handleFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onFieldChange) {
      onFieldChange(e?.target?.value || '');
    }
  };

  const displayInput = (
    type: 'date' | 'text' | 'radio' | 'result' | 'checkbox' | 'autocomplete'
  ) => {
    switch (type) {
      case 'date':
        return (
          <input
            id="new-test-npi-input"
            type={isError && edit ? 'date' : 'text'}
            value={fieldValue}
            onChange={handleFieldChange}
            onFocus={(e) => {
              e.target.type = 'date';
            }}
            onBlur={(e) => {
              e.target.type = 'text';
            }}
            placeholder=""
            disabled={!edit}
            tabIndex={edit ? undefined : -1}
            autoComplete={autoFill ? '' : 'nope'}
          />
        );
      case 'radio':
        return (
          <Box
            sx={{
              display: 'flex',
              gridGap: '5px',
              alignItems: 'center',
              borderBottom: '1px solid #E7EBF2',
              paddingBottom: '6px',
            }}
          >
            <RadioInputField>
              <input
                style={{ width: 'auto' }}
                type="radio"
                onClick={() => {
                  if (onFieldChange) {
                    onFieldChange(!fieldChecked);
                  }
                }}
                checked={fieldChecked}
                disabled={!edit}
                tabIndex={edit ? undefined : -1}
                autoComplete={autoFill ? '' : 'nope'}
              />
              {
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>{fieldValue}</label>
              }
            </RadioInputField>
          </Box>
        );
      case 'checkbox':
        return (
          <Box
            sx={{
              display: 'flex',
              gridGap: '5px',
              alignItems: 'center',
              borderBottom: '1px solid #E7EBF2',
              paddingBottom: '6px',
            }}
          >
            <CheckboxInputField>
              <input
                style={{ width: 'auto' }}
                type="checkbox"
                onClick={() => {
                  if (onFieldChange) {
                    onFieldChange(!fieldChecked);
                  }
                }}
                checked={fieldChecked}
                disabled={!edit}
                tabIndex={edit ? undefined : -1}
              />
              {
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>{fieldValue}</label>
              }
            </CheckboxInputField>
          </Box>
        );
      case 'autocomplete':
        return (
          <Autocomplete
            sx={{
              display: 'inline-block',
              '& input': {
                width: 200,
                bgcolor: 'background.paper',
                color: (theme) =>
                  theme.palette.getContrastText(theme.palette.background.paper),
              },
            }}
            freeSolo={autoCompleteFreeSolo}
            PopperComponent={({ style, ...props }) => (
              <Popper
                {...props}
                style={{
                  ...style,
                  height: '0',
                  maxHeight: '100px',
                  minHeight: '100px',
                  zIndex: 9999,
                }}
              />
            )}
            disablePortal
            aria-autocomplete="none"
            disabled={!edit}
            value={fieldValue || null}
            onChange={(
              _event: React.SyntheticEvent<Element, Event>,
              newValue: string | null
            ) => {
              if (onFieldChange) {
                onFieldChange(newValue || '');
              }
            }}
            onInputChange={(_event, newInputValue) => {
              if (onFieldChange) {
                onFieldChange(newInputValue);
              }
            }}
            options={autoCompleteOptions}
            noOptionsText={autoCompleteNoOptionsText}
            placeholder={isError && edit ? placeholderOnError : ''}
            renderInput={(params) =>
              autoCompleteDropdownStyle ? (
                <AutoCompleteTextfieldWrapper>
                  <TextField
                    {...params}
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                      'aria-autocomplete': 'none',
                      autoComplete: 'nope',
                      placeholder: autoCompletePlaceholder,
                    }}
                    autoComplete="nope"
                  />
                </AutoCompleteTextfieldWrapper>
              ) : (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    autoComplete="nope"
                    placeholder={autoCompletePlaceholder}
                  />
                </div>
              )
            }
          />
        );
      default:
        return (
          <input
            type={fieldType}
            value={fieldValue || (isError || edit ? '' : '-')}
            onChange={handleFieldChange}
            placeholder={isError && edit ? placeholderOnError : ''}
            tabIndex={edit ? undefined : -1}
            disabled={!edit}
            autoComplete={autoFill ? '' : 'nope'}
          />
        );
    }
  };

  return (
    <FieldContainer style={styles} edit={edit} $isError={isError}>
      {!hideTitle && (
        <FieldTitle $isError={isError} newRequisition={newRequisition}>
          {fieldTitle} {isError && <ExclamationIcon />}
        </FieldTitle>
      )}
      {displayInput(fieldType)}
    </FieldContainer>
  );
};

export default FormField;
