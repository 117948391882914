import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const TestResultsLearnMore = (
  text: string,
  link: string,
  padding: string
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: text,
      size: 'small',
      textColor: 'redMedium',
      link,
      padding,
    })}
  </>
);
