import React from 'react';

import { Rating } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import {
  BabyPeekExperienceGradientContent,
  BabyPeekExperienceGradientContentGrid,
  BabyPeekExperienceGradientText,
  BabyPeekExperienceGradientTextTitle,
  BabyPeekExperienceTextField,
  BabyPeekExperienceGradientCheckboxGrid,
  BabyPeekExperienceGradientSectionGrid,
  BabyPeekExperienceGradientRatingGrid,
  BabyPeekExperienceButtonWrapper,
  BabyPeekExperienceThankText,
} from './BabyPeekViewResults.styles';

import { StarIconEmpty } from './BabyPeekIcons/StarIconEmpty';
import { StarIconFilled } from './BabyPeekIcons/StarIconFilled';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { BabyPeekButton } from './BabyPeekButton';
import { PostGeneralBabyPeekFeedback } from './Services/BabyPeekService';

import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { getUserAuth } from '../../recoil/selectors';

export const BabyPeekExperienceFormSlowReveal: React.FC<{
  getTraitFeedback: () => Promise<void>;
  singlePage?: boolean;
}> = ({ getTraitFeedback, singlePage }) => {
  const [starRating, setStarRating] = React.useState<number | null>(0);
  const [recommendation] = React.useState<number>(0);
  const [feedback, setFeedback] = React.useState<string>('');
  const [contacted, setContacted] = React.useState<boolean>(true);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const { babypeekPK } = useParams<{ babypeekPK: string }>();
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  const onSubmit = async () => {
    if (starRating) {
      setSubmitting(true);
      const response = await PostGeneralBabyPeekFeedback(
        babypeekPK,
        starRating,
        recommendation,
        feedback,
        contacted,
        auth?.access_token
      );
      setSubmitting(false);
      if (response.ok) {
        setSubmitted(true);
        setTimeout(async () => {
          await getTraitFeedback();
        }, 5000);
      }
    }
  };
  return (
    <>
      {starRating ? (
        <BabyPeekExperienceGradientContent
          deviceType={deviceType}
          style={{
            background: '#FFF6D2',
            width: '320px',
            justifySelf: 'center',
          }}
        >
          {submitted && (
            <BabyPeekExperienceThankText>
              Thank you for your feedback!
            </BabyPeekExperienceThankText>
          )}
          {!submitted && (
            <BabyPeekExperienceGradientContentGrid deviceType={deviceType}>
              <BabyPeekExperienceGradientText deviceType={deviceType}>
                Thanks for your rating!
              </BabyPeekExperienceGradientText>
              <Rating
                value={starRating}
                onChange={(e, newValue) => {
                  setStarRating(newValue);
                }}
                emptyIcon={<StarIconEmpty />}
                icon={<StarIconFilled />}
              />
              <hr />
              <BabyPeekExperienceGradientSectionGrid>
                <BabyPeekExperienceGradientTextTitle>
                  Please share your feedback
                </BabyPeekExperienceGradientTextTitle>
                <BabyPeekExperienceTextField
                  placeholder="What do you like about BabyPeek? How does it make you feel? Would you recommend BabyPeek?"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </BabyPeekExperienceGradientSectionGrid>
              <BabyPeekExperienceGradientCheckboxGrid>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={contacted}
                  onChange={(e) => setContacted(e.target.checked)}
                />
                <div>
                  I agree to be contacted by BillionToOne to discuss my
                  feedback.
                </div>
              </BabyPeekExperienceGradientCheckboxGrid>
              <BabyPeekExperienceButtonWrapper>
                {submitting ? (
                  <SpinnerLoading />
                ) : (
                  <BabyPeekButton
                    variant="outline"
                    content={<>Submit</>}
                    onClick={() => onSubmit()}
                  />
                )}
              </BabyPeekExperienceButtonWrapper>
            </BabyPeekExperienceGradientContentGrid>
          )}
        </BabyPeekExperienceGradientContent>
      ) : (
        <BabyPeekExperienceGradientContent
          deviceType={deviceType}
          style={{
            background: '#FFF6D2',
            width: '320px',
            justifySelf: 'center',
          }}
        >
          <BabyPeekExperienceGradientRatingGrid deviceType={deviceType}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: ' 22px',
                fontWeight: 700,
                lineHeight: '120%',
                letterSpacing: '0.22px',
                padding: '0px 16px',
              }}
            >
              {singlePage
                ? 'Thank you for choosing BabyPeek!'
                : 'You’ve revealed all your baby’s traits!'}{' '}
            </div>
            <div
              style={{
                color: '#2D3748',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '150%',
                padding: '0px 16px',
              }}
            >
              We hope you enjoyed your experience! Please let us know how would
              you rate it:
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '20px',
              }}
            >
              <Rating
                value={starRating}
                onChange={(e, newValue) => {
                  setStarRating(newValue);
                }}
                emptyIcon={<StarIconEmpty />}
                icon={<StarIconFilled />}
              />
            </div>
          </BabyPeekExperienceGradientRatingGrid>
        </BabyPeekExperienceGradientContent>
      )}
    </>
  );
};
