import * as React from 'react';

export const BillIcon = (): React.ReactElement => (
  <svg
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2578 4.17188C14.7266 4.64062 15 5.26562 15 5.92969V18C15 19.4062 13.8672 20.5 12.5 20.5H2.5C1.09375 20.5 0 19.4062 0 18V3C0 1.63281 1.09375 0.5 2.5 0.5H9.57031C10.2344 0.5 10.8594 0.773438 11.3281 1.24219L14.2578 4.17188ZM13.125 18H13.0859V6.75H10C9.29688 6.75 8.75 6.20312 8.75 5.5V2.41406H2.5C2.14844 2.41406 1.875 2.6875 1.875 3.03906V18C1.875 18.3516 2.14844 18.625 2.5 18.625H12.5C12.8125 18.625 13.125 18.3516 13.125 18ZM3.75 5.5C3.39844 5.5 3.125 5.22656 3.125 4.875C3.125 4.5625 3.39844 4.25 3.75 4.25H6.875C7.1875 4.25 7.5 4.5625 7.5 4.875C7.5 5.22656 7.1875 5.5 6.875 5.5H3.75ZM7.5 7.375C7.5 7.72656 7.1875 8 6.875 8H3.75C3.39844 8 3.125 7.72656 3.125 7.375C3.125 7.0625 3.39844 6.75 3.75 6.75H6.875C7.1875 6.75 7.5 7.0625 7.5 7.375ZM7.69531 12.5703C8.55469 12.8438 10.1953 13.2734 9.88281 14.9922C9.76562 15.8125 9.14062 16.3203 8.28125 16.5156V16.75C8.28125 17.2188 7.92969 17.5312 7.5 17.5312C7.03125 17.5312 6.71875 17.2188 6.71875 16.75V16.5156C6.36719 16.4375 6.01562 16.3203 5.70312 16.2031L5.54688 16.1641C5.15625 16.0078 4.92188 15.5781 5.07812 15.1484C5.23438 14.7578 5.66406 14.5234 6.09375 14.6797L6.25 14.7578C6.5625 14.8359 6.91406 14.9922 7.1875 15.0312C7.69531 15.1094 8.32031 15.0312 8.35938 14.7188C8.39844 14.5234 8.39844 14.4062 7.26562 14.0938L7.03125 14.0156C6.36719 13.8203 4.76562 13.3516 5.07812 11.6719C5.19531 10.8516 5.82031 10.3047 6.71875 10.1094V9.875C6.71875 9.44531 7.03125 9.09375 7.5 9.09375C7.92969 9.09375 8.28125 9.44531 8.28125 9.875V10.1484C8.47656 10.1875 8.71094 10.2656 8.98438 10.3438C9.41406 10.5 9.60938 10.9297 9.45312 11.3594C9.33594 11.75 8.86719 11.9844 8.47656 11.8281C8.24219 11.75 8.00781 11.6719 7.77344 11.6328C7.26562 11.5547 6.64062 11.6328 6.60156 11.9453C6.5625 12.1016 6.5625 12.2578 7.46094 12.5312L7.69531 12.5703Z"
      fill="#064682"
    />
  </svg>
);
