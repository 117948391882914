import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  ProfileResetButtonWrapper,
  ProfileResetEmailWrapper,
} from './Profile.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { TextField } from '../shared/TextField/TextField';
import { StyledButton } from '../shared/Button/Button.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { validateEmail } from '../../utils/hooks/helpers';
import { UpdateEmailRequest } from './Services/UpdateProfileInfoService';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { patientState } from '../../recoil/atoms';
import { networkErrorMessage } from '../../utils/helpers';

export const ProfileResetEmail: React.FC<{
  setResetEmail: (reset: boolean) => void;
}> = ({ setResetEmail }) => {
  const { deviceType } = useDeviceHook();
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [patientInfo] = useRecoilState(patientState);
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getLoadingOrMessage = () => {
    if (isLoading) {
      return <SpinnerLoading />;
    }
    if (responseMessage) {
      return (
        <ErrorPopup
          message={responseMessage}
          hideMessage={setResponseMessage}
        />
      );
    }
  };

  const submitNewEmail = async (email: string) => {
    if (!newEmail) {
      setEmailError('Required');
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email');
    } else {
      setIsLoading(true);
      try {
        const response = await UpdateEmailRequest(
          { new_email: email },
          patientInfo?.auth0?.access_token
        );
        setIsLoading(false);
        if (response.ok && patientInfo) {
          setResponseMessage(
            'Thank you - a verification link has been sent to the new email for you to confirm.'
          );
        } else {
          setResponseMessage('There was an error - please try again.');
        }
      } catch (e) {
        setIsLoading(false);
        setResponseMessage(networkErrorMessage);
      }
    }
  };

  return (
    <ProfileResetEmailWrapper deviceType={deviceType}>
      {GetTypographyContent({
        content: 'Edit personal details',
        size: 'xlarge',
        textColor: 'greyMedium',
        isBold: true,
      })}
      <TextField
        label="New email"
        value={newEmail}
        error={emailError}
        size="small"
        onChange={(e) => {
          setEmailError('');
          setNewEmail(e.target.value);
        }}
      />
      {getLoadingOrMessage() || (
        <ProfileResetButtonWrapper deviceType={deviceType}>
          <StyledButton
            $reverse
            size="small"
            $backgroundColor="white"
            $textColor="redMedium"
            $borderColor="redMedium"
            onClick={() => setResetEmail(false)}
          >
            <div>
              {GetTypographyContent({
                content: 'Cancel',
                size: 'medium',
                textColor: 'redMedium',
                isCaps: true,
                padding: '0 0 0 3px',
              })}
            </div>
          </StyledButton>
          <StyledButton
            size="small"
            $backgroundColor="redMedium"
            $textColor="white"
            onClick={() => submitNewEmail(newEmail)}
          >
            <div>
              {GetTypographyContent({
                content: 'Done',
                size: 'medium',
                textColor: 'white',
                isCaps: true,
                padding: '0 0 0 3px',
              })}
            </div>
          </StyledButton>
        </ProfileResetButtonWrapper>
      )}
    </ProfileResetEmailWrapper>
  );
};
