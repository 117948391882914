import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const TestResultsPhysician = (name: string): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: 'Physician',
      size: 'medium',
      textColor: 'greyMedium',
      isBold: true,
      isCaps: true,
    })}
    {GetTypographyContent({
      content: name,
      size: 'medium',
      textColor: 'greyMedium',
    })}
  </>
);
