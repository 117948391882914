import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { ContactUsMessageWrapper, ContactUsWrapper } from './ContactUs.styles';
import { ContactUsContent } from './ContactUsContent';

export const ContactUs = (): React.ReactElement => (
  <ContactUsWrapper data-testid="contactUsTestId">
    {GetTypographyContent({
      content: 'Get in touch',
      size: 'label',
      textColor: 'greyMedium',
      isBold: true,
      isCaps: true,
    })}
    <ContactUsMessageWrapper>
      {GetTypographyContent({
        content:
          'If you have a question, please fill in the form below or call us at ',
        size: 'medium',
        textColor: 'greyMedium',
      })}
      {GetTypographyContent({
        content: '+1(650) 460-2551',
        size: 'medium',
        isPhone: true,
      })}
    </ContactUsMessageWrapper>
    <ContactUsContent />
  </ContactUsWrapper>
);
