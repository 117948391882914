import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import './index.css';
import { RecoilRoot } from 'recoil';
import { App } from './App';
import { materialTheme, themePalette } from './theme';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <MuiThemeProvider theme={materialTheme}>
        <ThemeProvider theme={themePalette}>
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
