import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';
import {
  DetailedReportTop,
  AneuploidyGeneralContainer,
  AneuploidyDesktopLeftWrapper,
  AneuploidyDesktopRightWrapper,
  AneuploidyDesktopWrapper,
  AneuploidyTitleWrapper,
  AneuploidyWrapper,
  AneuploidyTabletMobileWrapper,
  AneuploidyBackToResults,
} from './Aneuploidy.styles';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { DetailedResultsHeader } from '../../components/DetailedResults/DetailedResultsHeader';
import { DetailedResultsTable } from '../../components/DetailedResults/DetailedResultsTable';
import { DetailedResultsViewReport } from '../../components/DetailedResults/DetailedResultsViewReport';
import { DetailedResultsRhD } from '../../components/DetailedResults/DetailedResultsRhD';
import { DetailedResultsFetalSex } from '../../components/DetailedResults/DetailedResultsFetalSex';
import { DetailedResultsFetalSexModal } from '../../components/DetailedResults/DetailedResultsFetalSexModal';
import { DetailedResultsExpert } from '../../components/DetailedResults/DetailedResultsExpert';
import { DetailedResultsFAQ } from '../../components/DetailedResults/DetailedResultsFAQ';
import FetalSexForwardPage from './FetalSexForwardPage';
import {
  getSpecific22q,
  getSpecificAneuploidyLowRisk,
  getSpecificAneuploidyReportID,
  getSpecificAneuploidyStatus,
  getSpecificFetalSex,
  getSpecificRequsition,
  getSpecificRhD,
  getUserAuth,
} from '../../recoil/selectors';
import { BABYPEEK_URL, LANDING_PAGE_URL } from '../../utils/urlConstants';
import { useLoginScreen } from '../../utils/hooks/useLoginScreen';

import { BackIcon } from '../../assets/iconComponents/BackIcon';
import { FullReportRequest } from '../../components/DetailedResults/Services/DetailedResultsService';
import { SendActionType } from '../../components/shared/Services/SharedServices';
import { DetailedResultsFetalSexHideButtons } from '../../components/DetailedResults/DetailedFetalSexResultsHideButtons';
import { DetailedResultsShareBabyPeek } from '../../components/DetailedResults/DetailedResultsShareBabyPeek';
import BabyPeekHeader from '../../components/BabyPeek/BabyPeekIcons/BabyPeekHeader.png';
import { DetailedResults22Q } from '../../components/DetailedResults/DetailedResults22Q';
import { BabyPeekButton } from '../../components/BabyPeek/BabyPeekButton';
import { BabyPeekAvailable } from '../Landing/Landing.styles';
import { UpfrontPaymentHelpWrapper } from '../../components/UpfrontPayment/UpfrontPayment.styles';
import AneuploidyVideoEmbed from '../../components/shared/Video/AneuploidyVideoEmbed';
import RhDVideoEmbed from '../../components/shared/Video/RhDVideoEmbed';
import { VideoFeedBackTitleText } from '../../components/shared/Video/VideoEmbed.styles';

const AneuploidyPage = (): React.ReactElement => {
  useLoginScreen();
  const { requisitionID } = useParams<{ requisitionID: string }>();
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const [showFetalSexModal, setShowFetalSexModal] = useState<
    '' | 'reveal' | 'report'
  >('');
  const [showFetalSex, setShowFetalSex] = useState(false);
  const [forwardFetalSex, setForwardFetalSex] = useState(false);
  const isLowRiskAneu = useRecoilValue(
    getSpecificAneuploidyLowRisk(requisitionID)
  );

  const requisition = useRecoilValue(getSpecificRequsition(requisitionID));
  const fetalSex = useRecoilValue(getSpecificFetalSex(requisitionID));
  const status = useRecoilValue(getSpecificAneuploidyStatus(requisitionID));
  const reportID = useRecoilValue(getSpecificAneuploidyReportID(requisitionID));
  const auth = useRecoilValue(getUserAuth);
  const rhDResults = useRecoilValue(getSpecificRhD(requisitionID));
  const del22Qstatus = useRecoilValue(getSpecific22q(requisitionID));

  useEffect(() => {
    const sendUIAction = async () => {
      try {
        await SendActionType(
          'ViewAneuploidyPage',
          requisitionID,
          auth?.access_token
        );
      } catch (e) {
        return undefined;
      }
    };
    sendUIAction();
  }, []);

  const getFullAneuploidyReport = async () => {
    if (reportID) {
      try {
        const reportData = await FullReportRequest(
          reportID,
          'aneuploidy',
          auth?.access_token
        );
        if (reportData.ok) {
          const url = await reportData.json();
          return url.pdf_url as string;
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    }
  };

  const showFetalSexAction = async () => {
    try {
      await SendActionType('ShowFetalSex', requisitionID, auth?.access_token);
    } catch (e) {
      return undefined;
    }
  };

  useEffect(() => {
    if (auth && (!status || !(isLowRiskAneu !== undefined))) {
      history.push(LANDING_PAGE_URL);
    }
  }, [status, isLowRiskAneu, auth]);

  const statusValid =
    status && status !== 'Processing' && status !== 'Not Ordered';

  const ModalComponent = (
    <Modal
      open={Boolean(showFetalSexModal)}
      onClose={() => setShowFetalSexModal('')}
      sx={{
        top: '33%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <DetailedResultsFetalSexModal
          showFetalSexAction={showFetalSexAction}
          downloadReport={getFullAneuploidyReport}
          showModal={setShowFetalSexModal}
          setShowFetalSex={setShowFetalSex}
          showFetalSexModal={showFetalSexModal}
        />
      </Box>
    </Modal>
  );
  if (forwardFetalSex) {
    return (
      <FetalSexForwardPage
        setForwardFetalSex={setForwardFetalSex}
        requisitionID={requisitionID}
      />
    );
  }

  if (deviceType === 'desktop') {
    return (
      <>
        {ModalComponent}
        <AneuploidyWrapper>
          <AneuploidyDesktopWrapper>
            <AneuploidyDesktopLeftWrapper>
              <AneuploidyBackToResults
                onClick={() => history.push(LANDING_PAGE_URL)}
              >
                <BackIcon />
                {GetTypographyContent({
                  content: 'Back to tests',
                  size: 'medium',
                  textColor: 'redMedium',
                })}
              </AneuploidyBackToResults>
              <AneuploidyTitleWrapper>
                {GetTypographyContent({
                  content: 'Aneuploidy Screen results',
                  size: 'h2',
                  textColor: 'greyMedium',
                  isBold: true,
                })}
              </AneuploidyTitleWrapper>
              <AneuploidyGeneralContainer padding="25px">
                <DetailedReportTop>
                  <DetailedResultsHeader
                    type="aneuploidy"
                    requisitionID={requisitionID}
                  />

                  {isLowRiskAneu && statusValid && (
                    <DetailedResultsTable
                      isAneuploidy
                      requisitionID={requisitionID}
                    />
                  )}
                  {statusValid && (
                    <DetailedResultsViewReport
                      fetalSexWarning={
                        (fetalSex?.status === 'Final' ||
                          fetalSex?.status === 'Corrected') &&
                        fetalSex?.value.toLowerCase() !== 'not ordered' &&
                        fetalSex?.value.toLowerCase() !== 'n/a'
                      }
                      reportDownload={getFullAneuploidyReport}
                      setShowFetalSexModal={setShowFetalSexModal}
                    />
                  )}
                </DetailedReportTop>
                <UpfrontPaymentHelpWrapper>
                  <VideoFeedBackTitleText>
                    What is UNITY Aneuploidy Screen?
                  </VideoFeedBackTitleText>

                  <AneuploidyVideoEmbed
                    reqID={requisitionID}
                    videoLocation="aneuploidy_page"
                  />
                </UpfrontPaymentHelpWrapper>
              </AneuploidyGeneralContainer>

              {rhDResults !== 'Not Ordered' && rhDResults !== 'Opted out' && (
                <AneuploidyGeneralContainer padding="25px">
                  <DetailedResultsRhD requisitionID={requisitionID} />
                  <UpfrontPaymentHelpWrapper style={{ marginTop: '10px' }}>
                    <VideoFeedBackTitleText>
                      What is UNITY Fetal RhD NIPT?
                    </VideoFeedBackTitleText>

                    <RhDVideoEmbed
                      reqID={requisitionID}
                      videoLocation="rhd_page"
                    />
                  </UpfrontPaymentHelpWrapper>
                </AneuploidyGeneralContainer>
              )}
              {Boolean(
                requisition && requisition.reveal_status === 'available'
              ) && (
                <BabyPeekAvailable style={{ marginTop: '20px' }}>
                  <div>
                    <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                    <div>BabyPeek</div>
                  </div>
                  <div>
                    <BabyPeekButton
                      variant="filled"
                      content={<>View Results</>}
                      onClick={() =>
                        history.push(
                          `${BABYPEEK_URL}/${
                            requisition && requisition.baby_peek_pk
                          }/summary?loc=dashboard`
                        )
                      }
                    />
                  </div>
                </BabyPeekAvailable>
              )}
              {del22Qstatus !== 'Opted out' && (
                <AneuploidyGeneralContainer padding="25px">
                  <DetailedResults22Q requisitionID={requisitionID} />
                </AneuploidyGeneralContainer>
              )}

              <AneuploidyGeneralContainer padding="25px 70px 35px 25px">
                {requisition && (
                  <DetailedResultsFetalSex
                    setShowFetalSexModal={setShowFetalSexModal}
                    showFetalSex={showFetalSex}
                    setForwardFetalSex={setForwardFetalSex}
                    requisitionID={requisitionID}
                  />
                )}

                {showFetalSex && (
                  <DetailedResultsFetalSexHideButtons
                    setForwardFetalSex={setForwardFetalSex}
                    setShowFetalSex={setShowFetalSex}
                  />
                )}
              </AneuploidyGeneralContainer>
            </AneuploidyDesktopLeftWrapper>
            <AneuploidyDesktopRightWrapper
              babyPeek={requisition?.reveal_status === 'pending'}
            >
              <div />
              <div />
              {requisition?.reveal_status === 'pending' && (
                <DetailedResultsShareBabyPeek
                  babyPeekPK={requisition.baby_peek_pk}
                  page="Aneuploidy"
                />
              )}
              <AneuploidyGeneralContainer padding="25px">
                <DetailedResultsExpert />
              </AneuploidyGeneralContainer>
              <AneuploidyGeneralContainer padding="25px">
                <DetailedResultsFAQ />
              </AneuploidyGeneralContainer>
            </AneuploidyDesktopRightWrapper>
          </AneuploidyDesktopWrapper>
        </AneuploidyWrapper>
      </>
    );
  }
  return (
    <>
      {ModalComponent}
      <AneuploidyWrapper>
        <AneuploidyTabletMobileWrapper deviceType={deviceType}>
          <AneuploidyBackToResults
            onClick={() => history.push(LANDING_PAGE_URL)}
          >
            <BackIcon />
            {GetTypographyContent({
              content: 'Back to tests',
              size: 'medium',
              textColor: 'redMedium',
            })}
          </AneuploidyBackToResults>
          <AneuploidyTitleWrapper>
            {GetTypographyContent({
              content: 'Aneuploidy Screen results',
              size: 'h2',
              textColor: 'greyMedium',
              isBold: true,
              padding: '0 0 0 10px',
            })}
          </AneuploidyTitleWrapper>
          <AneuploidyGeneralContainer padding="16px">
            <DetailedReportTop>
              <DetailedResultsHeader
                type="aneuploidy"
                requisitionID={requisitionID}
              />
              {isLowRiskAneu && statusValid && (
                <DetailedResultsTable
                  isAneuploidy
                  requisitionID={requisitionID}
                />
              )}
              {statusValid && (
                <DetailedResultsViewReport
                  setShowFetalSexModal={setShowFetalSexModal}
                  reportDownload={getFullAneuploidyReport}
                  fetalSexWarning={
                    (fetalSex?.status === 'Final' ||
                      fetalSex?.status === 'Corrected') &&
                    fetalSex?.value.toLowerCase() !== 'not ordered' &&
                    fetalSex?.value.toLowerCase() !== 'n/a'
                  }
                />
              )}
            </DetailedReportTop>
            <UpfrontPaymentHelpWrapper>
              <VideoFeedBackTitleText>
                What is UNITY Aneuploidy Screen?
              </VideoFeedBackTitleText>

              <AneuploidyVideoEmbed
                reqID={requisitionID}
                videoLocation="aneuploidy_page"
              />
            </UpfrontPaymentHelpWrapper>
          </AneuploidyGeneralContainer>
          {rhDResults !== 'Not Ordered' && rhDResults !== 'Opted out' && (
            <AneuploidyGeneralContainer padding="25px 15px 30px 16px">
              <DetailedResultsRhD requisitionID={requisitionID} />
              <UpfrontPaymentHelpWrapper style={{ marginTop: '10px' }}>
                <VideoFeedBackTitleText>
                  What is UNITY Fetal RhD NIPT?
                </VideoFeedBackTitleText>

                <RhDVideoEmbed reqID={requisitionID} videoLocation="rhd_page" />
              </UpfrontPaymentHelpWrapper>
            </AneuploidyGeneralContainer>
          )}
          {Boolean(
            requisition && requisition.reveal_status === 'available'
          ) && (
            <BabyPeekAvailable style={{ marginTop: '20px' }}>
              <div>
                <img src={BabyPeekHeader} alt="BabyPeekHeader" />
                <div>BabyPeek</div>
              </div>
              <div>
                <BabyPeekButton
                  variant="filled"
                  content={<>View Results</>}
                  onClick={() =>
                    history.push(
                      `${BABYPEEK_URL}/${
                        requisition && requisition.baby_peek_pk
                      }/summary?loc=dashboard`
                    )
                  }
                />
              </div>
            </BabyPeekAvailable>
          )}
          {del22Qstatus !== 'Opted out' && (
            <AneuploidyGeneralContainer padding="25px">
              <DetailedResults22Q requisitionID={requisitionID} />
            </AneuploidyGeneralContainer>
          )}
          {requisition?.reveal_status === 'pending' && (
            <DetailedResultsShareBabyPeek
              babyPeekPK={requisition.baby_peek_pk}
              page="Aneuploidy"
            />
          )}
          <AneuploidyGeneralContainer padding="30px 15px 25px 16px">
            {requisition && (
              <DetailedResultsFetalSex
                setShowFetalSexModal={setShowFetalSexModal}
                showFetalSex={showFetalSex}
                setForwardFetalSex={setForwardFetalSex}
                requisitionID={requisitionID}
              />
            )}
            {showFetalSex && (
              <DetailedResultsFetalSexHideButtons
                setForwardFetalSex={setForwardFetalSex}
                setShowFetalSex={setShowFetalSex}
              />
            )}
          </AneuploidyGeneralContainer>

          <AneuploidyGeneralContainer padding="30px 15px 0px 16px">
            <DetailedResultsExpert />
          </AneuploidyGeneralContainer>
          <AneuploidyGeneralContainer padding="16px">
            <DetailedResultsFAQ />
          </AneuploidyGeneralContainer>
        </AneuploidyTabletMobileWrapper>
      </AneuploidyWrapper>
    </>
  );
};

export default AneuploidyPage;
