import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const FooterAcreditationInfoBottom = (): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: '00353741',
      size: 'small',
      link: 'https://billiontoone.com/wp-content/uploads/2023/09/License-Certificate_CDF-00353741_20230515T203704.344-GMT.pdf',
      externalLink: true,
      textColor: 'greenDark',
    })}
    {GetTypographyContent({
      content: '|',
      size: 'small',
    })}
    {GetTypographyContent({
      content: 'MD: 2999',
      size: 'small',
      link: 'https://billiontoone.com/wp-content/uploads/2023/09/2999-NE_PMT-Billiontoone-062023.pdf',
      externalLink: true,
      textColor: 'greenDark',
    })}
    {GetTypographyContent({
      content: '|',
      size: 'small',
    })}
    {GetTypographyContent({
      content: 'RI: LCO01527',
      size: 'small',
      link: 'https://billiontoone.com/wp-content/uploads/2023/09/Whipple_RI_Certificate.pdf',
      externalLink: true,
      textColor: 'greenDark',
    })}
    {GetTypographyContent({
      content: '|',
      size: 'small',
    })}
    {GetTypographyContent({
      content: 'PA: 37021',
      size: 'small',
      link: 'https://billiontoone.com/wp-content/uploads/2023/09/Cert2023-1.pdf',
      externalLink: true,
      textColor: 'greenDark',
    })}
  </>
);
