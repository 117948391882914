import styled from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const HelpMainOutterContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  place-items: center;
  padding-bottom: 10px;
`;

export const HelpStandardImage = styled.img``;

export const HelpPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getHelpWidth = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '920px';
  }
  if (deviceType === 'tablet') {
    return '570px';
  }
  return '360px';
};

const getHelpPadding = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '90px 142px 142px 142px';
  }
  if (deviceType === 'tablet') {
    return '60px 18px 112px 18px';
  }
  return '40px 0px 92px 0px';
};

const getHelpVideoHeight = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '528px';
  }
  if (deviceType === 'tablet') {
    return '355px';
  }
  return '352px';
};

export const HelpPageComponentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) => getHelpWidth(deviceType)};
  padding: ${({ deviceType }) => getHelpPadding(deviceType)};
`;

export const HelpPageComponentGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: ${({ deviceType }) => (deviceType === 'mobile' ? '' : 'grid')};
  grid-template-rows: 40px ${({ deviceType }) => getHelpVideoHeight(deviceType)} repeat(
      6,
      auto
    );
`;

export const HelpGeneralContainer = styled.div<{
  padding: string;
  paddingBottom?: string;
}>`
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 920px;
  border-radius: 24px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

export const HelpPageResources = styled.div<{
  deviceType: DeviceType;
}>`
  display: ${({ deviceType }) => (deviceType !== 'desktop' ? '' : 'grid')};
  grid-template-columns: ${({ deviceType }) =>
    deviceType !== 'desktop' ? '' : '455px 430px'};
  grid-template-rows: ${({ deviceType }) =>
    deviceType !== 'desktop' ? '' : undefined};
  max-width: ${({ deviceType }) => (deviceType === 'mobile' ? '' : undefined)};
  grid-gap: 24px;

  ${HelpGeneralContainer} {
    padding: ${({ deviceType }) =>
      deviceType === 'mobile' ? '24px 16px 30px 16px' : '20px'};
  }
`;

export const HelpFAQAnswerWrapper = styled.div`
  p {
    display: inline;
  }
`;
