import { createTheme } from '@mui/material';

export interface ColorPalette {
  name: string;
  blueLight: string;
  blueMedium: string;
  blueDark: string;
  blueSubtle: string;
  greenLight: string;
  greenMedium: string;
  greenDark: string;
  greenPastel: string;
  gradient: string;
  purpleLight: string;
  purpleMedium: string;
  purpleDark: string;
  redLight: string;
  redMedium: string;
  redDark: string;
  redPastel: string;
  yellowPastel: string;
  greyLight: string;
  greyMedium: string;
  greyDark: string;
  greyBase: string;
  black: string;
  white: string;
  neutralBackground: string;
  neutral50: string;
  neutral100: string;
  neutral200: string;
  neutral300: string;
  neutral400: string;
  neutral500: string;
  neutral600: string;
  neutral700: string;
  secondaryBackgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  borderColor: string;
  resultsNegativeGreen: string;
  redrawParentGrey: string;
}

interface StyleTheme {
  borderRadius: string;
  boxShadow: string;
  largeFontSize: string;
  mediumFontSize: string;
  largeContentWidth: string;
  extraLargeContentWidth: string;
  windowMinWidthTablet: string;
  windowMinWidthDesktop: string;
}

export interface ThemePalette extends ColorPalette, StyleTheme {}

export const themePalette: ThemePalette = {
  name: 'normal',
  blueLight: '#C7E1F0',
  blueMedium: '#63CBF6',
  blueDark: '#064682',
  blueSubtle: '#EEF5FA',
  greenLight: '#CEEAE3',
  greenMedium: '#03CBBA',
  greenDark: '#017E72',
  greenPastel: '#EFF8F5',
  gradient:
    'linear-gradient(48.22deg, #3BBFAD 0%, #298F95 35%, #1A6881 61%, #216382 73%, #365683 91%, #444D84 100%)',
  purpleLight: '#C7CBF0',
  purpleMedium: '#7E73B7',
  purpleDark: '#504484',
  redLight: '#EDB193',
  redMedium: '#F0604B',
  redDark: '#C0250F',
  redPastel: '#FFF2F0',
  yellowPastel: '#F8F1AE',
  greyLight: '#B6B2B2',
  greyMedium: '#5D5A5A',
  greyDark: '#171923',
  greyBase: '#231F20',
  black: '#000000',
  white: '#FFFFFF',
  neutralBackground: '#EEEEEE',
  neutral50: '#F9F9F9',
  neutral100: '#E7EBF2',
  neutral200: '#DBDEE6',
  neutral300: '#C2C6CC',
  neutral400: '#AAADB3',
  neutral500: '#929499',
  neutral600: '#7A7C80',
  neutral700: '#616366',
  secondaryBackgroundColor: '#0c6182',
  primaryColor: '#3BBEB3',
  secondaryColor: '#19aae2',
  primaryTextColor: '#4f4f4f',
  secondaryTextColor: '#4f4f4f',
  borderColor: '#3BBEB3',
  borderRadius: '5px',
  boxShadow: '0px 6px 11px rgba(0, 0, 0, 0.04)',
  resultsNegativeGreen: '#6ACC74',
  redrawParentGrey: '#E9E7E7',
  largeFontSize: '1.8rem',
  mediumFontSize: '1.3rem',
  largeContentWidth: '853px;',
  extraLargeContentWidth: '1600px',
  windowMinWidthTablet: '600px',
  windowMinWidthDesktop: '1440px',
};

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: themePalette.greenMedium,
      dark: themePalette.greenDark,
    },
    secondary: {
      dark: themePalette.white,
      main: themePalette.secondaryBackgroundColor,
    },
    background: {
      default: themePalette.white,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'light',
  },
});
