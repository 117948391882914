import * as React from 'react';

export const BabyPeekChangeIcon = (): React.ReactElement => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37" cy="37" r="37" fill="#FFB828" />
    <g clipPath="url(#clip0_4011_142)">
      <path
        d="M44.3326 31.195H30.7669V25.265C30.7669 22.4489 33.058 20.1579 35.8742 20.1579C38.6904 20.1579 40.9814 22.4489 40.9814 25.265V26.8794C40.9814 27.7515 41.6885 28.4584 42.5604 28.4584C43.4323 28.4584 44.1393 27.7515 44.1393 26.8794V25.265C44.1392 20.7077 40.4315 17 35.8742 17C31.3169 17 27.609 20.7077 27.609 25.265V31.195H27.4156C25.0087 31.195 23.0505 33.1532 23.0505 35.5602V52.4403C23.0505 54.9545 25.0958 57 27.6101 57H44.1382C46.6525 57 48.6979 54.9545 48.6979 52.4403V35.5602C48.6979 33.1532 46.7395 31.195 44.3326 31.195Z"
        fill="black"
      />
      <path
        d="M35.8742 38.2273C34.0728 38.2273 32.6072 39.6929 32.6072 41.4943C32.6072 42.7232 33.2899 43.7948 34.2952 44.3525V48.3886C34.2952 49.2606 35.0023 49.9675 35.8742 49.9675C36.7461 49.9675 37.4531 49.2606 37.4531 48.3886V44.3525C38.4586 43.7948 39.1412 42.7232 39.1412 41.4943C39.1412 39.6929 37.6756 38.2273 35.8742 38.2273Z"
        fill="#FFB828"
      />
      <path
        d="M47.198 25.555C47.3908 25.6324 47.5896 25.6688 47.7854 25.6688C48.4115 25.6688 49.004 25.2938 49.2513 24.6773L50.4268 21.7463C50.7515 20.9369 50.3585 20.0176 49.5491 19.693C48.7399 19.3684 47.8205 19.7614 47.4958 20.5707L46.3203 23.5017C45.9958 24.3111 46.3887 25.2302 47.198 25.555Z"
        fill="black"
      />
      <path
        d="M49.0146 27.5002C49.2709 28.0996 49.8542 28.4588 50.4673 28.4588C50.6744 28.4588 50.8849 28.4179 51.0871 28.3312L53.9908 27.0898C54.7926 26.7471 55.1649 25.8193 54.8221 25.0173C54.4792 24.2155 53.5508 23.8432 52.7496 24.1862L49.8459 25.4275C49.0442 25.7705 48.6718 26.6983 49.0146 27.5002Z"
        fill="black"
      />
      <path
        d="M53.9081 30.4577L50.9771 29.2821C50.1677 28.9575 49.2485 29.3503 48.9238 30.1599C48.5992 30.9692 48.9922 31.8885 49.8016 32.2131L52.7326 33.3886C52.9254 33.466 53.1241 33.5025 53.3199 33.5025C53.946 33.5025 54.5386 33.1275 54.7858 32.5109C55.1103 31.7015 54.7175 30.7823 53.9081 30.4577Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4011_142">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(19 17)"
        />
      </clipPath>
    </defs>
  </svg>
);
