import * as React from 'react';

export const WaveCTA = (): React.ReactElement => (
  <svg
    width="20"
    height="8.209"
    viewBox="0 0 24 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="noun-wave-1434641 1" clipPath="url(#clip0_1830_3692)">
      <g id="Group">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.362 6.95797C11.7656 11.715 13.1976 4.00861 23.0286 9.90732C12.625 5.05513 12.3385 13.2373 0.502964 7.71901C-0.356005 7.24327 -0.069751 6.57734 1.36193 6.95783L1.362 6.95797Z"
          fill="black"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.8393 3.62801C12.2429 8.38502 13.6748 0.678652 23.5059 6.57736C13.1023 1.62999 12.9113 9.90731 0.980258 4.38905C0.121289 3.91331 0.407544 3.15224 1.83923 3.62787L1.8393 3.62801Z"
          fill="black"
        />
        <path
          id="Vector_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.41204 0.202824C12.7205 5.05502 14.1521 -2.65138 23.9831 3.15218C13.5795 -1.70002 13.3886 6.48212 1.45752 0.963864C0.598548 0.583276 0.884802 -0.177799 2.41194 0.202687L2.41204 0.202824Z"
          fill="black"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1830_3692">
        <rect
          width="24"
          height="9.85075"
          fill="white"
          transform="translate(0 0.074585)"
        />
      </clipPath>
    </defs>
  </svg>
);
