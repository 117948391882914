import React, { useEffect, useState } from 'react';
import {
  BabyPeekGaugeBottomText,
  BabyPeekGaugeData,
  BabyPeekGaugeGrid,
  BabyPeekGaugeMask,
  BabyPeekSemiCircle,
  BabyPeekSemiCircleMask,
} from './BabyPeekGauge.styles';
import babyPeekGaugeGif from '../../AnimatedGifs/halfDonut.gif';

export const BabyPeekGauge: React.FC<{
  percentage: number;
  bottomText: string;
  showGif: boolean;
}> = ({ percentage, bottomText, showGif }) => {
  const [showingGifs, setShowingGifs] = useState(showGif ?? false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showingGifs) {
        setShowingGifs(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <BabyPeekGaugeGrid>
      <>
        {showingGifs ? (
          <img
            src={babyPeekGaugeGif}
            alt="loading..."
            style={{
              width: '320px',
              marginBottom: '-35px',
              marginTop: '-25px',
              justifySelf: 'center',
            }}
          />
        ) : (
          <>
            <BabyPeekGaugeMask>
              <BabyPeekSemiCircle />
              <BabyPeekSemiCircleMask
                style={{ transform: `rotate(${(percentage / 100) * 180}deg)` }}
              />
              <BabyPeekGaugeData>
                <div className="gauge-percent">{`${percentage}%`}</div>
              </BabyPeekGaugeData>
            </BabyPeekGaugeMask>
          </>
        )}
        <BabyPeekGaugeBottomText>{bottomText}</BabyPeekGaugeBottomText>
      </>
    </BabyPeekGaugeGrid>
  );
};
