import React, { useEffect } from 'react';
import { Buffer } from 'buffer';
import Confetti from 'react-confetti';
import { useHistory, useParams } from 'react-router-dom';
import { FemaleFetus } from '../../assets/iconComponents/FemaleFetus';
import { MaleFetus } from '../../assets/iconComponents/MaleFetus';
import { useDeviceHook, DeviceType } from '../../utils/hooks/useDeviceHook';
import { LOGIN_PAGE_URL } from '../../utils/urlConstants';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  ExternalFetalSexRevealRow,
  ExternalFetalGrid,
  ExternalFetalSexRevealWrapper,
  ExternalRevealTitle,
} from './ExternalForwardFetalSex.styles';

type FetalSex =
  | 'MALE'
  | 'FEMALE'
  | 'MALE & MALE'
  | 'FEMALE & FEMALE'
  | 'MALE & FEMALE'
  | 'ONE OR BOTH MALES';

const getFetalDisplay = (fetalSex?: FetalSex) => {
  if (
    fetalSex === 'MALE' ||
    fetalSex === 'FEMALE' ||
    fetalSex === 'MALE & MALE' ||
    fetalSex === 'FEMALE & FEMALE' ||
    fetalSex === 'MALE & FEMALE'
  ) {
    return fetalSex.split('&').map((sex) => {
      if (sex.trim() === 'MALE') {
        return (
          <ExternalFetalSexRevealRow key={sex}>
            <MaleFetus />
            {GetTypographyContent({
              content: 'Boy',
              size: 'h2',
              textColor: 'greyMedium',
            })}
          </ExternalFetalSexRevealRow>
        );
      }
      return (
        <ExternalFetalSexRevealRow key={sex}>
          <FemaleFetus />
          {GetTypographyContent({
            content: 'Girl',
            size: 'h2',
            textColor: 'greyMedium',
          })}
        </ExternalFetalSexRevealRow>
      );
    });
  }

  if (fetalSex === 'ONE OR BOTH MALES') {
    return (
      <ExternalFetalSexRevealRow extend key={fetalSex}>
        <MaleFetus />
        {GetTypographyContent({
          content: 'One or both boys',
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </ExternalFetalSexRevealRow>
    );
  }
};

export const ExternalFetalSexReveal: React.FC = () => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const { base64PatientName, base64FetalSex } =
    useParams<{ base64PatientName: string; base64FetalSex: string }>();

  const decode = (str: string): string =>
    Buffer.from(str, 'base64').toString('binary');

  const friendName = decode(base64PatientName);
  const fetalSex = decode(base64FetalSex);

  const validFetalSex =
    fetalSex === 'MALE' ||
    fetalSex === 'FEMALE' ||
    fetalSex === 'MALE & MALE' ||
    fetalSex === 'FEMALE & FEMALE' ||
    fetalSex === 'MALE & FEMALE' ||
    fetalSex === 'ONE OR BOTH MALES';

  useEffect(() => {
    if (!validFetalSex) {
      history.push(LOGIN_PAGE_URL);
    }
  });

  const twins =
    fetalSex === 'MALE & MALE' ||
    fetalSex === 'FEMALE & FEMALE' ||
    fetalSex === 'MALE & FEMALE' ||
    fetalSex === 'ONE OR BOTH MALES';

  const confettiWidth = (deviceTypeConfetti: DeviceType): number => {
    if (deviceTypeConfetti === 'mobile') return 320;
    if (deviceTypeConfetti === 'tablet') return 530;
    return 720;
  };

  const confettiHeight = (deviceTypeConfetti: DeviceType): number => {
    if (deviceTypeConfetti === 'mobile') return 220;
    if (deviceTypeConfetti === 'tablet') return 220;
    return 220;
  };

  return (
    <ExternalFetalSexRevealWrapper>
      <Confetti
        width={confettiWidth(deviceType)}
        height={confettiHeight(deviceType)}
        numberOfPieces={100}
        gravity={0.1}
        initialVelocityX={5}
        initialVelocityY={5}
        recycle
        colors={['yellow', '#fa709a', '#c670fa', '#3431ce']}
      />
      <ExternalRevealTitle>
        {GetTypographyContent({
          content: `${friendName} is having ${twins ? 'twins:' : 'a:'}`,
          size: 'medium',
          textColor: 'greyMedium',
          padding: '0px 0px 0px 10px',
          isBold: true,
        })}
      </ExternalRevealTitle>
      <ExternalFetalGrid isTwin={twins}>
        {getFetalDisplay(fetalSex as FetalSex)}
      </ExternalFetalGrid>
      {GetTypographyContent({
        content: `You may be the first to know, so check with ${friendName} before you share the news!`,
        size: 'medium',
        textColor: 'greyMedium',
        padding: '0px 0px 0px 10px',
        isBold: true,
      })}
    </ExternalFetalSexRevealWrapper>
  );
};
