import * as React from 'react';

export const BabyPeekGameszList = (): React.ReactElement => (
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.00878906" width="33.5117" height="40" rx="4" fill="#FFB828" />
    <rect
      x="0.5"
      y="0.508789"
      width="32.5117"
      height="39"
      rx="3.5"
      stroke="black"
      strokeOpacity="0.1"
    />
    <path
      d="M11.5703 4.67773H6.8991C6.03916 4.67773 5.34204 5.34199 5.34204 6.16139V10.6123C5.34204 11.4317 6.03916 12.096 6.8991 12.096H11.5703C12.4302 12.096 13.1273 11.4317 13.1273 10.6123V6.16139C13.1273 5.34199 12.4302 4.67773 11.5703 4.67773Z"
      fill="#FFE88D"
    />
    <path
      d="M11.5703 15.0635H6.8991C6.03916 15.0635 5.34204 15.7277 5.34204 16.5471V20.9981C5.34204 21.8175 6.03916 22.4817 6.8991 22.4817H11.5703C12.4302 22.4817 13.1273 21.8175 13.1273 20.9981V16.5471C13.1273 15.7277 12.4302 15.0635 11.5703 15.0635Z"
      fill="#FFE88D"
    />
    <path
      d="M11.5703 25.449H6.8991C6.03916 25.449 5.34204 26.1132 5.34204 26.9326V31.3836C5.34204 32.203 6.03916 32.8672 6.8991 32.8672H11.5703C12.4302 32.8672 13.1273 32.203 13.1273 31.3836V26.9326C13.1273 26.1132 12.4302 25.449 11.5703 25.449Z"
      fill="#FFE88D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2628 27.6005C11.4096 27.725 11.4277 27.9449 11.3032 28.0917L9.20905 30.5614C9.03258 30.7695 8.72185 30.7977 8.5108 30.6248L7.15742 29.5159C7.00854 29.3939 6.98674 29.1743 7.10873 29.0254C7.23072 28.8765 7.45031 28.8547 7.59919 28.9767L8.49565 29.4313C8.68862 29.5292 8.92263 29.4918 9.07554 29.3388L10.7716 27.6409C10.8961 27.4941 11.116 27.476 11.2628 27.6005Z"
      fill="#FFB828"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2633 17.1454C11.4101 17.2699 11.4282 17.4899 11.3037 17.6367L9.20954 20.1063C9.03307 20.3145 8.72234 20.3427 8.51129 20.1697L7.15791 19.0608C7.00903 18.9388 6.98723 18.7192 7.10922 18.5703C7.23121 18.4215 7.4508 18.3997 7.59968 18.5217L8.49614 18.9763C8.68911 19.0741 8.92312 19.0368 9.07602 18.8837L10.7721 17.1859C10.8966 17.0391 11.1165 17.021 11.2633 17.1454Z"
      fill="#FFB828"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2633 6.69013C11.4101 6.81461 11.4282 7.03453 11.3037 7.18134L9.20954 9.65102C9.03307 9.85913 8.72234 9.88734 8.51129 9.7144L7.15791 8.60548C7.00903 8.48349 6.98723 8.26391 7.10922 8.11502C7.23121 7.96614 7.4508 7.94434 7.59968 8.06633L8.49614 8.52094C8.68911 8.6188 8.92312 8.58144 9.07602 8.42837L10.7721 6.73054C10.8966 6.58374 11.1165 6.56564 11.2633 6.69013Z"
      fill="#FFB828"
    />
    <path
      d="M21.9345 6.9026H16.4848C16.0549 6.9026 15.7063 6.57047 15.7063 6.16077C15.7063 5.75107 16.0549 5.41895 16.4848 5.41895H21.9345C22.3645 5.41895 22.7131 5.75107 22.7131 6.16077C22.7131 6.57047 22.3645 6.9026 21.9345 6.9026Z"
      fill="#FFE88D"
    />
    <path
      d="M21.156 10.6116H16.4848C16.0549 10.6116 15.7063 10.2795 15.7063 9.86976C15.7063 9.46006 16.0549 9.12793 16.4848 9.12793H21.156C21.586 9.12793 21.9345 9.46006 21.9345 9.86976C21.9345 10.2795 21.586 10.6116 21.156 10.6116Z"
      fill="#FFE88D"
    />
    <path
      d="M26.6057 17.2881H16.4848C16.0549 17.2881 15.7063 16.956 15.7063 16.5463C15.7063 16.1366 16.0549 15.8044 16.4848 15.8044H26.6057C27.0357 15.8044 27.3842 16.1366 27.3842 16.5463C27.3842 16.956 27.0357 17.2881 26.6057 17.2881Z"
      fill="#FFE88D"
    />
    <path
      d="M21.156 20.9968H16.4848C16.0549 20.9968 15.7063 20.6647 15.7063 20.255C15.7063 19.8453 16.0549 19.5132 16.4848 19.5132H21.156C21.586 19.5132 21.9345 19.8453 21.9345 20.255C21.9345 20.6647 21.586 20.9968 21.156 20.9968Z"
      fill="#FFE88D"
    />
    <path
      d="M18.8204 27.6736H16.4848C16.0549 27.6736 15.7063 27.3415 15.7063 26.9318C15.7063 26.5221 16.0549 26.1899 16.4848 26.1899H18.8204C19.2504 26.1899 19.5989 26.5221 19.5989 26.9318C19.5989 27.3415 19.2504 27.6736 18.8204 27.6736Z"
      fill="#FFE88D"
    />
    <path
      d="M18.0419 31.3826H16.4848C16.0549 31.3826 15.7063 31.0505 15.7063 30.6408C15.7063 30.2311 16.0549 29.8989 16.4848 29.8989H18.0419C18.4719 29.8989 18.8204 30.2311 18.8204 30.6408C18.8204 31.0505 18.4719 31.3826 18.0419 31.3826Z"
      fill="#FFE88D"
    />
  </svg>
);
