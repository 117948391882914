import * as React from 'react';

export const FemaleFetus = (): React.ReactElement => (
  <svg
    width="62"
    height="63"
    viewBox="0 0 62 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="31" cy="31.4177" rx="31" ry="31.2878" fill="#E7EBF2" />
    <ellipse cx="36.5" cy="22.8392" rx="9.5" ry="9.5882" fill="#03CBBA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9097 37.8048L32.5 37.8807V43.0246H35C35.8239 43.0246 36.5 43.707 36.5 44.5385C36.5 45.37 35.8239 46.0525 35 46.0525H32.5V48.5757C32.5 49.4072 31.8239 50.0896 31 50.0896C30.1761 50.0896 29.5 49.4072 29.5 48.5757V46.0525H27C26.1761 46.0525 25.5 45.37 25.5 44.5385C25.5 43.707 26.1761 43.0246 27 43.0246H29.5V37.8807L29.0903 37.8048C24.2033 36.8992 20.5 32.5828 20.5 27.3807C20.5 21.5229 25.1961 16.7832 31 16.7832C36.8039 16.7832 41.5 21.5229 41.5 27.3807C41.5 32.5828 37.7967 36.8992 32.9097 37.8048ZM38.5 27.3807C38.5 23.2061 35.1361 19.8111 31 19.8111C26.8639 19.8111 23.5 23.2061 23.5 27.3807C23.5 31.5552 26.8639 34.9503 31 34.9503C35.1361 34.9503 38.5 31.5552 38.5 27.3807Z"
      fill="#5D5A5A"
    />
  </svg>
);
