import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';
import brochure from '../../assets/icons/brochure.svg';
import indexCard from '../../assets/icons/indexCard.svg';
import form from '../../assets/icons/form.svg';
import {
  ResourcesLanguageContainer,
  ResourcesReportImage,
  ResourceDescriptionContainer,
  ResourcesSingleFrameContainter,
} from './Resources.styles';
import brochureEn from '../../assets/files/BTO-UNITY-Complete-Brochure-Patient-Web.pdf';
import brochureEs from '../../assets/files/BTO-UNITY-Complete-Brochure-Patient-Web-ES.pdf';
import unityEn from '../../assets/files/BTO-UNITY-Patient-Rack-Card-EN.pdf';
import unityEs from '../../assets/files/BTO-UNITY-Patient-Rack-Card-ES.pdf';
import untiyCompleteEn from '../../assets/files/BTO-UNITY-Complete-Patient-Rack-Card-EN-Web.pdf';
import unityCompleteEs from '../../assets/files/BTO-UNITY-Complete-Patient-Rack-Card-ES-Web.pdf';
import formEn from '../../assets/files/BTO-Unity-Financial-Assistance-Form-Fillable_April2021.pdf';
import formEs from '../../assets/files/BTO-Unity-Financial-Assistance-Form-Fillable-Spanish_April2021.pdf';

export const ResourcesData = (): JSX.Element[] => [
  <React.Fragment key="patient-brochure">
    <ResourcesReportImage src={brochure} alt="Brochure" />
    <ResourcesSingleFrameContainter>
      <ResourceDescriptionContainer>
        {GetTypographyContent({
          content: 'Patient brochure',
          isBold: true,
          size: 'large',
          textColor: 'greyMedium',
        })}
        {GetTypographyContent({
          content: '(PDF)',
          size: 'small',
          textColor: 'greyMedium',
          padding: '4px 0 0 0',
        })}
      </ResourceDescriptionContainer>
      <ResourcesLanguageContainer>
        {GetTypographyContent({
          content: 'English',
          size: 'large',
          textColor: 'redMedium',
          link: brochureEn,
          linkInNewWindow: true,
          isDownload: true,
        })}
        <div>&#124;</div>
        {GetTypographyContent({
          content: 'Español',
          size: 'large',
          textColor: 'redMedium',
          link: brochureEs,
          linkInNewWindow: true,
          isDownload: true,
        })}
      </ResourcesLanguageContainer>
    </ResourcesSingleFrameContainter>
  </React.Fragment>,
  <React.Fragment key="index-card">
    <ResourcesReportImage src={indexCard} alt="Index Card" />
    <ResourcesSingleFrameContainter>
      <ResourceDescriptionContainer>
        {GetTypographyContent({
          content: 'UNITY Index Card',
          isBold: true,
          size: 'large',
          textColor: 'greyMedium',
        })}
        {GetTypographyContent({
          content: '(PDF)',
          size: 'small',
          textColor: 'greyMedium',
          padding: '4px 0 0 0',
        })}
      </ResourceDescriptionContainer>
      <ResourcesLanguageContainer>
        {GetTypographyContent({
          content: 'English',
          size: 'large',
          textColor: 'redMedium',
          link: unityEn,
          linkInNewWindow: true,
          isDownload: true,
        })}
        <div>&#124;</div>
        {GetTypographyContent({
          content: 'Español',
          size: 'large',
          textColor: 'redMedium',
          link: unityEs,
          linkInNewWindow: true,
          isDownload: true,
        })}
      </ResourcesLanguageContainer>
    </ResourcesSingleFrameContainter>
  </React.Fragment>,
  <React.Fragment key="complete-index">
    <ResourcesReportImage src={indexCard} alt="Index Card" />
    <ResourcesSingleFrameContainter>
      <ResourceDescriptionContainer>
        {GetTypographyContent({
          content: 'UNITY Screen™ Index Card',
          isBold: true,
          size: 'large',
          textColor: 'greyMedium',
        })}
        {GetTypographyContent({
          content: '(PDF)',
          size: 'small',
          textColor: 'greyMedium',
          padding: '4px 0 0 0',
        })}
      </ResourceDescriptionContainer>
      <ResourcesLanguageContainer>
        {GetTypographyContent({
          content: 'English',
          size: 'large',
          textColor: 'redMedium',
          link: untiyCompleteEn,
          linkInNewWindow: true,
          isDownload: true,
        })}
        <div>&#124;</div>
        {GetTypographyContent({
          content: 'Español',
          size: 'large',
          textColor: 'redMedium',
          link: unityCompleteEs,
          linkInNewWindow: true,
          isDownload: true,
        })}
      </ResourcesLanguageContainer>
    </ResourcesSingleFrameContainter>
  </React.Fragment>,
  <React.Fragment key="financial-form">
    <ResourcesReportImage src={form} alt="Form" />
    <ResourcesSingleFrameContainter>
      <ResourceDescriptionContainer>
        {GetTypographyContent({
          content: 'Financial Assistance Form',
          isBold: true,
          size: 'large',
          textColor: 'greyMedium',
        })}
        {GetTypographyContent({
          content: '(PDF)',
          size: 'small',
          textColor: 'greyMedium',
          padding: '4px 0 0 0',
        })}
      </ResourceDescriptionContainer>
      <ResourcesLanguageContainer>
        {GetTypographyContent({
          content: 'English',
          size: 'large',
          textColor: 'redMedium',
          link: formEn,
          linkInNewWindow: true,
          isDownload: true,
        })}
        <div>&#124;</div>
        {GetTypographyContent({
          content: 'Español',
          size: 'large',
          textColor: 'redMedium',
          link: formEs,
          linkInNewWindow: true,
          isDownload: true,
        })}
      </ResourcesLanguageContainer>
    </ResourcesSingleFrameContainter>
  </React.Fragment>,
];
