import React from 'react';

import {
  BabyPeekBarVertical,
  BabyPeekBarSectionVertical,
  BabyPeekBarHorizontal,
  BabyPeekBarSectionHorizontal,
} from './BabyPeekPercentage.styles';

export const BabyPeekPercantageBar: React.FC<{
  vertical?: boolean;
  values: {
    percetange: number;
    color: string;
  }[];
  selected: boolean;
}> = ({ vertical, values, selected }) => {
  const fullHundred = values.reduce((acc, curr) => acc + curr.percetange, 0);

  return (
    <>
      {vertical ? (
        <BabyPeekBarVertical>
          {values.map((percentage, index) => (
            <BabyPeekBarSectionVertical
              key={percentage.percetange}
              $height={percentage.percetange}
              $color={percentage.color}
              $start={index === values.length - 1}
              $end={index === 0 && fullHundred === 100}
              selected={selected}
            />
          ))}
        </BabyPeekBarVertical>
      ) : (
        <BabyPeekBarHorizontal selected={selected}>
          {values.map((percentage, index) => (
            <BabyPeekBarSectionHorizontal
              key={percentage.percetange}
              $width={percentage.percetange}
              $color={percentage.color}
              $start={index === 0}
              $end={index === values.length - 1 && fullHundred === 100}
              selected={selected}
            />
          ))}
        </BabyPeekBarHorizontal>
      )}
    </>
  );
};
