import React from 'react';
import { DisplayWithIcon } from '../shared/Typography/types';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  TestResultIconAndTypography,
  TestResultsIcon,
} from './TestResults.styles';

export const TestResults22q = (
  displayWithIcon: DisplayWithIcon
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: '22q11.2',
      size: 'large',
      textColor: 'greyMedium',
    })}
    <TestResultIconAndTypography>
      <TestResultsIcon marginLeft={displayWithIcon.display === 'See Report'}>
        {displayWithIcon.icon}
      </TestResultsIcon>
      {GetTypographyContent({
        content: displayWithIcon.display,
        size: 'medium',
        textColor: 'greyMedium',
      })}
    </TestResultIconAndTypography>
  </>
);
