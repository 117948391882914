import { format } from 'date-fns';

export const dateFormatter = (date: string): string => {
  const utcDate = new Date(date);
  return format(
    new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000),
    'MM/dd/yyyy'
  );
};

export const clearHash = (): void => {
  const uri = window.location?.toString();
  if (uri.indexOf('#') > 0) {
    const cleanUri = uri.substring(0, uri.indexOf('#'));
    window.history.replaceState({}, document.title, cleanUri);
  }
};

export const dateWithTimestampFormatter = (date: string): string =>
  format(new Date(date), 'MM/dd/yyyy');

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return '';
};

export const capitalizeFirstLetter = (phrase: string): string =>
  phrase
    .split(' ')
    .map(
      (word) =>
        word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1)
    )
    .join(' ');

export const networkErrorMessage =
  'Error connecting - please check your network connection.';

export const toBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
