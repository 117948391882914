import styled from 'styled-components/macro';
import { Button as MuiButton } from '@mui/material';
import { ColorPalette, themePalette } from '../../../theme';

export type ButtonSize = 'small' | 'medium' | 'large';

export const getButtonPadding = (size: ButtonSize): string => {
  if (size === 'small') return '4px 16px';
  return '11px 24px';
};

export const getButtonFontSize = (size: ButtonSize): string => {
  if (size === 'small') return '14px';
  if (size === 'medium') return '18px';
  return '20px';
};

const getBorder = (
  reverse: boolean | undefined,
  backgroundColor: string | undefined
): string => {
  if (reverse) {
    return `2px solid ${backgroundColor || themePalette.redMedium}`;
  }
  return '0';
};

export const getBackground = (
  reverse: boolean | undefined,
  backgroundColor: string | undefined
): string =>
  reverse ? themePalette.white : backgroundColor || themePalette.redMedium;

export const StyledButton = styled(MuiButton)<{
  size: ButtonSize;
  $backgroundColor?: keyof ColorPalette;
  $textColor?: keyof ColorPalette;
  $reverse?: boolean;
  $borderColor?: keyof ColorPalette;
}>`
  && {
    background: ${({ $reverse, $backgroundColor, theme }) =>
      getBackground($reverse, $backgroundColor && theme[$backgroundColor])};
    border-radius: 30px;
    border: ${({ $reverse, $borderColor, $backgroundColor, theme }) =>
      getBorder(
        $reverse,
        ($borderColor && theme[$borderColor]) ||
          ($backgroundColor && theme[$backgroundColor])
      )};
    color: ${({ theme, $textColor, $reverse, $backgroundColor }) =>
      $reverse
        ? ($backgroundColor && theme[$backgroundColor]) || theme.redMedium
        : ($textColor && theme[$textColor]) || theme.white};
    padding: ${({ size }) => getButtonPadding(size)};
    font-size: ${({ size }) => getButtonFontSize(size)};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    opacity: ${({ disabled }) => (disabled ? 0.7 : '')};

    :hover {
      background: ${({ $reverse, $backgroundColor, theme }) =>
        getBackground($reverse, $backgroundColor && theme[$backgroundColor])};
      opacity: 0.8;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    }
  }
`;
