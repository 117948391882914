import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { getUserAuth } from '../../../recoil/selectors';
import {
  VideoFeedback,
  VideoFeedbackGrid,
  VideoFeedbackText,
} from './VideoEmbed.styles';
import { SendActionType } from '../Services/SharedServices';

const VideoFeedbackWidget: React.FC<{
  videoType: 'billing' | 'carrier' | 'aneuploidy' | 'rhd';
  videoLocation: string;
  showFeedback: boolean;
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  reqID: string;
}> = ({ videoLocation, showFeedback, setShowFeedback, reqID, videoType }) => {
  const auth = useRecoilValue(getUserAuth);
  const [sentFeedback, setSentFeedback] = React.useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sentFeedback) {
      timeout = setTimeout(() => {
        setShowFeedback(false);
        setSentFeedback(false);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [sentFeedback]);

  const getAction = () => {
    if (videoType === 'billing') {
      return 'BillingVideoFeedback';
    }
    if (videoType === 'carrier') {
      return 'CarrierVideoFeedback';
    }
    if (videoType === 'aneuploidy') {
      return 'AneuploidyVideoFeedback';
    }
    return 'RhDVideoFeedback';
  };
  return showFeedback ? (
    <VideoFeedback>
      {sentFeedback ? (
        <VideoFeedbackText style={{ padding: '12px 12px' }}>
          Thank you for your feedback!
        </VideoFeedbackText>
      ) : (
        <VideoFeedbackGrid>
          <VideoFeedbackText>Is this video helpful?</VideoFeedbackText>
          <div
            style={{ height: '40px', display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              onClick={async () => {
                await SendActionType(getAction(), reqID, auth?.access_token, {
                  videoLocation,
                  feedback: 'positive',
                });
                setSentFeedback(true);
              }}
            />
            <FontAwesomeIcon
              icon={faThumbsDown}
              onClick={async () => {
                await SendActionType(
                  'BillingVideoClicked',
                  reqID,
                  auth?.access_token,
                  {
                    videoLocation,
                    feedback: 'negative',
                  }
                );
                setSentFeedback(true);
              }}
            />
          </div>
        </VideoFeedbackGrid>
      )}
    </VideoFeedback>
  ) : (
    <></>
  );
};

export default VideoFeedbackWidget;
