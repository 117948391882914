import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  SchedulingPageComponentGrid,
  SchedulingPageComponentWrapper,
  SchedulingPageContentWrapper,
  SchedulingPageImageWrapper,
  SchedulingPageWrapper,
  SchedulingReturnButton,
} from './SchedulingPage.styles';
import geneticCounselor from '../../assets/images/geneticCounselor.png';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { Scheduling } from '../../components/Scheduling/Scheduling';
import { StyledButton } from '../../components/shared/Button/Button.styles';
import { SendActionType } from '../../components/shared/Services/SharedServices';
import { getFirstRequisitionID, getUserAuth } from '../../recoil/selectors';

const SchedulingPage = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);
  const requisitionID = useRecoilValue(getFirstRequisitionID);

  useEffect(() => {
    const sendUIAction = async () => {
      try {
        await SendActionType(
          'BookConsultation',
          requisitionID || '',
          auth?.access_token
        );
      } catch (e) {
        return undefined;
      }
    };
    sendUIAction();
  }, []);

  return (
    <SchedulingPageWrapper>
      <SchedulingPageComponentWrapper deviceType={deviceType}>
        <SchedulingPageComponentGrid deviceType={deviceType}>
          {GetTypographyContent({
            content: 'Book a consultation',
            size: 'h2',
            textColor: 'greyMedium',
            isBold: true,
          })}
          <SchedulingPageContentWrapper deviceType={deviceType}>
            <SchedulingPageImageWrapper deviceType={deviceType}>
              <img alt="genetic-counselor" src={geneticCounselor} />
            </SchedulingPageImageWrapper>
            {GetTypographyContent({
              content:
                'Our board certified and licensed genetic counselors are available to answer your questions and help you understand your results.',
              size: 'medium',
              textColor: 'greyMedium',
              className: 'scheduling-page-top',
            })}
            {GetTypographyContent({
              content: 'Schedule a complimentary genetic counseling session',
              size: 'label',
              textColor: 'greyMedium',
              className: 'scheduling-page-bottom',
              isBold: true,
            })}
            <Scheduling />
          </SchedulingPageContentWrapper>
          <SchedulingReturnButton>
            <StyledButton
              variant="outlined"
              size="small"
              $backgroundColor="neutral50"
              $textColor="redMedium"
              onClick={() => history.push('/client/dashboard')}
            >
              RETURN TO HOMEPAGE
            </StyledButton>
          </SchedulingReturnButton>
        </SchedulingPageComponentGrid>
      </SchedulingPageComponentWrapper>
    </SchedulingPageWrapper>
  );
};

export default SchedulingPage;
