import React from 'react';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { FAQHeader } from './FAQSection.styles';

const FAQTitle: React.FC<{ title?: string }> = ({
  title = 'Frequently asked questions',
}): React.ReactElement => (
  <FAQHeader>
    {GetTypographyContent({
      content: title,
      isBold: true,
      isCaps: true,
      size: 'large',
      padding: '12px 16px 5px 16px',
    })}
  </FAQHeader>
);
export default FAQTitle;
