import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Info } from '@mui/icons-material';
import {
  UpfrontPaymentAccordionContainer,
  UpfrontPaymentEmailText,
  UpfrontPaymentBillingTitle,
  UpfrontPaymentInfoBillContentHeader,
  UpfrontPaymentGeneExplanation,
  UpfrontBillingCreateAccount,
  UpfrontBillingCreateAccountText,
  UpfrontBillingCreateAccountWrapper,
  UpfrontPaymentItemizedBillDownload,
  UpfrontPaymentItemizedSectionWrapper,
  UpfrontPaymentBillingGridHeader,
  UpfrontPaymentContentSecondaryHeader,
} from './UpfrontPayment.styles';

import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Button } from '../shared/Button/Button';
import {
  GetUpfrontPaymentPaperStatementUrls,
  PaperStatementUrls,
  UpfrontPaymentType,
} from './Services/UpfrontPaymentService';
import { getUserAuth } from '../../recoil/selectors';

import { REGISTER_URL, LOGIN_PAGE_URL } from '../../utils/urlConstants';
import { DownloadButton } from '../../assets/iconComponents/DownloadButton';

export const UpfrontPaymentPaperStatements: React.FC<{
  upfrontBill: UpfrontPaymentType;
}> = ({ upfrontBill }) => {
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);
  const [paperStatementUrls, setPaperStatementUrls] =
    useState<PaperStatementUrls>();

  const { billingPK } = useParams<{ billingPK: string; adminID?: string }>();

  useEffect(() => {
    const getPaperStatementUrls = async () => {
      if (auth && billingPK) {
        const paperStatementUrlsResponse =
          await GetUpfrontPaymentPaperStatementUrls(
            auth.access_token,
            billingPK
          );
        const urlsJson = await paperStatementUrlsResponse.json();
        setPaperStatementUrls(urlsJson);
      }
    };
    getPaperStatementUrls();
  }, []);

  return (
    <>
      <UpfrontPaymentAccordionContainer deviceType={deviceType}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <UpfrontPaymentEmailText>Paper Statements</UpfrontPaymentEmailText>
          </AccordionSummary>
          <AccordionDetails>
            {!auth?.access_token ? (
              <>
                <UpfrontBillingCreateAccountWrapper>
                  <UpfrontBillingCreateAccount>
                    <UpfrontBillingCreateAccountText>
                      <Info />{' '}
                      {`Please ${
                        upfrontBill.signup_url ? 'create an account' : 'login'
                      } to see paper statements.`}
                    </UpfrontBillingCreateAccountText>
                    <Button
                      type="submit"
                      label={
                        upfrontBill.signup_url ? 'Create Account' : 'Login'
                      }
                      size="medium"
                      onClick={() =>
                        upfrontBill.signup_url
                          ? history.push(
                              `${REGISTER_URL}${
                                upfrontBill.signup_url.split('c/s')[1]
                              }`
                            )
                          : history.push(`${LOGIN_PAGE_URL}`)
                      }
                      backgroundColor="greenDark"
                    />
                  </UpfrontBillingCreateAccount>
                </UpfrontBillingCreateAccountWrapper>
              </>
            ) : (
              <>
                {paperStatementUrls?.paper_statements &&
                paperStatementUrls?.paper_statements.length > 0 ? (
                  <UpfrontPaymentItemizedSectionWrapper>
                    <UpfrontPaymentInfoBillContentHeader>
                      <UpfrontPaymentBillingTitle>
                        STATEMENT DATE
                      </UpfrontPaymentBillingTitle>
                      <UpfrontPaymentBillingGridHeader>
                        <UpfrontPaymentBillingTitle>
                          DOWNLOAD
                        </UpfrontPaymentBillingTitle>
                      </UpfrontPaymentBillingGridHeader>
                    </UpfrontPaymentInfoBillContentHeader>
                    {paperStatementUrls?.paper_statements?.map((item) => (
                      <React.Fragment key={item.url}>
                        <UpfrontPaymentContentSecondaryHeader>
                          <UpfrontPaymentGeneExplanation>
                            {`${item.date ?? ''}`}
                          </UpfrontPaymentGeneExplanation>
                          <UpfrontPaymentBillingGridHeader>
                            <UpfrontPaymentGeneExplanation>
                              <UpfrontPaymentItemizedBillDownload
                                style={{ margin: '0px', height: '20px;' }}
                                onClick={() => {
                                  const tempAnchor =
                                    document.createElement('a');
                                  tempAnchor.download = 'file.pdf';
                                  tempAnchor.target = '_blank';
                                  tempAnchor.href = item.url;
                                  tempAnchor.click();
                                  tempAnchor.remove();
                                }}
                              >
                                <DownloadButton />
                              </UpfrontPaymentItemizedBillDownload>
                            </UpfrontPaymentGeneExplanation>
                          </UpfrontPaymentBillingGridHeader>
                        </UpfrontPaymentContentSecondaryHeader>
                      </React.Fragment>
                    ))}
                  </UpfrontPaymentItemizedSectionWrapper>
                ) : (
                  <>
                    <UpfrontBillingCreateAccountWrapper>
                      <UpfrontBillingCreateAccount>
                        <UpfrontBillingCreateAccountText>
                          <Info />
                          No paper statements available.
                        </UpfrontBillingCreateAccountText>
                      </UpfrontBillingCreateAccount>
                    </UpfrontBillingCreateAccountWrapper>
                  </>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </UpfrontPaymentAccordionContainer>
    </>
  );
};
