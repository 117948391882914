import * as React from 'react';

export const PreviousTraitPreview = (): React.ReactElement => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.797729"
      y="0.123779"
      width="48"
      height="48"
      rx="24"
      fill="white"
    />
    <path
      d="M20.079 23.4363L26.079 17.4363C26.454 17.03 27.1102 17.03 27.4852 17.4363C27.8915 17.8113 27.8915 18.4675 27.4852 18.8425L22.204 24.1238L27.4852 29.4363C27.8915 29.8113 27.8915 30.4675 27.4852 30.8425C27.1102 31.2488 26.454 31.2488 26.079 30.8425L20.079 24.8425C19.6727 24.4675 19.6727 23.8113 20.079 23.4363Z"
      fill="#171923"
    />
  </svg>
);
