import {
  AccordionProps,
  LINE_BREAK,
} from '../../components/shared/Accordion/Accordions';
import { CardProps } from '../../components/shared/Cards/Card';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { DeviceType } from '../../utils/hooks/useDeviceHook';
import { LandingFAQAnswerWrapper } from './Landing.styles';
import geneticCounselor from '../../assets/images/geneticCounselor.png';
import { HelpFAQAnswerWrapper } from '../Help/Help.styles';
import BillingVideoEmbed from '../../components/shared/Video/BillingVideoEmbed';

export const landingPageAccordions = (reqID: string): AccordionProps[] => [
  {
    title: 'What does UNITY Screen Aneuploidy NIPT test for?',
    content: (
      <LandingFAQAnswerWrapper>
        {GetTypographyContent({
          content:
            'Non-invasive prenatal testing (NIPT) through UNITY screens for specific chromosome changes called aneuploidy. The average person has 23 pairs of chromosomes, or 46 chromosomes total, with one of each chromosome inherited from each parent. A person with aneuploidy has an extra or missing copy of a chromosome, which may cause them to have health problems, physical birth defects, and/or developmental delays. The type and severity of symptoms vary depending on which chromosome is extra or missing. Anyone can have a baby born with a chromosome change. Most aneuploidy does not run in families.',
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content:
            'UNITY screens for the most common aneuploidies present at birth:',
          size: 'medium',
        })}
        <ul>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 21 (Down syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 18 (Edwards syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 13 (Patau syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: '*Monosomy X (Turner syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: '*Other sex chromosome aneuploidies (XXX/XXY/XYY)',
              size: 'medium',
            })}
          </li>
        </ul>
        {GetTypographyContent({
          content:
            '*Note, monosomy X and other sex chromosome aneuploidies are only screened for in singleton pregnancies.',
          size: 'medium',
        })}
      </LandingFAQAnswerWrapper>
    ),
    key: 'aneuploidyTestFor',
    padding: '3px 20px 3px 4px',
  },
  {
    title: 'What does the UNITY Carrier Screen test for?',
    content: (
      <LandingFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Carrier screening through UNITY determines your carrier status for commonly inherited genetic conditions. 
          Everyone has two copies of each gene, one inherited from each parent. 
          Carriers are individuals who are not affected with the condition (not expected to have symptoms) but 
          who carry a genetic change that causes one of their two gene copies to not work properly. 
          If both you and your reproductive partner are carriers of the same condition, there is a 25% (1 in 4) 
          chance to have an affected child with each pregnancy.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `Most carriers of these conditions will not have symptoms or known family history of the condition.
             Therefore, medical guidelines recommend all pregnant individuals be given the option to screen for 
             the following conditions covered by the UNITY Carrier Screen:`,
          size: 'medium',
        })}
        <ul>
          <li>
            {GetTypographyContent({
              content: 'Alpha-thalassemia (HBA1, HBA2)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content:
                'Sickle cell disease / beta-thalassemia / beta-hemoglobinopathy (HBB)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Cystic fibrosis (CFTR)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Spinal muscular atrophy (SMN1)',
              size: 'medium',
            })}
          </li>
        </ul>
        {GetTypographyContent({
          content: `If you are at least 9 weeks pregnant, and you are found to be a carrier, 
          we will perform reflex single-gene NIPT to evaluate your baby’s risk of having that condition.`,
          size: 'medium',
        })}
      </LandingFAQAnswerWrapper>
    ),
    key: 'carrierTestFor',
    padding: '3px 20px 3px 4px',
  },
  {
    title: 'How can I get the UNITY Screen™?',
    content: (
      <LandingFAQAnswerWrapper>
        {GetTypographyContent({
          content:
            'UNITY is a physician-ordered test. Please call us or email us at ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
        {GetTypographyContent({
          content:
            ' so we can help you get the testing done through your OB or midwife.',
          size: 'medium',
        })}
      </LandingFAQAnswerWrapper>
    ),
    key: 'howToGetTesting',
    padding: '3px 20px 3px 4px',
  },
  {
    title: 'Billing and insurance process overview',
    content: (
      <LandingFAQAnswerWrapper style={{ display: 'grid' }}>
        <BillingVideoEmbed
          reqID={reqID}
          videoLocation="dashboard-faq-section"
        />
      </LandingFAQAnswerWrapper>
    ),
    key: 'billingInsuranceVideo',
    padding: '3px 20px 3px 4px',
  },
  {
    title: 'Is the UNITY Screen™ covered by my insurance?',
    content: (
      <LandingFAQAnswerWrapper>
        {GetTypographyContent({
          content: `We accept all insurances including Medicaid. Medical guidelines recommend every pregnant
           woman to be offered screening for the conditions covered on the UNITY Screen™; therefore, 
           most major private insurance plans and Medicaid plans cover the testing. Depending on 
           your insurance details, there may be a co-payment or deductibles you are responsible for.`,
          size: 'medium',
        })}

        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you have any questions or concerns with testing cost, please email us at `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
        {GetTypographyContent({
          content: ' or call us at ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
      </LandingFAQAnswerWrapper>
    ),
    key: 'isCovered',
    padding: '3px 20px 3px 4px',
  },
  {
    title:
      'I have more questions. Can I talk to the genetic counselor before taking the test?',
    content: (
      <LandingFAQAnswerWrapper>
        {GetTypographyContent({
          content:
            'Yes, we provide pre-test and post-test genetic counseling at no charge. To schedule an appointment, please go to our ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'scheduling page,',
          size: 'medium',
          link: './client/scheduling',
          textColor: 'greenMedium',
        })}
        {GetTypographyContent({
          content: ' please call at ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
        {GetTypographyContent({
          content: ' or email us at ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
      </LandingFAQAnswerWrapper>
    ),
    key: 'moreQuestions',
    padding: '3px 20px 3px 4px',
  },
  {
    title: 'What is an Explanation of Benefits (EOB)?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `After your test has been processed and a claim is submitted to your insurance, 
            you may receive an Explanation of Benefits (EOB). `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: `An EOB is not a bill`,
          size: 'medium',
          isBold: true,
        })}
        {GetTypographyContent({
          content: `, even though it may look like one. This document is from your insurance and shows details like how much 
            was billed to your insurance for your test; what the insurance reimbursed BillionToOne; and the
             specifics of your insurance plan (deductible), if applicable.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `There is no action you need to take on an EOB. BillionToOne will send a final bill with any remaining balance after you receive an EOB.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'explainWOB',
  },
];

export const landingPageCard = (deviceType: DeviceType): CardProps => ({
  buttons: [],
  component: 'img',
  titleTypography: {
    content: 'Schedule a complimentary genetic counseling session.',
    isBold: true,
    isCaps: false,
    size: 'h2',
    padding: `${
      deviceType === 'desktop' ? '10px 30px 20px 0px' : '10px 0 15px 0'
    }`,
  },
  bodyTypography: {
    content:
      'Our board certified and licensed genetic counselors are available to answer your questions and help you understand your results',
    isBold: false,
    isCaps: false,
    size: 'medium',
    padding: `${
      deviceType === 'desktop' ? '10px 30px 60px 0px' : '0 0 30px 0'
    }`,
  },
  gridDimension: '4fr 6fr',
  src: geneticCounselor,
  buttonSize: 'medium',
});
