import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DetailedResultsTableContainer,
  DetailedResultsTableRow,
} from './DetailedResults.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  getSpecificAneuploidy,
  getSpecificIsFragileX,
  getSpecificUnity,
} from '../../recoil/selectors';
import {
  AneuploidyReportDataJsonSerializer,
  UnityReportDataJsonSerializer,
} from '../../recoil/types';

interface ResultRow {
  disease:
    | 'Trisomy 21'
    | 'Trisomy 18'
    | 'Trisomy 13'
    | 'Monosomy X'
    | 'Sex Chromosome Aneuploidy (XXX / XXY / XYY)'
    | 'Alpha-Thalassemia'
    | 'Sickle Cell Disease / Beta-Thalassemia/ Hemoglobinopathies'
    | 'Cystic Fibrosis'
    | 'Spinal Muscular Atrophy'
    | 'Fragile X Syndrome';
  gene?: ' (HBA1, HBA2)' | ' (HBB)' | ' (CFTR)' | ' (SMN1)' | ' (FMR1)';
}

export const getSyndromeRows = (
  aneuploidy: boolean,
  unityReportData: UnityReportDataJsonSerializer | undefined,
  aneuReportData: AneuploidyReportDataJsonSerializer | undefined
): ResultRow[] => {
  if (aneuploidy) {
    const aneuploidyRows: ResultRow[] = [];
    if (aneuReportData?.trisomy_21) {
      aneuploidyRows.push({ disease: 'Trisomy 21' });
    }
    if (aneuReportData?.trisomy_18) {
      aneuploidyRows.push({ disease: 'Trisomy 18' });
    }
    if (aneuReportData?.trisomy_13) {
      aneuploidyRows.push({ disease: 'Trisomy 13' });
    }
    if (aneuReportData?.monosomy_x) {
      aneuploidyRows.push({ disease: 'Monosomy X' });
    }
    if (aneuReportData?.sex_aneuploidy) {
      aneuploidyRows.push({
        disease: 'Sex Chromosome Aneuploidy (XXX / XXY / XYY)',
      });
    }
    return aneuploidyRows;
  }
  const carrierRows: ResultRow[] = [];
  if (unityReportData?.athal_carrier) {
    carrierRows.push({ disease: 'Alpha-Thalassemia', gene: ' (HBA1, HBA2)' });
  }
  if (unityReportData?.bthal_carrier) {
    carrierRows.push({
      disease: 'Sickle Cell Disease / Beta-Thalassemia/ Hemoglobinopathies',
      gene: ' (HBB)',
    });
  }
  if (unityReportData?.cftr_carrier) {
    carrierRows.push({ disease: 'Cystic Fibrosis', gene: ' (CFTR)' });
  }
  if (unityReportData?.sma_carrier) {
    carrierRows.push({ disease: 'Spinal Muscular Atrophy', gene: ' (SMN1)' });
  }
  if (unityReportData?.fmr1_carrier) {
    carrierRows.push({ disease: 'Fragile X Syndrome', gene: ' (FMR1)' });
  }
  return carrierRows;
};

// Note, getResult assume the value is not a high risk/postivie result
const getResult = (
  aneuploidy: boolean,
  fragileXOptOut: boolean,
  sexChromosome: boolean
) => {
  if (sexChromosome) {
    return 'Not Detected';
  }
  if (aneuploidy) {
    return 'Low Risk';
  }
  if (fragileXOptOut) {
    return 'Opted Out';
  }
  return 'Negative';
};

export const DetailedResultsTable: React.FC<{
  isAneuploidy: boolean;
  requisitionID: string;
}> = ({ isAneuploidy, requisitionID }) => {
  const fragileX = useRecoilValue(getSpecificIsFragileX(requisitionID));
  const unityReportData = useRecoilValue(getSpecificUnity(requisitionID));
  const aneuReportData = useRecoilValue(getSpecificAneuploidy(requisitionID));

  const rows = getSyndromeRows(
    isAneuploidy,
    unityReportData?.report_data,
    aneuReportData?.report_data
  );

  return (
    <DetailedResultsTableContainer>
      <DetailedResultsTableRow>
        {GetTypographyContent({
          content: 'CONDITION SCREENED',
          size: 'small',
          textColor: 'greyMedium',
          isBold: true,
          isCaps: true,
        })}

        {GetTypographyContent({
          content: isAneuploidy ? 'Fetal Risk By NIPT' : 'Carrier Status',
          size: 'small',
          textColor: 'greyMedium',
          isBold: true,
          isCaps: true,
          className: isAneuploidy ? 'fetal-risk-title' : '',
        })}
      </DetailedResultsTableRow>
      {rows.map((row) => (
        <DetailedResultsTableRow key={row.disease}>
          <div>
            {GetTypographyContent({
              content: row.disease,
              size: 'medium',
              textColor: 'greyMedium',
            })}
            {row.gene &&
              GetTypographyContent({
                content: row.gene,
                size: 'medium',
                textColor: 'greyMedium',
                isItalic: true,
              })}
          </div>
          {GetTypographyContent({
            content: getResult(
              isAneuploidy,
              row.disease === 'Fragile X Syndrome' &&
                fragileX?.status === 'Not Ordered',
              row.disease === 'Sex Chromosome Aneuploidy (XXX / XXY / XYY)'
            ),
            size: 'medium',
            textColor: 'greyMedium',
          })}
        </DetailedResultsTableRow>
      ))}
    </DetailedResultsTableContainer>
  );
};
