import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import BabyPeekGamesMain from './BabyPeekIcons/BabyPeekGamesMain.png';
import {
  BabyPeekGamesAccordionGrid,
  BabyPeekGamesAccordionWrapper,
  BabyPeekGamesAnswers,
  BabyPeekGamesIntroGrid,
  BabyPeekGamesMainDescription,
  BabyPeekGamesTitle,
} from './BabyPeekGames.styles';
import { Accordions } from '../shared/Accordion/Accordions';
import {
  BabyPeekGameAccordionTitleGrid,
  BabyPeekGameAccordionTitle,
  BabyPeekGamesAccordionText,
} from './BabyPeekAccordion.styles';
import { BabyPeekButton } from './BabyPeekButton';
import { BabyPeekGamesGuessTheLittleOne } from './BabyPeekIcons/BabyPeekGamesGuessLittleOne';
import { BabyPeekGameszList } from './BabyPeekIcons/BabyPeekGamesList';
import { BabyPeekGamesGifts } from './BabyPeekIcons/BabyPeekGamesGIft';
import { BabyPeekGamesPeekABoo } from './BabyPeekIcons/BabyPeekGamesPeekABook';
import { BabyPeekGamesTraitReveal } from './BabyPeekIcons/BabyPeekGamesTraitReveal';
import { BabyPeekGamesWriting } from './BabyPeekIcons/BabyPeekGamesWriting';
import { getUserAuth } from '../../recoil/selectors';
import { BabyPeekDownloadGames } from './Services/BabyPeekService';

export const BabyPeekGames: React.FC = () => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const [downloading, setDownloading] = useState(false);
  const { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();

  return (
    <>
      <div
        style={{
          display: 'grid',
          background:
            'linear-gradient(180deg, #FFF6D2 0%, rgba(255, 246, 210, 0.00) 100%)',
          pointerEvents: downloading ? 'none' : 'auto',
        }}
      >
        <BabyPeekGamesIntroGrid>
          <BabyPeekGamesTitle>BabyPeek Games</BabyPeekGamesTitle>
          <img src={BabyPeekGamesMain} alt="main" />
          <BabyPeekGamesMainDescription>
            Download printable games and have fun discovering your baby’s traits
            with your loved ones!
          </BabyPeekGamesMainDescription>
          <BabyPeekGamesAccordionGrid>
            <BabyPeekGamesAccordionWrapper deviceType={deviceType}>
              <Accordions
                data={[
                  {
                    title: (
                      <BabyPeekGameAccordionTitleGrid>
                        <BabyPeekGameszList />{' '}
                        <BabyPeekGameAccordionTitle>
                          Guess The Little One
                        </BabyPeekGameAccordionTitle>
                      </BabyPeekGameAccordionTitleGrid>
                    ),
                    content: (
                      <>
                        <BabyPeekGamesAccordionText>
                          <div>
                            {`Can your friends and family guess the baby’s traits? This game allows them to `}{' '}
                            <span>{`predict your future baby's traits in a fun and interactive way. `}</span>{' '}
                          </div>
                          <br />
                          <BabyPeekGamesGuessTheLittleOne />
                          <BabyPeekButton
                            variant="filled"
                            content={<>Download PDF</>}
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'GuessTheLittleOne',
                                  'GamePlay',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download =
                                  'GuessTheLittleOneGame.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                            style={{
                              justifySelf: 'center',
                              marginTop: '-90px',
                            }}
                          />
                          <br />
                          <BabyPeekGamesAnswers
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'GuessTheLittleOne',
                                  'AnswerSheet',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download =
                                  'GuessTheLittleOneAnswer.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                            style={{
                              justifySelf: 'center',
                            }}
                          >
                            Download Answers
                          </BabyPeekGamesAnswers>
                          <br />
                        </BabyPeekGamesAccordionText>
                      </>
                    ),
                    key: 'guessLittleOne',
                  },
                ]}
                isLinked={false}
                size="small"
              />
            </BabyPeekGamesAccordionWrapper>
            <BabyPeekGamesAccordionWrapper deviceType={deviceType}>
              <Accordions
                data={[
                  {
                    title: (
                      <BabyPeekGameAccordionTitleGrid>
                        <BabyPeekGamesWriting />{' '}
                        <BabyPeekGameAccordionTitle>
                          Peek-a-Boo
                        </BabyPeekGameAccordionTitle>
                      </BabyPeekGameAccordionTitleGrid>
                    ),
                    content: (
                      <>
                        <BabyPeekGamesAccordionText>
                          <div>
                            {' '}
                            {`Rank and guess the likelihood of the baby’s unique traits! `}
                          </div>
                          <br />
                          <BabyPeekGamesPeekABoo />
                          <BabyPeekButton
                            variant="filled"
                            content={<>Download PDF</>}
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'PeekABoo',
                                  'GamePlay',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download = 'PeekABooGame.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                            style={{
                              justifySelf: 'center',
                              marginTop: '-90px',
                            }}
                          />
                          <br />
                          <BabyPeekGamesAnswers
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'PeekABoo',
                                  'AnswerSheet',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download = 'PeekABooAnswer.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                          >
                            Download Answers
                          </BabyPeekGamesAnswers>
                          <br />
                        </BabyPeekGamesAccordionText>
                      </>
                    ),
                    key: 'peekABoo',
                  },
                ]}
                isLinked={false}
                size="small"
              />
            </BabyPeekGamesAccordionWrapper>
            <BabyPeekGamesAccordionWrapper deviceType={deviceType}>
              <Accordions
                data={[
                  {
                    title: (
                      <BabyPeekGameAccordionTitleGrid>
                        <BabyPeekGamesGifts />{' '}
                        <BabyPeekGameAccordionTitle>
                          Traits Reveal
                        </BabyPeekGameAccordionTitle>
                      </BabyPeekGameAccordionTitleGrid>
                    ),
                    content: (
                      <>
                        <BabyPeekGamesAccordionText>
                          <div>
                            {' '}
                            {`Print out your baby’s trait reveal cards and share the excitement with your friends and family! `}
                          </div>
                          <br />
                          <BabyPeekGamesTraitReveal />
                          <BabyPeekButton
                            variant="filled"
                            content={<>Download PDF</>}
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'TraitsReveal',
                                  'GamePlay',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download = 'TraitsRevealGame.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                            style={{
                              justifySelf: 'center',
                              marginTop: '-90px',
                            }}
                          />
                          <br />
                          <BabyPeekGamesAnswers
                            onClick={async () => {
                              setDownloading(true);
                              const downloadLinkResponse =
                                await BabyPeekDownloadGames(
                                  babypeekPK,
                                  'TraitsReveal',
                                  'AnswerSheet',
                                  auth?.access_token
                                );
                              if (downloadLinkResponse.ok) {
                                const link = await downloadLinkResponse.json();
                                const tempAnchor = document.createElement('a');
                                tempAnchor.download = 'TraitsRevealAnswer.pdf';
                                const downloadUrl = link.s3_url;
                                if (downloadUrl) {
                                  tempAnchor.href = downloadUrl;
                                  tempAnchor.click();
                                }
                                tempAnchor.remove();
                              }
                              setDownloading(false);
                            }}
                          >
                            Download Answers
                          </BabyPeekGamesAnswers>
                          <br />
                        </BabyPeekGamesAccordionText>
                      </>
                    ),
                    key: 'babypeekGamesTraitReveal',
                  },
                ]}
                isLinked={false}
                size="small"
              />
            </BabyPeekGamesAccordionWrapper>
          </BabyPeekGamesAccordionGrid>
        </BabyPeekGamesIntroGrid>
      </div>
    </>
  );
};
