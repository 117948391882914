import styled from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const TestResultsCenterContainer = styled.div<{
  isRedrawParent: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${({ theme, isRedrawParent }) =>
    isRedrawParent ? theme.redrawParentGrey : theme.white};
  padding-bottom: 15px;
  border-radius: 24px;
`;

export const TestResultsOutterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
`;

export const TestResultsInnerContainer = styled.div<{ deviceType: DeviceType }>`
  display: grid;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === 'mobile' ? '3fr 6fr' : 'auto auto'};
  padding: 20px 20px 5px 20px;
  grid-gap: ${({ deviceType }) => (deviceType === 'mobile' ? '' : '10px 20px')};
`;

export const TestResultsRedrawWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
`;

export const TestResultsInnerDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
`;

export const TestResultsInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 15px 20px 5px 20px;
`;

export const TestResultsButtonContainer = styled.div<{
  deviceType: DeviceType;
  processing: boolean;
}>`
  display: flex;
  justify-self: ${({ deviceType, processing }) =>
    deviceType !== 'desktop' && processing
      ? 'flex-start'
      : 'flex-end'} !important;
  align-self: ${({ deviceType, processing }) =>
    deviceType !== 'desktop' && processing
      ? 'flex-start'
      : 'flex-end'} !important;
  padding: 5px 20px 5px 20px;
`;

export const TestResultsLearnMoreContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  p {
    padding: 0px 0px 0px
      ${({ deviceType }) => (deviceType === 'mobile' ? '20px' : '25px')} !important;
  }
`;

export const TestResultIconAndTypography = styled.div`
  display: inline-grid;
  grid-template-columns: 25px auto;
  grid-gap: 10px;
`;

export const TestResultsIcon = styled.div<{
  marginLeft?: boolean;
  dashboard?: boolean;
}>`
  margin-top: -2px;
  margin-left: ${({ marginLeft }) => marginLeft && '3px'};
`;

export const TestResultsHr = styled.hr`
  border: 1px solid ${({ theme }) => theme.neutral100};
  margin: 10px 0px;
`;

export const TestResultsDesktopContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto 1fr;
  grid-gap: 33px;
  padding: 18px 0px 0px 25px;
`;

export const TestResultsRiskDesktopContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 5px 20px 0px 25px;
  align-items: center;
`;

export const TestResultsInfoDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 20px 20px 0px 25px;
`;

export const TestResultsViewButton = styled.div<{ deviceType: DeviceType }>`
  background: ${({ theme }) => theme.redMedium};
  border-radius: 30px;
  border: 0;
  color: ${({ theme }) => theme.white};
  padding: 5px 16px 2px 16px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 32px;
  cursor: ${({ deviceType }) =>
    deviceType === 'desktop' ? 'pointer' : undefined};

  :hover {
    opacity: ${({ deviceType }) =>
      deviceType === 'desktop' ? '.9' : undefined};
    box-shadow: ${({ deviceType }) =>
      deviceType === 'desktop' ? '0px 2px 3px rgba(0, 0, 0, 0.3);' : undefined};
  }
`;
