import React from 'react';

export const CustomSwitch: React.FC<{
  clicked: boolean;
  color?: string;
}> = ({ clicked, color = '#FE706F' }) => (
  <>
    {!clicked ? (
      <svg
        width="40"
        height="24"
        viewBox="0 0 40 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="38"
          height="22"
          rx="11"
          stroke="#B6B2B2"
          strokeWidth="2"
        />
        <circle cx="12" cy="12" r="6" fill="#5D5A5A" />
      </svg>
    ) : (
      <svg
        width="40"
        height="24"
        viewBox="0 0 40 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="24" rx="12" fill={color} />
        <circle cx="28" cy="12" r="6" fill="white" />
      </svg>
    )}
  </>
);
