import { SetterOrUpdater } from 'recoil';
import {
  BabyPeekReveal,
  UserAuthClientResponse,
  UserProfileAuthClientResponse,
} from '../../recoil/types';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { TypographySize } from '../shared/Typography/Typography.styles';
import {
  BABYPEEK_URL,
  BABYPEEK_URL_FAQ,
  BILLING_URL,
  CONTACT_US_URL,
  HELP_PAGE_URL,
  LANDING_PAGE_URL,
  LOGIN_PAGE_URL,
  PROFILE_URL,
} from '../../utils/urlConstants';
import { ColorPalette } from '../../theme';
import { WarningRounded } from '../../assets/iconComponents/WarningRounded';
import {
  HeaderAccordionGrid,
  HeaderAccordionWrapper,
  HeaderIconContainerBabyPeekUnlockContainer,
  HeaderProfileWarning,
} from './Header.styles';
import { isAddressOrInsuranceRequired } from '../Profile/helpers';
import { DeviceType } from '../../utils/hooks/useDeviceHook';
import { removeCookieAccessToken } from '../../utils/requestUtils';
import { BabyPeekAccordionHeader } from './BabyPeekAccordionHeader';

interface Header {
  header: React.ReactElement;
  key: string;
  hide?: boolean;
}

export const GetHeaderMenuLinks = (
  typographyColor: keyof ColorPalette,
  fontSize: TypographySize,
  activeHeader: string,
  setActiveHeader: (header: string) => void,
  patientProfileInfo: UserProfileAuthClientResponse | undefined,
  setPatientInfo: SetterOrUpdater<UserAuthClientResponse | undefined>,
  setProfileInfo: SetterOrUpdater<UserProfileAuthClientResponse | undefined>,
  deviceType: DeviceType,
  billingPK?: string,
  babyPeek?: {
    status: BabyPeekReveal;
    babyPeekPK: string;
  },
  setNavBarToggle?: () => void
): Header[] => [
  {
    header: GetTypographyContent({
      content: 'Home',
      size: fontSize,
      padding: '20px',
      link: LANDING_PAGE_URL,
      textColor: `${
        activeHeader.includes(LANDING_PAGE_URL)
          ? 'greenMedium'
          : typographyColor
      }`,
      onClick: () => {
        setActiveHeader(LANDING_PAGE_URL);
        if (setNavBarToggle) setNavBarToggle();
      },
    }),
    key: 'home',
  },
  {
    header: GetTypographyContent({
      content: 'Help & FAQs',
      size: fontSize,
      padding: '20px',
      link: HELP_PAGE_URL,
      textColor: `${
        activeHeader.includes(HELP_PAGE_URL) ? 'greenMedium' : typographyColor
      }`,
      onClick: () => {
        setActiveHeader(HELP_PAGE_URL);
        if (setNavBarToggle) setNavBarToggle();
      },
    }),
    key: 'help',
  },
  {
    header: GetTypographyContent({
      content: 'Billing',
      size: fontSize,
      padding: '20px',
      link: `${BILLING_URL}/${billingPK}`,
      textColor: `${
        activeHeader.includes(BILLING_URL) ? 'greenMedium' : typographyColor
      }`,
      onClick: () => {
        setActiveHeader(BILLING_URL);
        if (setNavBarToggle) setNavBarToggle();
      },
    }),

    key: 'internalBill',
    hide: !billingPK,
  },
  {
    header:
      babyPeek?.status === 'pending' ? (
        GetTypographyContent({
          content: (
            <>
              BabyPeek
              <HeaderIconContainerBabyPeekUnlockContainer>
                Unlock
              </HeaderIconContainerBabyPeekUnlockContainer>
            </>
          ),
          size: fontSize,
          padding: '20px',
          link: `${BABYPEEK_URL}/${babyPeek?.babyPeekPK}/${'intro'}?loc=menu`,
          textColor: `${
            activeHeader.includes(BABYPEEK_URL)
              ? 'greenMedium'
              : typographyColor
          }`,
          onClick: () => {
            setActiveHeader(BABYPEEK_URL);
            if (setNavBarToggle) setNavBarToggle();
          },
        })
      ) : (
        <HeaderAccordionWrapper
          onClick={() => {
            setActiveHeader(BABYPEEK_URL);
          }}
          deviceType={deviceType}
          backgroundColor={deviceType !== 'desktop' ? '#063761' : undefined}
        >
          <BabyPeekAccordionHeader
            data={[
              {
                title: GetTypographyContent({
                  content: 'BabyPeek',
                  size: fontSize,
                  padding: '20px',
                  textColor: `${
                    activeHeader.includes(BABYPEEK_URL) ||
                    activeHeader.includes(BABYPEEK_URL_FAQ)
                      ? 'greenMedium'
                      : typographyColor
                  }`,
                  onClick: () => {
                    setActiveHeader(BABYPEEK_URL);
                    if (setNavBarToggle) setNavBarToggle();
                  },
                }),
                content: (
                  <HeaderAccordionGrid>
                    <div>
                      {GetTypographyContent({
                        content: 'Results',
                        size: fontSize,
                        padding: '20px',
                        link: `${BABYPEEK_URL}/${
                          babyPeek?.babyPeekPK
                        }/${'summary'}?loc=menu`,
                        textColor: `${typographyColor}`,
                        onClick: () => {
                          setActiveHeader(BABYPEEK_URL);
                          if (setNavBarToggle) setNavBarToggle();
                        },
                      })}
                    </div>
                    <div>
                      {GetTypographyContent({
                        content: 'Baby Shower Games',
                        size: fontSize,
                        padding: '20px',
                        link: `${BABYPEEK_URL}/${
                          babyPeek?.babyPeekPK
                        }/${'games'}?loc=menu`,
                        textColor: `${typographyColor}`,
                        onClick: () => {
                          setActiveHeader(BABYPEEK_URL);
                          if (setNavBarToggle) setNavBarToggle();
                        },
                      })}
                    </div>
                    <div>
                      {GetTypographyContent({
                        content: 'FAQs',
                        size: fontSize,
                        padding: '20px',
                        link: `${BABYPEEK_URL_FAQ}`,
                        textColor: `${typographyColor}`,
                        onClick: () => {
                          setActiveHeader(BABYPEEK_URL_FAQ);
                          if (setNavBarToggle) setNavBarToggle();
                        },
                      })}
                    </div>
                  </HeaderAccordionGrid>
                ),
                key: 'babyPeekAccordion',
              },
            ]}
          />
        </HeaderAccordionWrapper>
      ),

    key: 'babyPeek',
    hide: !babyPeek,
  },
  {
    header: isAddressOrInsuranceRequired(patientProfileInfo) ? (
      <HeaderProfileWarning
        to={PROFILE_URL}
        onClick={() => {
          setActiveHeader(PROFILE_URL);
        }}
        deviceType={deviceType}
      >
        {GetTypographyContent({
          content: 'Profile',
          size: fontSize,
          padding: '20px',
          link: PROFILE_URL,
          textColor: `${
            activeHeader.includes(PROFILE_URL) ? 'greenMedium' : typographyColor
          }`,
          onClick: () => {
            setActiveHeader(PROFILE_URL);
          },
        })}{' '}
        <WarningRounded />
      </HeaderProfileWarning>
    ) : (
      GetTypographyContent({
        content: 'Profile',
        size: fontSize,
        padding: '20px',
        link: PROFILE_URL,
        textColor: `${
          activeHeader.includes(PROFILE_URL) ? 'greenMedium' : typographyColor
        }`,
        onClick: () => {
          setActiveHeader(PROFILE_URL);
          if (setNavBarToggle) setNavBarToggle();
        },
      })
    ),
    key: 'profile',
  },
  {
    header: GetTypographyContent({
      content: 'Contact us',
      size: fontSize,
      padding: '20px',
      link: CONTACT_US_URL,
      textColor: `${
        activeHeader.includes(CONTACT_US_URL) ? 'greenMedium' : typographyColor
      }`,
      onClick: () => {
        setActiveHeader(CONTACT_US_URL);
        if (setNavBarToggle) setNavBarToggle();
      },
    }),
    key: 'contact',
  },
  {
    header: GetTypographyContent({
      content: 'Logout',
      size: fontSize,
      padding: '20px',
      link: LOGIN_PAGE_URL,
      textColor: `${
        activeHeader.includes(LOGIN_PAGE_URL) ? 'greenMedium' : typographyColor
      }`,
      onClick: () => {
        removeCookieAccessToken();
        setPatientInfo(undefined);
        setProfileInfo(undefined);
        sessionStorage.removeItem('current-pathname');
        if (setNavBarToggle) setNavBarToggle();
      },
    }),
    key: 'logout',
  },
];

export const GetLoginHeader = (
  typographyColor: keyof ColorPalette,
  fontSize: TypographySize,
  setPatientInfo: SetterOrUpdater<UserAuthClientResponse | undefined>,
  loginActive: boolean,
  setNavBarToggle?: () => void
): Header[] =>
  !loginActive
    ? [
        {
          header: GetTypographyContent({
            content: 'Login',
            size: fontSize,
            padding: '20px',
            link: LOGIN_PAGE_URL,
            textColor: typographyColor,
            onClick: () => {
              removeCookieAccessToken();
              setPatientInfo(undefined);
              if (setNavBarToggle) setNavBarToggle();
            },
          }),
          key: 'login',
        },
      ]
    : [];
