import * as React from 'react';
import { themePalette } from '../../theme';

export const PlayVideo = ({
  color = themePalette.white,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM12 20.272V11.728C12 10.464 13.408 9.696 14.464 10.384L21.104 14.656C22.08 15.28 22.08 16.72 21.104 17.344L14.464 21.616C13.408 22.304 12 21.536 12 20.272Z"
      fill={color}
    />
  </svg>
);
