import React from 'react';
import {
  CreateAccountDesktopContainer,
  CreateAccountFormDesktopColorbackground,
  CreateAccountFormVector,
  CreateAccountFormImage,
} from './CreateAccountForm.styles';
import unityRound from '../../assets/logos/unityRound.svg';
import vector from '../../assets/icons/vector.svg';

import { CreateAccountFormContent } from './CreateAccountFormContent';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const CreateAccountForm: React.FC<{ setPathname: () => void }> = ({
  setPathname,
}) => {
  const { isDesktop } = useDeviceHook();

  return (
    <>
      {isDesktop && (
        <CreateAccountDesktopContainer>
          <CreateAccountFormDesktopColorbackground>
            <CreateAccountFormVector src={vector} />
            <CreateAccountFormImage src={unityRound} width="321px" />
          </CreateAccountFormDesktopColorbackground>
          <CreateAccountFormContent setPathname={setPathname} />
        </CreateAccountDesktopContainer>
      )}
      {!isDesktop && <CreateAccountFormContent setPathname={setPathname} />}
    </>
  );
};
