import { Modal, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { useParams } from 'react-router-dom';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Button } from '../shared/Button/Button';

import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';

import { PatientInsuranceInfo } from '../Profile/helpers';
import {
  BillingRadioControlDropdown,
  ProfileInsuranceModalButtons,
  ProfileInsuranceModalTitle,
  ProfileModalTitleWrapper,
} from '../Profile/ProfileInsuranceModal.styles';
import ProfileInsuranceUpload from '../Profile/ProfileInsuranceUpload';
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentFinancialModalWrapper,
  UpfrontPaymentFinancialReceipt,
  UpfrontPaymentFinancialReceiptTitle,
  UpfrontPaymentSecondaryReceiptTitle,
} from './UpfrontPayment.styles';
import {
  SubmitNewUpfrontInsurance,
  UpfrontPaymentType,
} from './Services/UpfrontPaymentService';
import { GreenFullCheckIcon } from '../../assets/iconComponents/GreenFullCheckIcon';

const getInsuranceType = (
  insuranceType: 'primary' | 'secondary' | 'medicaid_or_hmo' | undefined
) => {
  if (insuranceType === 'primary') {
    return 'insurance';
  }
  if (insuranceType === 'secondary') {
    return 'secondary insurance';
  }
  if (insuranceType === 'medicaid_or_hmo') {
    return 'medicaid or HMO insurance';
  }
  return 'insurance';
};

const getInsuranceTypeTitle = (
  insuranceType: 'primary' | 'secondary' | 'medicaid_or_hmo' | undefined
) => {
  if (insuranceType === 'primary') {
    return 'Insurance';
  }
  if (insuranceType === 'secondary') {
    return 'Secondary Insurance';
  }
  if (insuranceType === 'medicaid_or_hmo') {
    return 'Medicaid or HMO Insurance';
  }
  return 'insurance';
};

export const UpfrontSecondaryInsuranceModal: React.FC<{
  secondayModalOpen: 'primary' | 'secondary' | 'medicaid_or_hmo' | undefined;
  setSecondaryModalOpen: React.Dispatch<
    React.SetStateAction<
      'primary' | 'secondary' | 'medicaid_or_hmo' | undefined
    >
  >;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  setHomeScreen: () => void;
}> = ({
  secondayModalOpen,
  setSecondaryModalOpen,
  setUpfrontBill,
  setHomeScreen,
}) => {
  const { deviceType } = useDeviceHook();
  const [isError, setCheckError] = useState(false);
  const { billingPK, adminID } =
    useParams<{ billingPK: string; adminID?: string }>();
  const [loading, setLoading] = useState(false);

  const [secondaryInsuranceResponse, setSecondaryInsuranceResponse] =
    useState<boolean>();

  const [patientInsuranceInfo, setPatientInsuranceInfo] =
    React.useState<PatientInsuranceInfo>({
      billToInsuranceType: 'Upload',
      insuranceCompanyName: '',
      groupId: '',
      memberId: '',
      policyOwnerName: '',
      policyOwnerDob: '',
      relationshipToPolicyOwnerSelf: true,
      relationshipToPolicyOwnerDependent: false,
      relationshipToPolicyOwnerOther: false,
      insuranceFileUrls: {},
      checkError: false,
    });

  useEffect(() => {
    setCheckError(false);
  }, [patientInsuranceInfo]);

  return (
    <Modal
      open={Boolean(secondayModalOpen)}
      onClose={() => setSecondaryModalOpen(undefined)}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <>
        {secondaryInsuranceResponse ? (
          <UpfrontPaymentFinancialModalWrapper
            deviceType={deviceType}
            allowScroll={false}
            receipt
          >
            <UpfrontPaymentFinancialReceipt deviceType={deviceType} noConfetti>
              <GreenFullCheckIcon />
              <UpfrontPaymentFinancialReceiptTitle>
                {`Thank you for submitting your ${getInsuranceType(
                  secondayModalOpen
                )} `}
              </UpfrontPaymentFinancialReceiptTitle>
              <UpfrontPaymentSecondaryReceiptTitle>
                {`Our team will work with your ${getInsuranceType(
                  secondayModalOpen
                )} and notify you
                in the future if there is any balance remaining.`}
              </UpfrontPaymentSecondaryReceiptTitle>
              {secondayModalOpen === 'secondary' && (
                <UpfrontPaymentSecondaryReceiptTitle>
                  Please note: If your secondary insurance is through Medicaid
                  you will not have any out-of-pocket costs.
                </UpfrontPaymentSecondaryReceiptTitle>
              )}
              <UpfrontPaymentButtonRow>
                <Button
                  label="Done"
                  onClick={() => setSecondaryModalOpen(undefined)}
                  size="medium"
                  backgroundColor="greenDark"
                />
              </UpfrontPaymentButtonRow>
            </UpfrontPaymentFinancialReceipt>
          </UpfrontPaymentFinancialModalWrapper>
        ) : (
          <UpfrontPaymentFinancialModalWrapper
            deviceType={deviceType}
            allowScroll
          >
            <ProfileInsuranceModalTitle deviceType={deviceType}>
              <ProfileModalTitleWrapper deviceType={deviceType}>
                <div>{getInsuranceTypeTitle(secondayModalOpen)}</div>
                <CloseIcon onClick={() => setSecondaryModalOpen(undefined)} />
              </ProfileModalTitleWrapper>
            </ProfileInsuranceModalTitle>

            {
              <>
                <BillingRadioControlDropdown $hideBorder>
                  {
                    <RadioGroup
                      value={patientInsuranceInfo.billToInsuranceType}
                      onChange={(e) =>
                        setPatientInsuranceInfo({
                          ...patientInsuranceInfo,
                          billToInsuranceType: e.target.value as
                            | 'Entry'
                            | 'Upload',
                        })
                      }
                    >
                      {
                        // UPLOAD
                        <ProfileInsuranceUpload
                          patientInsuranceInfo={patientInsuranceInfo}
                          setPatientInsuranceInfo={setPatientInsuranceInfo}
                          isError={isError}
                          upfrontBilling={secondayModalOpen}
                        />
                      }
                    </RadioGroup>
                  }
                </BillingRadioControlDropdown>
              </>
            }

            {loading ? (
              <SpinnerLoading />
            ) : (
              <ProfileInsuranceModalTitle deviceType={deviceType}>
                <ProfileInsuranceModalButtons deviceType={deviceType}>
                  <Button
                    className="upfront-cancel-button"
                    label="Close"
                    onClick={() => {
                      setSecondaryModalOpen(undefined);
                    }}
                    size="medium"
                    reverse
                    textColor="greyMedium"
                    backgroundColor="greyMedium"
                  />
                  <Button
                    label="Submit"
                    className="upfront-submit-button"
                    onClick={async () => {
                      if (
                        Object.keys(patientInsuranceInfo.insuranceFileUrls)
                          .length > 1 &&
                        secondayModalOpen
                      ) {
                        setLoading(true);
                        const secondayInsuranceResponse =
                          await SubmitNewUpfrontInsurance(
                            Object.keys(
                              patientInsuranceInfo.insuranceFileUrls
                            ).map(
                              (file) =>
                                patientInsuranceInfo.insuranceFileUrls[file]
                                  .fileName
                            ),
                            billingPK,
                            secondayModalOpen,
                            adminID
                          );
                        setLoading(false);
                        if (secondayInsuranceResponse.ok) {
                          const secondayInsuranceJson =
                            await secondayInsuranceResponse.json();
                          setUpfrontBill(secondayInsuranceJson);
                          setSecondaryInsuranceResponse(true);
                          setHomeScreen();
                        }
                      }
                    }}
                    size="medium"
                    backgroundColor="greenDark"
                  />
                </ProfileInsuranceModalButtons>
              </ProfileInsuranceModalTitle>
            )}
          </UpfrontPaymentFinancialModalWrapper>
        )}
      </>
    </Modal>
  );
};
