import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  ProfileFieldGrid,
  ProfileFieldRow,
  ProfileFieldRowMissingAddress,
  ProfileFieldRowTitle,
  ProfileMainWrapper,
} from './Profile.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { getUserAuth, getUserProfile } from '../../recoil/selectors';
import {
  dateWithTimestampFormatter,
  dateFormatter,
  formatPhoneNumber,
} from '../../utils/helpers';

export const Profile: React.FC<{
  setResetPassword: (reset: boolean) => void;
  setResetEmail: (reset: boolean) => void;
}> = ({ setResetEmail, setResetPassword }) => {
  const authInfo = useRecoilValue(getUserAuth);
  const profileInfo = useRecoilValue(getUserProfile);

  return (
    <>
      {profileInfo && authInfo && (
        <ProfileMainWrapper data-testid="ProfileTestId">
          {GetTypographyContent({
            content: 'Personal Details',
            size: 'h3',
            textColor: 'greyMedium',
            isBold: true,
          })}
          <ProfileFieldGrid>
            <ProfileFieldRow>
              <ProfileFieldRowTitle>Name</ProfileFieldRowTitle>
              {GetTypographyContent({
                content: `${profileInfo.first_name} ${profileInfo.last_name}`,
                size: 'medium',
                textColor: 'black',
              })}
            </ProfileFieldRow>

            <ProfileFieldRow>
              <ProfileFieldRowTitle>Date of Birth</ProfileFieldRowTitle>
              {GetTypographyContent({
                content: profileInfo.dob && dateFormatter(profileInfo.dob),
                size: 'medium',
                textColor: 'black',
              })}
            </ProfileFieldRow>
            {profileInfo.estimated_due_date && (
              <ProfileFieldRow>
                <ProfileFieldRowTitle>Estimated Due Date</ProfileFieldRowTitle>
                {GetTypographyContent({
                  content: dateFormatter(profileInfo.estimated_due_date),
                  size: 'medium',
                  textColor: 'black',
                })}
              </ProfileFieldRow>
            )}
            <ProfileFieldRow>
              <ProfileFieldRowTitle>Phone Number</ProfileFieldRowTitle>
              {GetTypographyContent({
                content: formatPhoneNumber(profileInfo.phone_number),
                size: 'medium',
                textColor: 'black',
              })}
            </ProfileFieldRow>
            <ProfileFieldRow>
              <ProfileFieldRowTitle>
                Email Address{' '}
                {GetTypographyContent({
                  content: 'Edit',
                  size: 'large',
                  textColor: 'redMedium',
                  onClick: () => setResetEmail(true),
                  className: 'edit-button',
                  isCaps: false,
                })}
              </ProfileFieldRowTitle>
              <div>
                {GetTypographyContent({
                  content: authInfo.email,
                  size: 'medium',
                  textColor: 'black',
                })}
              </div>
            </ProfileFieldRow>
            <ProfileFieldRow>
              <ProfileFieldRowTitle>
                Password{' '}
                {GetTypographyContent({
                  content: 'Edit',
                  size: 'large',
                  textColor: 'redMedium',
                  className: 'edit-button',
                  onClick: () => setResetPassword(true),
                  isCaps: false,
                })}
              </ProfileFieldRowTitle>
              <div>
                {GetTypographyContent({
                  content: authInfo.last_password_reset
                    ? `Last changed ${dateWithTimestampFormatter(
                        authInfo.last_password_reset
                      )}`
                    : 'N/A',
                  size: 'medium',
                  textColor: 'black',
                })}
              </div>
            </ProfileFieldRow>
            <ProfileFieldRow>
              <ProfileFieldRowTitle>Address</ProfileFieldRowTitle>
              {profileInfo.address ? (
                <>
                  {GetTypographyContent({
                    content: `${profileInfo.street_address_1}`,
                    size: 'medium',
                    textColor: 'black',
                  })}
                  {profileInfo.street_address_2 &&
                    GetTypographyContent({
                      content: `${profileInfo.street_address_2}`,
                      size: 'medium',
                      textColor: 'black',
                    })}
                  {GetTypographyContent({
                    content: `${profileInfo.city}, ${profileInfo.state} ${profileInfo.zip}`,
                    size: 'medium',
                    textColor: 'black',
                  })}
                </>
              ) : (
                <ProfileFieldRowMissingAddress>
                  Missing Address
                </ProfileFieldRowMissingAddress>
              )}
            </ProfileFieldRow>
          </ProfileFieldGrid>
        </ProfileMainWrapper>
      )}
    </>
  );
};
