import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Button } from '../shared/Button/Button';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  CreateAccountButtonContainer,
  CreateAccountInput,
  CreateAccountLoginMessageContainer,
  CreateAccountCenterContainer,
  CreateAccountDatePicker,
  Spinner,
  CreateAccountFormStyled,
  CreateAccountPassword,
  CreateAccountPasswordInfoContainer,
  CreateAccountFormStyledContainer,
  ButtonContainer,
  CreateAccountCenterFormContainer,
  CreateAccountPrivacyPolicy,
} from './CreateAccountForm.styles';
import {
  auth0ResponseMessages,
  CreateAccountRequest,
  SendViewSignUpPageAction,
  SendSignUpErrorAction,
} from './Services/CreateAccountService';
import { CreateAccountValuesType } from './types';
import {
  LANDING_PAGE_URL,
  LOGIN_PAGE_URL,
  VERIFY_EMAIL_URL,
} from '../../utils/urlConstants';
import { createAccountInitialValues } from './Services/CreateAccountServiceHelpers';
import { CreateAccountPasswordInfo } from './CreateAccountPasswordInfo';
import loading from '../../assets/icons/loading.svg';
import { patientState } from '../../recoil/atoms';
import { UserAuthClientResponse } from '../../recoil/types';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { validateEmail } from '../../utils/hooks/helpers';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { getUserAuth } from '../../recoil/selectors';
import { networkErrorMessage } from '../../utils/helpers';
import { setCookieAccessToken } from '../../utils/requestUtils';

export const CreateAccountFormContent: React.FC<{
  setPathname: () => void;
}> = ({ setPathname }) => {
  const history = useHistory();
  const { smsCode } = useParams<{ smsCode: string }>();
  const auth = useRecoilValue(getUserAuth);
  const [isFromEmailLink, setIsFromEmailLink] = useState(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPathname();
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    try {
      const sendPageView = async () => {
        const response = await SendViewSignUpPageAction(smsCode);
        if (response.status === 308) {
          history.push(LOGIN_PAGE_URL);
        }
      };
      sendPageView();
    } catch (e) {
      // error in viewing page
    }
  }, []);

  useEffect(() => {
    if (!smsCode) {
      history.push(LOGIN_PAGE_URL);
    }
    if (auth) {
      history.push(LANDING_PAGE_URL);
    }
  }, [auth]);

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fromEmail = params.get('e'); // e is set from a sign up email link
      setIsFromEmailLink(fromEmail === 'True');
    } catch (e) {
      // from sms
      setIsFromEmailLink(false);
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setPatientInfo] = useRecoilState(patientState);

  const [isPw8Chars, setIsPw8Chars] = useState(false);
  const [isPwUpperChar, setIsPwUpperChar] = useState(false);
  const [isPwLowerChar, setIsPwLowerChar] = useState(false);
  const [isPwDigit, setIsPwDigit] = useState(false);
  const { deviceType } = useDeviceHook();

  const checkPasswordRequirements = (input: string): boolean => {
    const pw8Chars = input.length >= 8;
    const pwUpperChar = /[A-Z]/.test(input);
    const pwLowerChar = /[a-z]/.test(input);
    const pwDigit = /[0-9]/.test(input);
    setIsPw8Chars(pw8Chars);
    setIsPwUpperChar(pwUpperChar);
    setIsPwLowerChar(pwLowerChar);
    setIsPwDigit(pwDigit);
    return pw8Chars && pwUpperChar && pwLowerChar && pwDigit;
  };

  const onSubmit = async ({
    first_name,
    last_name,
    dob,
    email,
    password,
  }: CreateAccountValuesType): Promise<void> => {
    setIsSubmitting(true);
    try {
      const response = await CreateAccountRequest({
        first_name,
        last_name,
        dob,
        email,
        password,
        sms_code: smsCode,
        is_from_email: isFromEmailLink,
      });
      setIsSubmitting(false);
      const statusCode: number = response[0];
      const newUserInfo: UserAuthClientResponse | undefined = response[1];
      if (newUserInfo?.auth0.access_token) {
        setCookieAccessToken(newUserInfo.auth0.access_token);
        setPatientInfo(newUserInfo);
        history.push(VERIFY_EMAIL_URL);
      } else {
        const msg = `${auth0ResponseMessages[statusCode]}`;
        setErrorMessage(msg);
        SendSignUpErrorAction(smsCode, msg);
      }
    } catch (e) {
      setIsSubmitting(false);
      setErrorMessage(networkErrorMessage);
      SendSignUpErrorAction(smsCode, networkErrorMessage);
    }
  };

  return (
    <CreateAccountCenterContainer
      data-testid="CreateAccountForm"
      deviceType={deviceType}
    >
      <ErrorPopup message={errorMessage} hideMessage={setErrorMessage} onTop />
      <CreateAccountCenterFormContainer>
        {GetTypographyContent({
          content: 'Welcome to UNITY',
          size: 'xlarge',
          textColor: 'neutral700',
          padding: deviceType === 'mobile' ? '40px 0 0' : '75px 0 0 0',
        })}
        {GetTypographyContent({
          content: 'Please create an account',
          size: 'h3',
          isBold: true,
          textColor: 'neutral700',
          padding: '5px 0 0 0',
        })}
        <CreateAccountLoginMessageContainer>
          {GetTypographyContent({
            content: 'Already have an account?',
            size: 'medium',
            textColor: 'neutral700',
          })}
          {GetTypographyContent({
            content: 'Log in',
            size: 'medium',
            textColor: 'greenDark',
            link: LOGIN_PAGE_URL,
          })}
        </CreateAccountLoginMessageContainer>
        {smsCode && (
          <Formik
            initialValues={createAccountInitialValues}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            validateOnChange={false}
            validate={(values) => {
              const errors: FormikErrors<CreateAccountValuesType> = {};
              if (!values.first_name) {
                errors.first_name = 'Required';
              } else if (!validateEmail(values.email)) {
                errors.email = 'Invalid email';
              }

              if (!values.last_name) {
                errors.last_name = 'Required';
              }
              if (!values.dob) {
                errors.dob = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              }

              if (!values.password) {
                errors.password = 'Required';
              } else if (!checkPasswordRequirements(values.password)) {
                errors.password = 'Password not valid';
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldError,
            }: FormikProps<CreateAccountValuesType>) => (
              <CreateAccountFormStyledContainer deviceType={deviceType}>
                <CreateAccountFormStyled
                  deviceType={deviceType}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !isSubmitting) {
                      handleSubmit();
                    }
                  }}
                >
                  <CreateAccountInput
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    placeholder="First name"
                    label="First name"
                    size="small"
                    onChange={(event) => {
                      setFieldError('first_name', '');
                      setFieldValue('first_name', event.target.value);
                    }}
                    error={errors.first_name}
                  />
                  <CreateAccountInput
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    placeholder="Last name"
                    label="Last name"
                    size="small"
                    onChange={(event) => {
                      setFieldError('last_name', '');
                      setFieldValue('last_name', event.target.value);
                    }}
                    error={errors.last_name}
                  />
                  <CreateAccountDatePicker
                    type="date"
                    id="create-account-dob"
                    name="dob"
                    value={values.dob}
                    label="Date of birth"
                    size="small"
                    onChange={(event) => {
                      setFieldError('dob', '');
                      setFieldValue('dob', event.target.value);
                    }}
                    error={errors.dob}
                    InputLabelProps={{ shrink: true }}
                    $noDateValue={!values.dob}
                  />
                  <CreateAccountInput
                    type="email"
                    name="email"
                    value={values.email}
                    placeholder="Email"
                    label="Email"
                    size="small"
                    onChange={(event) => {
                      setFieldError('email', '');
                      setFieldValue('email', event.target.value);
                    }}
                    error={errors.email}
                  />

                  <CreateAccountPassword
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={(event) => {
                      setFieldError('password', '');
                      checkPasswordRequirements(event.target.value);
                      setFieldValue('password', event.target.value);
                    }}
                    label="Password"
                    size="small"
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />

                  <CreateAccountPasswordInfoContainer>
                    <CreateAccountPasswordInfo
                      isPw8Chars={isPw8Chars}
                      isPwDigit={isPwDigit}
                      isPwUpperChar={isPwUpperChar}
                      isPwLowerChar={isPwLowerChar}
                    />
                  </CreateAccountPasswordInfoContainer>
                  <CreateAccountPrivacyPolicy>
                    <p>
                      {`By clicking 'Create Account' you agree to our `}
                      <a
                        style={{ textDecoration: 'underline' }}
                        href="https://billiontoone.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      {`, `}
                      <a
                        style={{ textDecoration: 'underline' }}
                        href="https://billiontoone.com/privacy-practices/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Notice of Privacy Practices
                      </a>
                      {`, and `}
                      <a
                        style={{ textDecoration: 'underline' }}
                        href="../../client/terms_of_use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use.
                      </a>
                    </p>
                  </CreateAccountPrivacyPolicy>
                </CreateAccountFormStyled>
                <CreateAccountButtonContainer>
                  {smsCode && isSubmitting && (
                    <Spinner>
                      <img src={loading} alt="Loading" />
                    </Spinner>
                  )}
                  <ButtonContainer>
                    <Button
                      type="submit"
                      label="Create Account"
                      size="medium"
                      onClick={handleSubmit}
                    />
                  </ButtonContainer>
                </CreateAccountButtonContainer>
              </CreateAccountFormStyledContainer>
            )}
          </Formik>
        )}
      </CreateAccountCenterFormContainer>
    </CreateAccountCenterContainer>
  );
};
