export function BabyPeekVerticalQuotes(): JSX.Element {
  return (
    <>
      <Quote
        quote={
          <>
            The results are perfect for{' '}
            <span style={{ fontWeight: 700 }}>gender reveal parties...</span>{' '}
            Very exciting [way] to have a closer bond with your baby. Also adds
            fun when you aren’t feeling well in the first trimester.
          </>
        }
        attribution="Sophia, Texas"
        quotePosition="left"
      />
      <Quote
        quote={
          <>
            I really love it. I think it’s awesome. As a pregnant person, I’m
            living in anticipation every day... Now, I can imagine... It
            definitely{' '}
            <span style={{ fontWeight: 700 }}>
              creates the connection and bond with my baby.
            </span>
          </>
        }
        attribution="Megan, Florida"
        quotePosition="right"
      />
      <Quote
        quote={
          <>
            I 100% believe the baby won’t like cliantro because the father can’t
            stand it! That was so neat to see! All in all the{' '}
            <span style={{ fontWeight: 700 }}>
              information gathered seems spot on
            </span>{' '}
            and I’m super curious now to see if it matches up.
          </>
        }
        attribution="Raylee, California"
        quotePosition="left"
      />
    </>
  );
}
interface IBabyPeekVerticalQuote {
  quote: React.ReactNode;
  attribution: string;
  quotePosition: 'left' | 'right';
}
function Quote({ quote, attribution, quotePosition }: IBabyPeekVerticalQuote) {
  return (
    <>
      <div
        style={{
          width: '80%',
          height: '100%',
          border: '1px #FFE88D solid',
          margin: '0 auto',
        }}
      />
      <div>
        <div style={{ textAlign: quotePosition }}>
          {quotePosition === 'left' && (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0212 12.4362C18.4066 12.3749 17.0545 12.3749 17.0545 11.5143C17.0545 10.3469 18.7752 8.81047 21.5408 7.15107C22.4009 6.5978 24.1837 5.73764 24.1837 4.56982C24.1837 3.64797 23.5074 2.9721 22.094 3.15639C20.7419 3.34068 18.6522 4.26253 15.8871 6.5978C12.9985 8.99476 10.2947 12.9894 10.2947 16.9224C10.2947 20.9171 12.9985 24.9118 17.3622 24.9118C20.8649 24.9118 23.8764 22.2692 23.8764 18.6431C23.876 15.8776 21.971 12.6822 19.0212 12.4362Z"
                fill="#FFB828"
              />
              <path
                d="M10.3828 10.7085C9.87805 10.4446 9.3244 10.272 8.72691 10.2223C8.11234 10.161 6.76022 10.161 6.76022 9.30046C6.76022 8.13302 8.48093 6.59661 11.2465 4.9372C12.107 4.38394 13.889 3.52378 13.889 2.35595C13.889 1.4341 13.2128 0.758233 11.7993 0.942525C10.4472 1.12682 8.35755 2.04867 5.59239 4.38394C2.70424 6.7809 0 10.7756 0 14.7089C0 18.7036 2.70386 22.6983 7.0675 22.6983C8.09333 22.6983 9.07648 22.4702 9.95449 22.0585C9.09355 20.5019 8.63457 18.7009 8.63457 16.9232C8.63496 14.728 9.34186 12.5991 10.3828 10.7085Z"
                fill="#FFB828"
              />
            </svg>
          )}
          {quotePosition === 'right' && (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.9788 13.388C6.59337 13.4493 7.94549 13.4493 7.94549 14.3099C7.94549 15.4773 6.22478 17.0137 3.45924 18.6732C2.59908 19.2264 0.816297 20.0866 0.816297 21.2544C0.816297 22.1763 1.49255 22.8521 2.90597 22.6678C4.2581 22.4835 6.34777 21.5617 9.11293 19.2264C12.0015 16.8295 14.7053 12.8348 14.7053 8.90179C14.7053 4.90712 12.0015 0.912439 7.63782 0.912439C4.13511 0.912439 1.12358 3.55499 1.12358 7.18109C1.12397 9.94663 3.02896 13.1421 5.9788 13.388Z"
                fill="#FFB828"
              />
              <path
                d="M14.6172 15.1158C15.1219 15.3796 15.6756 15.5522 16.2731 15.6019C16.8877 15.6632 18.2398 15.6632 18.2398 16.5238C18.2398 17.6912 16.5191 19.2276 13.7535 20.887C12.893 21.4403 11.111 22.3004 11.111 23.4683C11.111 24.3901 11.7872 25.066 13.2007 24.8817C14.5528 24.6974 16.6425 23.7756 19.4076 21.4403C22.2958 19.0433 25 15.0486 25 11.1153C25 7.12059 22.2961 3.12592 17.9325 3.12592C16.9067 3.12592 15.9235 3.35405 15.0455 3.7657C15.9064 5.32229 16.3654 7.12331 16.3654 8.90105C16.365 11.0963 15.6581 13.2251 14.6172 15.1158Z"
                fill="#FFB828"
              />
            </svg>
          )}
        </div>
        <div
          style={{
            width: '96%',
            textAlign: 'center',
            color: '#171923',
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 400,
            margin: '0 auto',
            marginTop: -4,
          }}
        >
          {quote}

          <div
            style={{
              fontFamily: 'Inter',
              fontStyle: 'italic',
              fontWeight: 400,
              fontSize: 16,
              marginTop: 16,
            }}
          >
            {attribution}
          </div>
        </div>
      </div>
    </>
  );
}
