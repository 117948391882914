import React, { useState } from 'react';
import {
  DetailedResultsViewReportButtonWrapper,
  DetailedResultsViewReportWarning,
  DetailedResultsViewReportWrapper,
} from './DetailedResults.styles';
import { StyledButton } from '../shared/Button/Button.styles';
import { ShowReportIcon } from '../../assets/iconComponents/ShowReport';
import { Warning } from '../../assets/iconComponents/Warning';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const DetailedResultsViewReport: React.FC<{
  fetalSexWarning?: boolean;
  reportDownload?: () => Promise<string | undefined>;
  setShowFetalSexModal?: (show: '' | 'reveal' | 'report') => void;
}> = ({ setShowFetalSexModal, reportDownload, fetalSexWarning }) => {
  const [downloading, setDownloading] = useState(false);

  const ViewReportButton = (
    <StyledButton
      size="small"
      $backgroundColor="redMedium"
      $textColor="white"
      data-testid="view-full-report"
      onClick={async () => {
        if (setShowFetalSexModal && fetalSexWarning) {
          setShowFetalSexModal('report');
        } else if (!downloading) {
          setDownloading(true);
          const tempAnchor = document.createElement('a');
          tempAnchor.download = 'file.pdf';
          const downloadUrl = reportDownload && (await reportDownload());
          if (downloadUrl) {
            tempAnchor.href = downloadUrl;
            tempAnchor.click();
          }
          tempAnchor.remove();
          setDownloading(false);
        }
      }}
    >
      <DetailedResultsViewReportButtonWrapper>
        <div>
          <ShowReportIcon color="white" />
        </div>
        <div>
          {GetTypographyContent({
            content: `View full report`,
            size: 'medium',
            textColor: 'white',
            isBold: true,
            isCaps: true,
          })}
        </div>
      </DetailedResultsViewReportButtonWrapper>
    </StyledButton>
  );

  return (
    <DetailedResultsViewReportWrapper>
      <>
        {ViewReportButton}
        {fetalSexWarning && (
          <DetailedResultsViewReportWarning>
            <div>
              <Warning />
            </div>
            <div>
              {GetTypographyContent({
                content: `Results contain your baby's sex`,
                size: 'medium',
                textColor: 'redDark',
              })}
            </div>
          </DetailedResultsViewReportWarning>
        )}
      </>
    </DetailedResultsViewReportWrapper>
  );
};
