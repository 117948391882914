import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowWidth } from '@react-hook/window-size';
import CloseIcon from '@mui/icons-material/Close';
import {
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentInfoRow,
  UpfrontPaymentButtonRow,
  UpfrontPaymentReceiptTitle,
  UpfrontPaymentReceiptHeader,
  UpfrontPaymentReceiptThankYou,
  UpfrontPaymentReceiptWrapper,
  UpfrontPaymentReceiptMessage,
  UpfrontPaymentFieldRow,
  UpfrontPaymentCost,
  UpfrontPaymentInfoBillContent,
  UpfrontPaymentMainText,
  UpfrontPaymentPlanTitle,
  UpfrontPaymentSolidLine,
  UpfrontPaymentCloseIcon,
} from './UpfrontPayment.styles';

import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

import { GetTypographyContent } from '../shared/Typography/Typography';
import { Button } from '../shared/Button/Button';

import {
  GetUpfrontPaymentPlan,
  GetUpfrontPaymentReceipt,
  UpfrontPaymentPlan,
  UpfrontPaymentReceiptType,
  getCentsToDollars,
} from './Services/UpfrontPaymentService';

import { GreenFullCheckIcon } from '../../assets/iconComponents/GreenFullCheckIcon';
import { themePalette } from '../../theme';
import { EOBIcon } from '../../assets/iconComponents/EOBIcon';
import { UpfrontPaymentPlanSteps } from './UpfrontPaymentPlanSteps';
import { StepperIndex } from './types';
import { BabyPeekIntroFAQAccordionWrapper } from './PaymentPlanNumPayments.styles';

export const UpfrontPaymentReceipt: React.FC<{
  isPaymentPlan: boolean;
  paymentAmount: number;
  modal?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<StepperIndex>>;
}> = ({ modal, setModal, isPaymentPlan, setActiveStep, paymentAmount }) => {
  const { deviceType } = useDeviceHook();

  const [finalReceipt, setFinalReceipt] = useState<UpfrontPaymentReceiptType>();
  const { billingPK, adminID } =
    useParams<{ billingPK: string; adminID?: string }>();
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [paymentPlan, setPaymentPlan] = useState<UpfrontPaymentPlan[]>();
  const [numberPayments, setNumberPayments] = useState<'6'>();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (!isPaymentPlan) {
      const getReceipt = async () => {
        if (billingPK) {
          const receiptResponse = await GetUpfrontPaymentReceipt(
            billingPK,
            undefined,
            adminID
          );
          const receiptJson = await receiptResponse.json();
          setFinalReceipt(receiptJson);
        }
      };
      getReceipt();
    }
  }, []);

  useEffect(() => {
    if (isPaymentPlan) {
      const x = async () => {
        const paymentPlanResponse = await GetUpfrontPaymentPlan(
          billingPK,
          adminID
        );
        const paymentPlanJson = await paymentPlanResponse.json();
        setPaymentPlan(paymentPlanJson);
        if (paymentPlanJson) {
          setNumberPayments(paymentPlanJson.length.toString());
        }
      };
      x();
    }
  }, []);

  const getConfettiHeight = (): number => {
    if (isPaymentPlan) {
      return 550;
    }
    if (deviceType !== 'mobile') {
      return 375;
    }

    return 450;
  };

  return (
    <>
      <UpfrontPaymentDesktopContainer
        deviceType={deviceType}
        overflowCheck={Boolean(isPaymentPlan && modal)}
      >
        {modal && !showNotice && (
          <Confetti
            height={getConfettiHeight()}
            numberOfPieces={100}
            gravity={0.1}
            initialVelocityX={5}
            initialVelocityY={5}
            recycle
            colors={[
              themePalette.blueMedium,
              themePalette.greenMedium,
              themePalette.blueLight,
              themePalette.greenLight,
            ]}
            width={deviceType !== 'mobile' ? 525 : windowWidth - 25}
          />
        )}
        <UpfrontPaymentReceiptWrapper
          deviceType={deviceType}
          noConfetti={!modal || showNotice}
          isPaymentPlan={isPaymentPlan}
        >
          {modal ? (
            <>
              <UpfrontPaymentCloseIcon>
                <CloseIcon onClick={() => setModal && setModal(false)} />
              </UpfrontPaymentCloseIcon>
              <UpfrontPaymentReceiptHeader>
                {showNotice ? (
                  <UpfrontPaymentReceiptThankYou>
                    Notice
                  </UpfrontPaymentReceiptThankYou>
                ) : (
                  <>
                    {' '}
                    <GreenFullCheckIcon />
                    <UpfrontPaymentReceiptThankYou>
                      {isPaymentPlan
                        ? 'Thank you for scheduling your payments!'
                        : 'Thanks for your payment'}
                    </UpfrontPaymentReceiptThankYou>{' '}
                  </>
                )}
              </UpfrontPaymentReceiptHeader>
            </>
          ) : (
            <UpfrontPaymentReceiptTitle>Receipt</UpfrontPaymentReceiptTitle>
          )}
          {modal && (
            <UpfrontPaymentReceiptMessage>
              {showNotice ? (
                <>
                  {GetTypographyContent({
                    content: (
                      <>
                        You will receive an Explanation of Benefits (EOB) from
                        your insurance company in the coming weeks.{' '}
                        <span
                          style={{ fontWeight: 'bold', display: 'contents' }}
                        >
                          {' '}
                          This is not a bill and no action is needed when you
                          receive your EOB from your insurance.
                        </span>{' '}
                      </>
                    ),
                    size: 'medium',
                    textColor: 'black',
                  })}
                </>
              ) : (
                <>
                  {GetTypographyContent({
                    content: 'A receipt has been sent to your email address.',
                    size: 'medium',
                    textColor: 'black',
                  })}
                </>
              )}
            </UpfrontPaymentReceiptMessage>
          )}
          {showNotice ? (
            <UpfrontPaymentReceiptMessage>
              <EOBIcon />
            </UpfrontPaymentReceiptMessage>
          ) : (
            <>
              {isPaymentPlan ? (
                paymentPlan && (
                  <>
                    <UpfrontPaymentInfoBillContent marginBottom={8}>
                      <UpfrontPaymentPlanTitle>
                        <UpfrontPaymentCost>
                          {getCentsToDollars(paymentAmount)}
                        </UpfrontPaymentCost>
                        <UpfrontPaymentMainText>
                          {`  split up monthly`}
                        </UpfrontPaymentMainText>
                      </UpfrontPaymentPlanTitle>
                      <BabyPeekIntroFAQAccordionWrapper>{`${
                        numberPayments || 6
                      } Payments`}</BabyPeekIntroFAQAccordionWrapper>
                    </UpfrontPaymentInfoBillContent>
                    <UpfrontPaymentPlanSteps steps={paymentPlan} />
                    <UpfrontPaymentSolidLine />
                    <UpfrontPaymentInfoBillContent
                      style={{ marginTop: '10px' }}
                    >
                      <UpfrontPaymentMainText>Total</UpfrontPaymentMainText>
                      <UpfrontPaymentMainText>
                        {getCentsToDollars(paymentAmount)}
                      </UpfrontPaymentMainText>
                    </UpfrontPaymentInfoBillContent>
                  </>
                )
              ) : (
                <>
                  {' '}
                  <UpfrontPaymentInfoRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Date of Service
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: finalReceipt?.date_of_service || '',
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Provider
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: finalReceipt?.provider_name || '',
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                  </UpfrontPaymentInfoRow>
                  <UpfrontPaymentInfoRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Amount Paid
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: `${getCentsToDollars(
                          finalReceipt?.amount_paid
                        )}`,
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Payment Date
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: finalReceipt?.payment_date || '',
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                  </UpfrontPaymentInfoRow>
                  <UpfrontPaymentInfoRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Payment Method
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: `${finalReceipt?.card_brand || ''} - ${
                          finalReceipt?.last_four_digits || ''
                        }`,
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                    <UpfrontPaymentFieldRow>
                      <UpfrontPaymentFieldRowTitle>
                        Transaction ID
                      </UpfrontPaymentFieldRowTitle>
                      {GetTypographyContent({
                        content: finalReceipt?.payment_intent_id || '',
                        size: 'medium',
                        textColor: 'black',
                      })}
                    </UpfrontPaymentFieldRow>
                  </UpfrontPaymentInfoRow>{' '}
                </>
              )}
            </>
          )}
          {modal && setModal && (
            <UpfrontPaymentButtonRow>
              <Button
                type="submit"
                label={showNotice ? 'I Understand' : 'Next'}
                size="medium"
                onClick={() => {
                  if (showNotice) {
                    setModal(false);
                    setActiveStep(StepperIndex.Main);
                  } else {
                    setShowNotice(true);
                  }
                }}
                backgroundColor="greenDark"
              />
            </UpfrontPaymentButtonRow>
          )}
        </UpfrontPaymentReceiptWrapper>
      </UpfrontPaymentDesktopContainer>
    </>
  );
};
