import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { CilantroTrait } from './Traits/Cilantro/CilantroTrait';
import { BabyPeekSingleTraitModalConfettiWrapper } from './BabyPeekTrait.styles';
import { BabyPeekButton } from './BabyPeekButton';
import { BabyPeekMobileButtonSticky } from './BabyPeekStripe.styles';
import { BABYPEEK_URL } from '../../utils/urlConstants';
import {
  GetBabyPeekSingleTrait,
  PostBabyPeekAction,
} from './Services/BabyPeekService';

import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { HairThicknessTrait } from './Traits/HairThickness/HairThicknessTrait';
import { getUserAuth } from '../../recoil/selectors';
import { CurlyHairTrait } from './Traits/CurlyHair/CurlyHairTrait';

interface FreeTraitInfo {
  traits: {
    aversion_to_cilantro_prob: number;
    hair_thickness_dosage: number;
    texture_prob: number;
  };
  baby_peek_pk: string;
}

export const BabyPeekSingleTrait: React.FC = (): React.ReactElement => {
  const { babypeekPK } = useParams<{
    babypeekPK: string;
    trait: 'cilantro' | 'thickness' | 'texture';
  }>();
  let { trait } = useParams<{
    babypeekPK: string;
    trait: 'cilantro' | 'thickness' | 'texture';
  }>();
  const { deviceType } = useDeviceHook();
  const history = useHistory();
  const [freeTraitInfo, setFreeTraiInfo] = useState<FreeTraitInfo>();

  const auth = useRecoilValue(getUserAuth);

  trait = trait.toLowerCase() as 'cilantro' | 'thickness' | 'texture';

  useEffect(() => {
    const sendAction = async () => {
      await PostBabyPeekAction(
        babypeekPK,
        'BabyPeekViewedFreeTrait',
        deviceType,
        undefined,
        undefined,
        { is_logged_in: auth ? 'true' : 'false' }
      );
    };
    sendAction();
  }, []);

  useEffect(() => {
    const getTraitInfo = async () => {
      const traitResponse = await GetBabyPeekSingleTrait(babypeekPK);
      const traitInfo = await traitResponse.json();
      setFreeTraiInfo(traitInfo);
    };
    getTraitInfo();
  }, []);

  const getTraitComponent = () => {
    if (trait === 'cilantro') {
      return (
        <CilantroTrait
          hideTraitMenu
          cilantroVal={freeTraitInfo?.traits.aversion_to_cilantro_prob}
        />
      );
    }
    if (trait === 'thickness') {
      return (
        <HairThicknessTrait
          freeTrait
          hideTraitMenu
          hairThickVal={freeTraitInfo?.traits.hair_thickness_dosage}
        />
      );
    }
    return (
      <CurlyHairTrait
        hideTraitMenu
        curlyHairVal={freeTraitInfo?.traits.texture_prob}
      />
    );
  };

  return (
    <>
      {' '}
      {trait ? (
        <BabyPeekSingleTraitModalConfettiWrapper deviceType={deviceType}>
          {getTraitComponent()}
          <BabyPeekMobileButtonSticky>
            <BabyPeekButton
              variant="filled"
              content={<>Unlock 11 More Traits</>}
              onClick={async () => {
                history.push(`${BABYPEEK_URL}/${babypeekPK}/SocialPromo`);
              }}
            />
          </BabyPeekMobileButtonSticky>
        </BabyPeekSingleTraitModalConfettiWrapper>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};
