import { AccordionProps } from '../../components/shared/Accordion/Accordions';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import AneuploidyVideoEmbed from '../../components/shared/Video/AneuploidyVideoEmbed';
import RhDVideoEmbed from '../../components/shared/Video/RhDVideoEmbed';
import { HelpFAQAnswerWrapper } from './Help.styles';

const LINE_BREAK = <br />;

export const aneuploidyHelpAccordions = (reqID: string): AccordionProps[] => [
  {
    title: 'What does UNITY Screen Aneuploidy NIPT test for?',
    content: (
      <HelpFAQAnswerWrapper>
        <div style={{ display: 'grid' }}>
          <AneuploidyVideoEmbed
            reqID={reqID}
            videoLocation="aneuploidy_learn_more"
          />
        </div>
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content:
            'Non-invasive prenatal testing (NIPT) through UNITY screens for specific chromosome changes called aneuploidy. The average person has 23 pairs of chromosomes, or 46 chromosomes total, with one of each chromosome inherited from each parent. A person with aneuploidy has an extra or missing copy of a chromosome, which may cause them to have health problems, physical birth defects, and/or developmental delays. The type and severity of symptoms vary depending on which chromosome is extra or missing. Anyone can have a baby born with a chromosome change. Most aneuploidy does not run in families.',
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content:
            'UNITY screens for the most common aneuploidies present at birth:',
          size: 'medium',
        })}
        <ul>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 21 (Down syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 18 (Edwards syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: 'Trisomy 13 (Patau syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: '*Monosomy X (Turner syndrome)',
              size: 'medium',
            })}
          </li>
          <li>
            {GetTypographyContent({
              content: '*Other sex chromosome aneuploidies (XXX/XXY/XYY)',
              size: 'medium',
            })}
          </li>
        </ul>
        {GetTypographyContent({
          content:
            '*Note, monosomy X and other sex chromosome aneuploidies are only screened for in singleton pregnancies.',
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'aneuploidyTestFor',
  },
  {
    title: 'What types of results can I get from UNITY Screen Aneuploidy NIPT?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `There are three types of results that you could get from UNITY Screen Aneuploidy NIPT: low risk, high risk, or no result.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `A low risk result means your baby has a very low chance of having the chromosome conditions screened.
           This significantly reduces but does not completely eliminate the chance of the baby having these conditions. 
           This is the most common result.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `A high risk result means that your baby has a significantly increased chance of having a specific 
          chromosome condition. The “Risk After NIPT” on your test report will indicate what the chance is for your 
          baby to be affected with the condition. Because the UNITY Screen™ is not diagnostic, diagnostic testing 
          (such as CVS or amniocentesis) is recommended following a high risk result to confirm if the baby does or 
          does not have the condition. No irreversible pregnancy decisions, such as pregnancy termination, should be 
          considered based on UNITY Screen™ results alone.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `“No Result” means we are unable to determine if the risk is high or low for your baby 
          to have the conditions screened. You can reference your test report to review the age-related 
          background risk for your baby having one of these conditions. There are various reasons a “no result” 
          can occur, such as if there is not enough DNA from the pregnancy in the blood sample provided or 
          if there is not clear information about the baby’s risk. We can usually accept a second blood sample 
          to perform repeat NIPT.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'aneuploidyTypeResults',
  },
  {
    title:
      'I don’t have any family history of Down syndrome or other chromosome conditions. Is this test still recommended?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Most chromosome conditions happen randomly (not passed down from either parent), 
          and the majority of the time there is no family history of the condition. Therefore, all pregnancies have a baseline 
          risk for having one of these conditions, even if there is no family history for you or your reproductive partner. 
          While the chance of having a baby with a chromosome condition increases with maternal age, individuals of 
          any age can have a baby with one of these conditions. Therefore, medical guidelines recommend all pregnant
           patients are given the option to screen for these conditions.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'downSyndromeHistory',
    padding: '0 0 20px 0',
  },
  {
    title: 'Am I eligible for UNITY Screen Aneuploidy NIPT?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `UNITY Screen Aneuploidy NIPT is available for any person at least 9 weeks pregnant with a singleton or twins.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `Note, individuals who became pregnant using an egg donor or gestational carrier are eligible for the 
          Aneuploidy Screen if they are pregnant with a singleton, but they are not eligible if pregnant with twins.
           Individuals pregnant with triplets or higher order multiples are not eligible for UNITY Screen Aneuploidy NIPT.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you are not eligible for UNITY Screen Aneuploidy NIPT, you can speak 
          with your doctor or midwife about other options for screening for chromosome conditions.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'aneuploidyEligibility',
  },
  {
    title:
      'How long do UNITY Screen Aneuploidy NIPT results take to come back?',
    content: `Most patients receive Aneuploidy Screen results within 10-14 days of our laboratory receiving the blood sample. Please contact your provider for the test results, as they will be provided a copy of the results as soon as they are ready.`,
    key: 'aneuploidyResultTime',
  },
  {
    title: 'How accurate is the fetal sex prediction?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `If ordered by your doctor's office, the UNITY Screen Aneuploidy NIPT results can include the predicted fetal sex with >99.9% accuracy for singleton pregnancies. 
          While these results are highly accurate, confirmation via ultrasound is recommended.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `For twin pregnancies, in most cases we can determine if there are two boys, two girls, or one of each.
           However, rarely we may instead report that there is at least one boy. This happens if we detect Y chromosome material 
           but do not have enough DNA to clearly determine if both twins are male or if there is one boy and one girl.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'fetalSexAccuracy',
  },
  {
    title: 'What do the Rh(D) results mean?',
    content: (
      <HelpFAQAnswerWrapper>
        <div style={{ display: 'grid' }}>
          <RhDVideoEmbed reqID={reqID} videoLocation="rhd_learn_more" />
        </div>
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `The Rh(D) screen is an optional add-on for UNITY Screen Aneuploidy NIPT. If ordered by your doctor's office, 
          Rh(D) NIPT assesses your baby's Rh(D) status. This test is indicated only for women who have Rh(D) 
          negative blood type (e.g., A-, B-, AB-, or O-).`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If your doctor has told you that your blood type is Rh(D) negative, the Rh(D) NIPT results can determine 
          if you need an injection of a specific medication (anti-D prophylaxis) during the pregnancy. 
          Typically, this medication is recommended if your baby is found to be Rh(D) positive. 
          Your doctor will communicate with you if the medication is necessary or not based on the UNITY Screen™ results.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If your doctor has told you that your blood type is Rh(D) positive (e.g., A+, B+, AB+, or O+), 
          the Rh(D) NIPT results do not impact the pregnancy, and anti-D prophylaxis is 
          not necessary, regardless of the fetal Rh(D) results.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you are unsure of your own blood type, please check with your doctor’s office for confirmation. 
          Note, the UNITY Screen™ does not determine the complete fetal blood type; it can only check for the Rh(D) status.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'rhdResults',
  },
];
