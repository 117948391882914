import { useEffect, useState } from 'react';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

const size = {
  mobile: 370,
  tablet: 600,
  desktop: 1200,
};

const getInitialDevice = (): DeviceType => {
  if (isBrowser) {
    return 'desktop';
  }
  if (isTablet) {
    return 'tablet';
  }
  if (isMobileOnly) {
    return 'mobile';
  }
  return 'desktop';
};

interface DeviceInfo {
  deviceType: DeviceType;
  physicalDevice: DeviceType; // no resizing taken into account
  isDesktop: boolean;
  isMobile: boolean;
  isTable: boolean;
}

export const useDeviceHook = (): DeviceInfo => {
  const [deviceType, setDeviceType] = useState<DeviceType>(getInitialDevice());
  const [physicalDevice] = useState<DeviceType>(getInitialDevice());

  const updateDevice = () => {
    if (
      window.innerWidth > size.desktop ||
      (window.innerWidth > size.desktop && isBrowser)
    ) {
      setDeviceType('desktop');
    } else if (isTablet || window.innerWidth > size.tablet) {
      setDeviceType('tablet');
    } else if (isMobileOnly || window.innerWidth > 0) {
      setDeviceType('mobile');
    }
  };

  useEffect(() => {
    updateDevice();
    window.addEventListener('resize', updateDevice);
    return () => {
      window.removeEventListener('resize', updateDevice);
    };
  }, []);

  return {
    deviceType,
    physicalDevice,
    isDesktop: deviceType === 'desktop',
    isMobile: deviceType === 'mobile',
    isTable: deviceType === 'tablet',
  };
};
