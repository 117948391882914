import * as React from 'react';
import { themePalette } from '../../theme';

export const LinkedIn = ({
  color = themePalette.neutral600,
  ...props
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1.71921C0 0.769972 0.789018 -3.68475e-07 1.76237 -3.68475e-07H22.0863C23.0596 -3.68475e-07 23.8487 0.769972 23.8487 1.71921V22.2808C23.8487 23.2303 23.0596 24 22.0863 24H1.76237C0.789018 24 0 23.2303 0 22.2808V1.71921V1.71921Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2295 20.0905V9.25314H3.62737V20.0905H7.2295V20.0905ZM5.42841 7.77349C6.68455 7.77349 7.46639 6.9413 7.46639 5.90131C7.44298 4.8379 6.68455 4.02881 5.45225 4.02881C4.22006 4.02881 3.41443 4.8379 3.41443 5.90131C3.41443 6.9413 4.1961 7.77349 5.40494 7.77349H5.42835H5.42841Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.22217 20.0905H12.8243V14.0385C12.8243 13.7146 12.8477 13.391 12.9428 13.1594C13.2032 12.5123 13.7959 11.8421 14.791 11.8421C16.0944 11.8421 16.6158 12.8359 16.6158 14.2928V20.0905H20.2176V13.8765C20.2176 10.5478 18.4405 8.99884 16.0706 8.99884C14.1274 8.99884 13.2743 10.085 12.8003 10.8248H12.8244V9.25314H9.22227C9.26954 10.27 9.22227 20.0905 9.22227 20.0905H9.22217Z"
      fill="white"
    />
  </svg>
);
