import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { Modal } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentCloseIcon,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentOptional,
  UpfrontPaymentStripeInput,
  UpfrontPaymentStripeInputError,
  UpfrontPaymentStripeInputRow,
} from './UpfrontPayment.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Button } from '../shared/Button/Button';
import { StyledButton } from '../shared/Button/Button.styles';
import {
  PostOA100PaymentSuccess,
  UpfrontPaymentType,
  getCentsToDollars,
} from './Services/UpfrontPaymentService';
import { BillingSupportNumber } from './types';
import { getUserAuth } from '../../recoil/selectors';
import { validateEmail } from '../../utils/hooks/helpers';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';

export const OA100PaymentStripe: React.FC<{
  stripeModalOpen: boolean;
  setStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  clientSecret: string;
  paymentAmount: number;
  useNewCard: boolean;
}> = ({
  stripeModalOpen,
  setStripeModalOpen,
  clientSecret,
  paymentAmount,
  setPaymentSubmitted,
  setUpfrontBill,
  useNewCard,
}) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);
  const { billingPK } = useParams<{ billingPK: string }>();

  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [stripeReady, setStripeReady] = useState(false);

  useEffect(() => {
    if (!clientSecret) {
      setErrorMessage(
        `There is an error - please contact us at ${BillingSupportNumber} for more details.`
      );
    }
  }, [clientSecret]);

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    const supportMessage = 'Please contact support at +1(650) 460-2551';

    if (!stripe || !elements || !clientSecret) {
      setErrorMessage(`System is not ready to take payment. ${supportMessage}`);
      return;
    }

    if (loading) {
      setErrorMessage('System is loading. Please wait.');
      return;
    }

    setLoading(true);

    const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);
    // Check that the payment amount from the API matches stripe
    if (paymentIntent.paymentIntent?.amount !== paymentAmount) {
      setErrorMessage(
        `There was an error validating payment - ${supportMessage}`
      );
      setLoading(false);
      return;
    }

    const elementsResult = await elements.submit();
    if (elementsResult.error) {
      setErrorMessage(`${elementsResult.error.message} ${supportMessage}`);
      setLoading(false);
      return;
    }

    setErrorMessage('');

    if (clientSecret) {
      const paymentMethodStripeResponse = await stripe.createPaymentMethod({
        elements,
        params: {
          metadata: {
            bill_pk: billingPK,
          },
        },
      });

      const oneTimePaymentStripeResponse = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethodStripeResponse.paymentMethod?.id || '',
          setup_future_usage: 'off_session',
        }
      );

      if (oneTimePaymentStripeResponse.error) {
        setErrorMessage(oneTimePaymentStripeResponse.error.message);
      } else if (
        oneTimePaymentStripeResponse.paymentIntent.status === 'succeeded'
      ) {
        const oneTimePaymentSyncResponse = await PostOA100PaymentSuccess(
          billingPK,
          clientSecret,
          auth ? auth.email : email,
          paymentMethodStripeResponse.paymentMethod?.id
        );
        if (oneTimePaymentSyncResponse.ok) {
          const oneTimePaymentSyncJson =
            await oneTimePaymentSyncResponse.json();
          setUpfrontBill(oneTimePaymentSyncJson);
          setPaymentSubmitted(true);
          setStripeModalOpen(false);
          setLoading(false);
        } else {
          setErrorMessage(
            `We're sorry, there was an error processing payment. ${supportMessage}`
          );
        }
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      open={stripeModalOpen}
      onClose={() => setStripeModalOpen(false)}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        pointerEvents: loading ? 'none' : undefined,
      }}
    >
      <UpfrontPaymentDesktopContainer deviceType={deviceType}>
        {errorMessage && (
          <ErrorPopup
            message={errorMessage}
            hideMessage={() => setErrorMessage('')}
          />
        )}
        {!useNewCard && <SpinnerLoading />}
        <form
          style={{
            display: useNewCard ? 'grid' : 'none',
          }}
          onSubmit={handleSubmit}
        >
          <UpfrontPaymentCloseIcon>
            <CloseIcon onClick={() => setStripeModalOpen(false)} />
          </UpfrontPaymentCloseIcon>
          {!auth && (
            <UpfrontPaymentStripeInputRow>
              <div>
                {' '}
                Email{' '}
                <UpfrontPaymentOptional>
                  {` ${' (optional)'}`}{' '}
                </UpfrontPaymentOptional>
              </div>
              <UpfrontPaymentStripeInput
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {email && !validateEmail(email) && (
                <UpfrontPaymentStripeInputError>
                  {' '}
                  Please enter a valid email
                </UpfrontPaymentStripeInputError>
              )}
            </UpfrontPaymentStripeInputRow>
          )}
          {!stripeReady && <SpinnerLoading />}
          <PaymentElement
            options={{
              paymentMethodOrder: ['card'],
              layout: {
                type: 'tabs',
              },
            }}
            onReady={() => setStripeReady(true)}
          />
          {loading && useNewCard ? (
            <SpinnerLoading />
          ) : (
            <>
              {' '}
              <UpfrontPaymentButtonRow>
                <StyledButton
                  type="submit"
                  size="medium"
                  $backgroundColor="greenDark"
                  disabled={
                    !stripe ||
                    !clientSecret ||
                    Boolean(email && !validateEmail(email))
                  }
                >
                  {`Pay ${getCentsToDollars(paymentAmount)}`}
                </StyledButton>
              </UpfrontPaymentButtonRow>
              <UpfrontPaymentButtonRow bottomRow tertiary>
                <Button
                  label="Cancel"
                  size="medium"
                  backgroundColor="greyMedium"
                  onClick={() => {
                    setErrorMessage('');
                    setStripeModalOpen(false);
                  }}
                  reverse
                />
              </UpfrontPaymentButtonRow>
            </>
          )}
        </form>
      </UpfrontPaymentDesktopContainer>
    </Modal>
  );
};
