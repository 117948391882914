import * as React from 'react';

export const TraitNavigationRight = (): React.ReactElement => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#E2E8F0" />
    <path
      d="M28.6875 23.3125C29.0938 23.6875 29.0938 24.3438 28.6875 24.7188L22.6875 30.7188C22.3125 31.125 21.6562 31.125 21.2812 30.7188C20.875 30.3438 20.875 29.6875 21.2812 29.3125L26.5625 24L21.2812 18.7188C20.875 18.3438 20.875 17.6875 21.2812 17.3125C21.6562 16.9062 22.3125 16.9062 22.6875 17.3125L28.6875 23.3125Z"
      fill="#171923"
    />
  </svg>
);
