import { atom } from 'recoil';
// import { defaultData, defaultProfileData } from './defaultData';
import {
  BabyPeekReveal,
  UserAuthClientResponse,
  UserProfileAuthClientResponse,
} from './types';

const getDefaultData = () => {
  if (import.meta.env.VITE_ENV === 'local') {
    //  return defaultData; // can be set to defaultData if testing of dummy data is needed
  }
  return undefined;
};

const getDefaultProfileData = () => {
  if (import.meta.env.VITE_ENV === 'local') {
    // return defaultProfileData; // can be set to defaultData if testing of dummy data is needed
  }
  return undefined;
};

export const patientState = atom<UserAuthClientResponse | undefined>({
  key: 'detailedResults',
  default: getDefaultData(),
});

export const patientProfileState = atom<
  UserProfileAuthClientResponse | undefined
>({
  key: 'detailedProfileResults',
  default: getDefaultProfileData(),
});

export const resetPwEmail = atom<string | undefined>({
  key: 'resetPwEmail',
  default: undefined,
});

export const babyPeekStatus = atom<BabyPeekReveal>({
  key: 'babyPeekPending',
  default: 'hidden',
});

export const showABNForm = atom<{ loaded: boolean; show: boolean }>({
  key: 'showABNForm',
  default: { loaded: false, show: false },
});
