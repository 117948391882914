import styled from 'styled-components/macro';
import { Box, FormControl, FormLabel } from '@mui/material';
import { DefaultTheme } from 'styled-components';
import { DeviceType } from '../../utils/hooks/useDeviceHook';
import { TextField } from '../shared/TextField/TextField';
import { StyledButton } from '../shared/Button/Button.styles';

const getDeviceWidth = (deviceType: DeviceType): string => {
  if (deviceType === 'mobile') return 'calc(100% - 25px);';
  if (deviceType === 'tablet') return '600px';
  return '896px';
};

export const ProfileInsuranceModalWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => getDeviceWidth(deviceType)};
  margin: ${({ deviceType }) =>
    deviceType === 'desktop' ? undefined : '10px 10px'};
  height: 82%;
  justify-content: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

  .profile-modal-title {
    margin-top: 40px;
    padding-left: ${({ deviceType }) =>
      deviceType === 'desktop' ? undefined : '10px'};
  }

  .profile-insurance-title {
    margin-bottom: 20px;
  }

  #date-time-edit {
    justify-self: flex-start;
  }
`;

export const ProfileAlabamaModalWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  height: ${({ deviceType }) => (deviceType === 'desktop' ? 'auto' : '80%')};
  overflow: ${({ deviceType }) =>
    deviceType === 'desktop' ? 'hidden' : 'scroll'};
  ${ProfileInsuranceModalWrapper} {
    height: auto;
  }
`;

const getWarningHeight = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '96pxpx';
  }
  if (deviceType === 'tablet') {
    return '110px';
  }
  return '200px';
};

export const ProfileModalWarningWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 16px;
  width: 95%;
  height: ${({ deviceType }) => getWarningHeight(deviceType)};
  justify-self: center;
  margin-top: 30px;
  margin-left: 24px;
  color: ${({ theme }) => theme.redDark};
  background: ${({ theme }) => theme.neutral100};
  border-radius: 24px;
`;

export const ProfileModalWarningText = styled.div`
  font-size: 18px;
`;

export const ProfileModalTitleWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  grid-gap: 8px;
  padding: ${({ deviceType }) =>
    deviceType === 'desktop' ? '12px 20px' : '12px 25px'};
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: -5px;

  svg {
    cursor: pointer;
  }
`;

export const ProfileInsuranceModalTitle = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.neutral50};
  width: 100%;
  color: ${({ theme }) => theme.greyDark};
  border: 1px solid ${({ theme }) => theme.neutral100};
  box-sizing: border-box;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1.2px;
  border-radius: 8px;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
  svg {
    margin-top: -4px;
    margin-left: 5px;
  }
`;
export const ProfileInsuranceRequiredMessage = styled.div`
  background: ${({ theme }) => theme.redPastel};
  border-left: 3px solid ${({ theme }) => theme.redDark};
  padding: 12px 20px;
  border-radius: 6px;
  width: 90%;
  justify-self: center;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  margin-bottom: 30px;
  margin-top: 20px;

  svg {
    margin-right: 10px;
  }
`;

export const ProfileAlabamaModalInfoWrapper = styled.div`
  padding: 24px 24px;
`;

export const ProfileAlabamaModalInfo = styled.div`
  display: grid;
`;

export const ProfileAlabamaModalInfoDescription = styled.div`
  font-size: 16px;
  display: flex;

  span {
    font-weight: 700;
  }
`;

export const ProfileAlabamaModalInfoText = styled.div`
  font-size: 16px;
  margin-bottom: 10px;

  span {
    font-weight: 700;
  }
`;

export const ProfileAlabamaModalInfoTitle = styled.div`
  font-size: 18px;
  font-weight: 800;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ProfileInsuranceModalButtons = styled.div<{
  deviceType: DeviceType;
}>`
  display: ${({ deviceType }) =>
    deviceType === 'desktop' ? 'flex' : 'grid'} !important;
  grid-template-columns: ${({ deviceType }) =>
    deviceType === 'desktop' ? undefined : '1fr 1fr'} !important;
  grid-gap: 20px;
  padding: 12px 20px;
  justify-content: space-between;
  width: 100%;

  ${StyledButton} {
    height: 48px;
  }
`;

export const StyledFormLabel = styled(FormLabel)<{ isError?: boolean }>`
  color: ${({ theme, isError }) =>
    isError ? theme.redDark : theme.neutral700} !important;
  font-size: 18px;
  font-family: bold !important;
  text-transform: uppercase;
  margin: 24px 0 16px 0;
`;

export const ProfileInsuranceTextFieldLabel = styled.div`
  svg {
    width: 20px;
    margin-bottom: -4px;
  }
`;

export const PolicyOwnerRelationshipText = styled.div`
  p {
    font-family: light !important;
    font-size: 16px !important;
  }
`;

const getDateColor = (
  theme: DefaultTheme,
  error?: string,
  noDateValue?: boolean
) => {
  if (error) return theme.redDark;
  if (noDateValue) return theme.neutral500;
  return theme.black;
};

export const ProfileInsuranceTextField = styled(TextField)<{
  $noDateValue?: boolean;
  $showHelpertext?: boolean;
}>`
  color: ${({ theme }) => theme.greyLight};
  width: 100%;
  font-family: light;

  .dateclass::-webkit-datetime-edit {
    color: ${({ theme, error, $noDateValue }) =>
      getDateColor(theme, error, $noDateValue)};
  }

  .dateclass {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem 0px;
    position: relative;
    width: 100%;
  }

  input::-webkit-date-and-time-value {
    margin-top: 0px;
    text-align: left;
    width: 100%;
  }

  .MuiFormHelperText-root {
    display: ${({ $showHelpertext }) => ($showHelpertext ? undefined : 'none')};
    color: ${({ theme }) => theme.blueDark} !important;
  }

  .MuiFormHelperText-root.Mui-error {
    display: ${({ $showHelpertext }) => ($showHelpertext ? undefined : 'none')};
    color: ${({ $showHelpertext, theme }) =>
      $showHelpertext ? theme.redDark : undefined} !important;
  }
`;

export const RadioFormControl = styled(FormControl)<{ $hideBorder?: boolean }>`
  && {
    .MuiFormLabel-root {
      color: ${({ theme, error }) =>
        error ? theme.redDark : theme.blueDark} !important;
      font-family: light !important;
      line-height: inherit !important;
      font-size: 12px !important;
    }
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
    .Mui-checked {
      color: ${({ theme }) => theme.redMedium} !important;
    }
    .MuiTypography-root {
      margin-left: -2px;
    }
    width: 100%;
    border-bottom: ${({ theme, $hideBorder }) =>
      !$hideBorder && `1px solid ${theme.neutral400}`};
  }

  .MuiRadio-root {
    color: ${({ theme, error }) =>
      error ? theme.redDark : undefined} !important;
  }
  .Mui-error {
    color: ${({ theme, error }) =>
      error ? theme.redDark : undefined} !important;
  }

  ${ProfileInsuranceTextFieldLabel} {
    svg {
      width: 15px;
    }
  }
  margin-top: 5px;
`;

export const BillingRadioControlDropdown = styled(RadioFormControl)`
  && {
    .MuiFormLabel-root {
      font-size: 16px !important;
    }

    .Mui-focused {
      color: ${({ theme }) => theme.black};
    }

    min-width: 120px;
    color: ${({ theme }) => theme.black};
    font-family: light;

    .MuiInput-input {
      font-family: light;
      font-size: 1rem;
    }

    .MuiAutocomplete-input {
      color: ${({ theme }) => theme.black} !important;
      font-family: light;
      font-size: 1rem;
    }

    .MuiInputLabel-root {
      color: ${({ theme }) => theme.blueDark};
      font-family: light;
      line-height: inherit;
      font-size: 20px !important;
    }

    .MuiInput-root:after {
      border-bottom: ${({ theme }) => `1px solid ${theme.greyLight}`};
    }

    .Mui-error.MuiInputLabel-root {
      color: ${({ theme }) => theme.redDark} !important;
    }
    .Mui-error.MuiInputLabel-asterisk {
      color: ${({ theme }) => theme.redDark} !important;
    }
    .Mui-error {
      .MuiInput-input {
        color: ${({ theme }) => theme.redDark} !important;
      }
      :after {
        border-bottom: 1px solid ${({ theme }) => theme.redDark} !important;
      }
    }
  }
  padding: 0px 25px 40px 25px !important;
  margin-top: -15px !important;
`;

const BoxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  flex-wrap: wrap;
`;

export const PatientBillingBox = styled(BoxWrapper)`
  margin-top: 10px;
  ${RadioFormControl} {
    border-bottom: none;
  }
`;

export const PatientBillingDateWrapper = styled.div`
  .MuiTextField-root {
    padding: 6px 0px !important;
  }
`;

export const ProfileInsuranceInsurancePadding = styled.div<{ $show: boolean }>`
  padding-left: 30px;
  padding-right: 5px;
  padding-bottom: 15px;
  margin-top: 10px;
  display: ${({ $show }) => ($show ? undefined : 'none')};

  ${'.MuiFormLabel-root'} {
    font-size: 16px !important;
  }
`;
