import React, { useState } from 'react';
import { Warning } from '../../assets/iconComponents/Warning';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { StyledButton } from '../shared/Button/Button.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  DetailedResultsFetalSexModalWrapper,
  ActionAndCancelButtonPair,
} from './DetailedResults.styles';

export const DetailedResultsFetalSexModal: React.FC<{
  showModal: (show: '' | 'reveal' | 'report') => void;
  showFetalSexModal: '' | 'reveal' | 'report';
  downloadReport: () => Promise<string | undefined>;
  showFetalSexAction: () => void;
  setShowFetalSex?: (show: boolean) => void;
}> = ({
  showModal,
  setShowFetalSex,
  showFetalSexModal,
  downloadReport,
  showFetalSexAction,
}) => {
  const { deviceType } = useDeviceHook();
  const [downloading, setDownloading] = useState(false);
  const continueFunction = async () => {
    if (showFetalSexModal === 'reveal' && setShowFetalSex) {
      setShowFetalSex(true);
      showFetalSexAction();
      showModal('');
    } else if (!downloading) {
      setDownloading(true);
      const tempAnchor = document.createElement('a');
      tempAnchor.download = 'file.pdf';
      const downloadUrl = downloadReport && (await downloadReport());
      if (downloadUrl) {
        tempAnchor.href = downloadUrl;
        tempAnchor.click();
      }
      tempAnchor.remove();
      setDownloading(false);
    }
  };

  const getModalText = () => {
    if (showFetalSexModal === 'report') {
      return 'The following report lists the baby’s sex. Do you wish to continue?';
    }
    return 'This will reveal the baby’s sex. Do you wish to continue?';
  };

  return (
    <DetailedResultsFetalSexModalWrapper deviceType={deviceType}>
      <div>
        <Warning />
      </div>
      <div data-testid="reveal-sex-modal-heading">
        {GetTypographyContent({
          content:
            showFetalSexModal === 'report'
              ? 'Download Report'
              : 'Reveal Fetal Sex?',
          size: 'h3',
          textColor: 'redDark',
          isBold: true,
        })}
      </div>
      <div>
        {GetTypographyContent({
          content: getModalText(),
          size: 'large',
          textColor: 'greyMedium',
        })}
      </div>
      <>
        <ActionAndCancelButtonPair>
          <StyledButton
            data-testid="reveal-sex-continue-button"
            size="small"
            $backgroundColor="redMedium"
            $textColor="white"
            variant="outlined"
            onClick={() => continueFunction()}
          >
            CONTINUE
          </StyledButton>
          <StyledButton
            data-testid="reveal-sex-cancel-button"
            size="small"
            $backgroundColor="white"
            $textColor="redMedium"
            variant="outlined"
            className="cancel-action"
            onClick={() => showModal('')}
          >
            CANCEL
          </StyledButton>
        </ActionAndCancelButtonPair>
      </>
    </DetailedResultsFetalSexModalWrapper>
  );
};
