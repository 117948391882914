import { UserProfileAuthClientResponse } from '../../recoil/types';
import { FileUploadInfo } from './ProfileInsuranceFileUploader';

export const getRelationshipToInsured = (
  self?: boolean,
  dependent?: boolean,
  other?: boolean
): 'Self' | 'Dependent' | 'Other' => {
  if (self) {
    return 'Self';
  }
  if (dependent) {
    return 'Dependent';
  }
  if (other) {
    return 'Other';
  }
  return 'Self';
};

export const getInsuredParameter = (
  insured?: 'Self' | 'Dependent' | 'Other'
):
  | 'relationshipToPolicyOwnerSelf'
  | 'relationshipToPolicyOwnerDependent'
  | 'relationshipToPolicyOwnerOther'
  | '' => {
  if (insured === 'Self') {
    return 'relationshipToPolicyOwnerSelf';
  }
  if (insured === 'Dependent') {
    return 'relationshipToPolicyOwnerDependent';
  }
  if (insured === 'Other') {
    return 'relationshipToPolicyOwnerOther';
  }
  return '';
};

export interface PatientInsuranceInfo {
  billToInsuranceType?: 'Entry' | 'Upload';
  insuranceCompanyName: string;
  groupId: string;
  memberId: string;
  policyOwnerName: string;
  policyOwnerDob: string;
  relationshipToPolicyOwnerSelf: boolean;
  relationshipToPolicyOwnerDependent: boolean;
  relationshipToPolicyOwnerOther: boolean;
  insuranceFileUrls: FileUploadInfo;
  checkError: boolean;
}

export interface FullAddress {
  fullAddress: string;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  aptUnit: string;
}

export const insuranceAddressUploadValidationCheck = (
  missingInsurance: boolean,
  missingAddress: boolean,
  patientInsuranceInfo: PatientInsuranceInfo,
  addressInfo: FullAddress
): boolean => {
  if (missingInsurance) {
    if (patientInsuranceInfo.billToInsuranceType === 'Entry') {
      return (
        !patientInsuranceInfo.insuranceCompanyName ||
        !patientInsuranceInfo.groupId ||
        !patientInsuranceInfo.memberId ||
        !patientInsuranceInfo.policyOwnerName ||
        !patientInsuranceInfo.policyOwnerDob ||
        (!patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
          !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
          !patientInsuranceInfo.relationshipToPolicyOwnerOther)
      );
    }
    if (patientInsuranceInfo.billToInsuranceType === 'Upload') {
      return (
        !Object.keys(patientInsuranceInfo.insuranceFileUrls).filter(
          (file) =>
            patientInsuranceInfo.insuranceFileUrls[file].side === 'front'
        ).length ||
        !Object.keys(patientInsuranceInfo.insuranceFileUrls).filter(
          (file) => patientInsuranceInfo.insuranceFileUrls[file].side === 'back'
        ).length ||
        (!patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
          !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
          !patientInsuranceInfo.relationshipToPolicyOwnerOther)
      );
    }
  }
  if (missingAddress) {
    return (
      !addressInfo.city ||
      !addressInfo.state ||
      !addressInfo.streetAddress ||
      !addressInfo.zipCode
    );
  }
  return false;
};

export const statesArray = [
  'AK - Alaska',
  'AL - Alabama',
  'AR - Arkansas',
  'AS - American Samoa',
  'AZ - Arizona',
  'CA - California',
  'CO - Colorado',
  'CT - Connecticut',
  'DC - District of Columbia',
  'DE - Delaware',
  'FL - Florida',
  'GA - Georgia',
  'GU - Guam',
  'HI - Hawaii',
  'IA - Iowa',
  'ID - Idaho',
  'IL - Illinois',
  'IN - Indiana',
  'KS - Kansas',
  'KY - Kentucky',
  'LA - Louisiana',
  'MA - Massachusetts',
  'MD - Maryland',
  'ME - Maine',
  'MI - Michigan',
  'MN - Minnesota',
  'MO - Missouri',
  'MS - Mississippi',
  'MT - Montana',
  'NC - North Carolina',
  'ND - North Dakota',
  'NE - Nebraska',
  'NH - New Hampshire',
  'NJ - New Jersey',
  'NM - New Mexico',
  'NV - Nevada',
  'NY - New York',
  'OH - Ohio',
  'OK - Oklahoma',
  'OR - Oregon',
  'PA - Pennsylvania',
  'PR - Puerto Rico',
  'RI - Rhode Island',
  'SC - South Carolina',
  'SD - South Dakota',
  'TN - Tennessee',
  'TX - Texas',
  'UT - Utah',
  'VA - Virginia',
  'VI - Virgin Islands',
  'VT - Vermont',
  'WA - Washington',
  'WI - Wisconsin',
  'WV - West Virginia',
  'WY - Wyoming',
];

export const getMissingProfileInfoMessage = (
  missingInsurance: boolean,
  missingAddress: boolean,
  missingPreBilling: boolean
): string => {
  if (missingPreBilling) {
    return 'Please upload a copy of your insurance card to avoid billing delays.';
  }
  if (missingAddress && missingInsurance) {
    return 'To prevent unexpected billing and delays in processing your claim, please provide your insurance information and address.';
  }
  if (missingAddress && !missingInsurance) {
    return 'To prevent unexpected billing and delays in processing your claim, please provide your address.';
  }
  if (!missingAddress && missingInsurance) {
    return 'To prevent unexpected billing and delays in processing your claim, please provide your insurance information.';
  }
  return 'To prevent unexpected billing and delays in processing your claim, please provide your information.';
};

export const isAddressOrInsuranceRequired = (
  patientInfo?: UserProfileAuthClientResponse
): boolean =>
  patientInfo?.phi.address_status === 'Required' ||
  patientInfo?.phi.ins_status === 'Required' ||
  patientInfo?.phi.prebilling_status === 'Required';
