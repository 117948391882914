import React from 'react';
import { useRecoilValue } from 'recoil';
import { getSpecific22q } from '../../recoil/selectors';
import { Get22qInfo } from '../shared/Typography/types';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { TestResultsIcon } from '../TestResults/TestResults.styles';
import {
  DetailedResultRhDIconRow,
  DetailedResultsRhDWrapper,
} from './DetailedResults.styles';

export const DetailedResults22Q: React.FC<{ requisitionID: string }> = ({
  requisitionID,
}) => {
  const del22Qstatus = useRecoilValue(getSpecific22q(requisitionID));
  const del22QInfo = Get22qInfo(del22Qstatus);

  return (
    <>
      {del22QInfo && (
        <DetailedResultsRhDWrapper>
          <div>
            {GetTypographyContent({
              content: '22q11.2',
              size: 'xlarge',
              textColor: 'greyMedium',
              isBold: true,
            })}
          </div>

          <DetailedResultRhDIconRow>
            <TestResultsIcon>{del22QInfo.icon}</TestResultsIcon>
            {GetTypographyContent({
              content: del22QInfo.display,
              size: 'h2',
              textColor: 'greyMedium',
            })}
          </DetailedResultRhDIconRow>
        </DetailedResultsRhDWrapper>
      )}
    </>
  );
};
