import React from 'react';
import { useRecoilValue } from 'recoil';
import { getSpecificRhD } from '../../recoil/selectors';
import { HELP_PAGE_URL } from '../../utils/urlConstants';
import { GetRhDInfo } from '../shared/Typography/types';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { TestResultsIcon } from '../TestResults/TestResults.styles';
import { TestResultsLearnMore } from '../TestResults/TestResultsLearnMore';
import {
  DetailedResultRhDIconRow,
  DetailedResultsRhDLearnMoreWrapper,
  DetailedResultsRhDWrapper,
} from './DetailedResults.styles';

export const DetailedResultsRhD: React.FC<{ requisitionID: string }> = ({
  requisitionID,
}) => {
  const rhDResults = useRecoilValue(getSpecificRhD(requisitionID));
  const rhDInfo = rhDResults && GetRhDInfo(rhDResults);
  return (
    <>
      {rhDInfo && (
        <DetailedResultsRhDWrapper>
          <div>
            {GetTypographyContent({
              content: 'RhD/Fetal Antigen',
              size: 'xlarge',
              textColor: 'greyMedium',
              isBold: true,
            })}
          </div>

          <DetailedResultRhDIconRow>
            <TestResultsIcon>{rhDInfo.icon}</TestResultsIcon>
            {GetTypographyContent({
              content: rhDInfo.display,
              size: 'h2',
              textColor: 'greyMedium',
            })}
          </DetailedResultRhDIconRow>
          <DetailedResultsRhDLearnMoreWrapper>
            {TestResultsLearnMore(
              'Learn More',
              `${HELP_PAGE_URL}#rhdResults-help`,
              '10px 20px 10px 25px'
            )}
          </DetailedResultsRhDLearnMoreWrapper>
        </DetailedResultsRhDWrapper>
      )}
    </>
  );
};
