import styled from 'styled-components/macro';

export const BabyPeekOptInWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 24px 24px;
  position: fixed;
  bottom: 0;
  justify-self: center;
  border: 1.024px solid var(--neutral-neutral-2, #f2f2f2);
  background: var(--neutral-neutral-1, #fff);
  box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.08);
  justify-content: center;
  width: 100%;
  z-index: 9999;

  button {
    border: transparent;
  }

  svg {
    height: 40px;
    margin-left: -20px;
  }

  img {
    height: 40px;
  }
`;

export const OptInTextBold = styled.p`
  color: var(--Grey-900, #171923);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  margin: 0;
`;

export const OptInTextRegular = styled.p`
  color: var(--Grey-600, #4a5568);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;

export const DismissText = styled.p`
  color: var(--Primary-Blue, #2b3ff6);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
`;

export const OptInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 40px;
  background: var(--Primary-Blue, #2b3ff6);
  color: white;
  border-radius: 30px;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  line-height: 100%;
  font-weight: 600;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
`;

export const OptInChoiceWrapper = styled.div`
  display: grid;
  grid-template-columns: 33% 67%;
  justify-content: center;
  align-items: center;
  justify-self: center;
  justify-content: center;
  width: 100%;
`;
