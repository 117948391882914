import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getUserAuth } from '../../../recoil/selectors';
import { LOGIN_PAGE_URL, REGISTER_URL } from '../../../utils/urlConstants';
import { LowerCasePageIndex, Trait } from './BabyPeekService';

export const useBabyPeekLoginScreen = (
  screen:
    | 'Intro'
    | 'Advocacy'
    | 'Summary'
    | 'Trait'
    | 'Checkout'
    | 'SampleReport'
    | Trait
    | LowerCasePageIndex
    | undefined,
  signup?: string
): void => {
  const auth = useRecoilValue(getUserAuth);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    sessionStorage.setItem(
      'current-pathname',
      `${pathname}${window.location.search}`
    );
    if (screen === 'SampleReport') {
      return;
    }
    if (!auth && screen === 'Checkout' && signup) {
      history.push(`${REGISTER_URL}/${signup}`);
    } else if (
      !auth &&
      screen &&
      screen !== 'intro' &&
      screen !== 'samplereport' &&
      screen !== 'checkout' &&
      screen !== 'socialpromo'
    ) {
      history.push(LOGIN_PAGE_URL);
    }
  }, [auth, pathname, screen]);
};
