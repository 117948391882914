import * as React from 'react';

export const TestTube = (): React.ReactElement => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_43_662)">
      <path
        opacity="0.24"
        d="M20.0019 40C23.9942 40.0297 27.9056 38.8819 31.2397 36.7019C34.5737 34.5221 37.1803 31.4084 38.7287 27.7561C40.2772 24.1037 40.6977 20.0771 39.937 16.1872C39.1762 12.2972 37.2684 8.71923 34.4556 5.90701C31.6429 3.09481 28.0519 1.17516 24.1382 0.391589C20.2245 -0.391984 16.1646 -0.00417304 12.4733 1.50583C8.7821 3.01583 5.62589 5.57996 3.40515 8.87294C1.18442 12.1659 -0.00079154 16.0394 -0.000115762 20.002C-0.0191422 25.2863 2.0774 30.3617 5.82839 34.1119C9.57939 37.8622 14.6777 39.9802 20.0019 40Z"
        fill="#F6FBFC"
      />
      <path
        d="M18.7499 19.2857C18.7499 17.6091 17.3908 16.2499 15.7142 16.2499C14.0376 16.2499 12.6785 17.6091 12.6785 19.2857V27.1428C12.6785 28.8194 14.0376 30.1785 15.7142 30.1785C17.3908 30.1785 18.7499 28.8194 18.7499 27.1428V19.2857Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M15.7142 15.1786C17.1935 15.1786 18.3928 13.9793 18.3928 12.5C18.3928 11.0207 17.1935 9.82144 15.7142 9.82144C14.2349 9.82144 13.0356 11.0207 13.0356 12.5C13.0356 13.9793 14.2349 15.1786 15.7142 15.1786Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M12.8571 11.4286C12.5713 12.1429 12.9761 13.3929 13.2142 13.9286L16.7856 9.82143C16.6071 9.76193 16.1071 9.60714 15.5356 9.46429C14.8213 9.28572 13.2142 10.5357 12.8571 11.4286Z"
        fill="white"
      />
      <path
        d="M23.5714 9.28571C23.5714 9.28571 23.5714 17.5 23.75 18.5714C23.9286 19.6428 24.3571 20 25.3571 20C26.3571 20 26.7262 19.0476 26.7857 18.5714C26.8452 18.0952 26.7857 9.28571 26.7857 9.28571H22.5H27.8571"
        stroke="#F27E7C"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_43_662">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
