import { FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Button } from '../shared/Button/Button';
import {
  ProfileInsuranceModalWrapper,
  ProfileInsuranceModalTitle,
  ProfileInsuranceModalButtons,
  ProfileModalTitleWrapper,
  ProfileAlabamaModalInfo,
  ProfileAlabamaModalInfoText,
  ProfileAlabamaModalInfoTitle,
  ProfileAlabamaModalInfoDescription,
  ProfileAlabamaModalInfoWrapper,
  ProfileAlabamaModalWrapper,
} from './ProfileInsuranceModal.styles';
import { patientProfileState } from '../../recoil/atoms';
import { RequisitionModelSerializer } from '../../recoil/types';
import { getUserAuth } from '../../recoil/selectors';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { SubmitABNInfo } from './Services/UpdateProfileInfoService';

export const ProfileAlabamaWarning: React.FC<{
  alabamaModalOpen: boolean | undefined;
  setAlabamaModalOpen: (show: boolean) => void;
  requisition: RequisitionModelSerializer;
}> = ({ alabamaModalOpen, setAlabamaModalOpen, requisition }) => {
  const { deviceType } = useDeviceHook();
  const [agreeOrCancel, setAgreeOrCancel] = useState('agree');
  const auth = useRecoilValue(getUserAuth);
  const [patientInfo] = useRecoilState(patientProfileState);

  const [loading, setLoading] = useState(false);

  const submitABNForm = async () => {
    setLoading(true);
    await SubmitABNInfo(
      agreeOrCancel === 'agree',
      requisition.req_identifier,
      auth?.access_token
    );
    setAlabamaModalOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      open={alabamaModalOpen ?? false}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setAlabamaModalOpen(false);
        }
      }}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <ProfileAlabamaModalWrapper deviceType={deviceType}>
        <ProfileInsuranceModalWrapper
          deviceType={deviceType}
          style={{ overflow: 'auto' }}
        >
          <ProfileInsuranceModalTitle deviceType={deviceType}>
            <ProfileModalTitleWrapper deviceType={deviceType}>
              <div> Alabama Medicaid Disclaimer </div>
            </ProfileModalTitleWrapper>
          </ProfileInsuranceModalTitle>
          <ProfileAlabamaModalInfoWrapper>
            <ProfileAlabamaModalInfo>
              <ProfileAlabamaModalInfoText>
                Patient Name:{' '}
                <span>
                  {' '}
                  {`${patientInfo?.phi.first_name} ${patientInfo?.phi.last_name}`}
                </span>
              </ProfileAlabamaModalInfoText>
              <ProfileAlabamaModalInfoText>
                Patient Date of Birth: <span>{`${patientInfo?.phi.dob}`}</span>
              </ProfileAlabamaModalInfoText>
              <ProfileAlabamaModalInfoText>
                Name of Healthcare Prodvider:{' '}
                <span>{requisition.physician_name}</span>
              </ProfileAlabamaModalInfoText>
            </ProfileAlabamaModalInfo>
            <ProfileAlabamaModalInfoTitle>
              Advanced Beneficiary Notice of Non-Payment
            </ProfileAlabamaModalInfoTitle>
            <ProfileAlabamaModalInfoText>
              The services listed below have been ordered for me by my
              healthcare provider (listed above) because my healthcare provider
              believes that the services benefit my care. Medicaid may not cover
              these services. The cost for these services is <span>$349</span>{' '}
              for Medicaid patients (if either or both tests are ordered). I
              understand that I am responsible for paying for these services
              ordered by my healthcare provider.{' '}
            </ProfileAlabamaModalInfoText>
            <ProfileAlabamaModalInfoText>
              Services ordered:{' '}
            </ProfileAlabamaModalInfoText>
            <ul>
              <li>
                <ProfileAlabamaModalInfoDescription>
                  <span>
                    {' '}
                    UNITY Carrier Screen™ (with or without Fetal Risk
                    Assessment){' '}
                  </span>
                </ProfileAlabamaModalInfoDescription>
              </li>
              <li>
                <ProfileAlabamaModalInfoDescription>
                  <span>
                    {' '}
                    UNITY Aneuploidy Screen™ (with or without Fetal Antigen
                    NIPT)
                  </span>
                </ProfileAlabamaModalInfoDescription>
              </li>
            </ul>
            <br />
            <br />
            <ProfileAlabamaModalInfoText>
              If you have questions about the services, please contact
              BillionToOne at 650-460-2551. If you have other questions about
              Medicare billing, call 1-800-MEDICARE (1-800-633-4227/TTY:
              1-877-486-2048).
            </ProfileAlabamaModalInfoText>
            <br />
            <br />
            <RadioGroup
              id="alabama-patient-service"
              style={{ marginTop: '-8px' }}
              value={agreeOrCancel}
              name="service"
              onChange={(e) => setAgreeOrCancel(e.target.value)}
            >
              <FormControlLabel
                id="new-test-patient-female"
                value="agree"
                control={<Radio />}
                label={
                  <>
                    I acknowledge receipt and understanding of this notice and{' '}
                    <span style={{ fontWeight: 700 }}>
                      {' '}
                      agree that I am responsible for the cost of the services
                      referenced above. The cost may be up to $349.{' '}
                    </span>
                  </>
                }
              />
              <br />
              <FormControlLabel
                id="new-test-patient-male"
                value="cancel"
                control={<Radio />}
                label={
                  <>
                    I acknowledge receipt and understanding of this notice and
                    <span style={{ fontWeight: 700 }}>
                      {' '}
                      want to cancel the services referenced above. No payment
                      will be required.
                    </span>
                  </>
                }
              />
            </RadioGroup>
          </ProfileAlabamaModalInfoWrapper>
          {loading ? (
            <SpinnerLoading />
          ) : (
            <ProfileInsuranceModalTitle deviceType={deviceType}>
              <ProfileInsuranceModalButtons deviceType={deviceType}>
                <div />
                <Button
                  label="SUBMIT"
                  onClick={() => submitABNForm()}
                  size="medium"
                />
              </ProfileInsuranceModalButtons>
            </ProfileInsuranceModalTitle>
          )}
        </ProfileInsuranceModalWrapper>
      </ProfileAlabamaModalWrapper>
    </Modal>
  );
};
