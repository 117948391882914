import styled from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';
import { StyledButton } from '../shared/Button/Button.styles';
import {
  StyledInputComponent,
  StyledSelectComponent,
} from '../shared/UI-Library/UI-Library.styles';

const formFieldWidthPretest = (deviceType: string) => {
  if (deviceType === 'desktop') return '450px';
  if (deviceType === 'tablet') return '421px';
  return '95%';
};

export const CreateAccountPretestDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: linear-gradient(
    48.22deg,
    #3bbfad 0%,
    #298f95 35%,
    #1a6881 61%,
    #216382 73%,
    #365683 91%,
    #444d84 100%
  );
`;

export const CreateAccountPretestMobileContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 24px;
  background: linear-gradient(
    48.22deg,
    #3bbfad 0%,
    #298f95 35%,
    #1a6881 61%,
    #216382 73%,
    #365683 91%,
    #444d84 100%
  );
  padding: 60px 20px;
`;

export const CreateAccountFormPreTestStyled = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-row-gap: ${({ deviceType }) =>
    deviceType === 'mobile' ? '16px' : '16px'};
  padding-top: 12px;
  place-items: ${({ deviceType }) =>
    deviceType !== 'desktop' ? undefined : 'center'};
  ${StyledInputComponent} {
    width: ${({ deviceType }) =>
      deviceType !== 'desktop' ? '95%' : undefined};
  }
  ${StyledSelectComponent} {
    width: ${({ deviceType }) =>
      deviceType !== 'desktop' ? '95%' : undefined};
  }
`;

export const CreateAccountCenterFormPreTestRowWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
`;

export const CreateAccountFormPreTestStyledWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => formFieldWidthPretest(deviceType)};
  display: grid;
  grid-template-rows: 485px auto;

  @media only screen and (max-height: 680px) {
    grid-template-rows: 350px auto;
  }
`;

export const CreateAccountFormPreTestStyledFormWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.greenDark};
  }
`;

export const CreateAccountFormPreTestStyledContainer = styled.div<{
  deviceType: DeviceType;
}>`
  ${CreateAccountCenterFormPreTestRowWrapper} {
    grid-template-columns: ${({ deviceType }) =>
      deviceType === 'desktop' ? 'min-content min-content' : undefined};
    grid-template-rows: ${({ deviceType }) =>
      deviceType === 'desktop' ? undefined : 'auto auto'};
    grid-column-gap: ${({ deviceType }) =>
      deviceType === 'desktop' ? '24px' : undefined};
    grid-row-gap: 20px;
  }
`;

export const CreateAccountCenterPreTestContainer = styled.div<{
  deviceType: string;
}>`
  position: relative;
  display: flex;
  background-color: transparent;
  height: calc(100vh - 89px);
  display: flex;
  align-items: ${({ deviceType }) =>
    deviceType === 'desktop' ? 'center' : 'baseline'};
  justify-content: center;
`;

export const CreateAccountPretestButtonContainer = styled.div<{
  deviceType: DeviceType;
}>`
  margin-top: 10px;
  width: ${({ deviceType }) => formFieldWidthPretest(deviceType)};
  background: white;
  justify-content: center;
  display: grid;
  grid-template-rows: auto auto;
  align-self: end;
  -webkit-align-self: end;
  padding: 15px;
  margin-bottom: -24px;
  border-radius: 24px;

  ${StyledButton} {
    padding: ${({ deviceType }) =>
      deviceType === 'desktop' ? '11px 105px' : undefined};
    text-transform: capitalize;
  }
`;

export const CreateAccountPretestLogin = styled.div<{
  deviceType: DeviceType;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

export const CreateAccountPretestLoginContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  align-items: center;
  display: flex;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const CreateAccountPretestLoginButton = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${({ theme }) => theme.greenDark};
  padding-left: 10px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  display: flex;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
  :hover {
    opacity: 0.7;
  }
`;

export const CreateAccountCenterFormPreTestContainer = styled.div`
  display: grid;
  grid-column-gap: 24px;
  padding: 24px;
  place-items: center;
  grid-template-rows: auto auto auto auto auto auto auto auto;
  background-color: white;
  left: 756px;
  top: 120px;
  border-radius: 24px;
`;

export const CreateAccountCenterFormPreTestInfoGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 20px;
  width: ${({ deviceType }) => formFieldWidthPretest(deviceType)};
  height: fit-content;
  align-self: center;
  justify-self: center;
`;

export const CreateAccountCenterFormPreTestInfoTitle = styled.div<{
  deviceType: DeviceType;
}>`
  font-style: normal;
  font-weight: 800;
  font-size: ${({ deviceType }) =>
    deviceType === 'desktop' ? '48px' : '32px'};
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.white};
`;

export const CreateAccountCenterFormPreTestInfoRow = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ deviceType }) =>
    deviceType === 'desktop' ? '20px' : '16px'};
  line-height: 32px;
  align-items: center;
  color: ${({ theme }) => theme.white};
`;

export const CreateAccountCenterFormPreTestInfoRowContent = styled.div<{
  deviceType: DeviceType;
}>`
  font-weight: 400;
  font-size: ${({ deviceType }) =>
    deviceType === 'desktop' ? '20px' : '16px'};
  line-height: ${({ deviceType }) =>
    deviceType === 'desktop' ? '32px' : '24px'};
`;

export const CreateAccountCenterFormPreTestError = styled.div`
  background: ${({ theme }) => theme.redPastel};
  border-left: 3px solid ${({ theme }) => theme.redDark};
  padding: 12px 20px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  svg {
    margin-right: 10px;
    min-width: 18px;
  }
`;
