import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilValue } from 'recoil';
import {
  GetBabyPeekTraitSharing,
  PostBabyPeekAction,
  PostBabyPeekTraitSharing,
} from './Services/BabyPeekService';
import {
  BabyPeekShareTraitModalWrapper,
  BabyPeekShareTraitModalTitle,
  BabyPeekFilledButton,
  BabyPeekShareOutlineButton,
} from './BabyPeekTrait.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { getUserAuth } from '../../recoil/selectors';
import {
  BabyPeekShareModalCheckboxGrid,
  BabyPeekShareBullets,
  BabyPeekFreeSwitch,
} from './BabyPeekStripe.styles';
import { TraitMenu } from './BabyPeekIcons/TraitIcons';
import { CustomSwitch } from '../shared/CustomSwitch';
import { BabyPeekButton } from './BabyPeekButton';
import { babyPeekStatus } from '../../recoil/atoms';

interface ShareStatus {
  share_status: 'not_shared' | 'shared';
  share_url: string;
  image_url: string;
}

interface ShareStatusTraits {
  LightOrDarkHair: ShareStatus;
  RedHair: ShareStatus;
  CurlyHair: ShareStatus;
  HairThickness: ShareStatus;
  EyeColor: ShareStatus;
  Asparagus: ShareStatus;
  BabyTeeth: ShareStatus;
  Freckle: ShareStatus;
  Cilantro: ShareStatus;
  Bitter: ShareStatus;
  SweetSalty: ShareStatus;
  Sweet: ShareStatus;
}

export const BabyPeekShareTraitModal: React.FC<{
  shareTraitModal: boolean;
  setShareTraitModal: (show: boolean) => void;
  traitPage: string;
}> = ({ setShareTraitModal, shareTraitModal, traitPage }) => {
  const { deviceType } = useDeviceHook();
  const [submitted, setSubmitted] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [sharingResults, setSharingResults] = useState<ShareStatusTraits>();

  const { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();
  const auth = useRecoilValue(getUserAuth);
  const babyPeekStatusFlag = useRecoilValue(babyPeekStatus);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (textCopied) {
      timeout = setTimeout(() => setTextCopied(false), 3000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [textCopied]);

  const shareTraits = async () => {
    await PostBabyPeekAction(
      babypeekPK,
      'BabyPeekSharedTraitClicked',
      deviceType
    );

    if (typeof navigator.share === 'undefined') {
      navigator.clipboard.writeText(getSharingResultsUrl());
      setTextCopied(true);
    } else {
      const response = await fetch(getSharingResultsImage());
      const blob = await response.blob();

      const filesArray = [
        new File([blob], 'meme.jpg', {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        }),
      ];
      await navigator.share({
        title: 'BabyPeek Results',
        text: getSharingResultsUrl(),
        files: filesArray,
      } as ShareData);
    }
  };

  const TraitsMap = {
    asparagus: 'Asparagus',
    babyteeth: 'BabyTeeth',
    bitter: 'Bitter',
    cilantro: 'Cilantro',
    curlyhair: 'CurlyHair',
    eyecolor: 'EyeColor',
    freckle: 'Freckle',
    hairthickness: 'HairThickness',
    lightordarkhair: 'LightOrDarkHair',
    redhair: 'RedHair',
    sweet: 'Sweet',
    sweetsalty: 'SweetSalty',
  } as { [trait: string]: string };

  useEffect(() => {
    const getSharingResults = async () => {
      const results = await GetBabyPeekTraitSharing(
        babypeekPK,
        auth?.access_token
      );
      const resultsJson = await results.json();
      setSharingResults(resultsJson.share_info);
    };
    if (auth && babyPeekStatusFlag === 'available') {
      getSharingResults();
    }
  }, [auth, babyPeekStatusFlag]);

  const toggleShare = async () => {
    if (TraitsMap[traitPage] && sharingResults) {
      const results = await PostBabyPeekTraitSharing(
        babypeekPK,
        TraitsMap[traitPage],
        sharingResults[TraitsMap[traitPage] as keyof ShareStatusTraits]
          ?.share_status !== 'shared'
          ? 'shared'
          : 'not_shared',
        auth?.access_token
      );
      const resultsJson = await results.json();
      setSharingResults(resultsJson.share_info);
    }
  };

  const getSharingResults = () => {
    if (TraitsMap[traitPage] && sharingResults) {
      return (
        sharingResults[TraitsMap[traitPage] as keyof ShareStatusTraits]
          ?.share_status === 'shared'
      );
    }
    return false;
  };

  const getSharingResultsUrl = () => {
    if (getSharingResults() && sharingResults) {
      return sharingResults[TraitsMap[traitPage] as keyof ShareStatusTraits]
        ?.share_url;
    }
    return '';
  };

  const getSharingResultsImage = () => {
    if (getSharingResults() && sharingResults) {
      return sharingResults[TraitsMap[traitPage] as keyof ShareStatusTraits]
        ?.image_url;
    }
    return '';
  };

  // });

  return (
    <>
      <Modal
        open={shareTraitModal}
        onClose={() => setShareTraitModal(false)}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <BabyPeekShareTraitModalWrapper deviceType={deviceType}>
          <CloseIcon
            onClick={() => setShareTraitModal(false)}
            style={{
              alignSelf: 'flex-end',
              marginBottom: '5px',
              cursor: 'pointer',
            }}
          />
          <BabyPeekShareTraitModalTitle>
            {submitted
              ? 'Results Shared!'
              : `Share ${
                  TraitMenu.find((trait) => trait.url === traitPage)?.trait ||
                  'BabyPeek'
                } Results`}
          </BabyPeekShareTraitModalTitle>
          {submitted ? (
            <>
              <BabyPeekShareOutlineButton
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  setSubmitted(false);
                }}
              >
                Share With Someone Else
              </BabyPeekShareOutlineButton>
              <BabyPeekFilledButton
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  setSubmitted(false);
                  setShareTraitModal(false);
                }}
              >
                Done
              </BabyPeekFilledButton>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto',
                  gap: '20px',
                }}
              >
                <BabyPeekShareModalCheckboxGrid
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    toggleShare();
                  }}
                >
                  <BabyPeekFreeSwitch onClick={() => toggleShare()}>
                    {' '}
                    <CustomSwitch
                      clicked={Boolean(
                        getSharingResults() && getSharingResultsUrl()
                      )}
                      color="#2B3FF6"
                    />
                  </BabyPeekFreeSwitch>

                  <div>
                    Allow link sharing
                    <br />
                    <BabyPeekShareBullets>
                      <li>
                        By allowing link sharing, the result will be accessible
                        for a year to anyone who has the link.
                      </li>
                      <br />
                      <li>
                        {`By not allowing link sharing, the result will NOT be accessible to anyone who has the link until it is enabled again. `}
                      </li>
                      <br />
                      <li>
                        {`My BabyPeek report does not contain any Protected Health information, including names, baby's sex, or birth dates, and it does not contain any UNITY medical test results. `}
                      </li>
                    </BabyPeekShareBullets>
                  </div>
                </BabyPeekShareModalCheckboxGrid>

                {textCopied ? (
                  <BabyPeekShareTraitModalTitle
                    style={{ justifySelf: 'center' }}
                  >
                    Copied!
                  </BabyPeekShareTraitModalTitle>
                ) : (
                  <BabyPeekButton
                    variant={
                      !getSharingResults() && !getSharingResultsUrl()
                        ? 'gray'
                        : 'filled'
                    }
                    id="share"
                    content={<>Share Now</>}
                    onClick={shareTraits}
                    disabled={!getSharingResults() && !getSharingResultsUrl()}
                    style={{
                      pointerEvents: !getSharingResults() ? 'none' : undefined,
                    }}
                  />
                )}
              </div>
            </>
          )}
        </BabyPeekShareTraitModalWrapper>
      </Modal>
    </>
  );
};
