import React, { useState } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  InNetworkDisclaimer,
  UpfrontPaymentBigCost,
  UpfrontPaymentButtonRow,
  UpfrontPaymentDesktopContainer,
  UpfrontPaymentFieldRow,
  UpfrontPaymentFieldRowTitle,
  UpfrontPaymentFinancialAssistance,
  UpfrontPaymentFinancialAssistanceWrapper,
  UpfrontPaymentFinancialExplanation,
  UpfrontPaymentFinancialExplanationCrossOut,
  UpfrontPaymentInfoRow,
  UpfrontPaymentLink,
  UpfrontPaymentMainBanner,
  UpfrontPaymentMainBannerBottomRow,
  UpfrontPaymentMainBannerTopRow,
  UpfrontPaymentMainNoticeBanner,
  UpfrontPaymentMainNoticeRow,
  UpfrontPaymentPageLink,
  UpfrontPaymentPhoneLink,
  UpfrontPaymentPlanAccordionContainer,
  UpfrontPaymentSmallMainBanner,
  UpfrontPaymentTooltip,
  UpfrontPaymentTooltipContent,
  UpfrontPaymentTooltipPopper,
  UpfrontPaymentYouHaveABill,
} from './UpfrontPayment.styles';

import { DeviceType, useDeviceHook } from '../../utils/hooks/useDeviceHook';

import { GetTypographyContent } from '../shared/Typography/Typography';
import { RightArrowIcon } from '../../assets/iconComponents/RightArrow';
import { Button } from '../shared/Button/Button';
import {
  BillingSupportNumber,
  CentsConversion,
  FinancialAssistanceMinDollars,
  PaymentPlanMinDollars,
  StepperIndex,
} from './types';
import {
  getCentsToDollars,
  RemoveUpfrontPayment,
  UpfrontPaymentType,
} from './Services/UpfrontPaymentService';
import { WarningRounded } from '../../assets/iconComponents/WarningRounded';
import { InfoIcon } from '../../assets/iconComponents/InfoIcon';
import { UpfrontSecondaryInsuranceModal } from './UpfrontPaymentSecondaryInsurance';
import { NoticeIcon } from '../../assets/iconComponents/NoticeIcon';
import { EOBIcon } from '../../assets/iconComponents/EOBIcon';
import { PaidIcon } from '../../assets/iconComponents/PaidIcon';
import { GreenCheckIcon } from '../../assets/iconComponents/GreenCheck';
import { PaymentPlanIcon } from '../../assets/iconComponents/PaymentPlanIcon';
import { UpfrontPaymentPlanAccordion } from './UpfrontPaymentPlanAccordion';
import { UpfrontPaymentReceiptDownload } from './UpfrontPaymentReceiptDownload';
import { BabyPeekRadioButton } from '../BabyPeek/BabyPeekRadioButton';
import {
  BabyPeekPaymentPlanTitle,
  BabyPeekPSavedCardGridRow,
  BabyPeekStripeIconPointGridText,
  BabyPeekStripeIconPointViewSampleTax,
} from '../BabyPeek/BabyPeekStripe.styles';
import { getCardIcon } from '../BabyPeek/BabyPeekStripe';
import { getUserAuth } from '../../recoil/selectors';
import { SavedCardType } from '../BabyPeek/Services/BabyPeekService';
import { SendActionType } from '../shared/Services/SharedServices';
import { UpfrontPaymentOA100 } from './UpfrontPaymentOA100';

const getUpperMessage = (
  upfrontBill: UpfrontPaymentType,
  setPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setInsurance: () => void,
  deviceType: DeviceType
) => {
  if (
    (upfrontBill.portal_bill_status === 'paid_in_full' ||
      upfrontBill.portal_bill_status === 'refunded' ||
      upfrontBill.portal_bill_status === 'in_progress') &&
    upfrontBill.oa_100_billing_status !== 'outstanding'
  ) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainNoticeBanner deviceType={deviceType}>
          <UpfrontPaymentMainBanner>
            <UpfrontPaymentMainBannerTopRow>
              <NoticeIcon /> Notice
            </UpfrontPaymentMainBannerTopRow>
            <UpfrontPaymentMainNoticeRow>
              You will receive an Explanation of Benefits (EOB) from your
              insurance company in the coming weeks.
              <span>
                {` This is not a bill and no action is needed when you receive
            your EOB from your insurance.`}
              </span>
            </UpfrontPaymentMainNoticeRow>
          </UpfrontPaymentMainBanner>
          <EOBIcon />
        </UpfrontPaymentMainNoticeBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (
    upfrontBill.portal_bill_status === 'secondary_ins_provided' ||
    upfrontBill.portal_bill_status === 'primary_ins_provided' ||
    upfrontBill.portal_bill_status === 'medicaid_or_hmo_ins_provided'
  ) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <InfoOutlined /> Pending Review
          </UpfrontPaymentMainBannerTopRow>
          <UpfrontPaymentMainBannerBottomRow>
            {`Our team will review your insurance and update the bill accordingly. There’s no action you need to take at this point. Please keep an eye out for a bill in the future. `}
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (upfrontBill.is_cash_pay && upfrontBill.accept_insurance) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <InfoOutlined /> Have insurance or need assistance?
          </UpfrontPaymentMainBannerTopRow>

          <UpfrontPaymentMainBannerBottomRow>
            <>
              {`If so, please `}
              <UpfrontPaymentLink onClick={() => setInsurance()}>
                Add Insurance
              </UpfrontPaymentLink>
              {` or if you need assistance, call us at `}
              <a
                href={`tel:${BillingSupportNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <UpfrontPaymentPhoneLink>
                  {BillingSupportNumber}
                </UpfrontPaymentPhoneLink>
              </a>
              {`.`}{' '}
            </>
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (upfrontBill.bill_type === 'in_network' && !upfrontBill.is_final) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <InfoOutlined /> Your claim is being applied to your in-network
            benefits with Cigna
          </UpfrontPaymentMainBannerTopRow>

          <UpfrontPaymentMainBannerBottomRow>
            <>
              {`Your balance due will apply to your in-network benefits. This amount is based on a co-insurance, co-pay, or annual in-network deductible on your plan. If you have questions, please contact us at `}
              <a
                href={`tel:${BillingSupportNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <UpfrontPaymentPhoneLink>
                  {BillingSupportNumber}
                </UpfrontPaymentPhoneLink>
              </a>
              {`.`}{' '}
            </>
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (
    upfrontBill.has_submitted_financial_assistance &&
    !upfrontBill.is_qualified_for_financial_assistance &&
    upfrontBill.portal_bill_status === 'outstanding'
  ) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <WarningRounded />{' '}
            {`It doesn't look like your financial information
            qualifies for an income based reduction `}
          </UpfrontPaymentMainBannerTopRow>
          <UpfrontPaymentMainBannerBottomRow>
            <>
              {`However, you may still qualify for other discounts. Please
            contact us at `}
              <a
                href={`tel:${BillingSupportNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <UpfrontPaymentPhoneLink>
                  {BillingSupportNumber}
                </UpfrontPaymentPhoneLink>
              </a>
              {` to discuss your options with one of our team members.`}{' '}
            </>
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (upfrontBill.bill_type === 'in_network' && upfrontBill.is_final) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <WarningRounded />
            <div>
              Your claim was approved and applied to your{' '}
              <span style={{ fontWeight: 'bold', display: 'inline' }}>
                {`in-network `}
              </span>
              benefits.
            </div>
          </UpfrontPaymentMainBannerTopRow>

          <UpfrontPaymentMainBannerBottomRow>
            This may have gone towards satisfying an annual in-network
            deductible on your plan, which may reduce your cost share on future
            medical claims.* View the itemized bill below for more detail. If
            you have questions, please contact us at{' '}
            <a
              href={`tel:${BillingSupportNumber}`}
              target="_blank"
              rel="noreferrer"
            >
              <UpfrontPaymentPhoneLink>
                {BillingSupportNumber}
              </UpfrontPaymentPhoneLink>
            </a>
            .
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
  if (upfrontBill.show_financial_assistance) {
    return (
      <UpfrontPaymentYouHaveABill>
        <UpfrontPaymentMainBanner>
          <UpfrontPaymentMainBannerTopRow>
            <InfoOutlined /> Looking for Assistance with Your Bill?
          </UpfrontPaymentMainBannerTopRow>

          <UpfrontPaymentMainBannerBottomRow>
            <>
              {`If you need assistance with your balance, please click `}
              <UpfrontPaymentPhoneLink
                onClick={() => setPaymentOpen(true)}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
                style={{ cursor: 'pointer', textDecoration: 'underline=' }}
              >
                Here
              </UpfrontPaymentPhoneLink>
              {` or if you need assistance, call us at `}
              <a
                href={`tel:${BillingSupportNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <UpfrontPaymentPhoneLink>
                  {BillingSupportNumber}
                </UpfrontPaymentPhoneLink>
              </a>
              {`.`}{' '}
            </>
          </UpfrontPaymentMainBannerBottomRow>
        </UpfrontPaymentMainBanner>
      </UpfrontPaymentYouHaveABill>
    );
  }
};

const getStatusIcon = (upfrontBill: UpfrontPaymentType) => {
  if (
    upfrontBill.portal_bill_status === 'paid_in_full' ||
    upfrontBill.portal_bill_status === 'in_progress'
  ) {
    return GetTypographyContent({
      content:
        upfrontBill.portal_bill_status === 'paid_in_full' ? (
          <PaidIcon />
        ) : (
          <PaymentPlanIcon />
        ),
      size: 'medium',
      textColor: 'black',
    });
  }
  return GetTypographyContent({
    content: 'Refunded',
    size: 'medium',
    textColor: 'black',
  });
};

export const UpfrontPaymentMain: React.FC<{
  setStep: React.Dispatch<React.SetStateAction<StepperIndex>>;
  setPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStripeOpen: (open: boolean) => Promise<void>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
  upfrontBill: UpfrontPaymentType;
  paymentSubmitted: boolean;
  setHomeScreen: () => void;
  savedPayments?: SavedCardType[];
  setSavedPayments: React.Dispatch<
    React.SetStateAction<SavedCardType[] | undefined>
  >;
  setUsedSavePayment: React.Dispatch<React.SetStateAction<string | undefined>>;
  useSavedPayment?: string;
  useNewCard: boolean;
  setUseNewCard: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  setStep,
  setPaymentOpen,
  setUpfrontBill,
  setStripeOpen,
  paymentSubmitted,
  upfrontBill,
  setHomeScreen,
  savedPayments,
  setUsedSavePayment,
  setUseNewCard,
  useNewCard,
  useSavedPayment,
  setSavedPayments,
}) => {
  const { deviceType } = useDeviceHook();
  const [openSecondayInsurance, setOpenSecondayInsurance] = useState<
    'primary' | 'secondary' | 'medicaid_or_hmo' | undefined
  >(undefined);
  const [receiptDownloadOpen, setReceiptDownloadOpen] = useState(false);
  const { billingPK } = useParams<{ billingPK: string }>();
  const auth = useRecoilValue(getUserAuth);

  return (
    <>
      <UpfrontPaymentDesktopContainer deviceType={deviceType}>
        <UpfrontSecondaryInsuranceModal
          setSecondaryModalOpen={setOpenSecondayInsurance}
          setUpfrontBill={setUpfrontBill}
          secondayModalOpen={openSecondayInsurance}
          setHomeScreen={setHomeScreen}
        />
        <UpfrontPaymentReceiptDownload
          receiptDownloadOpen={receiptDownloadOpen}
          setReceiptDownloadOpen={setReceiptDownloadOpen}
          upfrontBill={upfrontBill}
        />
        {upfrontBill && (
          <>
            {(upfrontBill.is_qualified_for_financial_assistance &&
              upfrontBill.portal_bill_status === 'outstanding') ||
            upfrontBill.portal_bill_status === 'fa_fully_covered' ||
            upfrontBill.portal_bill_status === 'has_medicaid' ? (
              <div>
                {' '}
                <UpfrontPaymentInfoRow>
                  <UpfrontPaymentFieldRow>
                    <UpfrontPaymentFieldRowTitle>
                      UPDATED AMOUNT OWED
                    </UpfrontPaymentFieldRowTitle>
                    <UpfrontPaymentFinancialExplanationCrossOut>
                      <UpfrontPaymentBigCost>
                        {getCentsToDollars(
                          upfrontBill.total_patient_responsibility_amount
                        )}
                      </UpfrontPaymentBigCost>
                      <div className="cross-out">
                        {' '}
                        {getCentsToDollars(
                          upfrontBill.total_insurance_allowed_amount -
                            upfrontBill.total_insurance_paid_amount
                        )}
                      </div>
                    </UpfrontPaymentFinancialExplanationCrossOut>
                  </UpfrontPaymentFieldRow>
                </UpfrontPaymentInfoRow>
                <UpfrontPaymentFinancialExplanation>
                  <GreenCheckIcon />
                  <div>
                    {`Thank you for submitting your information! You have qualified
                  for our ${
                    upfrontBill.portal_bill_status === 'has_medicaid'
                      ? 'medicaid'
                      : 'financial assistance'
                  } program and we've reduced your
                  balance. `}
                  </div>
                </UpfrontPaymentFinancialExplanation>
              </div>
            ) : (
              getUpperMessage(
                upfrontBill,
                setPaymentOpen,
                () => setOpenSecondayInsurance('primary'),
                deviceType
              )
            )}
            <UpfrontPaymentInfoRow>
              {!(
                upfrontBill.portal_bill_status === 'paid_in_full' ||
                upfrontBill.portal_bill_status === 'refunded'
              ) &&
                !(
                  (upfrontBill.is_qualified_for_financial_assistance &&
                    upfrontBill.portal_bill_status === 'outstanding') ||
                  upfrontBill.portal_bill_status === 'fa_fully_covered' ||
                  upfrontBill.portal_bill_status === 'has_medicaid'
                ) && (
                  <UpfrontPaymentFieldRow
                    adjustable={
                      !(
                        upfrontBill?.total_patient_responsibility_amount /
                          CentsConversion >
                        FinancialAssistanceMinDollars
                      )
                    }
                  >
                    <UpfrontPaymentFieldRowTitle>
                      Patient Amount Owed{' '}
                      <UpfrontPaymentTooltip
                        PopperComponent={UpfrontPaymentTooltipPopper}
                        title={
                          <UpfrontPaymentTooltipContent>
                            {`We've filed a claim with your insurance company and
                            found that your policy includes a remaining
                            deductible or co-insurance. `}
                          </UpfrontPaymentTooltipContent>
                        }
                      >
                        <div>
                          <InfoIcon />
                        </div>
                      </UpfrontPaymentTooltip>
                    </UpfrontPaymentFieldRowTitle>
                    {upfrontBill?.total_patient_responsibility_amount /
                      CentsConversion >
                      FinancialAssistanceMinDollars ||
                    upfrontBill.portal_bill_status === 'in_progress' ? (
                      GetTypographyContent({
                        content: getCentsToDollars(
                          upfrontBill?.total_patient_responsibility_amount
                        ),
                        size: 'medium',
                        textColor: 'black',
                      })
                    ) : (
                      <UpfrontPaymentBigCost marginTop={8}>
                        {getCentsToDollars(
                          upfrontBill?.total_patient_responsibility_amount
                        )}
                      </UpfrontPaymentBigCost>
                    )}
                  </UpfrontPaymentFieldRow>
                )}
              {paymentSubmitted && (
                <UpfrontPaymentFieldRow>
                  <UpfrontPaymentFieldRowTitle>
                    Patient Payment Status
                  </UpfrontPaymentFieldRowTitle>
                  {getStatusIcon(upfrontBill)}
                </UpfrontPaymentFieldRow>
              )}
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentInfoRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Date of Service
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: upfrontBill?.date_of_service,
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
              <UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRowTitle>
                  Provider
                </UpfrontPaymentFieldRowTitle>
                {GetTypographyContent({
                  content: upfrontBill?.provider_name,
                  size: 'medium',
                  textColor: 'black',
                })}
              </UpfrontPaymentFieldRow>
            </UpfrontPaymentInfoRow>
            {upfrontBill?.is_cash_pay && upfrontBill.accept_insurance && (
              <UpfrontPaymentInfoRow>
                <UpfrontPaymentFieldRow>
                  <UpfrontPaymentFieldRowTitle>
                    Billing Method
                  </UpfrontPaymentFieldRowTitle>
                  {GetTypographyContent({
                    content: 'Self Pay',
                    size: 'medium',
                    textColor: 'black',
                  })}
                </UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRow>
                  <UpfrontPaymentFieldRowTitle>
                    Insurance
                  </UpfrontPaymentFieldRowTitle>
                  {upfrontBill.portal_bill_status === 'primary_ins_provided'
                    ? GetTypographyContent({
                        content: 'Pending Review',
                        size: 'medium',
                        textColor: 'black',
                        isItalic: true,
                      })
                    : upfrontBill.portal_bill_status === 'outstanding' &&
                      !upfrontBill.has_submitted_financial_assistance && (
                        <UpfrontPaymentLink
                          onClick={() => setOpenSecondayInsurance('primary')}
                        >
                          Add
                        </UpfrontPaymentLink>
                      )}
                </UpfrontPaymentFieldRow>
              </UpfrontPaymentInfoRow>
            )}
            {!upfrontBill?.is_cash_pay && upfrontBill.accept_insurance && (
              <UpfrontPaymentInfoRow>
                <UpfrontPaymentFieldRow>
                  <UpfrontPaymentFieldRowTitle>
                    Primary Insurance
                  </UpfrontPaymentFieldRowTitle>
                  {upfrontBill.portal_bill_status ===
                  'medicaid_or_hmo_ins_provided'
                    ? GetTypographyContent({
                        content: 'Pending Review',
                        size: 'medium',
                        textColor: 'black',
                        isItalic: true,
                      })
                    : GetTypographyContent({
                        content: upfrontBill?.insurance_name,
                        size: 'medium',
                        textColor: 'black',
                      })}
                </UpfrontPaymentFieldRow>
                <UpfrontPaymentFieldRow>
                  <UpfrontPaymentFieldRowTitle>
                    {deviceType === 'mobile'
                      ? 'Sec. Insurance'
                      : 'Secondary Insurance'}
                    {upfrontBill.portal_bill_status === 'outstanding' &&
                      !upfrontBill.has_submitted_financial_assistance && (
                        <UpfrontPaymentTooltip
                          PopperComponent={UpfrontPaymentTooltipPopper}
                          title={
                            <UpfrontPaymentTooltipContent>
                              By providing your secondary insurance, you may be
                              eligible to have this bill reduced.
                            </UpfrontPaymentTooltipContent>
                          }
                        >
                          <div>
                            <InfoIcon />
                          </div>
                        </UpfrontPaymentTooltip>
                      )}
                  </UpfrontPaymentFieldRowTitle>
                  {upfrontBill.portal_bill_status === 'secondary_ins_provided'
                    ? GetTypographyContent({
                        content: 'Pending Review',
                        size: 'medium',
                        textColor: 'black',
                        isItalic: true,
                      })
                    : upfrontBill.portal_bill_status === 'outstanding' &&
                      !upfrontBill.has_submitted_financial_assistance &&
                      upfrontBill.accept_insurance && (
                        <UpfrontPaymentLink
                          onClick={() => setOpenSecondayInsurance('secondary')}
                        >
                          Add
                        </UpfrontPaymentLink>
                      )}
                </UpfrontPaymentFieldRow>
              </UpfrontPaymentInfoRow>
            )}
            <UpfrontPaymentInfoRow>
              {!upfrontBill.is_cash_pay && upfrontBill.accept_insurance && (
                <UpfrontPaymentPageLink
                  onClick={() => setStep(StepperIndex.Insurance)}
                >
                  <div>Insurance</div>
                  <RightArrowIcon />
                </UpfrontPaymentPageLink>
              )}
              {upfrontBill.portal_bill_status !== 'fa_fully_covered' && (
                <UpfrontPaymentPageLink
                  onClick={() => setStep(StepperIndex.ItemizedBill)}
                >
                  <div>Itemized Bill</div>
                  <RightArrowIcon />
                </UpfrontPaymentPageLink>
              )}
            </UpfrontPaymentInfoRow>
            <UpfrontPaymentInfoRow>
              {!(upfrontBill.portal_bill_status === 'secondary_ins_provided') &&
                paymentSubmitted && (
                  <UpfrontPaymentPageLink
                    onClick={() =>
                      upfrontBill.is_payment_plan_enabled
                        ? setReceiptDownloadOpen(true)
                        : setStep(StepperIndex.Receipt)
                    }
                  >
                    <div>Receipt</div>
                    <RightArrowIcon />
                  </UpfrontPaymentPageLink>
                )}
            </UpfrontPaymentInfoRow>
            {Boolean(savedPayments?.length) &&
              upfrontBill.portal_bill_status === 'outstanding' && (
                <div>
                  <hr />
                  <BabyPeekPaymentPlanTitle>Payment</BabyPeekPaymentPlanTitle>
                  {savedPayments
                    ?.filter((saved) => saved.is_chargeable)
                    .map((saved) => (
                      <BabyPeekPSavedCardGridRow key={saved.payment_method_id}>
                        <BabyPeekRadioButton
                          clicked={useSavedPayment === saved.payment_method_id}
                          setClick={() => {
                            setUsedSavePayment(saved.payment_method_id);
                            setUseNewCard(false);
                          }}
                        />
                        <BabyPeekStripeIconPointGridText
                          onClick={() => {
                            setUsedSavePayment(saved.payment_method_id);
                            setUseNewCard(false);
                          }}
                        >
                          <img src={getCardIcon(saved.card_brand)} alt="card" />
                          {`•••• ${saved.last_four_digits}`}
                        </BabyPeekStripeIconPointGridText>
                        {!saved.is_used_for_payment_plan && (
                          <BabyPeekStripeIconPointViewSampleTax
                            deviceType={deviceType}
                            onClick={async () => {
                              const removeResponse = await RemoveUpfrontPayment(
                                billingPK,
                                saved.payment_method_id,
                                auth?.access_token
                              );

                              const savedCards = await removeResponse.json();
                              setSavedPayments(savedCards.payment_methods);
                            }}
                          >
                            Remove
                          </BabyPeekStripeIconPointViewSampleTax>
                        )}
                      </BabyPeekPSavedCardGridRow>
                    ))}
                  <BabyPeekPSavedCardGridRow>
                    <BabyPeekRadioButton
                      clicked={useNewCard}
                      setClick={() => {
                        setUseNewCard(true);
                        setUsedSavePayment(undefined);
                      }}
                    />
                    <BabyPeekStripeIconPointGridText
                      onClick={() => {
                        setUseNewCard(true);
                        setUsedSavePayment(undefined);
                      }}
                    >
                      Pay with new card
                    </BabyPeekStripeIconPointGridText>
                  </BabyPeekPSavedCardGridRow>
                </div>
              )}
            {upfrontBill.portal_bill_status === 'outstanding' && (
              <>
                <UpfrontPaymentButtonRow>
                  <Button
                    type="submit"
                    label={`Pay ${getCentsToDollars(
                      upfrontBill?.total_patient_responsibility_amount
                    )}`}
                    size="medium"
                    onClick={() => setStripeOpen(true)}
                    backgroundColor="greenDark"
                  />
                </UpfrontPaymentButtonRow>
                {upfrontBill.total_patient_responsibility_amount /
                  CentsConversion >
                  PaymentPlanMinDollars && (
                  <UpfrontPaymentButtonRow bottomRow>
                    <Button
                      type="submit"
                      label="Build Payment Plan"
                      size="medium"
                      reverse
                      onClick={() => {
                        setStripeOpen(false);
                        setStep(StepperIndex.PaymentPlan);
                        SendActionType(
                          'PreviewPaymentPlan',
                          upfrontBill.req_identifier,
                          auth?.access_token
                        );
                      }}
                      backgroundColor="greenDark"
                    />
                  </UpfrontPaymentButtonRow>
                )}

                {upfrontBill.bill_type === 'in_network' &&
                  upfrontBill.is_final && (
                    <InNetworkDisclaimer>
                      *Individual policies and circumstances vary greatly. Refer
                      to your individual health insurance policy for coverage
                      details or contact your insurance company for details on
                      future expected out-of-pocket costs.
                    </InNetworkDisclaimer>
                  )}

                {upfrontBill.show_financial_assistance && (
                  <UpfrontPaymentFinancialAssistanceWrapper
                    onClick={() => setPaymentOpen(true)}
                  >
                    <UpfrontPaymentFinancialAssistance>
                      Apply For Financial Assistance
                    </UpfrontPaymentFinancialAssistance>
                  </UpfrontPaymentFinancialAssistanceWrapper>
                )}
                {upfrontBill.bill_type === 'out_of_network' &&
                  !upfrontBill.show_financial_assistance &&
                  upfrontBill?.oa100_balance === 0 && (
                    <>
                      <br />
                      <UpfrontPaymentYouHaveABill>
                        <UpfrontPaymentSmallMainBanner>
                          <UpfrontPaymentMainBannerTopRow>
                            <InfoOutlined /> Looking for Assistance with Your
                            Bill?
                          </UpfrontPaymentMainBannerTopRow>

                          <UpfrontPaymentMainBannerBottomRow>
                            <>
                              {`Call us at `}
                              <a
                                href={`tel:${BillingSupportNumber}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <UpfrontPaymentPhoneLink>
                                  {BillingSupportNumber}
                                </UpfrontPaymentPhoneLink>
                              </a>
                              {`.`}{' '}
                            </>
                          </UpfrontPaymentMainBannerBottomRow>
                        </UpfrontPaymentSmallMainBanner>
                      </UpfrontPaymentYouHaveABill>
                    </>
                  )}
              </>
            )}
          </>
        )}
      </UpfrontPaymentDesktopContainer>
      {upfrontBill?.is_payment_plan_enabled && (
        <UpfrontPaymentPlanAccordionContainer deviceType={deviceType}>
          <UpfrontPaymentPlanAccordion upfrontBill={upfrontBill} />
        </UpfrontPaymentPlanAccordionContainer>
      )}

      {upfrontBill?.oa_100_billing_status !== 'not_available' && (
        <UpfrontPaymentOA100
          upfrontBill={upfrontBill}
          setUpfrontBill={setUpfrontBill}
        />
      )}
    </>
  );
};
