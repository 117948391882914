import styled from 'styled-components/macro';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';

export const StyledAccordion = styled(MuiAccordion)`
  padding: 0 !important;
  margin: 2px 0 0 0 !important;
  box-shadow: none !important;
  background: ${({ theme }) => theme.white};
  width: 100%;
  z-index: 11;
  &:before {
    margin: auto;
    width: calc(100% - 32px);
    opacity: 1 !important;
  }
`;

export const AccordionCollapsedIcon = styled(ArrowForwardIosSharpIcon)`
  font-size: 1rem !important;
  transform: rotate(90deg);
  color: ${({ theme }) => theme.greenMedium};

  .MuiPaper-root-MuiAccordion-root:first-of-type {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
`;

export const AccordionExpandedIcon = styled(ArrowForwardIosSharpIcon)`
  font-size: 1rem !important;
  transform: rotate(90deg);
  color: ${({ theme }) => theme.greenMedium};
`;
