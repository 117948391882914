import * as React from 'react';

export const ShareUnderline = (): React.ReactElement => (
  <svg
    width="69"
    height="2"
    viewBox="0 0 69 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: 'block', marginTop: '3px' }}
  >
    <path
      d="M1 1H68"
      stroke="#008777"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0.15 4"
    />
  </svg>
);
