import * as React from 'react';

export const RedrawIcon = (): React.ReactElement => (
  <svg
    width="61"
    height="20"
    viewBox="0 0 61 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="61" height="20" fill="#5D5A5A" />
    <path
      d="M10.596 10.868C12.144 10.736 12.756 9.548 12.756 8.324C12.756 7.028 12.048 5.732 10.308 5.732H7.128V14H8.256V10.904H9.312L11.448 14H12.804L10.596 10.868ZM10.272 6.776C11.232 6.776 11.604 7.568 11.604 8.324C11.604 9.092 11.232 9.86 10.224 9.86H8.256V6.776H10.272ZM14.5577 5.732V14H19.5737V12.956H15.6737V10.46H18.9737V9.416H15.6737V6.776H19.4537V5.732H14.5577ZM24.5468 14C26.8988 14 28.2668 12.224 28.2668 9.884C28.2668 7.532 26.8988 5.732 24.5468 5.732H21.3428V14H24.5468ZM22.4588 12.956V6.776H24.5468C26.2628 6.776 27.1508 8.144 27.1508 9.884C27.1508 11.624 26.2628 12.956 24.5468 12.956H22.4588ZM33.4944 10.868C35.0424 10.736 35.6544 9.548 35.6544 8.324C35.6544 7.028 34.9464 5.732 33.2064 5.732H30.0264V14H31.1544V10.904H32.2104L34.3464 14H35.7024L33.4944 10.868ZM33.1704 6.776C34.1304 6.776 34.5024 7.568 34.5024 8.324C34.5024 9.092 34.1304 9.86 33.1224 9.86H31.1544V6.776H33.1704ZM38.8361 10.892L40.2041 7.22L41.5721 10.892H38.8361ZM41.9561 11.9L42.7601 14H43.9361L40.8041 5.732H39.6041L36.4601 14H37.6481L38.4521 11.9H41.9561ZM53.0843 5.732L51.0083 12.2L49.4843 6.848H48.6323L47.1083 12.2L45.0323 5.732H43.8323L46.6283 14H47.6003L49.0523 8.84L50.5163 14H51.4883L54.2843 5.732H53.0843Z"
      fill="white"
    />
  </svg>
);
