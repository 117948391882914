import React from 'react';
import {
  FooterPaddedTelephone,
  FooterPaddedEmail,
  FooterPaddedFax,
} from './Footer.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const FooterContactInfo = (): React.ReactElement => (
  <>
    <FooterPaddedTelephone />
    {GetTypographyContent({
      content: '+1(650) 460-2551',
      size: 'large',
      padding: '2px',
      textColor: 'neutral700',
      isPhone: true,
    })}
    <FooterPaddedEmail />
    {GetTypographyContent({
      content: 'support@unityscreen.com',
      size: 'large',
      padding: '2px',
      textColor: 'neutral700',
      isEmail: true,
    })}
    <FooterPaddedFax />
    {GetTypographyContent({
      content: '+1(833) 915-0146',
      size: 'large',
      padding: '2px',
    })}
  </>
);
