import Marquee from 'react-fast-marquee';

import Traits from './BabyPeekIcons/TraitIcons';
import {
  BabyPeekViewResultsTileWrapper,
  BabyPeekViewResultsTileWrapperGrid,
  BabyPeekViewResultsTileWrapperText,
} from './BabyPeekViewResultsChart.styles';

export const TraitMarquee: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
  const traits = Traits.map(({ trait, icon }) => (
    <BabyPeekViewResultsTileWrapper
      deviceType="mobile"
      style={{
        margin: '10px',
        background: 'white',
        fontSize: '14px',
        height: '115px',
        width: '115px',
      }}
      key={trait}
    >
      <BabyPeekViewResultsTileWrapperGrid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={icon}
            alt={`${trait}`}
            style={{ width: '40px', height: '40px' }}
          />
        </div>
        <BabyPeekViewResultsTileWrapperText style={{ lineHeight: '135%' }}>
          {trait}
        </BabyPeekViewResultsTileWrapperText>
      </BabyPeekViewResultsTileWrapperGrid>
    </BabyPeekViewResultsTileWrapper>
  ));

  return (
    <>
      <Marquee>{mobile ? traits.slice(0, 6) : traits}</Marquee>
      {mobile && <Marquee direction="right">{traits.slice(6)}</Marquee>}
    </>
  );
};
