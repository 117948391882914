import React from 'react';
import { dateFormatter } from '../../utils/helpers';
import { GetTypographyContent } from '../shared/Typography/Typography';

export const TestResultsReceived = (date: string): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: 'Received',
      size: 'medium',
      textColor: 'greyMedium',
      isBold: true,
      isCaps: true,
    })}
    {GetTypographyContent({
      content: dateFormatter(date),
      size: 'medium',
      textColor: 'greyMedium',
    })}
  </>
);
