import { BASE_URL } from '../../../utils/requestUtils';

export const ResetPasswordRequest = async (
  email: string
): Promise<Response> => {
  const response = await fetch(`${BASE_URL}/api/v1/account/resetpassword`, {
    body: JSON.stringify({ email }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};
