import React from 'react';
import loading from '../../../assets/icons/loading.svg';
import { LoadingSpinnerContainer } from './Loading.styles';

const Loading = (): React.ReactElement => (
  <LoadingSpinnerContainer>
    <img src={loading} alt="Loading" />
  </LoadingSpinnerContainer>
);

export default Loading;
