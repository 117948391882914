import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { StyledTextField } from './TextField.styles';

export const Password: React.FC<MuiTextFieldProps> = ({ type, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <StyledTextField
      label="Password"
      variant="outlined"
      value={props.value}
      type={showPassword ? 'text' : 'password'}
      onChange={props.onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
