import * as React from 'react';

export const StepperIconProgress = (): React.ReactElement => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="5.5" stroke="#2DBCB6" />
    <circle cx="6" cy="6" r="5.5" stroke="black" strokeOpacity="0.4" />
  </svg>
);
