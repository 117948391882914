import * as React from 'react';

export const ForwardIcon = (): React.ReactElement => (
  <svg
    width="30"
    height="22"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M13 14h-2a8.999 8.999 0 0 0-7.968 4.81A10.136 10.136 0 0 1 3 18C3 12.477 7.477 8 13 8V3l10 8-10 8v-5z"
        fill="#F0604B"
      />
    </g>
  </svg>
);
