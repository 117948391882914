import React from 'react';
import {
  FooterContactContainer,
  FooterDesktopContactContainer,
  FooterHr,
  FooterLegalContainer,
  FooterLinksContainer,
  FooterStyled,
  FooterPrivacyContainer,
  FooterAccreditationContainerDesktop,
  FooterAccredContainerNotDesktop1,
  FooterAccredContainerNotDesktop2,
  FooterUnityContainer,
  FooterUnityImage,
} from './Footer.styles';
import UnityLogo from '../../assets/logos/unity.svg';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { FooterContactInfo } from './FooterContactInfo';
import { FooterSocialInfo } from './FooterSocialInfo';
import { FooterLinkInfo } from './FooterLinkInfo';
import { FooterAccreditationInfoTop } from './FooterAccreditationInfoTop';
import { FooterAcreditationInfoBottom } from './FooterAccreditationInfoBottom';

export const Footer = (): React.ReactElement => {
  const { isDesktop, deviceType } = useDeviceHook();

  return (
    <>
      <FooterStyled deviceType={deviceType} data-testid="footerTestId">
        <FooterUnityContainer isDesktop={isDesktop}>
          {import.meta.env.VITE_IS_USER_TESTING_ENV !== 'true' && (
            <FooterUnityImage src={UnityLogo} width="156" alt="UNITY logo" />
          )}
        </FooterUnityContainer>
        {isDesktop && (
          <FooterDesktopContactContainer>
            <div>
              <FooterContactContainer deviceType={deviceType}>
                <FooterContactInfo />
              </FooterContactContainer>
              <br />
              <FooterSocialInfo />
            </div>
            <FooterLinksContainer deviceType={deviceType}>
              <FooterLinkInfo />
            </FooterLinksContainer>
          </FooterDesktopContactContainer>
        )}
        {!isDesktop && (
          <>
            <FooterContactContainer deviceType={deviceType}>
              <FooterContactInfo />
            </FooterContactContainer>
            <br />
            <FooterSocialInfo />
          </>
        )}

        {!isDesktop && (
          <>
            <FooterHr />{' '}
            <FooterLinksContainer deviceType={deviceType}>
              <FooterLinkInfo />
            </FooterLinksContainer>{' '}
            <FooterHr />
          </>
        )}

        <FooterLegalContainer isDesktop={isDesktop}>
          {GetTypographyContent({
            content:
              '1035 O’Brien Drive, Menlo Park, CA 94025 © 2016 – 2022 BillionToOne Inc. All rights reserved. BillionToOne® Registered in U.S. Patent and Trademark Office.',
            size: 'small',
            padding: '10px 0 0 0',
          })}
        </FooterLegalContainer>
        <FooterPrivacyContainer>
          {GetTypographyContent({
            content: 'Privacy Policy',
            size: 'small',
            link: 'https://billiontoone.com/privacy/',
            textColor: 'greenDark',
            externalLink: true,
          })}
          &nbsp;|&nbsp;
          {GetTypographyContent({
            content: 'Notice of Privacy Practices',
            size: 'small',
            textColor: 'greenDark',
            link: 'https://billiontoone.com/privacy-practices/',
            externalLink: true,
          })}
        </FooterPrivacyContainer>
        <br />
        {isDesktop && (
          <FooterAccreditationContainerDesktop>
            <FooterAccreditationInfoTop />
            <FooterAcreditationInfoBottom />
          </FooterAccreditationContainerDesktop>
        )}
        {!isDesktop && (
          <>
            <FooterAccredContainerNotDesktop1>
              <FooterAccreditationInfoTop />
            </FooterAccredContainerNotDesktop1>
            <FooterAccredContainerNotDesktop2>
              <FooterAcreditationInfoBottom />
            </FooterAccredContainerNotDesktop2>
          </>
        )}
      </FooterStyled>
    </>
  );
};
