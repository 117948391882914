import styled from 'styled-components/macro';
import { Link as ReactLink } from 'react-router-dom';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const HeaderIcon = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  margin: auto;
  height: inherit;
`;

export const HeaderContainer = styled.div`
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.11));
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 89px;
  background: ${({ theme }) => theme.white};
`;

export const HeaderIconsLinksContainer = styled.div`
  display: flex;
`;

export const HeaderIconsContainer = styled.div<{ paddingLeft?: number }>`
  display: flex;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 20px;
  padding-left: ${({ paddingLeft }) => `${paddingLeft || 50}px`};
  cursor: pointer;
  margin-top: 25px;
  :hover {
    opacity: 0.7;
  }
`;

export const HeaderLinksContainer = styled.div<{ onlyLogin: boolean }>`
  display: flex;
  flex: 1;
  white-space: nowrap;
  margin: auto 0;
  justify-content: flex-end;
  padding-right: min(10%, 50px);
  max-height: 60px;
`;

export const HeaderContainerMobile = styled.div`
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.11));
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 63px;
  background: ${({ theme }) => theme.white};
`;

export const HeaderProfileWarning = styled(ReactLink)<{
  deviceType: DeviceType;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    padding: 20px 0px 20px 20px;
  }

  svg {
    align-self: center;
    margin-left: -14px;
    margin-top: -4px;
  }

  path {
    fill: ${({ deviceType, theme }) =>
      deviceType === 'mobile' ? theme.white : theme.redDark};
  }
`;

export const HeaderIconContainerMobile = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr min-content;
  padding-left: 10px;
`;

export const HeaderIconContainerMobileWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 15px;
  padding-left: 10px;
`;

export const HeaderIconContainerMobileGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: auto;
  padding-right: ${({ deviceType }) =>
    deviceType === 'mobile' ? '15px' : '30px'};
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderIconContainerBabyPeekUnlockContainer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  height: 24px;
  width: 44px;
  color: white;
  border-radius: 6px;
  margin-left: 10px;
  color: ${({ theme }) => theme.neutral50};
  background: var(--primary-blue, #2b3ff6);
  display: inline-table;
  height: 24px;
  width: 55px;
  text-align: center;
`;

export const HeaderAccordionWrapper = styled.div<{
  backgroundColor?: string;
  deviceType: DeviceType;
}>`
  margin: ${({ deviceType }) => (deviceType !== 'desktop' ? '-4px' : '0px')};
  margin-top: ${({ deviceType }) =>
    deviceType !== 'desktop' ? '-4px' : '6px'};
  width: ${({ deviceType }) =>
    deviceType !== 'desktop' ? undefined : '180px'};
  .MuiAccordion-root {
    background: ${({ backgroundColor }) => backgroundColor};
  }
  .MuiTypography-root {
    padding: 0px 0px;
  }

  .MuiAccordionSummary-root {
    margin-left: 8px;
  }
  .Mui-expanded {
    background: ${({ backgroundColor }) => backgroundColor};
    min-height: 20px !important;
  }
  .MuiCollapse-root {
    z-index: 10;
    position: ${({ deviceType }) =>
      deviceType === 'desktop' ? 'fixed' : undefined};
    background: ${({ deviceType }) =>
      deviceType === 'desktop' ? 'white' : undefined};
  }
  .MuiSvgIcon-root {
    width: ${({ deviceType }) => (deviceType !== 'desktop' ? '1.5em' : '1em')};
    height: ${({ deviceType }) => (deviceType !== 'desktop' ? '1.5em' : '1em')};
    fill: ${({ deviceType }) =>
      deviceType !== 'desktop' ? 'white' : 'rgb(93, 90, 90)'};
  }
`;

export const HeaderAccordionGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  margin-left: 20px;
  margin-top: -5px;
`;
