import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  BabyPeekOptInWrapper,
  DismissText,
  OptInButton,
  OptInChoiceWrapper,
  OptInTextBold,
  OptInTextRegular,
} from './BabyPeekOptIn.styles';
import { patientProfileState } from '../../recoil/atoms';
import { UpdateNotifications } from '../Profile/Services/UpdateProfileInfoService';
import { getAllRequisitions, getUserAuth } from '../../recoil/selectors';
import { BabyPeekIcon } from './BabyPeekIcons/BabyPeekIcon';
import BabyPeekHeader from './BabyPeekIcons/BabyPeekHeader.png';
import { PostBabyPeekAction } from './Services/BabyPeekService';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const BabyPeekOptIn: React.FC = () => {
  const [profileInfo, setProfileInfo] = useRecoilState(patientProfileState);
  const { deviceType } = useDeviceHook();
  const [isDismissed, setIsDismissed] = useState(false);
  const [, setOptInMessage] = useState('');
  const authInfo = useRecoilValue(getUserAuth);
  const requisitions = useRecoilValue(getAllRequisitions);

  useEffect(() => {
    const isDismissedLocalStorage = localStorage.getItem(
      'babyPeekOptInDismissed'
    );
    if (isDismissedLocalStorage) {
      setIsDismissed(true);
    }
  }, []);

  const handleDismiss = async () => {
    localStorage.setItem('babyPeekOptInDismissed', 'true');
    setIsDismissed(true);
    if (requisitions && requisitions[0]?.baby_peek_pk)
      await PostBabyPeekAction(
        requisitions[0]?.baby_peek_pk,
        'BabyPeekMarketingClickedDismissed',
        deviceType
      );
  };

  const handleOptIn = async () => {
    setIsDismissed(true);
    const response = await UpdateNotifications(
      true,
      profileInfo?.phi.is_new_report_subscribed ?? true,
      authInfo?.access_token
    );
    if (response.ok) {
      const responseJson = await response.json();
      setProfileInfo(responseJson);
      setOptInMessage('Thank you for subscribing to BabyPeek communication.');
    }
    if (!response.ok) {
      setIsDismissed(false); // hopefully they can retry
      setOptInMessage('There was an error - please try again.');
    }
  };

  // wait for profile into to load
  if (!profileInfo) {
    return null;
  }
  // profile settings being true means don't show the window
  if (profileInfo?.phi.is_marketing_subscribed) {
    return null;
  }

  // wait for requisitions to load
  if (!requisitions) {
    return null;
  }

  // only show if the requisition is in pending
  if (!(requisitions[0]?.reveal_status === 'pending')) {
    return null;
  }

  // otherwise respect the local storage dismiss setting
  if (isDismissed) {
    return null;
  }

  return (
    <BabyPeekOptInWrapper>
      <div
        style={{
          height: '40px',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}
      >
        <BabyPeekIcon />
        <img src={BabyPeekHeader} alt="Intro Summary" height="32px" />
      </div>
      <OptInTextBold>
        Text me about BabyPeek, the only way to discover hair color and other
        genetic traits before birth.
      </OptInTextBold>
      <OptInTextRegular>
        Message and data rates may apply. Reply STOP to unsubscribe at any time.
      </OptInTextRegular>
      <OptInChoiceWrapper>
        <DismissText onClick={handleDismiss}>Dismiss</DismissText>
        <OptInButton onClick={handleOptIn}>OK</OptInButton>
      </OptInChoiceWrapper>
    </BabyPeekOptInWrapper>
  );
};
