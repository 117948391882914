import { ConsentStatus } from '../../../recoil/types';
import { BASE_URL } from '../../../utils/requestUtils';

interface ConsentSerializer {
  consent_status?: ConsentStatus;
  survey_status?: ConsentStatus;
  has_aneuploidy_consent?: boolean;
  has_carrier_consent?: boolean;
}

export const UpdateConsent = async (
  consent_info: ConsentSerializer,
  access_token?: string
): Promise<Response> =>
  fetch(`${BASE_URL}/api/v1/users/update_consent/`, {
    body: JSON.stringify(consent_info),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  });
