import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LightOrDarkHairTrait } from './Traits/LightOrDarkHair/LightOrDarkHairTrait';
import { RedHairTrait } from './Traits/RedHair/RedHairTrait';
import { CurlyHairTrait } from './Traits/CurlyHair/CurlyHairTrait';
import { HairThicknessTrait } from './Traits/HairThickness/HairThicknessTrait';
import { EyeColorTrait } from './Traits/EyeColor/EyeColorTrait';
import { AsparagusTrait } from './Traits/Asparagus/AsparagusTrait';
import { BabyTeethTrait } from './Traits/BabyTeeth/BabyTeethTrait';
import { FreckleTrait } from './Traits/Freckles/FreckleTrait';
import { CilantroTrait } from './Traits/Cilantro/CilantroTrait';
import { BitterTrait } from './Traits/BitterTaste/BitterTrait';
import { SweetSaltyTrait } from './Traits/SweetSalty/SweetSaltyTrait';
import { SweetTrait } from './Traits/Sweet/SweetTrait';
import {
  BabyPeekButtonNames,
  BabyPeekTraitResponse,
  GetBabyPeekTraitDataRequest,
  PostBabyPeekAction,
} from './Services/BabyPeekService';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { LANDING_PAGE_URL } from '../../utils/urlConstants';

import { getUserAuth } from '../../recoil/selectors';
import { BabyPeekButton } from './BabyPeekButton';
import {
  BabyPeekLearnMorePublic,
  BabyPeekMobileButtonSticky,
} from './BabyPeekStripe.styles';

export const BabyPeekPublicTrait: React.FC = () => {
  const [isFromEmailLink, setIsFromEmailLink] = useState(false);
  const [traitData, setTraitData] = useState<TraitInfo>();
  const [lastButtonClicked, setLastButtonClicked] =
    useState<BabyPeekButtonNames>();
  const [pageIndex, setPageIndex] = useState('');

  const { deviceType } = useDeviceHook();

  const { traitPK } = useParams<{
    traitPK: string;
  }>();

  interface TraitInfo extends BabyPeekTraitResponse {
    trait_name: 'RedHair';
    baby_peek_pk: '02vCstXw95ACnP24545cLO';
  }

  const TraitsMap = {
    Asparagus: 'asparagus',
    BabyTeeth: 'babyteeth',
    Bitter: 'bitter',
    Cilantro: 'cilantro',
    CurlyHair: 'curlyhair',
    EyeColor: 'eyecolor',
    Freckle: 'freckle',
    HairThickness: 'hairthickness',
    LightOrDarkHair: 'lightordarkhair',
    RedHair: 'redhair',
    Sweet: 'sweet',
    SweetSalty: 'sweetsalty',
  } as { [trait: string]: string };

  const history = useHistory();
  const auth = useRecoilValue(getUserAuth);

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fromEmail = params.get('e'); // e is set from a sign up email link
      setIsFromEmailLink(fromEmail === 'True');
    } catch (e) {
      // from sms
      setIsFromEmailLink(false);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await GetBabyPeekTraitDataRequest(traitPK);
      if (response.ok) {
        const responseJson = (await response.json()) as TraitInfo;
        setTraitData(responseJson);
        setPageIndex(TraitsMap[responseJson.trait_name]);
      } else {
        history.push(LANDING_PAGE_URL);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const sendPageAction = async () => {
      window.scrollTo(0, 0);
      let pageAction: string | undefined;
      if (traitData) {
        switch (pageIndex) {
          case 'sweet':
            pageAction = 'ViewedSweetTaste';
            break;

          case 'sweetsalty':
            pageAction = 'ViewedSweetVsSaltyTaste';
            break;
          case 'bitter':
            pageAction = 'ViewedBitterTaste';
            break;
          case 'cilantro':
            pageAction = 'ViewedCilantro';
            break;
          case 'freckle':
            pageAction = 'ViewedFreckles';
            break;

          case 'babyteeth':
            pageAction = 'ViewedTeeth';
            break;

          case 'asparagus':
            pageAction = 'ViewedAsparagus';
            break;

          case 'eyecolor':
            pageAction = 'ViewedEyeColor';
            break;

          case 'hairthickness':
            pageAction = 'ViewedHairThickness';
            break;

          case 'curlyhair':
            pageAction = 'ViewedCurlyHair';
            break;

          case 'redhair':
            pageAction = 'ViewedRedHair';
            break;

          case 'lightordarkhair':
            pageAction = 'ViewedLightOrDarkHair';
            break;

          default:
        }
      } else {
        pageAction = 'ViewedSummaryPage';
      }

      if (pageAction) {
        await PostBabyPeekAction(
          traitPK,
          pageAction,
          deviceType,
          isFromEmailLink,
          false,
          {
            lastButtonClicked,
            sharedTraitVersion: 'true',
            is_logged_in: auth ? 'true' : 'false',
          }
        );
        setLastButtonClicked(undefined);
      }
    };
    sendPageAction();
  }, [pageIndex, traitData]);

  const getPage = () => {
    if (traitData) {
      switch (pageIndex) {
        case 'sweet': {
          return (
            <SweetTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'sweetsalty': {
          return (
            <SweetSaltyTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'bitter': {
          return (
            <BitterTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'cilantro': {
          return (
            <CilantroTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'freckle': {
          return (
            <FreckleTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'babyteeth': {
          return (
            <BabyTeethTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'asparagus': {
          return (
            <AsparagusTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'eyecolor': {
          return (
            <EyeColorTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'hairthickness': {
          return (
            <HairThicknessTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'curlyhair': {
          return (
            <CurlyHairTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'redhair': {
          return (
            <RedHairTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        case 'lightordarkhair': {
          return (
            <LightOrDarkHairTrait
              traitData={traitData}
              hideTraitMenu={false}
              publicTrait
              showAnimation
            />
          );
        }
        default:
          return <div />;
      }
    }
    return <div />;
  };

  return (
    <>
      <div style={{ maxWidth: '99%', display: 'grid' }}>
        {!pageIndex || !traitData ? <SpinnerLoading /> : getPage()}
        <BabyPeekMobileButtonSticky>
          <BabyPeekLearnMorePublic>
            {`What's BabyPeek? `}{' '}
          </BabyPeekLearnMorePublic>
          <BabyPeekButton
            variant="filled"
            content={<>Learn More</>}
            onClick={async () => {
              window.open(`https://www.babypeek.com`, '_blank');
            }}
          />
        </BabyPeekMobileButtonSticky>
      </div>
    </>
  );
};
