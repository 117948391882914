import styled from 'styled-components/macro';
import {
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  CardActions as MuiCardActions,
  Card as MuiCard,
} from '@mui/material';
import { ComponentType, GridDimensions } from './types';
import { ChatIcon } from '../../../assets/iconComponents/ChatIcon';
import { DeviceType } from '../../../utils/hooks/useDeviceHook';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const getCardPadding = (deviceType?: DeviceType) => {
  if (deviceType === 'mobile') {
    return '24px 24px 10px 24px';
  }
  if (deviceType === 'tablet') {
    return '24px 10px 10px 15px';
  }
  return '24px 50px 15px 40px';
};

export const CardPaddedContainer = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  padding: ${({ deviceType }) => getCardPadding(deviceType)};
`;

export const CardMedia = styled(MuiCardMedia)<{
  component?: ComponentType;
}>`
  component: ${(props) => props.component};
  src: ${({ src }) => src};
  height: 100%;
`;

export const CardActions = styled(MuiCardActions)<{
  $deviceType: DeviceType;
}>`
  justify-content: ${({ $deviceType }) =>
    $deviceType === 'tablet' ? 'center' : 'start'}; ;
`;

export const CardContentDesktopTablet = styled(MuiCardContent)<{
  $gridDimension?: GridDimensions;
}>`
  display: grid;
  grid-template-columns: ${({ $gridDimension }) => $gridDimension || '40% 60%'};
  grid-gap: 0;
  padding: 0 !important;
`;

export const CardContentMobile = styled(MuiCardContent)`
  display: inline-grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  padding: 0 !important;
`;

export const CardStyledQuestionMark = styled(ChatIcon)`
  margin: 14px 0 7px 0px;
`;

export const StyledCard = styled(MuiCard)`
  @media only screen and (max-width: 360px) {
    max-width: 360px;
  }
  @media only screen and (max-width: 568px) {
    max-width: 568px;
  }
  @media only screen and (max-width: 825px) {
    max-width: 825px;
  }
  box-shadow: ${({ theme }) => theme.boxShadow} !important;
  background: ${({ theme }) => theme.white};
  border-radius: 24px !important;
`;
