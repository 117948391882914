import React, { useEffect, useState } from 'react';

import { useWindowWidth } from '@react-hook/window-size';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from '../Shared/BabyPeekTrait.styles';
import HairThicknessBackground from './HairThicknessBackground.png';
import HairThicknessIcon from './HairThicknessIcon.png';

import { BabyPeekTraitResponse, Trait } from '../../Services/BabyPeekService';
import { useDeviceHook } from '../../../../utils/hooks/useDeviceHook';
import { TraitNavigationLeft } from '../Shared/TraitNavigationLeft';
import { TraitNavigationRight } from '../Shared/TraitNavigationRight';
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from '../../BabyPeekStripe.styles';
import { BabyPeekButton } from '../../BabyPeekButton';
import { TraitMenu } from '../Shared/TraitMenu';
import { BabyPeekTraitAccordion } from '../Shared/BabyPeekTraitAccordion';
import { HairThicknessGene } from './HairThicknessGene';
import { HairThicknessHowItWorks } from './HairThicknessHowItWorks';
import { HairThicknessOtherFactors } from './HairThicknessOtherFactors';
import { NotLikely } from '../Shared/NotLikely';
import { SlightltLikely } from '../Shared/SlightlyLikely';
import { Likely } from '../Shared/Likely';
import { ShareUnderline } from '../../BabyPeekIcons/ShareUnderline';
import babyPeekProbGif from '../../AnimatedGifs/probability.gif';

const getHairThicknessProb = (
  dosage: number,
  showPreview?: boolean
): React.ReactElement => {
  if (showPreview)
    return (
      <img src={babyPeekProbGif} alt="loading..." style={{ height: '70px' }} />
    );
  if (dosage < 0.5) return <NotLikely />;
  if (dosage >= 0.5 && dosage <= 1) return <SlightltLikely />;
  return <Likely />;
};

const getHairThicknessDosage = (dosage: number): string => {
  if (dosage < 0.5) return 'not likely';
  if (dosage >= 0.5 && dosage <= 1) return 'slightly likely';
  return 'likely';
};

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>Why is hair thick or fine?</BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      How thick hair appears is due in part to the thickness of the individual
      strands of hair themselves. Hair grows out from a small pore in the scalp
      called the <span>“follicle.”</span>
      <br />
      The width of each hair strand is determined by complicated processes in
      the follicle. Wide hair strands often results in thicker appearing hair!
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        {' '}
        <HairThicknessHowItWorks />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      {`How did we determine your baby's hair thickness? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at 1 genetic variation in a gene called{' '}
      <span className="italic">EDAR</span> to determine how likely your baby is
      to have thick hair. This gene is involved in the development of hair
      follicles as well as skin, nails, and teeth. A specific variation has been
      associated with wider hair strands and therefore thicker hair.
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <HairThicknessGene />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect hair thickness?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect the appearance of hair thickness include:
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        {' '}
        <HairThicknessOtherFactors />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{' '}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${'(650) 460-2551'}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const HairThicknessTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData?: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  freeTrait?: boolean;
  publicTrait?: boolean;
  hideTraitMenu: boolean;
  hairThickVal?: number;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  freeTrait,
  publicTrait,
  hairThickVal = 0,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== 'desktop'
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const [showingGifs, setShowingGifs] = useState(
    (modalPreview || showAnimation) ?? false
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showingGifs) {
        setShowingGifs(false);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {' '}
      <div
        style={{
          backgroundImage: `url(${HairThicknessBackground})`,
          height: '260px',
          backgroundSize: 'cover',
          padding: '20px',
          display: 'grid',
          justifyContent: deviceType !== 'mobile' ? 'center' : 'flex-start',
          paddingBottom: '32px',
          paddingTop: '24px',
          gridGap: '12px',
        }}
        className="trait-top-background title-div"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={HairThicknessIcon}
              alt="HairThicknessIcon"
              style={{ justifySelf: 'center' }}
            />
            <div>Hair Thickness</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {' '}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait ? 'The baby is' : 'Your baby is'}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${getHairThicknessDosage(
              traitData ? traitData?.hair_thickness_dosage : hairThickVal
            )} to have thick hair`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          {getHairThicknessProb(
            traitData ? traitData?.hair_thickness_dosage : hairThickVal,
            showingGifs
          )}
        </div>
        {!modalPreview && (
          <>
            {' '}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: '25px',
                paddingTop: '0px',
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'How it works',
                    content: HowItWorks,
                    key: 'howItWorksEye',
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'Other factors',
                    content: OtherFactors,
                    key: 'otherFactorsEye',
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'About BabyPeek',
                    content: AboutBabyPeek,
                    key: 'aboutBabyPeekEye',
                  },
                ]}
              />
            </div>{' '}
          </>
        )}
      </BabyPeekTraitWrapper>
      {!freeTrait && !hairThickVal && setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu &&
            setCurrentTrait &&
            TraitMenu(setCurrentTrait, 'HairThickness')}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait && setCurrentTrait('CurlyHair')}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{' '}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? ' Hide Traits' : 'View Traits'}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() =>
                setCurrentTrait && setCurrentTrait('LightOrDarkHair')
              }
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
