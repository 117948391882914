import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import {
  BabyPeekFooterStyled,
  BabyPeekFooterItem,
  FooterPaddedTelephone,
} from './Footer.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const BabyPeekFooter = (): React.ReactElement => {
  const { deviceType } = useDeviceHook();

  return (
    <>
      <BabyPeekFooterStyled deviceType={deviceType}>
        <BabyPeekFooterItem>
          <a
            href={`mailto:${'hello@babypeek.com'}`}
            target="_blank"
            rel="noreferrer"
          >
            hello@babypeek.com
          </a>
        </BabyPeekFooterItem>

        <BabyPeekFooterItem>
          <FooterPaddedTelephone />
          <a
            href={`tel:${'+1(650) 460-2551'}`}
            target="_blank"
            rel="noreferrer"
          >
            +1(650) 460-2551
          </a>
        </BabyPeekFooterItem>

        <BabyPeekFooterItem>
          <a
            href="https://billiontoone.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy{' '}
          </a>
        </BabyPeekFooterItem>

        <BabyPeekFooterItem>
          <a
            href={`mailto:${'media@babypeek.com'}`}
            target="_blank"
            rel="noreferrer"
          >
            media@babypeek.com
          </a>
        </BabyPeekFooterItem>

        <BabyPeekFooterItem>
          <a href="https://billiontoone.com/" target="_blank" rel="noreferrer">
            BillionToOne
          </a>
          <FontAwesomeIcon icon={faExternalLink} />
        </BabyPeekFooterItem>
        <BabyPeekFooterItem>
          <a
            href="https://www.unityscreen.com/"
            target="_blank"
            rel="noreferrer"
          >
            Unity
          </a>
          <FontAwesomeIcon icon={faExternalLink} />
        </BabyPeekFooterItem>

        <hr />
        <BabyPeekFooterItem>
          © 2024 BillionToOne, Inc. All rights reserved.
        </BabyPeekFooterItem>

        <br />
      </BabyPeekFooterStyled>
    </>
  );
};
