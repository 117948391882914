import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Modal } from '@mui/material';

import { Close } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { useWindowWidth } from '@react-hook/window-size';
import {
  BabyPeekViewResultsMobileWrapper,
  BabyPeekViewResultsMainTitleHeader,
  BabyPeekViewResultsTitleMobile,
  BabyPeekViewResultsTitleDescription,
  BabyPeekDownloadTitle,
  BabyPeekViewDownload,
  BabyPeekViewDownloadSubTitle,
} from './BabyPeekViewResults.styles';
import {
  BabyPeekTraitResponse,
  LowerCasePageIndex,
  Trait,
  TraitLabel,
} from './Services/BabyPeekService';
import {
  BabyPeekPreviewModalWrapper,
  BabyPeekViewResultsTileGrid,
  BabyPeekViewResultsTileSeeResultsText,
  BabyPeekViewResultsTileWrapper,
  BabyPeekViewResultsTileWrapperGrid,
  BabyPeekViewResultsTileWrapperText,
} from './BabyPeekViewResultsChart.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import BackgroundRectangle from './BabyPeekIcons/BackgroundRectangle.png';
import { TraitMenuView } from './BabyPeekIcons/TraitIcons';
import { BabyPeekDownloadModal } from './BabyPeekDownloadModal';
import { BabyPeekGamesExclusive } from './BabyPeekIcons/BabyPeekGamesExclusive';
import {
  BabyPeekGamesIntro,
  BabyPeekGamesIntroDescription,
  BabyPeekGamesIntroGrid,
  BabyPeekGamesIntroTitle,
  BabyPeekGamesPreviewModalGrid,
  BabyPeekGamesPreviewModalSubTitle,
  BabyPeekGamesPreviewModalTitle,
  BabyPeekGamesPreviewModalTop,
} from './BabyPeekGames.styles';
import { BabyPeekButton } from './BabyPeekButton';
import { BabyPeekGamesConfetti } from './BabyPeekIcons/BabyPeekGamesConfetti';
import { BabyPeekGamesBallons } from './BabyPeekIcons/BabyPeekGamesBallons';
import { BABYPEEK_URL } from '../../utils/urlConstants';
import { BitterTrait } from './Traits/BitterTaste/BitterTrait';
import { AsparagusTrait } from './Traits/Asparagus/AsparagusTrait';
import { BabyTeethTrait } from './Traits/BabyTeeth/BabyTeethTrait';
import { CilantroTrait } from './Traits/Cilantro/CilantroTrait';
import { CurlyHairTrait } from './Traits/CurlyHair/CurlyHairTrait';
import { EyeColorTrait } from './Traits/EyeColor/EyeColorTrait';
import { FreckleTrait } from './Traits/Freckles/FreckleTrait';
import { HairThicknessTrait } from './Traits/HairThickness/HairThicknessTrait';
import { LightOrDarkHairTrait } from './Traits/LightOrDarkHair/LightOrDarkHairTrait';
import { RedHairTrait } from './Traits/RedHair/RedHairTrait';
import { SweetTrait } from './Traits/Sweet/SweetTrait';
import { SweetSaltyTrait } from './Traits/SweetSalty/SweetSaltyTrait';
import {
  BabyPeekPreviewModalTraitNavigationWrapper,
  BabyPeekPreviewTraitNavigationButton,
} from './BabyPeekStripe.styles';
import { PreviousTraitPreview } from './BabyPeekIcons/PreviousTraitPreview';
import { NextTraitPreview } from './BabyPeekIcons/NextTraitPreview';
import { BabyPeekExperienceFormSlowReveal } from './BabyPeekExperienceFormSlowReveal';
import { getUserAuth } from '../../recoil/selectors';

type TraitRevealed = {
  [key in TraitLabel]: boolean;
};
const traitLabels = [
  'Eye Color',
  'Light or Dark Hair',
  'Hair Thickness',
  'Hair Texture',
  'Red Hair',
  'Freckles',
  'Teething Timeline',
  'Cilantro Aversion',
  'Asparagus',
  'Bitter Taste',
  'Sweet Taste',
  'Sweet vs. Salty',
] as TraitLabel[];

const getTraitComponent = (
  trait: TraitLabel,
  traitData: BabyPeekTraitResponse
) => {
  if (trait === 'Eye Color')
    return <EyeColorTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Light or Dark Hair')
    return (
      <LightOrDarkHairTrait traitData={traitData} hideTraitMenu modalPreview />
    );
  if (trait === 'Hair Thickness')
    return (
      <HairThicknessTrait traitData={traitData} hideTraitMenu modalPreview />
    );
  if (trait === 'Hair Texture')
    return <CurlyHairTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Red Hair')
    return <RedHairTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Freckles')
    return <FreckleTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Teething Timeline')
    return <BabyTeethTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Cilantro Aversion')
    return <CilantroTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Asparagus')
    return <AsparagusTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Bitter Taste')
    return <BitterTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Sweet Taste')
    return <SweetTrait traitData={traitData} hideTraitMenu modalPreview />;
  if (trait === 'Sweet vs. Salty')
    return <SweetSaltyTrait traitData={traitData} hideTraitMenu modalPreview />;
};

export const BabyPeekViewResultsMobile: React.FC<{
  setCurrentTrait: (trait: Trait | undefined) => void;
  traitData?: BabyPeekTraitResponse;
  getTraitFeedback: () => Promise<void>;
  feedbackGiven?: boolean;
}> = ({ setCurrentTrait, getTraitFeedback, feedbackGiven, traitData }) => {
  const [downloadModal, setDownloadModal] = useState(false);
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const auth = useRecoilValue(getUserAuth);
  const history = useHistory();
  const { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();
  const [traitsRevealed, setTraitsRevealed] = useState<TraitRevealed>();
  const [showTraitPreviewModal, setTraitPreviewModal] = useState<{
    trait: TraitLabel;
    icon: string;
    url: LowerCasePageIndex;
  }>();
  const [showBabyPeekGamePreview, setBabyPeekGamePreview] = useState(false);
  const [showPreviewBlur, setShowPreviewBlue] = useState(false);

  const allTraitsRevealed = useMemo(
    () =>
      traitLabels.reduce(
        (acc, label) => Boolean(traitsRevealed && traitsRevealed[label] && acc),
        true
      ),
    [traitsRevealed]
  );

  useEffect(() => {
    setShowPreviewBlue(true);
    const timeout = setTimeout(() => {
      setShowPreviewBlue(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [showTraitPreviewModal]);

  useEffect(() => {
    if (!localStorage.getItem(`traitsRevealed${babypeekPK}`)) {
      const localTraitsRevealed: TraitRevealed = traitLabels.reduce(
        (acc, trait) => ({ ...acc, [trait]: false }),
        {} as TraitRevealed
      );
      setTraitsRevealed(localTraitsRevealed);
      localStorage.setItem(
        `traitsRevealed${babypeekPK}`,
        JSON.stringify(localTraitsRevealed)
      );
    } else {
      setTraitsRevealed(
        JSON.parse(
          localStorage.getItem(`traitsRevealed${babypeekPK}`) as string
        )
      );
    }
  }, []);

  const BabyPeekGames = (
    <div style={{ display: 'grid' }}>
      <BabyPeekGamesIntro>
        <BabyPeekGamesIntroGrid>
          <BabyPeekGamesExclusive />
          <BabyPeekGamesIntroTitle>BabyPeek Games</BabyPeekGamesIntroTitle>
          <BabyPeekGamesIntroDescription>
            Enjoy printable games and have fun discovering your baby’s traits
            with friends and family!
          </BabyPeekGamesIntroDescription>
          <BabyPeekButton
            variant="filled"
            content={<> Access </>}
            onClick={() => {
              history.push(`${BABYPEEK_URL}/${babypeekPK}/Games`);
            }}
            style={{
              justifySelf: 'center',
            }}
          />
        </BabyPeekGamesIntroGrid>
        <div style={{ position: 'absolute', bottom: 0, left: 0 }}>
          <BabyPeekGamesConfetti />
        </div>
        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
          <BabyPeekGamesBallons />
        </div>
      </BabyPeekGamesIntro>
    </div>
  );

  return (
    <>
      <BabyPeekViewResultsMobileWrapper>
        <BabyPeekDownloadModal
          shareTraitModal={downloadModal}
          setShareTraitModal={setDownloadModal}
        />
        <Modal
          open={Boolean(showTraitPreviewModal)}
          onClose={() => setTraitPreviewModal(undefined)}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: windowWidth,
          }}
          sx={{
            '& .MuiModal-backdrop': {
              width: 'inherit',
            },
          }}
        >
          {allTraitsRevealed && auth && showBabyPeekGamePreview ? (
            <BabyPeekGamesPreviewModalGrid>
              <BabyPeekGamesPreviewModalTop>
                <BabyPeekGamesPreviewModalTitle>
                  {' '}
                  You revealed all traits!
                </BabyPeekGamesPreviewModalTitle>
                <BabyPeekGamesPreviewModalSubTitle>
                  We hope this experience brought you closer to you baby.
                </BabyPeekGamesPreviewModalSubTitle>
              </BabyPeekGamesPreviewModalTop>
              {BabyPeekGames}
            </BabyPeekGamesPreviewModalGrid>
          ) : (
            <>
              <div
                style={{
                  display: 'grid',
                  height: 'min-content',
                  width: '100%',
                  zoom: '75%',
                }}
              >
                <BabyPeekPreviewModalWrapper
                  deviceType={deviceType}
                  showBlur={showPreviewBlur}
                  windowWidth={windowWidth}
                >
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '20px',
                      display: 'grid',
                    }}
                  >
                    <div className="close-icon">
                      <Close onClick={() => setTraitPreviewModal(undefined)} />
                    </div>
                    {traitData && showTraitPreviewModal && (
                      <>
                        {getTraitComponent(
                          showTraitPreviewModal.trait,
                          traitData
                        )}

                        <div
                          style={{
                            display: 'grid',
                            padding: '24px',
                            gap: ' 16px',
                          }}
                        >
                          <BabyPeekButton
                            variant="filled"
                            style={{
                              height: '44px',
                              borderColor: 'transparent',
                              background: 'transparent',
                              color: '#2B3FF6',
                              fontWeight: 700,
                            }}
                            content={<> Learn More </>}
                            onClick={() =>
                              setCurrentTrait(
                                showTraitPreviewModal.url as Trait
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </BabyPeekPreviewModalWrapper>
                {showTraitPreviewModal && (
                  <BabyPeekPreviewModalTraitNavigationWrapper
                    $width={400}
                    deviceType={deviceType}
                  >
                    {traitLabels.indexOf(showTraitPreviewModal?.trait) > 0 &&
                    !showBabyPeekGamePreview ? (
                      <BabyPeekPreviewTraitNavigationButton
                        onClick={() => {
                          const newTrait =
                            TraitMenuView[
                              traitLabels.indexOf(
                                showTraitPreviewModal?.trait
                              ) - 1
                            ];
                          setTraitPreviewModal(newTrait);
                          if (
                            traitsRevealed &&
                            !traitsRevealed[newTrait.trait]
                          ) {
                            setTraitsRevealed({
                              ...traitsRevealed,
                              [newTrait.trait]: true,
                            });
                            localStorage.setItem(
                              `traitsRevealed${babypeekPK}`,
                              JSON.stringify({
                                ...traitsRevealed,
                                [newTrait.trait]: true,
                              })
                            );
                          }
                        }}
                      >
                        <PreviousTraitPreview />{' '}
                      </BabyPeekPreviewTraitNavigationButton>
                    ) : (
                      <div />
                    )}
                    {traitLabels.indexOf(showTraitPreviewModal.trait) >= 0 ? (
                      <BabyPeekPreviewTraitNavigationButton
                        onClick={() => {
                          if (
                            traitLabels.indexOf(showTraitPreviewModal.trait) ===
                            traitLabels.length - 1
                          ) {
                            setBabyPeekGamePreview(true);
                          } else {
                            const newTrait =
                              TraitMenuView[
                                traitLabels.indexOf(
                                  showTraitPreviewModal.trait
                                ) + 1
                              ];
                            setTraitPreviewModal(newTrait);
                            if (
                              traitsRevealed &&
                              !traitsRevealed[newTrait.trait]
                            ) {
                              setTraitsRevealed({
                                ...traitsRevealed,
                                [newTrait.trait]: true,
                              });
                              localStorage.setItem(
                                `traitsRevealed${babypeekPK}`,
                                JSON.stringify({
                                  ...traitsRevealed,
                                  [newTrait.trait]: true,
                                })
                              );
                            }
                          }
                        }}
                      >
                        <NextTraitPreview />{' '}
                      </BabyPeekPreviewTraitNavigationButton>
                    ) : (
                      <div />
                    )}
                  </BabyPeekPreviewModalTraitNavigationWrapper>
                )}
              </div>
            </>
          )}
        </Modal>
        <BabyPeekViewResultsMainTitleHeader
          style={{
            backgroundImage: `url(${BackgroundRectangle})`,
            alignItems: 'center',
            background: '#FFF6D2',
            paddingBottom: '32px',
          }}
        >
          <BabyPeekViewResultsTitleMobile>
            BabyPeek Results
          </BabyPeekViewResultsTitleMobile>
          <BabyPeekViewResultsTitleDescription>
            {`Your BabyPeek results have arrived! Take a peek at your baby's traits. `}
          </BabyPeekViewResultsTitleDescription>
          <BabyPeekViewDownload onClick={() => setDownloadModal(true)}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <BabyPeekDownloadTitle>
                  Access your printable report
                </BabyPeekDownloadTitle>
                <BabyPeekViewDownloadSubTitle>
                  Share it with your loved ones!
                </BabyPeekViewDownloadSubTitle>
              </div>
              <BabyPeekDownloadTitle onClick={() => setDownloadModal(true)}>
                <svg
                  width="48"
                  height="45"
                  viewBox="0 0 48 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 22.5471C0 10.3969 9.84974 0.547119 22 0.547119H26C38.1503 0.547119 48 10.3969 48 22.5471C48 34.6974 38.1503 44.5471 26 44.5471H22C9.84974 44.5471 0 34.6974 0 22.5471Z"
                    fill="#2B3FF6"
                  />
                  <path
                    d="M25 15.5471V23.1409L27.2812 20.8596C27.6562 20.4534 28.3125 20.4534 28.6875 20.8596C29.0938 21.2346 29.0938 21.8909 28.6875 22.2659L24.6875 26.2659C24.3125 26.6721 23.6562 26.6721 23.2812 26.2659L19.2812 22.2659C18.875 21.8909 18.875 21.2346 19.2812 20.8596C19.6562 20.4534 20.3125 20.4534 20.6875 20.8596L23 23.1409V15.5471C23 15.0159 23.4375 14.5471 24 14.5471C24.5312 14.5471 25 15.0159 25 15.5471ZM18 25.5471H21.1562L22.5625 26.9846C23.3438 27.7659 24.625 27.7659 25.4062 26.9846L26.8125 25.5471H30C31.0938 25.5471 32 26.4534 32 27.5471V28.5471C32 29.6721 31.0938 30.5471 30 30.5471H18C16.875 30.5471 16 29.6721 16 28.5471V27.5471C16 26.4534 16.875 25.5471 18 25.5471ZM29.5 27.2971C29.0625 27.2971 28.75 27.6409 28.75 28.0471C28.75 28.4846 29.0625 28.7971 29.5 28.7971C29.9062 28.7971 30.25 28.4846 30.25 28.0471C30.25 27.6409 29.9062 27.2971 29.5 27.2971Z"
                    fill="white"
                  />
                </svg>
              </BabyPeekDownloadTitle>
            </div>
          </BabyPeekViewDownload>
        </BabyPeekViewResultsMainTitleHeader>
        <div>
          <BabyPeekViewResultsTitleDescription style={{ padding: '20px 20px' }}>
            {`Tap to learn your baby's traits based on your baby's DNA results. `}
          </BabyPeekViewResultsTitleDescription>
          {allTraitsRevealed && feedbackGiven !== undefined && !feedbackGiven && (
            <div style={{ display: 'grid', padding: '16px' }}>
              <BabyPeekExperienceFormSlowReveal
                getTraitFeedback={getTraitFeedback}
              />
            </div>
          )}
          {allTraitsRevealed && auth && BabyPeekGames}

          <BabyPeekViewResultsTileGrid deviceType={deviceType}>
            {TraitMenuView.map((trait) => (
              <BabyPeekViewResultsTileWrapper
                deviceType={deviceType}
                clicked={Boolean(traitsRevealed && traitsRevealed[trait.trait])}
                key={trait.trait}
                onClick={() => {
                  if (traitsRevealed && traitsRevealed[trait.trait]) {
                    setCurrentTrait(trait.url as Trait);
                  } else if (traitsRevealed) {
                    setTraitPreviewModal(trait);
                    setTraitsRevealed({
                      ...traitsRevealed,
                      [trait.trait]: true,
                    });
                    localStorage.setItem(
                      `traitsRevealed${babypeekPK}`,
                      JSON.stringify({
                        ...traitsRevealed,
                        [trait.trait]: true,
                      })
                    );
                  }
                }}
              >
                <BabyPeekViewResultsTileWrapperGrid>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={trait.icon}
                      alt={trait.icon}
                      style={{ width: '64px' }}
                    />
                  </div>
                  <BabyPeekViewResultsTileWrapperText>
                    {trait.trait}
                  </BabyPeekViewResultsTileWrapperText>
                  {traitsRevealed &&
                    (traitsRevealed[trait.trait] ? (
                      <BabyPeekViewResultsTileSeeResultsText>
                        {' '}
                        See details
                      </BabyPeekViewResultsTileSeeResultsText>
                    ) : (
                      <BabyPeekButton
                        variant="filled"
                        style={{ height: '35px' }}
                        content={<> Reveal </>}
                      />
                    ))}
                </BabyPeekViewResultsTileWrapperGrid>
              </BabyPeekViewResultsTileWrapper>
            ))}
          </BabyPeekViewResultsTileGrid>

          {!allTraitsRevealed && auth && BabyPeekGames}
          <hr />
        </div>
      </BabyPeekViewResultsMobileWrapper>
    </>
  );
};
