import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BabyPeekFAQWrapper } from './BabyPeekIntro.styles';
import { BabyPeekIcon } from './BabyPeekIcons/BabyPeekIcon';

import { BabyPeekExperienceFormSlowReveal } from './BabyPeekExperienceFormSlowReveal';
import {
  BabyPeekFeedbackResponse,
  GetBabyPeekFeedback,
} from './Services/BabyPeekService';

export const BabyPeekSingleFeedback: React.FC<{ onlyFeedback?: boolean }> = ({
  onlyFeedback,
}) => {
  const [feedbackData, setFeedback] = useState<BabyPeekFeedbackResponse>();
  const { babypeekPK } = useParams<{
    babypeekPK: string;
  }>();

  const getTraitFeedback = async () => {
    const response = await GetBabyPeekFeedback(babypeekPK);
    if (response.ok) {
      const responseJson = (await response.json()) as BabyPeekFeedbackResponse;
      setFeedback(responseJson);
    }
  };

  if (onlyFeedback) {
    return feedbackData?.has_general_feedback ? (
      <div />
    ) : (
      <div
        style={{
          margin: '32px 0px',
          display: 'grid',
          justifyContent: 'center',
        }}
      >
        <BabyPeekExperienceFormSlowReveal
          getTraitFeedback={getTraitFeedback}
          singlePage
        />
      </div>
    );
  }

  return (
    <BabyPeekFAQWrapper
      style={{
        display: 'grid',
        background:
          'linear-gradient(180deg, #FFF6CF 0%, rgba(255, 253, 244, 0.60) 20.5%, #FFFDF4 100%, #FFFDF4 100%), #FFF',
      }}
    >
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          alignItems: 'center',
          justifySelf: 'center',
        }}
      >
        <BabyPeekIcon />
      </div>
      <div
        style={{
          margin: '32px 0px',
          display: 'grid',
          justifyContent: 'center',
        }}
      >
        {feedbackData?.has_general_feedback ? (
          <div style={{ fontSize: '16px' }}>
            {' '}
            Thank you for already submitting feedback!{' '}
          </div>
        ) : (
          <BabyPeekExperienceFormSlowReveal
            getTraitFeedback={getTraitFeedback}
            singlePage
          />
        )}
      </div>
    </BabyPeekFAQWrapper>
  );
};
