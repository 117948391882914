import * as React from 'react';

export const SaltySweetHowItWorks = (): React.ReactElement => (
  <svg
    width="249"
    height="187"
    viewBox="0 0 249 187"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1168_41636)">
      <path
        d="M94.707 105.514L94.86 103.196C94.8598 103.196 94.8598 103.195 94.8596 103.195C96.992 100.669 98.2796 97.4064 98.2796 93.8416C98.2796 89.5036 96.3739 85.613 93.3569 82.9537C93.9014 81.5034 94.201 79.9333 94.201 78.2927C94.201 74.1568 92.308 70.4636 89.342 68.0302L88.0182 65.0694L89.9359 64.8568C89.962 64.5014 89.9758 64.1426 89.9758 63.7807C89.9758 55.7659 83.4784 49.2684 75.4635 49.2684L73.7707 48.1669L75.1226 46.2855C73.7687 40.3948 68.4968 36 62.1951 36C57.4872 36 53.3552 38.4542 51 42.1507C48.6448 38.4542 44.5128 36 39.8049 36C33.5032 36 28.2315 40.3948 26.8774 46.2855L27.5508 47.4752L26.5365 49.2682C18.5218 49.2682 12.0243 55.7657 12.0243 63.7807C12.0243 64.1428 12.038 64.5016 12.0641 64.8568L13.3032 65.9482L12.6578 68.0302C9.6916 70.4635 7.79882 74.1568 7.79882 78.2927C7.79882 79.9333 8.09844 81.5034 8.64291 82.9537C5.62594 85.613 3.72021 89.5036 3.72021 93.8416C3.72021 97.4062 5.00777 100.669 7.1402 103.195C7.14 103.195 7.14 103.196 7.1398 103.196L7.39959 104.96L6.0706 106.41C5.90764 107.24 5.81739 108.097 5.81739 108.976C5.81739 114.792 9.56131 119.73 14.7693 121.522C14.7689 121.563 14.7661 121.603 14.7661 121.644C14.7661 127.14 19.2214 131.595 24.7173 131.595C25.3006 131.595 25.8713 131.542 26.4272 131.446C29.021 135.392 33.4853 138 38.5608 138C43.8405 138 48.4606 135.18 50.9998 130.964C53.5392 135.18 58.1593 138 63.439 138C68.5145 138 72.9788 135.393 75.5726 131.446C76.1285 131.542 76.6992 131.596 77.2825 131.596C82.7784 131.596 87.2337 127.14 87.2337 121.644C87.2337 121.603 87.2311 121.563 87.2305 121.522C92.4385 119.73 96.1824 114.792 96.1824 108.976C96.1824 108.097 96.0922 107.241 95.9292 106.41L94.707 105.514Z"
        fill="#FFA1A7"
      />
      <path
        d="M94.7069 105.514L94.8599 103.196C94.8597 103.196 94.8597 103.195 94.8595 103.195C96.992 100.669 98.2795 97.4064 98.2795 93.8416C98.2795 89.5036 96.3738 85.613 93.3568 82.9537C93.9013 81.5034 94.2009 79.9333 94.2009 78.2927C94.2009 74.1568 92.308 70.4636 89.342 68.0302L88.0182 65.0694L89.9359 64.8568C89.962 64.5014 89.9757 64.1426 89.9757 63.7807C89.9757 55.7659 83.4784 49.2684 75.4634 49.2684L73.7706 48.1669L75.1225 46.2855C73.7687 40.3948 68.4967 36 62.195 36C60.9732 36 59.7909 36.1665 58.6675 36.4759C63.4318 37.7846 67.1253 41.6807 68.1377 46.5598C68.4537 48.082 69.6847 49.2395 71.2105 49.5373C77.8875 50.8404 82.9267 56.7219 82.9267 63.7805C82.9267 64.5741 82.8626 65.3527 82.7397 66.1117C82.5476 67.2971 82.9116 68.497 83.718 69.3867C85.8519 71.741 87.1522 74.8648 87.1522 78.2927C87.1522 79.2498 87.0502 80.1827 86.8571 81.082C86.6033 82.264 86.9548 83.4892 87.7415 84.407C89.9159 86.9436 91.2308 90.2383 91.2308 93.8416C91.2308 96.7183 90.3923 99.3984 88.9479 101.653C88.3206 102.632 88.1568 103.837 88.5099 104.945C88.9151 106.216 89.1338 107.57 89.1338 108.976C89.1338 114.244 86.0617 118.793 81.6117 120.934C80.7561 121.346 80.0664 122.04 79.696 122.915C78.7222 125.214 76.0698 129.699 69.8803 131.18C69.0366 131.382 68.2756 131.856 67.7343 132.534C65.7792 134.983 63.0487 136.785 59.9144 137.567C61.043 137.849 62.2231 138 63.4388 138C68.5143 138 72.9786 135.393 75.5724 131.446C76.1282 131.543 76.699 131.596 77.2823 131.596C82.7781 131.596 87.2335 127.14 87.2335 121.645C87.2335 121.604 87.2309 121.563 87.2303 121.522C92.4382 119.731 96.1822 114.792 96.1822 108.976C96.1822 108.098 96.0919 107.241 95.929 106.41L94.7069 105.514Z"
        fill="#FF7676"
      />
      <path
        d="M67.3313 119.799C66.5061 119.799 65.8372 119.13 65.8372 118.305C65.8372 111.994 70.9714 106.859 77.2825 106.859C78.1076 106.859 78.7766 107.528 78.7766 108.354C78.7766 109.179 78.1076 109.848 77.2825 109.848C72.6192 109.848 68.8254 113.641 68.8254 118.305C68.8254 119.13 68.1565 119.799 67.3313 119.799Z"
        fill="#FE646F"
      />
      <path
        d="M82.4587 65.8081C84.9376 65.8081 87.3581 66.5982 89.3555 68.0421C89.3509 68.0384 89.3465 68.0342 89.3417 68.0302C89.6521 67.015 89.8547 65.9529 89.9356 64.8568C87.6818 63.5342 85.0966 62.8198 82.4587 62.8198C77.7398 62.8198 73.2679 65.1041 70.4964 68.9301C70.0123 69.5982 70.1615 70.5326 70.8299 71.0167C71.4981 71.501 72.4324 71.3514 72.9165 70.6832C75.1277 67.6304 78.6949 65.8081 82.4587 65.8081Z"
        fill="#FE646F"
      />
      <path
        d="M83.1529 90.2065C80.9477 90.2065 78.7985 89.4351 77.1012 88.0344C75.256 86.5117 74.0741 84.3839 73.739 82.0566C77.0598 81.5755 80.096 79.7656 82.0914 77.0108C82.5755 76.3426 82.4263 75.4083 81.7579 74.9242C81.0898 74.4399 80.1556 74.5893 79.6713 75.2577C77.8843 77.7246 75.0014 79.1974 71.96 79.1974C69.7548 79.1974 67.6056 78.4261 65.9083 77.0253C63.7073 75.2091 62.4451 72.5328 62.4451 69.6827C62.4451 68.8576 61.7761 68.1886 60.9509 68.1886C60.1258 68.1886 59.4568 68.8576 59.4568 69.6827C59.4568 73.4279 61.1149 76.9443 64.0061 79.3303C65.9206 80.9103 68.2724 81.8789 70.7318 82.1228C71.0412 84.8321 72.2249 87.3528 74.1101 89.3245C72.5257 89.9214 71.1047 90.924 69.9955 92.2682C68.5479 94.0223 67.7507 96.2426 67.7507 98.52C67.7507 99.3452 68.4196 100.014 69.2448 100.014C70.07 100.014 70.7389 99.3452 70.7389 98.52C70.7389 96.9351 71.2934 95.3903 72.3002 94.1703C73.5282 92.6823 75.3028 91.7916 77.214 91.6908C79.0276 92.6686 81.0706 93.1947 83.1525 93.1947C83.9776 93.1947 84.6466 92.5258 84.6466 91.7006C84.6466 90.8754 83.978 90.2065 83.1529 90.2065Z"
        fill="#FE646F"
      />
      <path
        d="M75.4634 49.2682C75.4634 49.2674 75.4634 49.2666 75.4634 49.2656C75.4634 49.2654 75.4632 49.265 75.4634 49.265C75.4632 48.2406 75.3431 47.2449 75.1231 46.2873C75.1231 46.2865 75.1229 46.2861 75.1227 46.2855C69.394 46.4052 64.1597 49.5754 61.4037 54.6228C61.0083 55.3471 61.2748 56.2548 61.999 56.6502C62.7234 57.0459 63.631 56.7791 64.0264 56.0549C66.3121 51.8686 70.6927 49.268 75.4586 49.268C75.4602 49.268 75.4618 49.2682 75.4634 49.2682Z"
        fill="#FE646F"
      />
      <path
        d="M94.86 103.196C92.4701 106.038 88.8912 107.848 84.8962 107.848C84.0711 107.848 83.4021 108.517 83.4021 109.342C83.4021 110.168 84.0711 110.836 84.8962 110.836C89.1705 110.836 93.0554 109.149 95.9292 106.41C95.7076 105.281 95.3479 104.202 94.86 103.196Z"
        fill="#FE646F"
      />
      <path
        d="M34.6687 119.799C35.4939 119.799 36.1628 119.13 36.1628 118.305C36.1628 111.994 31.0286 106.859 24.7175 106.859C23.8924 106.859 23.2234 107.528 23.2234 108.354C23.2234 109.179 23.8924 109.848 24.7175 109.848C29.3808 109.848 33.1746 113.641 33.1746 118.305C33.1746 119.13 33.8435 119.799 34.6687 119.799Z"
        fill="#FE646F"
      />
      <path
        d="M19.5411 65.8082C17.0622 65.8082 14.6417 66.5983 12.6443 68.0422C12.6489 68.0384 12.6533 68.0342 12.6581 68.0303C12.3477 67.015 12.1451 65.953 12.0642 64.8569C14.318 63.5343 16.9032 62.8199 19.5411 62.8199C24.26 62.8199 28.7318 65.1041 31.5034 68.9301C31.9875 69.5983 31.8383 70.5326 31.1699 71.0167C30.5017 71.501 29.5674 71.3514 29.0833 70.6832C26.8721 67.6304 23.3049 65.8082 19.5411 65.8082Z"
        fill="#FE646F"
      />
      <path
        d="M18.847 90.2065C21.0522 90.2065 23.2013 89.4351 24.8987 88.0344C26.7438 86.5117 27.9258 84.3839 28.2609 82.0566C24.9401 81.5755 21.9038 79.7656 19.9085 77.0108C19.4243 76.3426 19.5736 75.4083 20.2419 74.9242C20.9101 74.4399 21.8443 74.5893 22.3286 75.2577C24.1156 77.7246 26.9984 79.1974 30.0399 79.1974C32.2451 79.1974 34.3942 78.4261 36.0916 77.0253C38.2926 75.2091 39.5548 72.5328 39.5548 69.6827C39.5548 68.8576 40.2238 68.1886 41.0489 68.1886C41.8741 68.1886 42.5431 68.8576 42.5431 69.6827C42.5431 73.4279 40.885 76.9443 37.9937 79.3303C36.0792 80.9103 33.7275 81.8789 31.2681 82.1228C30.9587 84.8321 29.775 87.3528 27.8898 89.3245C29.4741 89.9214 30.8952 90.924 32.0044 92.2682C33.4519 94.0223 34.2492 96.2426 34.2492 98.52C34.2492 99.3452 33.5802 100.014 32.7551 100.014C31.9299 100.014 31.2609 99.3452 31.2609 98.52C31.2609 96.9351 30.7065 95.3903 29.6997 94.1703C28.4717 92.6823 26.697 91.7916 24.7859 91.6908C22.9722 92.6686 20.9292 93.1947 18.8474 93.1947C18.0222 93.1947 17.3533 92.5258 17.3533 91.7006C17.3533 90.8754 18.0218 90.2065 18.847 90.2065Z"
        fill="#FE646F"
      />
      <path
        d="M26.5366 49.2682C26.5366 49.2674 26.5366 49.2666 26.5366 49.2656C26.5366 49.2654 26.5368 49.265 26.5366 49.265C26.5368 48.2407 26.6569 47.245 26.8769 46.2873C26.8769 46.2865 26.8771 46.2861 26.8773 46.2855C32.606 46.4053 37.8403 49.5754 40.5963 54.6228C40.9917 55.3472 40.7252 56.2548 40.001 56.6503C39.2767 57.0459 38.369 56.7792 37.9736 56.055C35.6879 51.8686 31.3073 49.268 26.5414 49.268C26.5398 49.268 26.5382 49.2682 26.5366 49.2682Z"
        fill="#FE646F"
      />
      <path
        d="M7.14001 103.196C9.52984 106.038 13.1088 107.848 17.1037 107.848C17.9289 107.848 18.5979 108.517 18.5979 109.342C18.5979 110.168 17.9289 110.837 17.1037 110.837C12.8295 110.837 8.94453 109.149 6.0708 106.41C6.29233 105.281 6.65212 104.202 7.14001 103.196Z"
        fill="#FE646F"
      />
      <path
        d="M64.9332 107.273C64.9332 106.448 64.2642 105.779 63.4391 105.779C59.0031 105.779 55.0476 107.865 52.4942 111.105V91.406C52.4942 87.6969 54.3505 84.2693 57.4599 82.2369C58.1506 81.7855 58.3445 80.8595 57.893 80.1688C57.4414 79.4779 56.5154 79.2843 55.8249 79.7357C54.5364 80.578 53.4182 81.6098 52.4942 82.7798V58.3902C52.4942 54.6812 54.3505 51.2536 57.4599 49.2212C58.1506 48.7698 58.3445 47.8438 57.893 47.1531C57.4414 46.4622 56.5154 46.2686 55.8249 46.72C54.5364 47.5623 53.4182 48.594 52.4942 49.7641V40.2217C51.9398 40.8163 51.4389 41.462 51.0001 42.1507C50.5612 41.4618 50.0603 40.8163 49.5059 40.2217V49.7641C48.5821 48.5938 47.4639 47.5623 46.1752 46.72C45.4845 46.2686 44.5587 46.4624 44.1071 47.1531C43.6557 47.8438 43.8495 48.7698 44.5402 49.2212C47.6496 51.2536 49.5059 54.6812 49.5059 58.3902V82.7796C48.5821 81.6094 47.4639 80.5778 46.1752 79.7355C45.4845 79.2841 44.5587 79.4781 44.1071 80.1686C43.6557 80.8593 43.8495 81.7853 44.5402 82.2367C47.6496 84.2691 49.5059 87.6967 49.5059 91.4058V111.105C46.9525 107.865 42.997 105.778 38.561 105.778C37.7359 105.778 37.0669 106.447 37.0669 107.273C37.0669 108.098 37.7359 108.767 38.561 108.767C44.596 108.767 49.5059 113.677 49.5059 119.712V133.013C50.0605 132.376 50.562 131.692 51.0001 130.964C51.4381 131.692 51.9396 132.376 52.4942 133.013V119.712C52.4942 113.677 57.4041 108.767 63.4391 108.767C64.2642 108.767 64.9332 108.098 64.9332 107.273Z"
        fill="#FE646F"
      />
    </g>
    <g clipPath="url(#clip1_1168_41636)">
      <path
        d="M214.673 40.4394C215.973 47.8637 210.273 51.6808 208.896 46.2902C208.896 46.2902 206.178 47.0775 204.199 45.0732C202.22 43.0688 202.997 40.3173 202.997 40.3173C197.674 38.9226 201.443 33.1499 208.775 34.4665L214.673 40.4394Z"
        fill="#F39BFF"
      />
      <path
        d="M214.673 40.4395L213.083 38.83C214.026 44.2151 211.287 47.7008 209.185 47.1533C210.925 51.214 215.896 47.4231 214.673 40.4395Z"
        fill="#EB76E4"
      />
      <path
        d="M240.432 14.3536C247.764 15.6702 251.533 9.89807 246.21 8.50281C246.21 8.50281 246.988 5.75196 245.009 3.74695C243.029 1.74256 240.312 2.53053 240.312 2.53053C238.934 -2.86009 233.235 0.957042 234.535 8.38135L240.432 14.3536Z"
        fill="#F39BFF"
      />
      <path
        d="M247.062 8.79578C247.603 10.9247 244.161 13.6993 238.843 12.7441L240.432 14.3536C247.328 15.592 251.072 10.5585 247.062 8.79578Z"
        fill="#EB76E4"
      />
      <path
        d="M221.415 22.218L212.701 11.3269L210.907 10.4303C206.93 14.4257 204.839 20.0067 205.195 25.6683C205.195 25.6683 205.195 25.6683 205.196 25.6689L208.65 30.4626L219.453 28.8128L224.604 24.4104L221.415 22.218Z"
        fill="#FF8C72"
      />
      <path
        d="M224.604 24.4104L220.116 30.0989L211.704 35.8002L210.798 38.2806C214.744 42.3077 220.255 44.4256 225.846 44.0643C225.846 44.0643 225.846 44.0643 225.847 44.0637L230.12 38.9068L227.95 27.3718L224.604 24.4104Z"
        fill="#FF8C72"
      />
      <path
        d="M241.452 19.8199L231.117 18.9197L227.095 21.4996L224.604 24.4104L227.95 27.3718L238.301 38.3905C242.278 34.3945 244.369 28.8141 244.012 23.1525C244.012 23.1525 244.012 23.1525 244.012 23.1512L241.452 19.8199Z"
        fill="#FF8C72"
      />
      <path
        d="M223.361 4.75652C223.361 4.75652 223.361 4.75652 223.361 4.75652L217.593 8.35087L218.95 16.8756L221.415 22.218L224.604 24.4104L227.095 21.4997L231.117 18.9197L238.409 10.5396C234.463 6.5125 228.952 4.39458 223.361 4.75652Z"
        fill="#FF8C72"
      />
      <path
        d="M178.358 49.1125C171.254 49.5068 167.308 44.3695 173.095 42.8478C173.095 42.8478 171.631 40.4486 173.49 38.5656C175.35 36.6827 177.719 38.1652 177.719 38.1652C179.222 32.3053 184.295 36.3006 183.905 43.4948L178.358 49.1125Z"
        fill="#FF8C72"
      />
      <path
        d="M171.946 43.252C167.924 45.116 171.791 49.4775 178.358 49.1125L179.947 47.5024C174.904 47.7832 171.453 45.2747 171.946 43.252Z"
        fill="#F2564C"
      />
      <path
        d="M200.811 71.8493C200.421 79.0429 205.494 83.0388 206.997 77.1782C206.997 77.1782 209.366 78.6608 211.225 76.7778C213.084 74.8955 211.62 72.4962 211.62 72.4962C217.407 70.974 213.462 65.8367 206.357 66.2316L200.811 71.8493Z"
        fill="#FF8C72"
      />
      <path
        d="M202.4 70.2391L200.811 71.8492C200.45 78.4984 204.757 82.4144 206.598 78.3415C204.6 78.8408 202.123 75.3465 202.4 70.2391Z"
        fill="#F2564C"
      />
      <path
        d="M195.106 46.6424L184.452 47.2473L177.745 51.8493C177.725 52.1038 177.716 52.3583 177.716 52.6116C177.716 55.1122 178.656 57.611 180.541 59.5184L181.09 60.0756L195.146 60.4955L202.638 54.2693L195.106 46.6424Z"
        fill="#A1D3E8"
      />
      <path
        d="M206.991 63.1542L195.146 60.4955L188.622 67.7026L190.534 69.6386C194.301 73.4527 200.409 73.4527 204.175 69.6386C205.952 67.8399 206.891 65.512 206.991 63.1542Z"
        fill="#A1D3E8"
      />
      <path
        d="M230.102 38.808C229.87 38.9246 229.637 39.0387 229.401 39.1473C227.209 40.1453 224.932 40.6982 222.667 40.8447C218.524 41.1127 214.427 40.0171 210.976 37.7936L210.799 38.2806C213.969 41.5167 218.239 43.5638 222.719 44.01C223.753 44.1125 224.798 44.1321 225.847 44.0637L230.12 38.9069L230.102 38.808Z"
        fill="#F2564C"
      />
      <path
        d="M244.012 23.1519L241.452 19.8199L240.82 19.765C240.824 19.8206 240.829 19.8767 240.833 19.9329C241.187 25.5566 239.125 31.0998 235.2 35.0897L238.301 38.3899C240.662 36.0174 242.358 33.0865 243.273 29.9115C243.901 27.7264 244.157 25.423 244.012 23.1519Z"
        fill="#F2564C"
      />
      <path
        d="M238.301 38.3905C236.69 40.0097 234.771 41.3684 232.579 42.3663C230.388 43.3642 228.111 43.9172 225.847 44.0637C225.91 43.9435 230.771 34.7858 224.604 24.4104C224.604 24.4104 234.196 24.9658 238.301 38.3905Z"
        fill="#FFEBB4"
      />
      <path
        d="M238.039 10.9656L238.409 10.5402C238.138 10.2637 237.859 9.99512 237.572 9.73694C237.277 9.47082 236.974 9.21265 236.664 8.96484C237.158 9.59656 237.618 10.2637 238.039 10.9656Z"
        fill="#F2564C"
      />
      <path
        d="M242.336 16.3336C243.321 18.5528 243.867 20.8587 244.012 23.1518C243.893 23.0871 234.849 18.1653 224.604 24.4104C224.604 24.4104 225.152 14.6966 238.409 10.5402C240.008 12.1704 241.35 14.1144 242.336 16.3336Z"
        fill="#FFEBB4"
      />
      <path
        d="M224.604 24.4104C224.604 24.4104 215.012 23.855 210.907 10.4303C212.517 8.81103 214.437 7.45239 216.628 6.45386C218.82 5.45593 221.096 4.90295 223.361 4.75647C223.297 4.87732 218.437 14.035 224.604 24.4104Z"
        fill="#FFEBB4"
      />
      <path
        d="M224.604 24.4104C224.604 24.4104 224.055 34.1235 210.798 38.2806C209.199 36.6498 207.858 34.7058 206.872 32.4866C205.886 30.2673 205.341 27.962 205.196 25.6689C205.315 25.7336 214.359 30.6555 224.604 24.4104Z"
        fill="#FFEBB4"
      />
      <path
        d="M244.012 23.1518C243.867 20.8588 243.321 18.5528 242.336 16.3336C241.35 14.1144 240.008 12.1704 238.409 10.5402C238.231 10.5957 238.055 10.6537 237.882 10.7111C238.354 11.4716 238.782 12.2717 239.156 13.1146C240.142 15.3339 240.688 17.6392 240.833 19.9322C240.833 19.9328 240.833 19.9328 240.833 19.9328C240.876 20.6122 240.883 21.2897 240.856 21.9635C242.802 22.4939 243.97 23.1286 244.012 23.1518Z"
        fill="#FFBA32"
      />
      <path
        d="M236.423 33.7231C236.015 34.2249 235.581 34.7089 235.122 35.1709C232.922 37.381 230.19 39.0399 227.235 39.9811C226.687 42.4805 225.872 44.0149 225.847 44.0637C226.413 44.0277 226.979 43.9654 227.546 43.8775C231.584 43.2495 235.402 41.3061 238.301 38.3905C237.759 36.6193 237.121 35.0745 236.423 33.7231Z"
        fill="#FFBA32"
      />
      <path
        d="M209.365 36.637C209.669 37.0258 209.987 37.4054 210.319 37.7698C210.476 37.9419 210.634 38.1122 210.798 38.2806C211.012 38.2141 211.221 38.1457 211.427 38.0762C210.712 37.6428 210.023 37.1631 209.365 36.637Z"
        fill="#FFBA32"
      />
      <path
        d="M183.719 56.2994C181.928 54.4854 181.003 52.0812 180.941 49.6563L177.79 51.8182C177.575 54.5788 178.483 57.42 180.518 59.4952C180.526 59.5031 180.533 59.511 180.54 59.5184L181.09 60.0756L187.642 60.2715L183.719 56.2994Z"
        fill="#75BDDB"
      />
      <path
        d="M195.106 46.6424C190.494 48.5644 184.412 50.6707 177.744 51.8493C177.916 49.6093 178.849 47.4176 180.54 45.7049C184.307 41.8908 190.415 41.8908 194.181 45.7049L195.106 46.6424Z"
        fill="#FFEBB4"
      />
      <path
        d="M193.714 66.4196L191.663 64.3431L188.622 67.7025L190.534 69.6385C194.301 73.4526 200.409 73.4526 204.175 69.6385C204.302 69.5104 204.423 69.3791 204.541 69.2461C204.575 69.207 204.608 69.1674 204.641 69.1277C204.725 69.03 204.807 68.9311 204.887 68.831C204.921 68.7877 204.955 68.7438 204.988 68.7004C205.068 68.5955 205.145 68.4899 205.221 68.3824C205.246 68.3458 205.272 68.3098 205.298 68.2732C205.395 68.1291 205.491 67.9839 205.58 67.8362C201.863 70.1506 196.933 69.68 193.714 66.4196Z"
        fill="#75BDDB"
      />
      <path
        d="M206.991 63.1542C201.837 65.086 195.428 66.9616 188.622 67.7026L181.09 60.0756C189.376 59.1735 197.075 56.5881 202.638 54.2693L204.175 55.8257C206.058 57.7319 207 60.2319 207 62.7325C207 62.8735 206.997 63.0138 206.991 63.1542Z"
        fill="#FFEBB4"
      />
      <path
        d="M194.166 66.8462C194.012 66.7089 193.86 66.5679 193.714 66.4196L186.61 59.226C184.812 59.58 182.966 59.8712 181.091 60.0756L185.537 64.5788L188.622 67.7026C190.507 67.4975 192.36 67.2027 194.166 66.8462Z"
        fill="#FFBA32"
      />
      <path
        d="M181.094 51.1699C180.68 48.8268 181.093 46.35 182.337 44.2742C182.185 44.3682 182.036 44.467 181.888 44.5702C181.871 44.5818 181.854 44.5934 181.838 44.6056C181.71 44.6959 181.584 44.7911 181.459 44.8888C181.431 44.9108 181.402 44.9327 181.373 44.9553C181.259 45.0469 181.148 45.1421 181.037 45.2397C181.006 45.2672 180.974 45.2935 180.942 45.3215C180.806 45.4448 180.671 45.5724 180.54 45.7048C180.426 45.8208 180.316 45.9392 180.208 46.0594C180.2 46.0686 180.193 46.0778 180.185 46.0869C178.744 47.7172 177.946 49.7563 177.788 51.8407C178.909 51.6423 180.011 51.4159 181.094 51.1699Z"
        fill="#FFBA32"
      />
    </g>
    <g clipPath="url(#clip2_1168_41636)">
      <path
        d="M234.971 161.834C237.827 156.593 240 148.869 240 139.379C240 117.31 228.051 114 223.707 114C217.95 114 212.475 115.512 208.5 119.815C205.643 116.571 200.636 114 193.293 114C188.948 114 177 117.31 177 139.379C176.957 146.4 178.344 153.354 181.073 159.804C181.236 160.168 181.388 160.51 181.529 160.808C181.676 161.149 181.843 161.481 182.029 161.801C180.304 163.063 179.385 165.174 179.628 167.319C180.019 170.452 183.517 172.483 187.862 170.276C199.778 180.504 217.221 180.504 229.138 170.276L228.844 170.121C229.268 170.331 229.692 170.541 230.104 170.717C233.091 171.986 236.904 170.783 237.339 167.308C237.572 165.184 236.669 163.096 234.971 161.834ZM193.228 154.75C192.847 153.967 186.776 146.177 186.776 137.172C186.776 128.345 191.12 125.034 194.379 125.034C197.638 125.034 203.069 126.138 203.069 139.379C203.069 145.393 198.365 150.723 193.228 154.75ZM203.644 161.448C202.955 161.119 202.284 160.751 201.635 160.345C204.181 157.906 206.48 155.215 208.5 152.312C210.506 155.242 212.803 157.956 215.354 160.411C211.896 162.807 207.459 163.2 203.644 161.448ZM224.619 153.448C224.619 153.448 223.815 154.651 223.783 154.695C218.949 150.977 213.931 145.592 213.931 139.379C213.808 136.075 214.344 132.78 215.506 129.691C216.612 126.719 219.499 124.829 222.62 125.034C229.138 125.034 234.884 137.338 224.619 153.492V153.448Z"
        fill="#FFBA32"
      />
      <path
        d="M208.239 152.676C207.74 153.437 207.153 154.177 206.621 154.883L208.239 152.676Z"
        fill="#E4C05C"
      />
      <path
        d="M207.881 119.186C207.707 119.01 207.533 118.855 207.36 118.701L207.881 119.186Z"
        fill="#F5EFCA"
      />
      <path
        d="M229.138 170.276C228.541 170.806 227.911 171.313 227.259 171.788C222.169 169.016 217.486 165.536 213.344 161.448C214.043 161.131 214.719 160.762 215.365 160.345C217.238 162.15 219.226 163.827 221.317 165.366C223.774 167.237 226.391 168.88 229.138 170.276Z"
        fill="#FF9E5C"
      />
      <path
        d="M205.024 116.946C205.215 117.057 205.4 117.179 205.578 117.31L205.024 116.946Z"
        fill="#F5EFCA"
      />
      <path
        d="M203.645 161.448C199.504 165.556 194.812 169.048 189.709 171.821C189.057 171.379 188.427 170.828 187.862 170.276C192.911 167.659 197.561 164.314 201.668 160.345C202.306 160.75 202.966 161.118 203.645 161.448Z"
        fill="#FF9E5C"
      />
      <path
        d="M214.876 144.091C213.977 146.979 212.71 149.735 211.107 152.29C210.499 153.283 210.021 153.934 209.847 154.199C209.38 153.581 208.934 152.952 208.5 152.312C211.66 147.876 213.541 142.633 213.931 137.172C214.083 137.172 213.931 136.919 213.931 139.379C213.946 140.997 214.267 142.597 214.876 144.091Z"
        fill="#FF9E5C"
      />
      <path
        d="M215.506 129.691C214.546 132.067 214.031 134.604 213.985 137.172H213.931C214.116 130.993 212.845 124.593 208.5 119.826C208.994 119.286 209.524 118.781 210.086 118.314C210.699 118.95 211.258 119.636 211.759 120.367C213.637 123.174 214.914 126.35 215.506 129.691Z"
        fill="#FF9E5C"
      />
      <path
        d="M182.431 132.759C183.031 132.759 183.517 132.265 183.517 131.655C183.517 131.046 183.031 130.552 182.431 130.552C181.831 130.552 181.345 131.046 181.345 131.655C181.345 132.265 181.831 132.759 182.431 132.759Z"
        fill="#F5EFCA"
      />
      <path
        d="M184.604 127.241C185.203 127.241 185.69 126.747 185.69 126.138C185.69 125.529 185.203 125.034 184.604 125.034C184.004 125.034 183.517 125.529 183.517 126.138C183.517 126.747 184.004 127.241 184.604 127.241Z"
        fill="#F5EFCA"
      />
      <path
        d="M186.776 121.724C187.376 121.724 187.862 121.23 187.862 120.621C187.862 120.011 187.376 119.517 186.776 119.517C186.176 119.517 185.69 120.011 185.69 120.621C185.69 121.23 186.176 121.724 186.776 121.724Z"
        fill="#F5EFCA"
      />
      <path
        d="M191.121 122.828C191.721 122.828 192.207 122.334 192.207 121.724C192.207 121.115 191.721 120.621 191.121 120.621C190.521 120.621 190.035 121.115 190.035 121.724C190.035 122.334 190.521 122.828 191.121 122.828Z"
        fill="#F5EFCA"
      />
      <path
        d="M194.379 119.517C194.979 119.517 195.466 119.023 195.466 118.414C195.466 117.804 194.979 117.31 194.379 117.31C193.78 117.31 193.293 117.804 193.293 118.414C193.293 119.023 193.78 119.517 194.379 119.517Z"
        fill="#F5EFCA"
      />
      <path
        d="M208.5 131.655C209.1 131.655 209.586 131.161 209.586 130.552C209.586 129.942 209.1 129.448 208.5 129.448C207.9 129.448 207.414 129.942 207.414 130.552C207.414 131.161 207.9 131.655 208.5 131.655Z"
        fill="#F5EFCA"
      />
      <path
        d="M198.724 122.828C199.324 122.828 199.811 122.334 199.811 121.724C199.811 121.115 199.324 120.621 198.724 120.621C198.124 120.621 197.638 121.115 197.638 121.724C197.638 122.334 198.124 122.828 198.724 122.828Z"
        fill="#F5EFCA"
      />
      <path
        d="M234.569 132.759C235.169 132.759 235.655 132.265 235.655 131.655C235.655 131.046 235.169 130.552 234.569 130.552C233.969 130.552 233.483 131.046 233.483 131.655C233.483 132.265 233.969 132.759 234.569 132.759Z"
        fill="#F5EFCA"
      />
      <path
        d="M232.397 127.241C232.997 127.241 233.483 126.747 233.483 126.138C233.483 125.529 232.997 125.034 232.397 125.034C231.797 125.034 231.311 125.529 231.311 126.138C231.311 126.747 231.797 127.241 232.397 127.241Z"
        fill="#F5EFCA"
      />
      <path
        d="M230.224 121.724C230.824 121.724 231.311 121.23 231.311 120.621C231.311 120.011 230.824 119.517 230.224 119.517C229.624 119.517 229.138 120.011 229.138 120.621C229.138 121.23 229.624 121.724 230.224 121.724Z"
        fill="#F5EFCA"
      />
      <path
        d="M225.879 122.828C226.479 122.828 226.966 122.334 226.966 121.724C226.966 121.115 226.479 120.621 225.879 120.621C225.28 120.621 224.793 121.115 224.793 121.724C224.793 122.334 225.28 122.828 225.879 122.828Z"
        fill="#F5EFCA"
      />
      <path
        d="M222.621 119.517C223.221 119.517 223.707 119.023 223.707 118.414C223.707 117.804 223.221 117.31 222.621 117.31C222.021 117.31 221.535 117.804 221.535 118.414C221.535 119.023 222.021 119.517 222.621 119.517Z"
        fill="#F5EFCA"
      />
      <path
        d="M218.276 122.828C218.876 122.828 219.362 122.334 219.362 121.724C219.362 121.115 218.876 120.621 218.276 120.621C217.676 120.621 217.19 121.115 217.19 121.724C217.19 122.334 217.676 122.828 218.276 122.828Z"
        fill="#F5EFCA"
      />
      <path
        d="M205.241 127.241C205.841 127.241 206.328 126.747 206.328 126.138C206.328 125.529 205.841 125.034 205.241 125.034C204.642 125.034 204.155 125.529 204.155 126.138C204.155 126.747 204.642 127.241 205.241 127.241Z"
        fill="#F5EFCA"
      />
      <path
        d="M207.414 136.069C208.014 136.069 208.5 135.575 208.5 134.966C208.5 134.356 208.014 133.862 207.414 133.862C206.814 133.862 206.328 134.356 206.328 134.966C206.328 135.575 206.814 136.069 207.414 136.069Z"
        fill="#F5EFCA"
      />
      <path
        d="M204.155 122.828C204.755 122.828 205.242 122.334 205.242 121.724C205.242 121.115 204.755 120.621 204.155 120.621C203.555 120.621 203.069 121.115 203.069 121.724C203.069 122.334 203.555 122.828 204.155 122.828Z"
        fill="#F5EFCA"
      />
      <path
        d="M181.519 160.819C181.774 160.646 182.039 160.488 182.311 160.345L181.519 160.852V160.819Z"
        fill="#B19046"
      />
      <path
        d="M192.522 153.879C189.329 156.342 185.922 158.507 182.344 160.345L192.522 153.879Z"
        fill="#B19046"
      />
      <path
        d="M193.217 154.728C189.96 157.25 186.485 159.466 182.833 161.349C182.556 161.493 182.291 161.659 182.04 161.846C181.899 161.581 181.736 161.261 181.54 160.863C181.399 160.566 181.247 160.223 181.084 159.859C184.876 157.864 188.508 155.569 191.946 152.996H192.022C192.294 153.36 192.891 154.298 193.217 154.728Z"
        fill="#B19046"
      />
      <path
        d="M192.011 152.897H191.935C188.502 155.478 184.87 157.773 181.073 159.76C181.236 160.124 181.388 160.466 181.529 160.764C181.725 161.161 181.888 161.481 182.029 161.746C182.28 161.56 182.545 161.394 182.822 161.25C186.474 159.367 189.949 157.151 193.206 154.629C192.891 154.298 192.305 153.36 192.011 152.897Z"
        fill="#FF9E5C"
      />
      <path
        d="M224.978 152.919H225.054C228.487 155.5 232.119 157.795 235.916 159.782C235.753 160.146 235.601 160.488 235.46 160.786C235.264 161.183 235.101 161.503 234.96 161.768C234.709 161.582 234.444 161.416 234.167 161.272C230.516 159.389 227.04 157.173 223.783 154.651C224.098 154.309 224.685 153.382 224.978 152.919Z"
        fill="#FF9E5C"
      />
    </g>
    <line
      x1="101.707"
      y1="64.0439"
      x2="157.826"
      y2="46.8391"
      stroke="#A0AEC0"
      strokeWidth="2"
    />
    <line
      x1="102.321"
      y1="126.053"
      x2="158.321"
      y2="145.053"
      stroke="#A0AEC0"
      strokeWidth="2"
    />
    <rect width="16" height="24" transform="translate(128 41)" fill="white" />
    <path
      d="M135.023 55.5795V55.4318C135.027 54.7462 135.091 54.2008 135.216 53.7955C135.345 53.3902 135.527 53.0644 135.761 52.8182C135.996 52.5682 136.282 52.339 136.619 52.1307C136.85 51.9867 137.057 51.8295 137.239 51.6591C137.424 51.4848 137.57 51.2917 137.676 51.0795C137.782 50.8636 137.835 50.6231 137.835 50.358C137.835 50.0587 137.765 49.7992 137.625 49.5795C137.485 49.3598 137.295 49.1894 137.057 49.0682C136.822 48.947 136.559 48.8864 136.267 48.8864C135.998 48.8864 135.742 48.9451 135.5 49.0625C135.261 49.1761 135.063 49.3504 134.903 49.5852C134.748 49.8163 134.661 50.1098 134.642 50.4659H132.619C132.638 49.7462 132.813 49.1439 133.142 48.6591C133.475 48.1742 133.915 47.8106 134.46 47.5682C135.009 47.3258 135.616 47.2045 136.278 47.2045C137.002 47.2045 137.638 47.3314 138.188 47.5852C138.741 47.839 139.17 48.2008 139.477 48.6705C139.788 49.1364 139.943 49.6894 139.943 50.3295C139.943 50.7614 139.873 51.1477 139.733 51.4886C139.597 51.8295 139.402 52.1326 139.148 52.3977C138.894 52.6629 138.593 52.8996 138.244 53.108C137.938 53.2973 137.686 53.4943 137.489 53.6989C137.295 53.9034 137.152 54.1439 137.057 54.4205C136.966 54.6932 136.919 55.0303 136.915 55.4318V55.5795H135.023ZM136.011 59.125C135.67 59.125 135.377 59.0038 135.131 58.7614C134.884 58.5189 134.761 58.2235 134.761 57.875C134.761 57.5341 134.884 57.2424 135.131 57C135.377 56.7576 135.67 56.6364 136.011 56.6364C136.348 56.6364 136.64 56.7576 136.886 57C137.136 57.2424 137.261 57.5341 137.261 57.875C137.261 58.1061 137.203 58.3163 137.085 58.5057C136.972 58.6951 136.82 58.8466 136.631 58.9602C136.445 59.0701 136.239 59.125 136.011 59.125Z"
      fill="#2D3748"
    />
    <rect width="16" height="24" transform="translate(128 124)" fill="white" />
    <path
      d="M135.023 138.58V138.432C135.027 137.746 135.091 137.201 135.216 136.795C135.345 136.39 135.527 136.064 135.761 135.818C135.996 135.568 136.282 135.339 136.619 135.131C136.85 134.987 137.057 134.83 137.239 134.659C137.424 134.485 137.57 134.292 137.676 134.08C137.782 133.864 137.835 133.623 137.835 133.358C137.835 133.059 137.765 132.799 137.625 132.58C137.485 132.36 137.295 132.189 137.057 132.068C136.822 131.947 136.559 131.886 136.267 131.886C135.998 131.886 135.742 131.945 135.5 132.062C135.261 132.176 135.063 132.35 134.903 132.585C134.748 132.816 134.661 133.11 134.642 133.466H132.619C132.638 132.746 132.813 132.144 133.142 131.659C133.475 131.174 133.915 130.811 134.46 130.568C135.009 130.326 135.616 130.205 136.278 130.205C137.002 130.205 137.638 130.331 138.188 130.585C138.741 130.839 139.17 131.201 139.477 131.67C139.788 132.136 139.943 132.689 139.943 133.33C139.943 133.761 139.873 134.148 139.733 134.489C139.597 134.83 139.402 135.133 139.148 135.398C138.894 135.663 138.593 135.9 138.244 136.108C137.938 136.297 137.686 136.494 137.489 136.699C137.295 136.903 137.152 137.144 137.057 137.42C136.966 137.693 136.919 138.03 136.915 138.432V138.58H135.023ZM136.011 142.125C135.67 142.125 135.377 142.004 135.131 141.761C134.884 141.519 134.761 141.223 134.761 140.875C134.761 140.534 134.884 140.242 135.131 140C135.377 139.758 135.67 139.636 136.011 139.636C136.348 139.636 136.64 139.758 136.886 140C137.136 140.242 137.261 140.534 137.261 140.875C137.261 141.106 137.203 141.316 137.085 141.506C136.972 141.695 136.82 141.847 136.631 141.96C136.445 142.07 136.239 142.125 136.011 142.125Z"
      fill="#2D3748"
    />
    <defs>
      <clipPath id="clip0_1168_41636">
        <rect
          width="102"
          height="102"
          fill="white"
          transform="translate(0 36)"
        />
      </clipPath>
      <clipPath id="clip1_1168_41636">
        <rect width="79" height="80" fill="white" transform="translate(170)" />
      </clipPath>
      <clipPath id="clip2_1168_41636">
        <rect
          width="63"
          height="64"
          fill="white"
          transform="translate(177 114)"
        />
      </clipPath>
    </defs>
  </svg>
);
