import React from 'react';

import { Stepper, Step, StepLabel, StepContent } from '@mui/material';

import moment from 'moment';

import { PaymentPendingCircle } from '../../assets/iconComponents/PaymentPendingCircle';
import { PaymentCompleteCircle } from '../../assets/iconComponents/PaymentCompleteCircle';
import {
  UpfrontPaymentPlan,
  getCentsToDollars,
} from '../UpfrontPayment/Services/UpfrontPaymentService';
import {
  UpfrontPaymentStepperRow,
  UpfrontPaymentStepperRowDate,
  UpfrontPaymentStepperRowCost,
  UpfrontPaymentStepperWrapper,
} from '../UpfrontPayment/UpfrontPayment.styles';

export const BabyPeekPaymentPlanSteps: React.FC<{
  steps: UpfrontPaymentPlan[];
}> = ({ steps }) => {
  const stepComponents = steps?.map((payment, index) => ({
    component: (
      <UpfrontPaymentStepperRow>
        <UpfrontPaymentStepperRowDate>
          {index === 0 && payment.status === 'draft' ? ' Today, ' : ''}{' '}
          {moment(new Date(payment.epoch * 1000)).format('MMMM Do, YYYY')}{' '}
        </UpfrontPaymentStepperRowDate>{' '}
        <UpfrontPaymentStepperRowCost>{`${getCentsToDollars(
          payment.amount
        )}`}</UpfrontPaymentStepperRowCost>
      </UpfrontPaymentStepperRow>
    ),
    payment,
  }));

  return (
    <>
      <UpfrontPaymentStepperWrapper>
        <Stepper activeStep={0} orientation="vertical">
          {stepComponents?.map((step) => (
            <Step key={step.payment.epoch}>
              <StepLabel
                StepIconComponent={
                  step.payment.status === 'paid'
                    ? PaymentCompleteCircle
                    : PaymentPendingCircle
                }
              >
                {step.component}
              </StepLabel>
              <StepContent />
            </Step>
          ))}
        </Stepper>
      </UpfrontPaymentStepperWrapper>
    </>
  );
};
