import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { ResetPasswordCenterContainerPaddedTop } from './ResetPassword.styles';
import { LOGIN_PAGE_URL, RESET_PASSWORD_URL } from '../../utils/urlConstants';
import { resetPwEmail } from '../../recoil/atoms';

export const ResetPasswordSentContent = (): React.ReactElement => {
  const [resetEmail, setResetEmail] = useRecoilState(resetPwEmail);
  const history = useHistory();

  useEffect(() => {
    if (!resetEmail) {
      history.push(LOGIN_PAGE_URL);
    }
  }, []);

  return (
    <>
      {resetEmail && (
        <ResetPasswordCenterContainerPaddedTop>
          {GetTypographyContent({
            content: 'Email sent',
            size: 'h3',
            isBold: true,
            textColor: 'neutral700',
          })}
          {GetTypographyContent({
            content: 'We have sent an email with a recovery link to',
            size: 'medium',
            padding: '30px 0 0 0',
            textColor: 'neutral700',
          })}
          {GetTypographyContent({
            content: resetEmail,
            size: 'medium',
            isBold: true,
            padding: '3px 0 0 0',
            textColor: 'greenDark',
          })}
          {GetTypographyContent({
            content: "Didn't receive the email?",
            size: 'small',
            textColor: 'greenDark',
            padding: '30px 0 0 0',
            link: RESET_PASSWORD_URL,
            onClick: () => setResetEmail(undefined),
          })}
        </ResetPasswordCenterContainerPaddedTop>
      )}
    </>
  );
};
