import React from 'react';

import { useWindowWidth } from '@react-hook/window-size';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from '../Shared/BabyPeekTrait.styles';
import CilantroBackground from './CilantroBackground.png';
import CilantroIcon from './CilantroIcon.png';

import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from '../../Services/BabyPeekService';
import { useDeviceHook } from '../../../../utils/hooks/useDeviceHook';
import { TraitNavigationLeft } from '../Shared/TraitNavigationLeft';
import { TraitNavigationRight } from '../Shared/TraitNavigationRight';
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from '../../BabyPeekStripe.styles';
import { BabyPeekButton } from '../../BabyPeekButton';
import { TraitMenu } from '../Shared/TraitMenu';
import { BabyPeekTraitAccordion } from '../Shared/BabyPeekTraitAccordion';
import { CilantroGene } from './CilantroGene';
import { CilantroHowItWorks } from './CilantroHowItWorks';
import { CilantroOtherFactors } from './CilantroOtherFactors';
import { BabyPeekGauge } from '../Shared/BabyPeekGauge';
import { ShareUnderline } from '../../BabyPeekIcons/ShareUnderline';
import { BabyPeekSingleFeedback } from '../../BabyPeekSingleFeedback';

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      Why do some people dislike cilantro?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      {`Cilantro is a divisive herb – some feel it tastes like soap while others love the flavor. This is actually due to cilantro's smell! Smell is closely tied to our sense of taste. `}
      <br />
      Aldehydes, naturally occurring chemicals found in some foods, are what
      likely most affect cilantro’s taste. <span>Aldehydes</span> often used in
      soaps and lotions are also found in cilantro -- and certain people are
      more sensitive to the smell of these aldehydes than others!
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        {' '}
        <CilantroHowItWorks />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
    <BabyPeekV2SectionTitle>
      How did we predict if your baby is more or less likely to dislike
      cilantro?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      We looked at a single genetic variation in a gene that affects if our
      olfactory receptors (tiny structures in our nose that detect smells) can
      detect the aldehydes in cilantro that are also found in soaps and lotions.
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <CilantroGene />{' '}
      </div>
      <br />
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect our senses of taste and smell?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect our perception of cilantro include:
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        {' '}
        <CilantroOtherFactors />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{' '}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${'(650) 460-2551'}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const CilantroTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData?: BabyPeekTraitResponse;
  setShareModal?: (share: boolean) => void;
  publicTrait?: boolean;
  hideTraitMenu: boolean;
  cilantroVal?: number;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  cilantroVal = 0,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== 'desktop'
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const cilantroTraits = [
    {
      color: '#7A7A7A',
      gradient: 'linear-gradient(90deg, #E7E7E7 0%, #797979 98.96%)',
      name: 'Dislike',
      percentage: traitData
        ? convertPercentage(traitData.aversion_to_cilantro_prob)
        : convertPercentage(cilantroVal),
    },
  ].sort((a, b) => b.percentage - a.percentage);
  return (
    <>
      {' '}
      <div
        style={{
          backgroundImage: `url(${CilantroBackground})`,
          height: '315px',
          backgroundSize: 'cover',
          padding: '20px',
          display: 'grid',
          justifyContent: deviceType !== 'mobile' ? 'center' : 'flex-start',
          paddingBottom: '32px',
          paddingTop: '24px',
          gridGap: '12px',
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={CilantroIcon}
              alt="CilantroIcon"
              style={{ justifySelf: 'center' }}
            />
            <div>Cilantro Aversion</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {' '}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>

        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? 'The baby is more likely to'
              : 'Your baby is more likely to'}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${
              cilantroTraits[0].percentage >= 50 ? '' : 'not'
            } think cilantro tastes like soap`}{' '}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div style={{ paddingBottom: '10px' }} />
        <BabyPeekGauge
          showGif={modalPreview || showAnimation}
          percentage={cilantroTraits[0].percentage}
          bottomText="chance think cilantro tastes like soap"
        />
        {!modalPreview && (
          <>
            {' '}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: '25px',
                paddingTop: '0px',
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'How it works',
                    content: HowItWorks,
                    key: 'howItWorksEye',
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'Other factors',
                    content: OtherFactors,
                    key: 'otherFactorsEye',
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'About BabyPeek',
                    content: AboutBabyPeek,
                    key: 'aboutBabyPeekEye',
                  },
                ]}
              />
            </div>{' '}
          </>
        )}
        {!modalPreview && !cilantroVal && (
          <BabyPeekSingleFeedback onlyFeedback />
        )}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu &&
            setCurrentTrait &&
            TraitMenu(setCurrentTrait, 'Cilantro')}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait && setCurrentTrait('Bitter')}
            >
              <TraitNavigationLeft />
            </BabyPeekTraitNavigationButtonWrapper>{' '}
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? ' Hide Traits' : 'View Traits'}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait && setCurrentTrait('EyeColor')}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
