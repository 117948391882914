import * as React from 'react';

export const PaymentCompleteCircle = (): React.ReactElement => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14.75C3.11719 14.75 0 11.6328 0 7.75C0 3.89453 3.11719 0.75 7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75ZM10.0898 6.46484H10.0625C10.3359 6.21875 10.3359 5.80859 10.0625 5.53516C9.81641 5.28906 9.40625 5.28906 9.16016 5.53516L6.125 8.59766L4.83984 7.3125C4.56641 7.03906 4.15625 7.03906 3.91016 7.3125C3.63672 7.55859 3.63672 7.96875 3.91016 8.21484L5.66016 9.96484C5.90625 10.2383 6.31641 10.2383 6.58984 9.96484L10.0898 6.46484Z"
      fill="#60C66A"
    />
  </svg>
);
