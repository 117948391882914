import React from 'react';
import {
  HeaderContainer,
  HeaderIcon,
  HeaderIconsContainer,
  HeaderIconsLinksContainer,
} from './Header.styles';
import unityLogo from '../../assets/logos/unity.svg';
import byIcon from '../../assets/logos/by.svg';
import poweredByBTOLogo from '../../assets/logos/billionToOne.svg';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const BlankHeader: React.FC<{ link: string }> = ({ link }) => {
  const { deviceType } = useDeviceHook();
  return (
    <>
      <HeaderContainer>
        <HeaderIconsLinksContainer>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex' }}
          >
            <HeaderIconsContainer
              data-testid="HeaderIcons"
              paddingLeft={deviceType === 'mobile' ? 10 : 40}
            >
              {import.meta.env.VITE_IS_USER_TESTING_ENV !== 'true' && (
                <>
                  {' '}
                  <HeaderIcon src={unityLogo} width="156px" />
                  <HeaderIcon src={byIcon} width="15px" />
                  <HeaderIcon src={poweredByBTOLogo} width="112px" />{' '}
                </>
              )}
            </HeaderIconsContainer>
          </a>
        </HeaderIconsLinksContainer>
      </HeaderContainer>
    </>
  );
};
