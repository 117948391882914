import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  CreateAccountDesktopContainer,
  CreateAccountFormDesktopColorbackground,
  CreateAccountFormImage,
  CreateAccountFormVector,
} from '../CreateAccount/CreateAccountForm.styles';
import unityRound from '../../assets/logos/unityRound.svg';
import vector from '../../assets/icons/vector.svg';
import { VerifyEmailConfirmedSection } from './VerifyEmailConfirmedSection';
import VerifyEmailRequest from '../../services/VerifyEmailService';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { RefreshPatientInfo } from '../CreateAccount/Services/CreateAccountService';
import { patientState } from '../../recoil/atoms';
import { LOGIN_PAGE_URL } from '../../utils/urlConstants';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { getCookieAccessToken } from '../../utils/requestUtils';

export const VerifyEmailConfirmed = (): React.ReactElement => {
  const { isDesktop } = useDeviceHook();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { verifyJwt } = useParams<{ verifyJwt: string }>();
  const [, setPatientInfo] = useRecoilState(patientState);

  const sendVerifyEmailCode = async () => {
    const response = await VerifyEmailRequest(verifyJwt);
    if (response && response.ok) {
      const authToken = getCookieAccessToken();
      if (authToken) {
        await RefreshPatientInfo(authToken, setPatientInfo);
      }
      setEmail(await response.json());
    } else {
      setErrorMessage('Email verification failed');
    }
  };

  useEffect(() => {
    if (!verifyJwt) {
      history.push(LOGIN_PAGE_URL);
    }
    sendVerifyEmailCode();
  }, []);

  return (
    <>
      {verifyJwt && isDesktop && (
        <CreateAccountDesktopContainer>
          <CreateAccountFormDesktopColorbackground>
            <ErrorPopup message={errorMessage} hideMessage={setErrorMessage} />
            <CreateAccountFormVector src={vector} />
            <CreateAccountFormImage src={unityRound} width="321px" />
          </CreateAccountFormDesktopColorbackground>
          <VerifyEmailConfirmedSection email={email} />
        </CreateAccountDesktopContainer>
      )}
      {verifyJwt && !isDesktop && (
        <>
          <ErrorPopup message={errorMessage} hideMessage={setErrorMessage} />
          <VerifyEmailConfirmedSection email={email} />{' '}
        </>
      )}
    </>
  );
};
