import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import {
  ProfileInsuranceInsurancePadding,
  PatientBillingBox,
  PolicyOwnerRelationshipText,
  RadioFormControl,
  StyledFormLabel,
} from './ProfileInsuranceModal.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  getRelationshipToInsured,
  getInsuredParameter,
  PatientInsuranceInfo,
} from './helpers';
import ProfileInsuranceFileUploader, {
  FileUploadInfo,
} from './ProfileInsuranceFileUploader';

const ProfileInsuranceUpload: React.FC<{
  isError: boolean;
  patientInsuranceInfo: PatientInsuranceInfo;
  setPatientInsuranceInfo: React.Dispatch<
    React.SetStateAction<PatientInsuranceInfo>
  >;
  upfrontBilling?: 'primary' | 'secondary' | 'medicaid_or_hmo' | undefined;
}> = ({
  isError,
  patientInsuranceInfo,
  setPatientInsuranceInfo,
  upfrontBilling,
}) => {
  const { deviceType } = useDeviceHook();
  const [isFileUploadProcessing, setIsFileUploadProcessing] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        {!upfrontBilling && (
          <FormControlLabel
            value="Upload"
            control={<Radio />}
            label={GetTypographyContent({
              content: 'Upload a digital copy of insurance card',
              size: 'medium',
              textColor: 'greyMedium',
            })}
          />
        )}
      </Grid>
      <ProfileInsuranceInsurancePadding
        $show={patientInsuranceInfo.billToInsuranceType === 'Upload'}
      >
        {!upfrontBilling && (
          <div>
            <StyledFormLabel
              style={{ lineHeight: 2.5, textTransform: 'none' }}
              isError={
                !patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
                !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
                !patientInsuranceInfo.relationshipToPolicyOwnerOther
              }
            >
              <PolicyOwnerRelationshipText>
                {GetTypographyContent({
                  content: "Patient's Relationship to Policy Owner*",
                  size: 'medium',
                  textColor: 'blueDark',
                })}
              </PolicyOwnerRelationshipText>
            </StyledFormLabel>
            <PatientBillingBox>
              <RadioFormControl
                $hideBorder
                error={
                  isError &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerDependent &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerSelf &&
                  !patientInsuranceInfo.relationshipToPolicyOwnerOther
                }
              >
                <RadioGroup
                  value={getRelationshipToInsured(
                    patientInsuranceInfo.relationshipToPolicyOwnerSelf,
                    patientInsuranceInfo.relationshipToPolicyOwnerDependent,
                    patientInsuranceInfo.relationshipToPolicyOwnerOther
                  )}
                  onChange={(e) =>
                    setPatientInsuranceInfo({
                      ...patientInsuranceInfo,
                      relationshipToPolicyOwnerSelf: false,
                      relationshipToPolicyOwnerDependent: false,
                      relationshipToPolicyOwnerOther: false,
                      [getInsuredParameter(
                        e.target.value as 'Self' | 'Dependent' | 'Other'
                      )]: true,
                    })
                  }
                  row={deviceType !== 'mobile'}
                >
                  <Grid container spacing={2} />
                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Self"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: 'Self',
                        size: 'medium',
                        textColor: 'greyMedium',
                      })}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Dependent"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: 'Dependent',
                        size: 'medium',
                        textColor: 'greyMedium',
                      })}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label={GetTypographyContent({
                        content: 'Other',
                        size: 'medium',
                        textColor: 'greyMedium',
                      })}
                    />
                  </Grid>
                </RadioGroup>
              </RadioFormControl>
            </PatientBillingBox>
          </div>
        )}
        <Grid item xs={12}>
          <ProfileInsuranceFileUploader
            isError={isError}
            value={patientInsuranceInfo.insuranceFileUrls}
            setValue={(files: FileUploadInfo) => {
              setPatientInsuranceInfo({
                ...patientInsuranceInfo,
                insuranceFileUrls: files,
              });
            }}
            uploadProcessing={isFileUploadProcessing}
            setUploadProcessing={setIsFileUploadProcessing}
            upfrontPayment={upfrontBilling}
          />
        </Grid>
      </ProfileInsuranceInsurancePadding>
    </>
  );
};
export default ProfileInsuranceUpload;
