import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useRecoilValue } from 'recoil';
import billingVideo from '../../../assets/images/billingVideo.png';
import { getUserAuth } from '../../../recoil/selectors';
import { SendActionType } from '../Services/SharedServices';
import VideoFeedbackWidget from './VideoFeedback';

const BillingVideoEmbed: React.FC<{
  videoLocation:
    | 'billing_page'
    | 'billing-faq-section'
    | 'dashboard-faq-section';
  reqID: string;
}> = ({ videoLocation, reqID }) => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const auth = useRecoilValue(getUserAuth);

  return (
    <>
      <ReactPlayer
        playing
        url="https://player.vimeo.com/video/960669684?playsinline=0"
        id="billing-video-player"
        light={billingVideo}
        height="200px"
        width="115px"
        style={{ justifySelf: 'center' }}
        controls
        vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        data-testid="billing-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        playsinline={false}
        onPlay={async () => {
          await SendActionType(
            'BillingVideoClicked',
            reqID,
            auth?.access_token,
            {
              videoLocation,
            }
          );
          // const el = document.getElementById('billing-video-player');
          // if (el) {
          //   await screenfull.request(el);
          // }
          setShowFeedback(true);
        }}
      />
      {showFeedback && (
        <VideoFeedbackWidget
          videoType="billing"
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          reqID={reqID}
          videoLocation={videoLocation}
        />
      )}
    </>
  );
};

export default BillingVideoEmbed;
