import styled from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const ProfilePageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getProfileWidth = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '1090px';
  }
  if (deviceType === 'tablet') {
    return '570px';
  }
  return '360px';
};

const getProfilePadding = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '40px 142px 142px 142px';
  }
  if (deviceType === 'tablet') {
    return '40px 18px 112px 18px';
  }
  return '40px 0px 92px 0px';
};

export const ProfilePageComponentWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-columns: ${({ deviceType }) => getProfileWidth(deviceType)};
  padding: ${({ deviceType }) => getProfilePadding(deviceType)};
`;

export const ProfilePageComponentGrid = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto auto 40px;
`;

export const ProfileContactWrapper = styled.div`
  margin-top: 20px;
  p {
    display: inline;
  }
  justify-self: center;
`;

const getWarningHeight = (deviceType: DeviceType) => {
  if (deviceType === 'desktop') {
    return '96pxpx';
  }
  if (deviceType === 'tablet') {
    return '110px';
  }
  return '250px';
};

export const ProfileWarningWrapper = styled.div<{ deviceType: DeviceType }>`
  display: grid;
  grid-template-columns: auto auto 20%;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 16px;
  width: 100%;
  height: ${({ deviceType }) => getWarningHeight(deviceType)};
  justify-self: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.redDark};
  background: ${({ theme }) => theme.neutral100};
  border-radius: 24px;
`;

export const ProfileWarningMobileTextWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
`;

export const ProfileWarningMobileWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  display: grid;
  grid-template-rows: auto min-content;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  gap: 16px;
  width: 100%;
  height: ${({ deviceType }) => getWarningHeight(deviceType)};
  justify-self: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.redDark};
  background: ${({ theme }) => theme.neutral100};
  border-radius: 24px;

  svg {
    margin-top: 10px;
  }
`;

export const ProfileWarningText = styled.div`
  font-size: 18px;
`;

export const LandingGeneralContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 1090px;
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
`;
