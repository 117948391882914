import React from 'react';
import { useRecoilValue } from 'recoil';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { CreateAccountCenterContainerPaddedTop } from '../CreateAccount/CreateAccountForm.styles';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { VERIFY_EMAIL_SEND_AGAIN_URL } from '../../utils/urlConstants';
import { getUserAuth } from '../../recoil/selectors';

export const VerifyEmailSection = (): React.ReactElement => {
  const authInfo = useRecoilValue(getUserAuth);
  const { isDesktop } = useDeviceHook();
  return (
    <CreateAccountCenterContainerPaddedTop isDesktop={isDesktop}>
      {GetTypographyContent({
        content: 'Verify your email',
        size: 'h3',
        isBold: true,
        textColor: 'neutral700',
      })}
      {GetTypographyContent({
        content: 'Thank you. We have sent a verification email to',
        size: 'medium',
        textColor: 'neutral700',
        padding: '24px 0 0 0',
      })}
      {GetTypographyContent({
        content: authInfo?.email as string,
        size: 'medium',
        isBold: true,
        textColor: 'greenDark',
      })}
      {GetTypographyContent({
        content:
          'Please click the link in your email to activate your account.',
        size: 'medium',
        textColor: 'neutral700',
        padding: '24px 0 0 0',
      })}
      {GetTypographyContent({
        content: "Didn't receive the email?",
        size: 'small',
        textColor: 'greenDark',
        padding: '24px 0 0 0',
        link: VERIFY_EMAIL_SEND_AGAIN_URL,
      })}
    </CreateAccountCenterContainerPaddedTop>
  );
};
