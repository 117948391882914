import React from 'react';
import unityRound from '../../assets/logos/unityRound.svg';
import vector from '../../assets/icons/vector.svg';
import {
  ResetPasswordDesktopContainer,
  ResetPasswordFormDesktopColorbackground,
  ResetPasswordFormImage,
  ResetPasswordFormVector,
  ResetPasswordMobileContainer,
} from './ResetPassword.styles';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const ResetPassword = (): React.ReactElement => {
  const { isDesktop } = useDeviceHook();

  return (
    <>
      {isDesktop && (
        <ResetPasswordDesktopContainer>
          <ResetPasswordFormDesktopColorbackground>
            <ResetPasswordFormVector src={vector} />
            <ResetPasswordFormImage src={unityRound} width="321px" />
          </ResetPasswordFormDesktopColorbackground>
          <ResetPasswordForm />
        </ResetPasswordDesktopContainer>
      )}
      {!isDesktop && 
        <ResetPasswordMobileContainer>
          <ResetPasswordForm />
        </ResetPasswordMobileContainer>
      }
    </>
  );
};
