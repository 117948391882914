import LightDarkHair from './LightDarkIcon.png';
import RedHair from './RedHairIcon.png';
import CurlyHair from './CurlyHairIcon.png';
import HairThickness from './HairThicknessIcon.png';
import Cilantro from './CilantroIcon.png';
import SweetSalty from './SweetSaltyIcon.png';
import Cookies from './SweetIcon.png';
import BitterTaste from './BitterIcon.png';
import TeethingTimelineSmall from './TeethIcon.png';
import EyeColor from './EyeIcon.png';
import Freckles from './FrecklesIcon.png';
import Asparagus from './AsparagusIcon.png';
import {
  LowerCasePageIndex,
  Trait,
  TraitLabel,
} from '../Services/BabyPeekService';

export default [
  { trait: 'Asparagus', icon: Asparagus },
  { trait: 'Bitter Taste', icon: BitterTaste },
  { trait: 'Cilantro Aversion', icon: Cilantro },
  { trait: 'Cookies', icon: Cookies },
  { trait: 'Hair Texture', icon: CurlyHair },
  { trait: 'Eye Color', icon: EyeColor },
  { trait: 'Freckles', icon: Freckles },
  { trait: 'Hair Thickness', icon: HairThickness },
  { trait: 'Light or Dark Hair', icon: LightDarkHair },
  { trait: 'Red Hair', icon: RedHair },
  { trait: 'Sweet vs. Salty', icon: SweetSalty },
  { trait: 'Teething Timeline', icon: TeethingTimelineSmall },
] as { trait: Trait; icon: string }[];

export const TraitMenu = [
  { trait: 'Asparagus', icon: Asparagus, url: 'asparagus' },
  { trait: 'Bitter Taste', icon: BitterTaste, url: 'bitter' },
  { trait: 'Cilantro Aversion', icon: Cilantro, url: 'cilantro' },
  { trait: 'Sweet Taste', icon: Cookies, url: 'sweet' },
  { trait: 'Hair Texture', icon: CurlyHair, url: 'curlyhair' },
  { trait: 'Eye Color', icon: EyeColor, url: 'eyecolor' },
  { trait: 'Freckles', icon: Freckles, url: 'freckle' },
  { trait: 'Hair Thickness', icon: HairThickness, url: 'hairthickness' },
  { trait: 'Light or Dark Hair', icon: LightDarkHair, url: 'lightordarkhair' },
  { trait: 'Red Hair', icon: RedHair, url: 'redhair' },
  { trait: 'Sweet vs. Salty', icon: SweetSalty, url: 'sweetsalty' },
  { trait: 'Teething Timeline', icon: TeethingTimelineSmall, url: 'babyteeth' },
] as { trait: string; icon: string; url: LowerCasePageIndex }[];

export const TraitMenuView = [
  { trait: 'Eye Color', icon: EyeColor, url: 'eyecolor' },
  { trait: 'Light or Dark Hair', icon: LightDarkHair, url: 'lightordarkhair' },
  { trait: 'Hair Thickness', icon: HairThickness, url: 'hairthickness' },
  { trait: 'Hair Texture', icon: CurlyHair, url: 'curlyhair' },
  { trait: 'Red Hair', icon: RedHair, url: 'redhair' },
  { trait: 'Cilantro Aversion', icon: Cilantro, url: 'cilantro' },
  { trait: 'Freckles', icon: Freckles, url: 'freckle' },
  { trait: 'Teething Timeline', icon: TeethingTimelineSmall, url: 'babyteeth' },
  { trait: 'Asparagus', icon: Asparagus, url: 'asparagus' },
  { trait: 'Bitter Taste', icon: BitterTaste, url: 'bitter' },
  { trait: 'Sweet Taste', icon: Cookies, url: 'sweet' },
  { trait: 'Sweet vs. Salty', icon: SweetSalty, url: 'sweetsalty' },
] as { trait: TraitLabel; icon: string; url: LowerCasePageIndex }[];
