import * as React from 'react';

export const StarIconFilled = (): React.ReactElement => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7031 1.52344L15.4961 7.23828L21.6406 8.14062C22.1562 8.22656 22.5859 8.57031 22.7578 9.08594C22.9297 9.55859 22.8008 10.1172 22.4141 10.4609L17.9453 14.8867L19.0195 21.1602C19.1055 21.6758 18.8906 22.1914 18.4609 22.4922C18.0312 22.8359 17.4727 22.8359 17 22.6211L11.5 19.6562L5.95703 22.6211C5.52734 22.8359 4.96875 22.8359 4.53906 22.4922C4.10938 22.1914 3.89453 21.6758 3.98047 21.1602L5.01172 14.8867L0.542969 10.4609C0.199219 10.1172 0.0703125 9.55859 0.199219 9.08594C0.371094 8.57031 0.800781 8.22656 1.31641 8.14062L7.50391 7.23828L10.2539 1.52344C10.4688 1.05078 10.9414 0.75 11.5 0.75C12.0156 0.75 12.4883 1.05078 12.7031 1.52344Z"
      fill="#FFB828"
    />
  </svg>
);
