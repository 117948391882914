import { AccordionProps } from '../../components/shared/Accordion/Accordions';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { HelpFAQAnswerWrapper } from './Help.styles';

const LINE_BREAK = <br />;

export const billingHelpAccordion: AccordionProps[] = [
  {
    title: 'What is an Explanation of Benefits (EOB)?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `After your test has been processed and a claim is submitted to your insurance, 
            you may receive an Explanation of Benefits (EOB). `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: `An EOB is not a bill`,
          size: 'medium',
          isBold: true,
        })}
        {GetTypographyContent({
          content: `, even though it may look like one. This document is from your insurance and shows details like how much 
            was billed to your insurance for your test; what the insurance reimbursed BillionToOne; and the
             specifics of your insurance plan (deductible), if applicable.`,
          size: 'medium',
        })}
        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `There is no action you need to take on an EOB. BillionToOne will send a final bill with any remaining balance after you receive an EOB.`,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'explainWOB',
  },
  {
    title: 'When might you expect to receive an EOB or bill?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `The first EOB is typically received 30-60 days after blood draw. You may receive more than one EOB 
          throughout the process (if, for example, BillionToOne appealed and the insurance provider covered more after the initial EOB).
           Then a bill outlining any remaining cost will come from BillionToOne after 
           the claim is finalized which may be up to a few months after your test. `,
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'expectEOB',
  },
  {
    title: 'Do you have a patient-pay price?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `If you decide to not use your insurance, BillionToOne offers the UNITY test at a
              cash pay rate. This includes carrier screening, reflex to fetal risk assessment for recessive conditions when needed, 
              fetal aneuploidy screening, and optional fetal RhD status. Our billing services team is available to discuss this cost and can be reached at `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
        {GetTypographyContent({
          content: ' or email us at ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: 'patientPay',
  },
  {
    title: 'Who do I speak to about additional billing questions?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `Please email us or call us at `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
        {GetTypographyContent({
          content: ' or ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: 'billingContact',
  },
];
