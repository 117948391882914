import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowIcon } from '../../assets/iconComponents/ArrowIcon';
import { QuestionMark } from '../../assets/iconComponents/QuestionMark';
import { HELP_PAGE_URL } from '../../utils/urlConstants';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  DetailedResultsFAQActionWrapper,
  DetailedResultsFAQArrowWrapper,
  DetailedResultsFAQWrapper,
} from './DetailedResults.styles';

export const DetailedResultsFAQ = (): React.ReactElement => {
  const history = useHistory();
  return (
    <DetailedResultsFAQWrapper>
      <div>
        <QuestionMark />
      </div>
      <div>
        {GetTypographyContent({
          content: 'Have a question?',
          size: 'xlarge',
          textColor: 'greyMedium',
          isBold: true,
        })}
      </div>
      <DetailedResultsFAQActionWrapper>
        {GetTypographyContent({
          content: 'View our list of FAQs',
          size: 'medium',
          textColor: 'greyMedium',
          link: HELP_PAGE_URL,
        })}
        <DetailedResultsFAQArrowWrapper onClick={() => history.push('./help')}>
          <ArrowIcon />
        </DetailedResultsFAQArrowWrapper>
      </DetailedResultsFAQActionWrapper>
    </DetailedResultsFAQWrapper>
  );
};
