import styled from 'styled-components/macro';
import { DeviceType } from '../../utils/hooks/useDeviceHook';

export const BabyPeekGamesIntro = styled.div`
  display: flex;
  position: relative;
  width: 327px;
  padding: var(--Padding-Left-with-Icon, 24px) 16px;

  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Grey-200, #e2e8f0);
  background: var(--Base-White, #fff);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  justify-self: center;
  margin: 24px;
  .babypeek-gift-traits {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`;

export const BabyPeekGamesPreviewModalGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  margin: 24px;

  ${BabyPeekGamesIntro} {
    border-radius: 0px 0px 20px 20px;
    width: auto;
    margin: 0px;
  }
`;

export const BabyPeekGamesPreviewModalTop = styled.div`
  display: flex;
  height: 122px;
  padding: var(--Padding-Left-with-Icon, 24px) 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: var(--Spacing, 12px);

  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  background: #fff6d2;
`;

export const BabyPeekGamesPreviewModalTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 27px */
  text-align: center;
`;

export const BabyPeekGamesPreviewModalSubTitle = styled.div`
  color: var(--Grey-700, #2d3748);
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: center;
`;

export const BabyPeekGamesIntroTitle = styled.div`
  color: var(--Grey-900, #171923);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
`;

export const BabyPeekGamesIntroGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 24px;
  justify-items: center;

  .babypeek-social-media {
    width: 120px;
    height: 75px;
  }
`;

export const BabyPeekGamesIntroDescription = styled.div`
  color: #171923;

  text-align: center;
  font-feature-settings: 'case' on;
  /* Text/Regular/Mobile */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

export const BabyPeekGamesTitle = styled.div`
  color: var(--Grey-900, #171923);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
  letter-spacing: 0.4px;
  padding: 0px 16px;
  margin-top: 32px;
`;

export const BabyPeekGamesMainDescription = styled.div`
  color: var(--Grey-700, #2d3748);
  text-align: center;
  font-feature-settings: 'case' on;
  /* Text/Medium/Mobile */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  padding: 0px 16px;
`;

export const BabyPeekGamesAccordionWrapper = styled.div<{
  deviceType: DeviceType;
}>`
  width: ${({ deviceType }) => (deviceType === 'mobile' ? undefined : '400px')};
  .MuiAccordion-root {
    background: white;
    border-radius: var(--Padding-Left-with-Icon, 24px) !important;
    border: 1px #f1f1f1;
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.04) !important;
  }
  .MuiTypography-root {
    padding: 16px 0px;
  }
  .MuiAccordionSummary-root {
    margin-left: 8px;
  }

  .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
    fill: black;
  }
`;

export const BabyPeekGamesAccordionGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 16px;
  justify-items: center;
  padding: 0px 16px;
  margin-bottom: 32px;

  .button {
    height: 44px;
  }
`;

export const BabyPeekGamesAnswers = styled.div`
  color: var(--Primary-Blue, #2b3ff6);
  text-align: center;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.48px;
  display: inline-flex;
  max-height: var(--Max-Height, 64px);
  padding: 4px 0px 8px 0px;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing, 12px);
  border-bottom: 3px solid var(--Primary-Blue, #2b3ff6);
  width: max-content;
  justify-self: center;
  cursor: pointer;
`;
