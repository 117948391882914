import React, { useState, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HeaderContainer,
  HeaderContainerMobile,
  HeaderIcon,
  HeaderIconContainerMobileWrapper,
  HeaderIconContainerMobileGrid,
  HeaderIconContainerMobile,
  HeaderIconsContainer,
  HeaderIconsLinksContainer,
  HeaderLinksContainer,
} from './Header.styles';
import unityLogo from '../../assets/logos/unity.svg';
import byIcon from '../../assets/logos/by.svg';
import poweredByBTOLogo from '../../assets/logos/billionToOne.svg';
import menuExpand from '../../assets/icons/menu.svg';
import close from '../../assets/icons/close.svg';
import { MobileMenu } from '../../MobileMenu/MobileMenu';
import { GetHeaderMenuLinks, GetLoginHeader } from './HeaderMenuLinks';
import { LANDING_PAGE_URL, LOGIN_PAGE_URL } from '../../utils/urlConstants';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { getUserAuth } from '../../recoil/selectors';
import {
  babyPeekStatus,
  patientProfileState,
  patientState,
} from '../../recoil/atoms';
import { showUpfrontPayment } from '../UpfrontPayment/Services/UpfrontPaymentService';

export const Header = (): React.ReactElement => {
  const [activeHeader, setActiveHeader] = useState('');
  const [patientInfo, setPatientInfo] = useRecoilState(patientState);
  const [profileInfo, setProfileInfo] = useRecoilState(patientProfileState);
  const [babyPeekStatusFlag, setBabyPeekStatusFlag] =
    useRecoilState(babyPeekStatus);

  const user = useRecoilValue(getUserAuth);
  const history = useHistory();
  const { pathname } = useLocation();
  const { isDesktop, deviceType } = useDeviceHook();

  useEffect(() => {
    setActiveHeader(pathname);
  }, []);

  const setPathnameCheck = () => {
    setActiveHeader(history.location.pathname);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPathnameCheck();
    });
    return () => {
      unlisten();
    };
  }, []);

  const babyPeekReq = patientInfo?.requisitions?.filter((req) =>
    Boolean(
      req.reveal_status === 'pending' || req.reveal_status === 'available'
    )
  )[0];

  useEffect(() => {
    if (
      babyPeekReq &&
      babyPeekStatusFlag === 'hidden' &&
      babyPeekReq.reveal_status !== 'hidden'
    ) {
      setBabyPeekStatusFlag(babyPeekReq.reveal_status);
    }
  }, [babyPeekReq, babyPeekStatus]);

  const headerLinks = useMemo(
    () =>
      user?.email_verified
        ? GetHeaderMenuLinks(
            'greyMedium',
            'medium',
            activeHeader,
            setActiveHeader,
            profileInfo,
            setPatientInfo,
            setProfileInfo,
            deviceType,
            patientInfo?.requisitions?.filter((req) =>
              showUpfrontPayment(req.portal_bill_status)
            )[0]?.bill_pk,
            babyPeekReq && {
              status: babyPeekStatusFlag,
              babyPeekPK: babyPeekReq.baby_peek_pk,
            }
          )
        : GetLoginHeader(
            'greyMedium',
            'medium',
            setPatientInfo,
            pathname === LOGIN_PAGE_URL
          ),
    [
      pathname,
      user?.email_verified,
      profileInfo,
      babyPeekStatusFlag,
      activeHeader,
      deviceType,
    ]
  );

  const [navBarOpen, setNavBarOpen] = useState(false);

  const handleToggle = () => {
    setNavBarOpen(!navBarOpen);
  };

  return (
    <>
      {isDesktop && (
        <HeaderContainer>
          <HeaderIconsLinksContainer>
            <div style={{ position: 'absolute' }}>
              <HeaderIconsContainer
                data-testid="HeaderIcons"
                onClick={() => history.push(LANDING_PAGE_URL)}
              >
                {import.meta.env.VITE_IS_USER_TESTING_ENV !== 'true' && (
                  <>
                    {' '}
                    <HeaderIcon src={unityLogo} width="156px" />
                    <HeaderIcon src={byIcon} width="15px" />
                    <HeaderIcon src={poweredByBTOLogo} width="112px" />{' '}
                  </>
                )}
              </HeaderIconsContainer>
            </div>
            <HeaderLinksContainer onlyLogin={!user?.email_verified}>
              {headerLinks
                .filter((header) => !header.hide)
                .map((headerLink) => (
                  <React.Fragment key={headerLink.key}>
                    {headerLink.header}
                  </React.Fragment>
                ))}
            </HeaderLinksContainer>
          </HeaderIconsLinksContainer>
        </HeaderContainer>
      )}
      {!isDesktop && (
        <>
          <HeaderContainerMobile>
            <HeaderIconContainerMobile>
              <HeaderIconContainerMobileWrapper
                onClick={() => history.push(LANDING_PAGE_URL)}
              >
                <HeaderIcon src={unityLogo} width="107px" />
                <HeaderIcon src={byIcon} width="15px" />
                <HeaderIcon src={poweredByBTOLogo} width="94px" />
              </HeaderIconContainerMobileWrapper>
              {pathname !== LOGIN_PAGE_URL && (
                <HeaderIconContainerMobileGrid deviceType={deviceType}>
                  <HeaderIcon
                    src={navBarOpen ? close : menuExpand}
                    width="20px"
                    onClick={handleToggle}
                  />
                </HeaderIconContainerMobileGrid>
              )}
            </HeaderIconContainerMobile>
          </HeaderContainerMobile>
          {navBarOpen && <MobileMenu handleToggle={handleToggle} />}
        </>
      )}
    </>
  );
};
