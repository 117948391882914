import { DisplayWithIcon } from '../shared/Typography/types';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  TestResultIconAndTypography,
  TestResultsIcon,
} from './TestResults.styles';

export const TestResultsCarrierInfo = (
  displayWithIcon: DisplayWithIcon
): React.ReactElement => (
  <>
    {GetTypographyContent({
      content: 'Carrier Screen',
      size: 'large',
      textColor: 'greyMedium',
      isBold: true,
      isCaps: true,
    })}
      <div>
        <TestResultIconAndTypography>
          <TestResultsIcon
            marginLeft={displayWithIcon.display === 'See Report'}
          >
            {displayWithIcon.icon}
          </TestResultsIcon>
          {GetTypographyContent({
            content: displayWithIcon.display,
            size: 'medium',
            textColor: 'greyMedium',
          })}
        </TestResultIconAndTypography>
      </div>
  </>
);
