import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useRecoilValue } from 'recoil';
import {
  VideoIconAndTypography,
  IconAndTypographyContainer,
  VideoPlayContainer,
  StyledPlayVideo,
  VideoDiv,
} from './VideoEmbed.styles';
import unityMother from '../../../assets/images/unityMother.png';
import { GetTypographyContent } from '../Typography/Typography';
import { useDeviceHook } from '../../../utils/hooks/useDeviceHook';
import { SendActionType } from '../Services/SharedServices';
import { getUserAuth } from '../../../recoil/selectors';

const VideoEmbed: React.FC<{ overlayText: string; requisitionID?: string }> = ({
  overlayText,
  requisitionID,
}) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  const videoOverlay = (
    <VideoPlayContainer deviceType={deviceType}>
      {GetTypographyContent({
        content: overlayText,
        size: 'h3',
        textColor: 'white',
        isBold: true,
        padding: '10px',
      })}
      <IconAndTypographyContainer>
        <VideoIconAndTypography>
          <StyledPlayVideo />
          {GetTypographyContent({
            content: 'Watch the video',
            size: 'xlarge',
            textColor: 'white',
            isBold: true,
          })}
        </VideoIconAndTypography>
      </IconAndTypographyContainer>
    </VideoPlayContainer>
  );

  return (
    <VideoDiv>
      <ReactPlayer
        playing
        url="https://player.vimeo.com/video/589930889?h=54814c7b52#t=0m0s"
        playIcon={videoOverlay}
        light={unityMother}
        height={`${deviceType === 'desktop' ? '100%' : '328px'}`}
        width="100%"
        controls
        data-testid="unity-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        onPlay={async () => {
          if (requisitionID) {
            await SendActionType(
              'ViewedDashboardVideo',
              requisitionID,
              auth?.access_token
            );
          }
        }}
      />
    </VideoDiv>
  );
};

export default VideoEmbed;
