import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { patientProfileState } from '../../recoil/atoms';
import { getSpecificFetalSex, getUserAuth } from '../../recoil/selectors';
import { UserProfileAuthClientResponse } from '../../recoil/types';
import { networkErrorMessage } from '../../utils/helpers';
import { validateEmail } from '../../utils/hooks/helpers';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { GetPhiRequest } from '../Profile/Services/UpdateProfileInfoService';
import { StyledButton } from '../shared/Button/Button.styles';
import { ErrorPopup } from '../shared/ErrorPopup/ErrorPopup';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  DetailedResultsFetalSexForwardInput,
  DetailedResultsFetalSexForwardWrapper,
  ActionAndCancelButtonPair,
  DetailedResultsForwardFetalSexTerms,
  DetailedResultsTypographyWrapper,
} from './DetailedResults.styles';
import ForwardFetalSexEmailRequest from './Services/DetailedResultsService';

export const DetailedResultsForwardFetalSex: React.FC<{
  setFetalSexSent: (sent: boolean) => void;
  setForwardFetalSex: (forward: boolean) => void;
  setConfirmedEmail: (email: string) => void;
  requsitionID: string;
}> = ({
  setFetalSexSent,
  setForwardFetalSex,
  setConfirmedEmail,
  requsitionID,
}) => {
  const { deviceType } = useDeviceHook();
  const [forwardName, setForwardName] = useState('');
  const [forwardEmail, setForwardEmail] = useState('');
  const [forwardEmailError, setForwardEmailError] = useState('');
  const [forwardNameError, setForwardNameError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const auth = useRecoilValue(getUserAuth);
  const fetalSex = useRecoilValue(getSpecificFetalSex(requsitionID));
  const [profileInfo, setPatientInfo] = useRecoilState(patientProfileState);

  useEffect(() => {
    const getProfileInfo = async () => {
      const response = await GetPhiRequest(auth?.access_token);
      if (response.ok) {
        const profile =
          (await response.json()) as UserProfileAuthClientResponse;
        setPatientInfo(profile);
      }
    };
    if (!profileInfo && auth) {
      getProfileInfo();
    }
  }, [profileInfo, auth]);

  const getLoadingOrMessage = () => {
    if (isLoading) {
      return <SpinnerLoading />;
    }
    if (responseMessage) {
      return (
        <ErrorPopup
          message={responseMessage}
          hideMessage={setResponseMessage}
        />
      );
    }
  };

  const submitForward = async (email: string) => {
    if (!forwardName) {
      setForwardNameError('Required');
    }
    if (!forwardEmail) {
      setForwardEmailError('Required');
    } else if (!validateEmail(email)) {
      setForwardEmailError('Invalid email');
    } else if (profileInfo && auth && fetalSex) {
      setIsLoading(true);
      try {
        const response = await ForwardFetalSexEmailRequest(
          {
            req_identifier: requsitionID,
            to_email: forwardEmail,
            to_name: forwardName,
          },
          auth?.access_token
        );
        setIsLoading(false);
        if (response.ok) {
          setConfirmedEmail(forwardEmail);
          setFetalSexSent(true);
        } else {
          setResponseMessage('There was an error - please try again.');
        }
      } catch (e) {
        setIsLoading(false);
        setResponseMessage(networkErrorMessage);
      }
    }
  };

  return (
    <DetailedResultsFetalSexForwardWrapper deviceType={deviceType}>
      <div>
        {GetTypographyContent({
          content: 'Forward fetal sex',
          size: 'h3',
          textColor: 'greyMedium',
          isBold: true,
        })}
      </div>
      <div>
        {GetTypographyContent({
          content:
            'Please let us know to whom we should send the fetal sex information.',
          size: 'large',
          textColor: 'greyMedium',
        })}
      </div>
      <DetailedResultsFetalSexForwardInput
        label="Their name"
        size="small"
        value={forwardName}
        error={forwardNameError}
        onChange={(e) => {
          setForwardNameError('');
          setForwardName(e.target.value);
        }}
      />
      <DetailedResultsFetalSexForwardInput
        label="Their email"
        error={forwardEmailError}
        size="small"
        value={forwardEmail}
        onChange={(e) => {
          setForwardEmailError('');
          setForwardEmail(e.target.value);
        }}
      />
      {getLoadingOrMessage() || (
        <ActionAndCancelButtonPair>
          <StyledButton
            size="small"
            $backgroundColor="redMedium"
            $textColor="white"
            onClick={() => submitForward(forwardEmail)}
            disabled={!termsChecked}
          >
            FORWARD FETAL SEX
          </StyledButton>
          <StyledButton
            size="small"
            $backgroundColor="white"
            $textColor="redMedium"
            variant="outlined"
            className="cancel-action"
            onClick={() => setForwardFetalSex(false)}
          >
            CANCEL
          </StyledButton>
        </ActionAndCancelButtonPair>
      )}
      <DetailedResultsForwardFetalSexTerms>
        {profileInfo && (
          <>
            <Checkbox
              name="termsOfService"
              data-testid="termsOfServiceCheck"
              style={{ marginRight: 8 }}
              className="forward-fetal-checkbox"
              checked={termsChecked}
              onChange={(e) => setTermsChecked(e.target.checked)}
            />
            <DetailedResultsTypographyWrapper
              onClick={() => setTermsChecked(!termsChecked)}
            >
              {GetTypographyContent({
                content:
                  'By clicking this checkbox you are authorizing BillionToOne to provide health information and/or test results via email. For more information ',
                size: 'small',
                textColor: 'greyMedium',
              })}
              {GetTypographyContent({
                content: 'click here.',
                size: 'small',
                textColor: 'greenMedium',
                link: 'https://billiontoone.com/privacy-practices/',
                externalLink: true,
              })}
            </DetailedResultsTypographyWrapper>
          </>
        )}
      </DetailedResultsForwardFetalSexTerms>
    </DetailedResultsFetalSexForwardWrapper>
  );
};
