import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';

import { ForwardIcon } from '../../assets/iconComponents/Forward';
import { StyledButton } from '../shared/Button/Button.styles';
import { GetTypographyContent } from '../shared/Typography/Typography';
import {
  DetailedResultsFetalSexWrapper,
  DetailedResultsFetalSexButton,
  DetailedResultsFetalSexButtonWrappers,
  DetailedResultsButtonTextWrapper,
  DetailedResulFetalSexIconRow,
  DetailedResultsFetalSexTitle,
} from './DetailedResults.styles';
import { DetailedResultsFetalSexReveal } from './DetailedResultsFetalSexReveal';
import { EyeIcon } from '../../assets/iconComponents/EyeIcon';
import { getSpecificFetalSex } from '../../recoil/selectors';
import { TestResultsIcon } from '../TestResults/TestResults.styles';
import { NotOrderedIcon } from '../../assets/iconComponents/NotOrdered';
import { ProcessingIcon } from '../../assets/iconComponents/Processing';
import { capitalizeFirstLetter, clearHash } from '../../utils/helpers';
import { themePalette } from '../../theme';
import { TestResultsLearnMore } from '../TestResults/TestResultsLearnMore';
import { HELP_PAGE_URL } from '../../utils/urlConstants';
import { NoValueProvided } from '../../recoil/types';

const getNoFetalDisplay = (fetalSexStatus?: string, fetalSexValue?: string) => {
  if (fetalSexValue === NoValueProvided || fetalSexStatus === 'Ordered') {
    return (
      <DetailedResulFetalSexIconRow>
        <EyeIcon color={themePalette.resultsNegativeGreen} />
        {GetTypographyContent({
          content: 'See Report',
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </DetailedResulFetalSexIconRow>
    );
  }
  if (
    fetalSexStatus === 'Not Ordered' ||
    !fetalSexStatus ||
    !fetalSexValue ||
    fetalSexValue === 'NO RESULT' ||
    fetalSexValue === 'NO CALL' ||
    fetalSexValue === 'NOT REPORTED' ||
    fetalSexValue === 'NOT ORDERED' ||
    fetalSexValue === 'N/A'
  ) {
    return (
      <DetailedResulFetalSexIconRow>
        <TestResultsIcon>
          <NotOrderedIcon />
        </TestResultsIcon>
        {GetTypographyContent({
          content:
            fetalSexStatus === 'Not Ordered'
              ? 'Opted Out'
              : capitalizeFirstLetter(fetalSexValue || 'NO RESULT'),
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </DetailedResulFetalSexIconRow>
    );
  }

  if (fetalSexStatus === 'Processing') {
    return (
      <DetailedResulFetalSexIconRow>
        <TestResultsIcon>
          <ProcessingIcon />
        </TestResultsIcon>
        {GetTypographyContent({
          content: capitalizeFirstLetter(fetalSexValue),
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </DetailedResulFetalSexIconRow>
    );
  }
  if (fetalSexStatus === 'N/A') {
    return (
      <DetailedResulFetalSexIconRow>
        <TestResultsIcon>
          <NotOrderedIcon />
        </TestResultsIcon>
        {GetTypographyContent({
          content: 'N/A',
          size: 'h2',
          textColor: 'greyMedium',
        })}
      </DetailedResulFetalSexIconRow>
    );
  }
};

export const DetailedResultsFetalSex: React.FC<{
  setShowFetalSexModal: (show: '' | 'reveal' | 'report') => void;
  showFetalSex: boolean;
  setForwardFetalSex: (forward: boolean) => void;
  requisitionID: string;
}> = ({
  setShowFetalSexModal: showFetalSexModal,
  showFetalSex,
  setForwardFetalSex,
  requisitionID,
}) => {
  const fetalSex = useRecoilValue(getSpecificFetalSex(requisitionID));
  const noResult = getNoFetalDisplay(fetalSex?.status, fetalSex?.value);
  const fetalSexRevealRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (showFetalSex && fetalSexRevealRef.current) {
      const position = fetalSexRevealRef.current.getBoundingClientRect();
      window.scrollTo(position.left, position.top + window.scrollY - 20);
    }
  }, [showFetalSex, fetalSexRevealRef]);

  useEffect(() => {
    if (hash.length) {
      const hashElement = document.querySelector(hash);
      if (hashElement) {
        const position = hashElement?.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }
      clearHash();
    }
  }, []);

  return (
    <DetailedResultsFetalSexWrapper ref={fetalSexRevealRef}>
      <DetailedResultsFetalSexTitle id="fetal_sex_section">
        {GetTypographyContent({
          content: 'Fetal Sex',
          size: 'label',
          textColor: 'greyMedium',
          isBold: true,
          isCaps: true,
          padding: '0 0 10px 0',
        })}

        {TestResultsLearnMore(
          'Learn More',
          `${HELP_PAGE_URL}#fetalSexAccuracy-help`,
          '0px 20px 0px 20px'
        )}
      </DetailedResultsFetalSexTitle>
      {noResult || (
        <>
          <div>
            {GetTypographyContent({
              content: `You can decide whether you'd like to find out your baby's sex or keep it a secret. 
        We can also forward the fetal sex to a friend or relative who can let you know when you’re ready, 
        or at a special occasion like a gender reveal party.`,
              size: 'medium',
              textColor: 'greyMedium',
              padding: '0 0 20px 0',
            })}
          </div>
          {showFetalSex ? (
            <DetailedResultsFetalSexReveal requisitionID={requisitionID} />
          ) : (
            <DetailedResultsFetalSexButtonWrappers>
              <StyledButton
                size="small"
                $backgroundColor="redMedium"
                $textColor="white"
                onClick={() => showFetalSexModal('reveal')}
              >
                <DetailedResultsFetalSexButton>
                  <div>
                    <EyeIcon color={themePalette.white} />
                  </div>
                  <div data-testid="yes-reveal-fetal-sex-button">
                    <DetailedResultsButtonTextWrapper>
                      {GetTypographyContent({
                        content: 'YES, REVEAL FETAL SEX',
                        size: 'medium',
                        textColor: 'white',
                        isCaps: true,
                        padding: '0 0 0 3px',
                      })}
                    </DetailedResultsButtonTextWrapper>
                  </div>
                </DetailedResultsFetalSexButton>
              </StyledButton>
              <StyledButton
                $reverse
                size="small"
                $backgroundColor="white"
                $textColor="redMedium"
                $borderColor="redMedium"
                onClick={() => setForwardFetalSex(true)}
              >
                <DetailedResultsFetalSexButton>
                  <div>
                    <ForwardIcon />
                  </div>
                  <DetailedResultsButtonTextWrapper>
                    {GetTypographyContent({
                      content: 'FORWARD FETAL SEX',
                      size: 'medium',
                      textColor: 'redMedium',
                      isCaps: true,
                      padding: '0 0 0 3px',
                    })}
                  </DetailedResultsButtonTextWrapper>
                </DetailedResultsFetalSexButton>
              </StyledButton>
            </DetailedResultsFetalSexButtonWrappers>
          )}
        </>
      )}
    </DetailedResultsFetalSexWrapper>
  );
};
