import * as React from "react";
import { themePalette } from "../../theme";

export const SuccessUploadIcon = ({
  color = themePalette.greenMedium,
}: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1126_36413"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1126_36413)">
      <path
        d="M14.4666 20.7668L11.7 18.0001C11.6111 17.9112 11.5111 17.8446 11.4 17.8001C11.2889 17.7557 11.1722 17.7335 11.05 17.7335C10.9277 17.7335 10.8055 17.7612 10.6833 17.8168C10.5611 17.8724 10.4555 17.9446 10.3666 18.0335C10.1889 18.2335 10.1 18.4668 10.1 18.7335C10.1 19.0001 10.1889 19.2224 10.3666 19.4001L13.6666 22.7001C13.7777 22.8335 13.9055 22.9279 14.05 22.9835C14.1944 23.039 14.3333 23.0668 14.4666 23.0668C14.6222 23.0668 14.7666 23.039 14.9 22.9835C15.0333 22.9279 15.1555 22.8335 15.2666 22.7001L21.5 16.4668C21.6777 16.2668 21.7666 16.039 21.7666 15.7835C21.7666 15.5279 21.6777 15.3001 21.5 15.1001C21.3222 14.9224 21.1 14.8335 20.8333 14.8335C20.5666 14.8335 20.3333 14.9224 20.1333 15.1001L14.4666 20.7668ZM7.39997 29.5335C6.79997 29.5335 6.27219 29.3057 5.81663 28.8501C5.36108 28.3946 5.1333 27.8668 5.1333 27.2668V4.73346C5.1333 4.11124 5.36108 3.57791 5.81663 3.13346C6.27219 2.68902 6.79997 2.4668 7.39997 2.4668H18.5C18.8111 2.4668 19.1111 2.52791 19.4 2.65013C19.6889 2.77235 19.9333 2.93346 20.1333 3.13346L26.2 9.20013C26.4 9.40013 26.5611 9.64457 26.6833 9.93346C26.8055 10.2224 26.8666 10.5224 26.8666 10.8335V27.2668C26.8666 27.8668 26.6444 28.3946 26.2 28.8501C25.7555 29.3057 25.2222 29.5335 24.6 29.5335H7.39997ZM18.3 9.80013V4.73346H7.39997V27.2668H24.6V10.9335H19.4333C19.1 10.9335 18.8277 10.8279 18.6166 10.6168C18.4055 10.4057 18.3 10.1335 18.3 9.80013Z"
        fill={color}
      />
    </g>
  </svg>
);
