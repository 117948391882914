import React from 'react';

import { useWindowWidth } from '@react-hook/window-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import {
  BabyPeekTraitWrapper,
  BabyPeekV2HeaderTitleWrapper,
  BabyPeekV2HeaderTitleTop,
  BabyPeekV2HeaderTitleBottom,
  BabyPeekV2HeaderIconGrid,
  BabyPeekV2SectionText,
  BabyPeekV2SectionTitle,
  BabyPeekV2Section,
  BabyPeekV2HeaderIconShare,
  BabyPeekV2HeaderIconShareTitle,
} from '../Shared/BabyPeekTrait.styles';

import AsparagusIcon from './AsparagusIcon.png';
import AsparagusBackground from './AsparagusBackground.png';
import {
  BabyPeekTraitResponse,
  Trait,
  convertPercentage,
} from '../../Services/BabyPeekService';
import { useDeviceHook } from '../../../../utils/hooks/useDeviceHook';

import { TraitNavigationRight } from '../Shared/TraitNavigationRight';
import {
  BabyPeekMobileButtonSticky,
  BabyPeekTraitNavigationWrapper,
  BabyPeekTraitNavigationButtonWrapper,
  BabyPeekDesktopButtonSticky,
} from '../../BabyPeekStripe.styles';
import { BabyPeekButton } from '../../BabyPeekButton';
import { TraitMenu } from '../Shared/TraitMenu';
import { BabyPeekTraitAccordion } from '../Shared/BabyPeekTraitAccordion';
import { AsparagusGene } from './AsparagusGene';
import { AsparagusHowItWorks } from './AsparagusHowItWorks';
import { AsparagusOtherFactors } from './AsparagusOtherFactors';
import { BabyPeekGauge } from '../Shared/BabyPeekGauge';
import { ShareUnderline } from '../../BabyPeekIcons/ShareUnderline';
import { BabyPeekSingleFeedback } from '../../BabyPeekSingleFeedback';

const HowItWorks = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What determines the ability to smell asparagus-related odor in urine?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      When your body digests asparagus, it produces a chemical called
      <span>{` asparagusic acid`}</span> which then breaks down into
      sulfur-containing compounds, which{' '}
      {`don't smell very good. The ability to smell the chemical varies from
      one person to another, some can smell this odor in their urine after
      eating asparagus, but others can't. `}
      <br />
      <br />
    </BabyPeekV2SectionText>

    <div
      style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
    >
      {' '}
      <AsparagusHowItWorks />{' '}
    </div>
    <br />
    <BabyPeekV2SectionTitle>
      {`How do we determine how likely your baby is to be able to smell asparagus-related odor? `}
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      Scientists think the <span>OR2M7</span> gene affects the ability to smell
      the asparagus-related odor. The gene provides instructions for a tiny
      structures in the nose called <span>“olfactory receptors.”</span> Genetic
      variations near <span className="italic">OR2M7</span> can affect how one
      specific olfactory receptor works, and therefore alter a person’s ability
      to smell the asparagus-related odor.
      <br />
      <BabyPeekV2SectionTitle>The genes we peeked at</BabyPeekV2SectionTitle>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <AsparagusGene />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
  </BabyPeekV2Section>
);

const OtherFactors = (
  <BabyPeekV2Section>
    <BabyPeekV2SectionTitle>
      What else can affect the ability to smell asparagus-related odor?
    </BabyPeekV2SectionTitle>
    <BabyPeekV2SectionText>
      A person’s traits are often determined by a combination of genetic and
      environmental factors. While many of the genetic variations that influence
      these traits are well-studied, there are some genetic factors that are
      still unknown to scientists.
      <br />
      Other factors that can affect our perception of cilantro include:
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
      >
        {' '}
        <AsparagusOtherFactors />{' '}
      </div>
      <br />
    </BabyPeekV2SectionText>
    <br />
  </BabyPeekV2Section>
);

const AboutBabyPeek = (
  <BabyPeekV2SectionText>
    BabyPeek results are:
    <ul>
      <li>
        A <span style={{ fontWeight: 700 }}>predictor</span> of what traits your
        baby will have. These results do not mean that your baby will have these
        traits with absolute certainty and a baby may have different traits than
        what is predicted.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT medical test results.</span> These
        results should not be used to make medical decisions about your
        pregnancy. The genetic changes mentioned in these results do not affect
        a baby’s health.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>NOT paternity testing.</span> These
        results should not be used to determine paternity. Given the complex
        genetics of these traits, a baby may be born with different traits from
        the biological mother or father.
      </li>
      <li>
        <span style={{ fontWeight: 700 }}>
          NOT managed by your healthcare provider.
        </span>{' '}
        These results are unlocked by the patient directly. Please do not
        contact your healthcare provider regarding your BabyPeek results.
      </li>
    </ul>
    If you have questions about BabyPeek results, please contact BillionToOne
    directly at
    <a href={`tel:${'(650) 460-2551'}`} target="_blank" rel="noreferrer">
      {` (650) 460-2551`}
    </a>
    .
    <br />
  </BabyPeekV2SectionText>
);

export const AsparagusTrait: React.FC<{
  setCurrentTrait?: (trait: Trait | undefined) => void;
  traitData: BabyPeekTraitResponse;
  publicTrait?: boolean;
  setShareModal?: (share: boolean) => void;
  hideTraitMenu: boolean;
  modalPreview?: boolean;
  showAnimation?: boolean;
}> = ({
  setCurrentTrait,
  traitData,
  setShareModal,
  hideTraitMenu,
  publicTrait,
  modalPreview = false,
  showAnimation = false,
}) => {
  const { deviceType } = useDeviceHook();
  const windowWidth = useWindowWidth();
  const [showTraitMenu, setShowTraitMenu] = React.useState(false);
  const StickyWrapper =
    deviceType !== 'desktop'
      ? BabyPeekMobileButtonSticky
      : BabyPeekDesktopButtonSticky;
  const asparagusTraits = [
    {
      color: '#079638',
      gradient:
        'linear-gradient(90deg, rgba(20, 184, 76, 0.48) 0%, #069637 98.96%)',
      name: 'Can smell',
      percentage: convertPercentage(traitData.asparagus_detection_prob),
    },
  ].sort((a, b) => b.percentage - a.percentage);

  return (
    <>
      {' '}
      <div
        style={{
          backgroundImage: `url(${AsparagusBackground})`,
          height: '315px',
          backgroundSize: 'cover',
          padding: '20px',
          display: 'grid',
          justifyContent: deviceType !== 'mobile' ? 'center' : 'flex-start',
          paddingBottom: '32px',
          paddingTop: '24px',
          gridGap: '12px',
        }}
        className="trait-top-background"
      >
        <BabyPeekV2HeaderIconShare>
          <BabyPeekV2HeaderIconGrid>
            <img
              src={AsparagusIcon}
              alt="AsparagusIcon"
              style={{ justifySelf: 'center' }}
            />
            <div>Asparagus Odor Detection</div>
          </BabyPeekV2HeaderIconGrid>
          {setShareModal && !modalPreview && (
            <BabyPeekV2HeaderIconShareTitle onClick={() => setShareModal(true)}>
              {' '}
              <FontAwesomeIcon icon={faArrowUpFromBracket} /> Share
              <ShareUnderline />
            </BabyPeekV2HeaderIconShareTitle>
          )}
        </BabyPeekV2HeaderIconShare>
        <BabyPeekV2HeaderTitleWrapper
          deviceType={deviceType}
          windowWidth={windowWidth}
        >
          <BabyPeekV2HeaderTitleTop>
            {publicTrait
              ? 'The baby is more likely to'
              : 'Your baby is more likely to'}
          </BabyPeekV2HeaderTitleTop>
          <BabyPeekV2HeaderTitleBottom>
            {`${
              asparagusTraits[0].percentage > 50 ? '' : 'not'
            } smell asparagus-related odor`}
          </BabyPeekV2HeaderTitleBottom>
        </BabyPeekV2HeaderTitleWrapper>
      </div>
      <BabyPeekTraitWrapper deviceType={deviceType}>
        <div style={{ paddingBottom: '10px' }} />
        <BabyPeekGauge
          showGif={modalPreview || showAnimation}
          percentage={asparagusTraits[0].percentage}
          bottomText="chance to smell asparagus-related odor"
        />
        {!modalPreview && (
          <>
            {' '}
            <hr />
            <div
              style={{
                maxWidth: `${windowWidth - 2}px`,
                padding: '25px',
                paddingTop: '0px',
              }}
            >
              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'How it works',
                    content: HowItWorks,
                    key: 'howItWorksEye',
                    defaultExpanded: true,
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'Other factors',
                    content: OtherFactors,
                    key: 'otherFactorsEye',
                  },
                ]}
              />

              <BabyPeekTraitAccordion
                data={[
                  {
                    title: 'About BabyPeek',
                    content: AboutBabyPeek,
                    key: 'aboutBabyPeekEye',
                  },
                ]}
              />
            </div>{' '}
          </>
        )}
        {!modalPreview && <BabyPeekSingleFeedback onlyFeedback />}
      </BabyPeekTraitWrapper>
      {setCurrentTrait && !modalPreview && (
        <StickyWrapper style={{ zIndex: 10 }}>
          {showTraitMenu && TraitMenu(setCurrentTrait, 'Asparagus')}
          <BabyPeekTraitNavigationWrapper
            $width={windowWidth}
            deviceType={deviceType}
          >
            <div />
            {!hideTraitMenu && (
              <BabyPeekButton
                variant="gray"
                content={<>{showTraitMenu ? ' Hide Traits' : 'View Traits'}</>}
                onClick={async () => {
                  setShowTraitMenu(!showTraitMenu);
                }}
              />
            )}
            <BabyPeekTraitNavigationButtonWrapper
              onClick={() => setCurrentTrait('Bitter')}
            >
              <TraitNavigationRight />
            </BabyPeekTraitNavigationButtonWrapper>
          </BabyPeekTraitNavigationWrapper>
        </StickyWrapper>
      )}
    </>
  );
};
