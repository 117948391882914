import React, { useEffect } from 'react';

import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { SchedulingAppointmentContainer } from './Scheduling.styles';

export const Scheduling = (): React.ReactElement => {
  const { physicalDevice } = useDeviceHook();
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://embed.acuityscheduling.com/js/embed.js';
    script.type = 'text/javascript';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <SchedulingAppointmentContainer deviceType={physicalDevice}>
      <iframe
        src="https://app.acuityscheduling.com/schedule.php?owner=17869846"
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
        data-testid="acuity-scheduling-appointment"
      />
    </SchedulingAppointmentContainer>
  );
};
