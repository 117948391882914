import React from 'react';

import {
  StyledInputWrapper,
  StyledInputInputTitle,
  StyledInputOptional,
  StyledSelectComponent,
  StyledInputRowWrapper,
} from './UI-Library.styles';

interface StyledSelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  fieldTitle: string;
  error?: string;
  optional?: boolean;
}

export const StyledUISelect: React.FC<StyledSelectProps> = ({
  fieldTitle,
  optional,
  error,
  ref,
  ...props
}) => (
  <StyledInputRowWrapper>
    <StyledInputWrapper>
      <StyledInputInputTitle>
        {`${fieldTitle} `}{' '}
        {optional && <StyledInputOptional>(optional)</StyledInputOptional>}
      </StyledInputInputTitle>
      <StyledSelectComponent {...props} error={error} />
    </StyledInputWrapper>
  </StyledInputRowWrapper>
);
