import styled, { DefaultTheme } from 'styled-components';

const getBorder = (isError: boolean, edit: boolean) => {
  if (isError) {
    return '1px solid #EDB193';
  }
  if (edit) {
    return '1px solid #096dd9';
  }
  return '1px solid #e7ebf2';
};

export const FieldContainer = styled.div<{ edit: boolean; $isError: boolean }>`
  padding: 5px 0px 5px 0px;
  width: 50%;

  input {
    border: none;
    width: 100% !important;
    border-bottom: ${({ $isError, edit }) => getBorder($isError, edit)};
    padding-bottom: 5px;
    color: ${({ theme }) => theme.black};
    align-self: center;
    font-size: 15px;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    color: ${({ $isError }) => ($isError ? '#EDB193' : '#000')};
  }
  input:disabled {
    background-color: white;
  }

  input[type='date'] {
    box-sizing: border-box;
    outline: 0;
    position: relative;
    width: 100%;
    color: ${({ $isError }) => ($isError ? '#EDB193' : '#000')};
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .MuiAutocomplete-root {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`;

const getFieldTitleColor = (
  theme: DefaultTheme,
  newRequisition?: boolean,
  isError?: boolean
) => {
  if (isError) {
    return theme.redDark;
  }
  if (newRequisition) {
    return theme.blueDark;
  }
  if (isError) {
    return '#5d5a5a';
  }
};

export const FieldTitle = styled.div<{
  $isError: boolean;
  newRequisition?: boolean;
}>`
  font-size: ${({ newRequisition }) => (newRequisition ? '12px' : '17px')};
  color: ${({ $isError, theme, newRequisition }) =>
    getFieldTitleColor(theme, newRequisition, $isError)};
  display: flex;
  align-items: center;
  grid-gap: 3px;
  font-family: ${({ newRequisition }) => newRequisition && 'light'};

  svg {
    width: 16px;
    margin-top: -5px;
  }
`;

export const RadioInputField = styled.div`
  display: flex;
  grid-gap: 5px;
`;

export const CheckboxInputField = styled.div`
  display: flex;
  grid-gap: 5px;
  input[type='checkbox'] {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const AutoCompleteTextfieldWrapper = styled.div`
  && {
    .MuiAutocomplete-input {
      border-bottom: none !important;
    }
  }
`;
