import React from 'react';
import { AccordionProps } from '../../components/shared/Accordion/Accordions';
import { CardProps } from '../../components/shared/Cards/Card';
import gc from '../../assets/images/geneticCounselor.png';
import { GetTypographyContent } from '../../components/shared/Typography/Typography';
import { HelpFAQAnswerWrapper } from './Help.styles';

const LINE_BREAK = <br />;

export const helpAccordions: AccordionProps[] = [
  {
    title: 'How can I get UNITY testing?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content:
            'UNITY is a physician-ordered test. Please call us or email us at ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
        {GetTypographyContent({
          content:
            ' so we can help you get the testing done through your OB or midwife.',
          size: 'medium',
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'howToGetTesting',
  },
  {
    title: 'Is UNITY covered by my insurance?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content: `We accept all insurances including Medicaid. Medical guidelines recommend every pregnant
           woman to be offered screening for the conditions covered on the UNITY Screen™; therefore, 
           most major private insurance plans and Medicaid plans cover the testing. Depending on 
           your insurance details, there may be a co-payment or deductibles you are responsible for.`,
          size: 'medium',
        })}

        {LINE_BREAK}
        {LINE_BREAK}
        {GetTypographyContent({
          content: `If you have any questions or concerns with testing cost, please email us at `,
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
        {GetTypographyContent({
          content: ' or call us at ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
      </HelpFAQAnswerWrapper>
    ),
    key: 'isCovered',
  },
  {
    title:
      'I have more questions. Can I talk to a genetic counselor before taking the test?',
    content: (
      <HelpFAQAnswerWrapper>
        {GetTypographyContent({
          content:
            'Yes, we provide pre-test and post-test genetic counseling at no charge. To schedule an appointment, please go to our ',
          size: 'medium',
        })}
        {GetTypographyContent({
          content: 'scheduling page,',
          size: 'medium',
          link: './client/scheduling',
          textColor: 'greenMedium',
        })}
        {GetTypographyContent({
          content: ' call us at, ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: '+1(650) 460-2551',
          size: 'medium',
          isPhone: true,
        })}
        {GetTypographyContent({
          content: ' or email us at ',
          size: 'medium',
          link: './client/scheduling',
        })}
        {GetTypographyContent({
          content: 'support@unityscreen.com',
          size: 'medium',
          isEmail: true,
        })}
      </HelpFAQAnswerWrapper>
    ),

    key: 'moreQuestions',
  },
];

export const helpLargeCard: CardProps = {
  buttons: [],
  component: 'img',
  titleTypography: {
    content: 'Schedule a complimentary genetic counseling session',
    isBold: true,
    isCaps: false,
    size: 'h2',
    padding: '15px 0px 18px 0',
  },
  bodyTypography: {
    content:
      'Our board certified and licensed genetic counselors are available to answer your questions and help you understand your results',
    isBold: false,
    isCaps: false,
    size: 'large',
    padding: '0px 0px 45px 0',
  },
  gridDimension: '4fr 6fr',
  src: gc,
  buttonSize: 'medium',
};
