import React from 'react';
import {
  CreateAccountDesktopContainer,
  CreateAccountFormDesktopColorbackground,
  CreateAccountFormImage,
  CreateAccountFormVector,
  CreateAccountMobileContainer,
} from '../CreateAccount/CreateAccountForm.styles';
import unityRound from '../../assets/logos/unityRound.svg';
import vector from '../../assets/icons/vector.svg';
import { VerifyEmailSendAgainSection } from './VerifyEmailSectionSendAgainSection';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';

export const VerifyEmailSendAgain = (): React.ReactElement => {
  const { isDesktop } = useDeviceHook();

  return (
    <>
      {isDesktop && (
        <CreateAccountDesktopContainer>
          <CreateAccountFormDesktopColorbackground>
            <CreateAccountFormVector src={vector} />
            <CreateAccountFormImage src={unityRound} width="321px" />
          </CreateAccountFormDesktopColorbackground>
          <VerifyEmailSendAgainSection />
        </CreateAccountDesktopContainer>
      )}
      {!isDesktop && 
        <CreateAccountMobileContainer>
          <VerifyEmailSendAgainSection />
        </CreateAccountMobileContainer>
      }
    </>
  );
};
